import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";

import {PAContext} from "../../Services/PAContext";

import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, ListItem,
    Snackbar, TextField
} from "@mui/material";
import PAEvent from "../../MasterEventObjects/PAEvent";
import PAAttendee from "../../MasterEventObjects/Attendee";
import usePA_GlobalStore from "../../Services/ZustandGlobalStore";
import PALocation from "../../MasterEventObjects/Location";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import {capitalizeFirstLetter} from "../../components/Helpers/TextStyling";
import BusinessExpenseAuthorizationDialog from "../../components/Dialogs/BusinessExpenseAuthorizationDialog";
import BusinessExpenseReport from "../../components/Dialogs/BusinessExpenseReport";
import SavePlanning from "../../components/Dialogs/SavePlanning";




function FeaturesList(props) {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray, setAccount } = useContext(PAContext);
    const { Account } = dataArray;

    const [currentFeature, setCurrentFeature] = useState(props.currentFeature);

    useEffect(() => {
        console.log("setCurrentFeature : "+setCurrentFeature);
        setCurrentFeature(props.currentFeature);
    }, [props.currentFeature]);

    const [needToSave, setNeedToSave] = useState(false);
    useEffect(() => {
        console.log("PA_GlobalObject.Planning : "+usePA_GlobalStore.getState().PA_GlobalObject.Planning.need_to_save);
        setNeedToSave(usePA_GlobalStore.getState().PA_GlobalObject.Planning.need_to_save);

        /*
            see if anything needs to change at the high level
            this may include activities, lodging, meals, flights, supplies, etc

         */


    }, [PA_GlobalObject.Planning]);

    const [ totalBudget, setTotalBudget ] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.budget_total);
    const [ totalBudgetActual, setTotalBudgetActual ] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.budget_actual);
    useEffect(() => {
        console.log("updated budget_actual or budget_total : "+usePA_GlobalStore.getState().PA_GlobalObject.Planning.budget_actual+" : "+usePA_GlobalStore.getState().PA_GlobalObject.Planning.budget_total);
//        setTotalBudget(props.eventBudgetTotal);
//        setTotalBudgetActual(props.eventBudgetActual);
    }, [PA_GlobalObject.Planning.budget_actual, PA_GlobalObject.Planning.budget_total]);

    const handleIconClick = (featureName) => {
        // Handle the click event here
        console.log(`Clicked on ${featureName}`);

        //decide whether to show the console or not
        if (featureName !== "Save" && featureName !== "Clear"){
            if (!props.showEventItemConfig){
                //if it's not showing then show it
                props.setShowEventItemConfig(true);
                setCurrentFeature(featureName);

            } else {
                //if it is showing then decide whether it is just changing features and needs to stay open
                // - or - if they are just meaning to close it
                if (featureName === currentFeature){
                    props.setShowEventItemConfig(false);
                    setCurrentFeature("Map");
                } else {
                    setCurrentFeature(featureName);
                }
            }
        }


        switch (featureName) {
            case "Details":
                navigate('/Planner/Details')
                break;
            case "Location":
                navigate('/Planner/Location')
                break;
            case "Dates":
                navigate('/Planner/Dates')
                break;
            case "Map":
                props.setShowEventItemConfig(false);
                navigate('/Planner/Map')
                break;
            case "Itinerary":
                navigate('/Planner/Itinerary')
                break;
            case "Documents":
                navigate('/Planner/Documents')
                break;
            case "Activities":
                navigate('/Planner/Activities')
                break;
            case "Ideas":
                navigate('/Planner/Ideas')
                break;
            case "Attendees":
                navigate('/Planner/Attendees')
                break;
            case "Lodging":
                navigate('/Planner/Lodging')
                break;
            case "Flights":
                navigate('/Planner/Flights')
                break;
            case "carRentals":
                navigate('/Planner/CarRentals')
                break;
            case "Cruises":
                navigate('/Planner/Cruises')
                break;
            case "Messages":
                navigate('/Planner/Messages')
                break;
            case "Meals":
                navigate('/Planner/Meals')
                break;
            case "Photos":
                navigate('/Planner/Photos')
                break;
            case "Supplies":
                navigate('/Planner/Supplies')
                break;
            case "Receipts":
                navigate('/Planner/Receipts')
                break;
            case "To-Do":
                navigate('/Planner/Todo')
                break;
            case "Weather":
                navigate('/Planner/Weather')
                break;
            case "Clear":
                clearPlanning();
                break;
            default:
        }

        props.setShowEventItemExtendedConfig(false);
    };

    const theme = createTheme({
        palette: {
            badge: {
                main: '#cbe0f7'
            },
        }
    });


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleSnackbarClose = () => setSnackbarOpen(false);
    const saveButtonRef = useRef(null);

    function clearPlanning(){
        //show the prompt
        setOpenDialogDelete(true);

    }

    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    function handleDialogDeleteClose() {
        setOpenDialogDelete(false);
    }
    function handleDialogDeleteOK(){
        setOpenDialogDelete(false);

        const _new_event = new PAEvent();
        _new_event.title = "My New Event";
        _new_event.status = 10;

        //see if they are logged in
        if (Object.keys(Account).length === 0){
            //create the default Planner Attendee
            console.log("Need to create the default Planner Attendee");
            const _planner = new PAAttendee(
                'Sample',
                'Planning',
                'sample.planning@example.com');
            _planner.id = "100000";
            _planner.role = 'planner';
            _planner.planner = true;
            _planner.nickname = 'Planner';
            _planner.isSample = true;
            _new_event.attendees.push(_planner);

            setAccount(_planner);
        } else {
            //they are logged in so use their account
            console.log("User is logged in");

            const _planner = new PAAttendee(
                Account.fname,
                Account.lname,
                Account.email);
            _planner.role = 'admin';
            _planner.planner = false;
            _planner.nickname = Account.nickname;

            const _att_location = new PALocation();
            _att_location.address = Account.address;
            _att_location.city = Account.city;
            _att_location.state = Account.state;
            _att_location.zip = Account.zip;
            _att_location.country = Account.country;
            _planner.location = _att_location;

            _new_event.attendees.push(_planner);

        }

        set_PA_GlobalObject({ Planning: _new_event });

//        props.setClearEvent(true);

    }

    const [isBusinessExpenseReportOpen, setIsBusinessExpenseReportOpen] = useState(false);
    const [isExpenseAuthDialogOpen, setIsExpenseAuthDialogOpen] = useState(false);
    const [isSavePlanningDialogOpen, setIsSavePlanningDialogOpen] = useState(false);

    const [isExpenseReportOpen, setIsExpenseReportOpen] = useState(false);
    const [expenseReport, setExpenseReport] = useState([]);
    const [email, setEmail] = useState("");

    const attendeesWithExpenses = PA_GlobalObject.Planning.attendees.filter((attendee) =>
        [PA_GlobalObject.Planning.lodging, PA_GlobalObject.Planning.meals, PA_GlobalObject.Planning.activities, PA_GlobalObject.Planning.flights].some((category) =>
            category.some((item) =>
                item.attendees?.some((att) => att.id === attendee.id && att.is_business_expense)
            )
        )
    );

    const formatDate = (timestamp) => (timestamp ? dayjs(timestamp).format("MMM D, YYYY") : "N/A");


    useEffect(() => {
        generateExpenseReport();
    }, [
        PA_GlobalObject.Planning.attendees,
        PA_GlobalObject.Planning.lodging,
        PA_GlobalObject.Planning.meals,
        PA_GlobalObject.Planning.activities]);

    // Function to generate the report data
    const generateExpenseReport = () => {
        const report = [];

        PA_GlobalObject.Planning.attendees.forEach(attendee => {
            const attendeeExpenses = {
                name: `${attendee.fname} ${attendee.lname}`,
                lodging: PA_GlobalObject.Planning.lodging
                    .filter(item => item.attendees.some(a => a.id === attendee.id && a.is_business_expense))
                    .reduce((sum, item) => sum + (item.attendees.find(a => a.id === attendee.id)?.business_expense_amount || 0), 0),
                meals: PA_GlobalObject.Planning.meals
                    .filter(item => item.attendees.some(a => a.id === attendee.id && a.is_business_expense))
                    .reduce((sum, item) => sum + (item.attendees.find(a => a.id === attendee.id)?.business_expense_amount || 0), 0),
                activities: PA_GlobalObject.Planning.activities
                    .filter(item => item.attendees.some(a => a.id === attendee.id && a.is_business_expense))
                    .reduce((sum, item) => sum + (item.attendees.find(a => a.id === attendee.id)?.business_expense_amount || 0), 0),
                total: 0
            };
            attendeeExpenses.total = attendeeExpenses.lodging + attendeeExpenses.meals + attendeeExpenses.activities;
            report.push(attendeeExpenses);
        });

        console.log("generateExpenseReport : ", report);

        setExpenseReport(report);
    };

    // Function to handle sending report via email
    const handleSendReport = () => {
        if (!email) {
            alert("Please enter an email address.");
            return;
        }

        const reportData = expenseReport.map(attendee => ({
            Name: attendee.name,
            Lodging: `$${attendee.lodging.toFixed(2)}`,
            Meals: `$${attendee.meals.toFixed(2)}`,
            Activities: `$${attendee.activities.toFixed(2)}`,
            Total: `$${attendee.total.toFixed(2)}`
        }));

        console.log("Sending report to:", email, reportData);

        // TODO: Implement actual email sending logic
        alert(`Report sent to ${email}`);
    };

    // Function to handle exporting report as CSV
    const handleExportCSV = () => {
        let csvContent = "Name,Lodging,Meals,Activities,Total\n";
        expenseReport.forEach(attendee => {
            csvContent += `${attendee.name},${attendee.lodging.toFixed(2)},${attendee.meals.toFixed(2)},${attendee.activities.toFixed(2)},${attendee.total.toFixed(2)}\n`;
        });

        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Business_Expense_Report.csv";
        a.click();
    };

    function onExpenseReportClose() {
        setIsExpenseReportOpen(false);
    }

    const [expandedItems, setExpandedItems] = useState({});

    // Function to toggle expansion
    const toggleExpand = (attendeeId, itemId) => {
        const key = `${attendeeId}-${itemId}`; // Create a unique key
        setExpandedItems((prev) => ({
            ...prev,
            [key]: !prev[key], // Toggle only for this attendee's specific item
        }));
    };




    return (

        <ThemeProvider theme={theme}>

            {/* add the Details icon */}
            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Details"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Details' ? '#fef4ec' : '',
                        border: currentFeature === 'Details' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Details")}
                >
                    {<i className="icon-settings-icons"/>}
                </IconButton>
                {
                    Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0
                    && usePA_GlobalStore.getState().PA_GlobalObject.Planning.title?.length > 3 ? (
                        <i className="icon-check"
                           style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'green'}}/>
                    ) : (
                        <i className="icon-warning"
                           style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'red'}}/>
                    )
                }
            </div>

            {/* add the Budget settings */}
            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Business Authorizations"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Business' ? '#fef4ec' : '',
                        border: currentFeature === 'Budget' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => setIsExpenseAuthDialogOpen(!isExpenseAuthDialogOpen)}
                >
                    {<i className="icon-wallet"/>}
                </IconButton>
                {/* add the green check or the warning sign */}
                {
                    parseFloat(props.eventBudgetActual) > parseFloat(props.eventBudgetTotal) ? (
                        <i className="icon-warning"
                           style={{
                               position: 'absolute',
                               top: 10,
                               right: 5,
                               fontSize: '11px',
                               color: 'red'
                           }}
                        />
                    ) : (
                        <i className="icon-check"
                           style={{
                               position: 'absolute',
                               top: 10,
                               right: 5,
                               fontSize: '11px',
                               color: 'green'
                           }}
                        />
                    )
                }
            </div>

            {/* add the Budget icon */}
            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Budget"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Budget' ? '#fef4ec' : '',
                        border: currentFeature === 'Budget' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => setIsBusinessExpenseReportOpen(!isBusinessExpenseReportOpen)}
                >
                    {<i className="icon-wallet"/>}
                </IconButton>
                {/* add the green check or the warning sign */}
                {
                    parseFloat(props.eventBudgetActual) > parseFloat(props.eventBudgetTotal) ? (
                        <i className="icon-warning"
                           style={{
                               position: 'absolute',
                               top: 10,
                               right: 5,
                               fontSize: '11px',
                               color: 'red'
                           }}
                        />
                    ) : (
                        <i className="icon-check"
                           style={{
                               position: 'absolute',
                               top: 10,
                               right: 5,
                               fontSize: '11px',
                               color: 'green'
                           }}
                        />
                    )
                }
            </div>

            {/* add the Dates icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <IconButton
                    title="Dates"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Dates' ? '#fef4ec' : '',
                        border: currentFeature === 'Dates' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Dates")}
                >
                    {<i className="icon-calendar3"/>}
                </IconButton>
                {Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0
                    && usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates.length > 0 ? (
                    <i className="icon-check"
                       style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'green'}}/>
                ) : (
                    <i className="icon-warning"
                       style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'red'}}/>
                )}
            </div>

            {/* add the Map icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <IconButton
                    title="Map"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Map' ? '#fef4ec' : '',
                        border: currentFeature === 'Map' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Map")}
                >
                    <i className="icon-map"/>
                </IconButton>
            </div>

            {/* add the Itinerary icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <IconButton
                    title="Itinerary"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Itinerary' ? '#fef4ec' : '',
                        color: '#f09637',
                        border: currentFeature === 'Itinerary' ? '1px solid #fccca5' : '0',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Itinerary")}
                >
                    {<i className="icon-calendar"/>}
                </IconButton>
            </div>

            {/* add the Documents icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.documents?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Documents"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Documents' ? '#fef4ec' : '',
                            border: currentFeature === 'Documents' ? '1px solid #fccca5' : '0',
                            color: '#F00',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Documents")}
                    >
                        {<i className="icon-file-check"/>}
                    </IconButton>
                </Badge>
            </div>

            {/* add the Activities icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities?.length
                        ) : (
                            0
                        )
                    }
                    color="badge"
                >
                    <IconButton
                        title="Activities"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Activities' ? '#fef4ec' : '',
                            border: currentFeature === 'Activities' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Activities")}
                    >
                        {<i className="icon-list-heart"/>}
                    </IconButton>
                </Badge>
            </div>

            {/* add the Ideas icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.ideas?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Ideas"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Ideas' ? '#fef4ec' : '',
                            border: currentFeature === 'Ideas' ? '1px solid #fccca5' : '0',
                            color: '#F00',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Ideas")}
                    >
                        {
                            <i className="icon-lamp-bright"/>
                        }
                    </IconButton>
                </Badge>
            </div>

            {/* add the Attendees icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0
                            ? usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees === undefined
                                ? 0
                                : usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.filter(
                                    (attendee) => attendee.status !== 4 && attendee.planner !== true
                                ).length
                            : 0
                    }
                    color="badge"
                >
                    <IconButton
                        title="Attendees"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Attendees' ? '#fef4ec' : '',
                            border: currentFeature === 'Attendees' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Attendees")}
                    >
                        {<i className="icon-group"/>}
                    </IconButton>
                </Badge>
            </div>

            {/* add the Lodging icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Lodging"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Lodging' ? '#fef4ec' : '',
                            border: currentFeature === 'Lodging' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Lodging")}
                    >
                        {<i className="icon-bed"/>}
                    </IconButton>
                </Badge>
            </div>

            {/* add the Flights icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.flights?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Flights"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Flights' ? '#fef4ec' : '',
                            border: currentFeature === 'Flights' ? '1px solid #fccca5' : '0',
                            color: '#F00',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Flights")}
                    >
                        {<i className="icon-airplane"/>}
                    </IconButton>
                </Badge>
            </div>

            {/* add the Car Rentals icon */}
            {/*
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.car_rentals.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Car Rentals"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'carRentals' ? '#fef4ec' : '',
                            border: currentFeature === 'carRentals' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("carRentals")}
                    >
                        {<i className="icon-car2"/>}
                    </IconButton>
                </Badge>
            </div>
            */}

            {/* add the Cruises icon */}
            {/*
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.cruises.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Cruises"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'cruises' ? '#fef4ec' : '',
                            border: currentFeature === 'cruises' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("cruises")}
                    >
                        {<i className="icon-ship"/>}
                    </IconButton>
                </Badge>
            </div>
            */}

            {/* add the Messages icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.messages?.length
                        ) : (
                            0
                        )
                    }
                    color="badge"
                    max={Infinity}
                    sx={{
                        '& .MuiBadge-badge': {
                            minWidth: 'auto', // Adjust the minimum width
                            height: 'auto', // Adjust the height
                            padding: '0 6px', // Adjust padding for better fit
                            fontSize: '0.75rem', // Adjust the font size
                            lineHeight: '1', // Ensure line height doesn't cause overflow
                        }
                    }}
                >
                    <IconButton
                        title="Messaging"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Messages' ? '#fef4ec' : '',
                            border: currentFeature === 'Messages' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Messages")}
                    >
                        {<i className="icon-smartphone-text"/>}
                    </IconButton>
                </Badge>
            </div>

            {/* add the Meals icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Meals"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Meals' ? '#fef4ec' : '',
                            border: currentFeature === 'Meals' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Meals")}
                    >
                        {<i className="icon-fork-knife2"/>}
                    </IconButton>
                </Badge>
            </div>

            {/* add the Photos icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.photos?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Photos"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Photos' ? '#fef4ec' : '',
                            border: currentFeature === 'Photos' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Photos")}
                    >
                        {<i className="icon-picture2"/>}
                    </IconButton>
                </Badge>
            </div>

            {/* add the Supplies icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.supplies?.length
                        ) : (
                            0
                        )
                    }
                    color="badge"
                >
                    <IconButton
                        title="Supplies"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Supplies' ? '#fef4ec' : '',
                            border: currentFeature === 'Supplies' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Supplies")}
                    >
                        {<i className="icon-checklist2"/>}
                    </IconButton>
                </Badge>
            </div>

            {/* add the Receipts icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.receipts?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Receipts"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Receipts' ? '#fef4ec' : '',
                            border: currentFeature === 'Receipts' ? '1px solid #fccca5' : '0',
                            color: '#F00',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Receipts")}
                    >
                        {<i className="icon-receipt"/>}
                    </IconButton>
                </Badge>
            </div>

            {/* add the ToDo icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.to_do?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="To-Do"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'To-Do' ? '#fef4ec' : '',
                            border: currentFeature === 'To-Do' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("To-Do")}
                    >
                        {<i className="icon-checklist-time"/>}
                    </IconButton>
                </Badge>
            </div>

            {/* add the Weather icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <IconButton
                    title="Weather"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Weather' ? '#fef4ec' : '',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Weather")}
                >
                    <i className="icon-sun-wind"/>
                </IconButton>
                {
                    props.weatherReportReceived ? (
                        <i className="icon-check"
                           style={{position: 'absolute', top: 5, right: 5, fontSize: '11px', color: 'green'}}/>
                    ) : (
                        <></>
                    )
                }
            </div>

            {/* add the Save icon */}
            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Save to Database"
                    ref={saveButtonRef}
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Save' ? '#fef4ec' : '',
                        border: currentFeature === 'Save' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        setIsSavePlanningDialogOpen(!isSavePlanningDialogOpen);
                        setSnackbarOpen(false); // Close Snackbar when clicked
                    }}
                >
                    {<i className="icon-floppy-disk"/>}
                </IconButton>
                {needToSave && (
                    <i
                        className="icon-warning"
                        style={{ position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'red' }}
                    />
                )}
                <Snackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message="When you're ready to SAVE this, click here"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    style={{
                        position: 'absolute',
                        left: saveButtonRef.current ? saveButtonRef.current.offsetLeft - 150 : 0, // Offset near the button
                        top: saveButtonRef.current ? saveButtonRef.current.offsetTop - 30 : 0,
                        zIndex: 1000
                    }}
                >
                    <Alert onClose={() => setSnackbarOpen(false)} severity="warning" sx={{width: '100%'}}>
                        When you're ready to SAVE this, click here
                    </Alert>
                </Snackbar>
            </div>

            {/* add the Clear icon */}
            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Clear"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Clear' ? '#fef4ec' : '',
                        border: currentFeature === 'Clear' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Clear")}
                >
                    {<i className="icon-trash"/>}
                </IconButton>
            </div>


            <Dialog
                open={openDialogDelete}
                onClose={handleDialogDeleteClose}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This will close out this Planning session for <strong>{PA_GlobalObject.Planning.title}</strong> and create a new blank Template.
                        Any unsaved changes will be lost. Ok to Proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDeleteClose}>Cancel</Button>
                    <Button onClick={handleDialogDeleteOK} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Business Expense Authorization Dialog */}
            <BusinessExpenseAuthorizationDialog
                isOpen={isExpenseAuthDialogOpen}
                onClose={() => setIsExpenseAuthDialogOpen(false)}
            />

            {/* Business Expense Report Dialog */}
            <BusinessExpenseReport
                isOpen={isBusinessExpenseReportOpen}
                onClose={() => setIsBusinessExpenseReportOpen(false)}
            />


            <Dialog open={isExpenseReportOpen} onClose={onExpenseReportClose} fullWidth maxWidth="md">
                <DialogTitle>Business Expense Report</DialogTitle>
                <DialogContent>
                    {attendeesWithExpenses.length === 0 ? (
                        <Typography>No business expenses found.</Typography>
                    ) : (
                        attendeesWithExpenses.map((attendee) => {
                            const attendeeExpenses = {
                                lodging: PA_GlobalObject.Planning.lodging.filter((lodgingItem) =>
                                    lodgingItem.attendees?.some(
                                        (att) => att.id === attendee.id && att.is_business_expense
                                    )
                                ),
                                meals: PA_GlobalObject.Planning.meals.filter((mealItem) =>
                                    mealItem.attendees?.some(
                                        (att) => att.id === attendee.id && att.is_business_expense
                                    )
                                ),
                                activities: PA_GlobalObject.Planning.activities.filter((activityItem) =>
                                    activityItem.attendees?.some(
                                        (att) => att.id === attendee.id && att.is_business_expense
                                    )
                                ),
                                flights: PA_GlobalObject.Planning.flights.filter((flightItem) =>
                                    flightItem.attendees?.some(
                                        (att) => att.id === attendee.id && att.is_business_expense
                                    )
                                ),
                            };

                            // Calculate the total for the attendee grouped by currency
                            const totalExpensesByCurrency = {};
                            Object.values(attendeeExpenses).forEach((category) => {
                                category.forEach((item) => {
                                    const expense = item.attendees.find((att) => att.id === attendee.id);
                                    const currency = item.currency || "USD"; // Default to USD if no currency
                                    const expenseAmount = parseFloat(expense.business_expense_amount) || 0; // Ensure numeric conversion

                                    if (!totalExpensesByCurrency[currency]) {
                                        totalExpensesByCurrency[currency] = 0;
                                    }

                                    totalExpensesByCurrency[currency] += expenseAmount; // Correct numeric summation
                                });
                            });

                            return (
                                <div key={attendee.id} style={{ marginBottom: "20px" }}>
                                    {/* Attendee Name & Total grouped by currency */}
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography variant="h6">
                                            {capitalizeFirstLetter(attendee.fname)} {capitalizeFirstLetter(attendee.lname)}
                                        </Typography>
                                        <Box textAlign="right">
                                            {Object.entries(totalExpensesByCurrency).map(([currency, total]) => (
                                                <Typography key={currency} variant="h6" color="primary">
                                                    {total.toFixed(2)} {currency}
                                                </Typography>
                                            ))}
                                        </Box>
                                    </Box>
                                    <Divider style={{ marginBottom: "10px" }} />

                                    {Object.entries(attendeeExpenses).map(([category, items]) => {
                                        if (items.length === 0) return null;

                                        // Calculate totals per currency for this category
                                        const categoryTotalsByCurrency = {};
                                        items.forEach((item) => {
                                            const expense = item.attendees.find((att) => att.id === attendee.id);
                                            const currency = item.currency || "USD";
                                            const expenseAmount = parseFloat(expense.business_expense_amount) || 0; // Ensure numeric conversion

                                            if (!categoryTotalsByCurrency[currency]) {
                                                categoryTotalsByCurrency[currency] = 0;
                                            }
                                            categoryTotalsByCurrency[currency] += expenseAmount;
                                        });

                                        return (
                                            <div key={category} style={{ marginBottom: "15px" }}>
                                                {/* Category Name & Total grouped by currency */}
                                                {Object.entries(categoryTotalsByCurrency).map(([currency, total]) => (
                                                    <Box key={currency} display="flex" justifyContent="space-between" fontWeight="bold">
                                                        <Typography variant="subtitle1" color="primary">
                                                            {category.charAt(0).toUpperCase() + category.slice(1)}
                                                        </Typography>
                                                        <Typography variant="subtitle1" color="primary">
                                                            {Number(total || 0).toFixed(2)} {currency}
                                                        </Typography>
                                                    </Box>
                                                ))}

                                                {/* List of Expenses (Light Gray) */}
                                                <List dense style={{ backgroundColor: "#f8f8f8", padding: "5px 10px", borderRadius: "5px" }}>
                                                    {items.map((item) => {
                                                        const expense = item.attendees.find((att) => att.id === attendee.id);
                                                        if (!expense || !expense.is_business_expense) return null; // Skip items without business expenses

                                                        const uniqueKey = `${attendee.id}-${item.id}`; // Unique key per attendee-item pair
                                                        const isExpanded = expandedItems[uniqueKey] || false;

                                                        return (
                                                            <ListItem key={uniqueKey} alignItems="flex-start">
                                                                {/* Primary Section with Toggle Button */}
                                                                <ListItemText
                                                                    primary={
                                                                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                                                            <Typography variant="body2">{item.name || item.title}</Typography>
                                                                            <Box display="flex" alignItems="center">
                                                                                <Typography variant="body2">
                                                                                    <strong>{Number(expense?.business_expense_amount || 0).toFixed(2)} {item.currency || "USD"}</strong>
                                                                                </Typography>
                                                                                <IconButton size="small" onClick={() => toggleExpand(attendee.id, item.id)} sx={{ marginLeft: "8px" }}>
                                                                                    {isExpanded ?
                                                                                        <i className="icon-chevron-up"/> :
                                                                                        <i className="icon-chevron-down"/>}
                                                                                </IconButton>
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                    secondary={isExpanded && (
                                                                        <Box textAlign="right" sx={{ marginTop: "8px", width: "100%" }}>
                                                                            <Typography variant="body2" color="textPrimary" fontWeight="bold">
                                                                                Auth & Payment
                                                                            </Typography>
                                                                            <Box display="flex" flexDirection="column" alignItems="flex-end" gap={0.5}>
                                                                                <Typography variant="body2" color="textSecondary">
                                                                                    Authorized: {Number(expense.business_expense_amount_authorized || 0).toFixed(2)} {item.currency || "USD"}
                                                                                </Typography>
                                                                                <Typography variant="body2" color="textSecondary">
                                                                                    Reimbursed: {Number(expense.paid || 0).toFixed(2)} {item.currency || "USD"}
                                                                                </Typography>
                                                                                <Typography variant="body2" color="textSecondary">
                                                                                    Authorized By: {expense.business_expense_amount_authorized_by || "N/A"}
                                                                                </Typography>
                                                                                <Typography variant="body2" color="textSecondary">
                                                                                    Reference ID: {expense.business_expense_reference_id || "N/A"}
                                                                                </Typography>
                                                                                <Typography variant="body2" color="textSecondary">
                                                                                    Authorization Date: {formatDate(expense.business_expense_amount_authorized_timestamp)}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    )}
                                                                />
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                            </div>
                                        );
                                    })}

                                    {/* Email & Submit Section */}
                                    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2} gap={1}>
                                        <TextField
                                            label="Recipient Email"
                                            variant="outlined"
                                            type="email"
                                            size="small"
                                            sx={{ minWidth: "250px" }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => alert(`Expense report sent for ${attendee.fname} ${attendee.lname}`)}
                                        >
                                            Submit
                                        </Button>
                                    </Box>

                                    <Divider />
                                </div>
                            );
                        })
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onExpenseReportClose} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <SavePlanning
                isOpen={isSavePlanningDialogOpen}
                onClose={() => setIsSavePlanningDialogOpen(false)}
            />

        </ThemeProvider>

    )
}

export default FeaturesList;
