import {openDb} from './BrowserDatabaseIDB';
import {convertEventVersion1To2} from "../components/Helpers/EventConverter";


export function addKeyValue(_key, _value){
    console.log("addKeyValue: key : "+_key+" : value : "+JSON.stringify(_value));

    return openDb('pa', 1, function(upgradeDb) {

        console.log('making a new object store');
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data');
        }
        if (!upgradeDb.objectStoreNames.contains('pa_photos')) {
            upgradeDb.createObjectStore('pa_photos');
        }

    }).then(function (db){

        const tx = db.transaction('pa_data', 'readwrite');
        const store = tx.objectStore('pa_data');

        store.put(_value, _key);
        return tx.complete;

    }).then(function() {
        console.log('added item to the store!');
        return true;
    }).catch(function(error) {
        console.error('Failed to add item to the store:', error);
        return false;
    });
}

export function getAllEventsFromDatabase(_user_id) {
    console.log('getAllEventsFromDatabase : ' + _user_id);

    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data');
            upgradeDb.createObjectStore('pa_photos');
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readonly');
        const store = tx.objectStore('pa_data');

        return store.get('Account').then(_account => {
            return [_account, store.get('Events')];
        });
    }).then(function ([_account, _events]) {
        // Check if _account exists and its id matches _user_id
        if (_account && _account.id === _user_id) {
            console.log('_account : ' + _account);
            console.log('Events found:', _events);
            return _events;
        } else {
            console.log('Account not found or id does not match.');
            return null;
        }
    }).catch(function (error) {
        console.error("An error occurred:", error);
        throw error; // Re-throw the error to be caught by the caller
    });
}
export function getEventFromDatabase(eventId) {
    console.log('getEventFromDatabase : '+eventId);

    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data');
            upgradeDb.createObjectStore('pa_photos');
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readonly');
        const store = tx.objectStore('pa_data');

        return store.get('Events');
    }).then(function (_events) {
        // Check if _account exists and its id matches _user_id
        if (_events) {
            const event = _events.find(evt => evt.id === eventId);
            if (event) {
                console.log('Event found:', event);
                return event;
            } else {
                console.log('Event not found with ID:', eventId);
                return null;
            }
        } else {
            console.log('No events found.');
            return null;
        }
    }).catch(function (error) {
        console.error("An error occurred:", error);
        throw error; // Re-throw the error to be caught by the caller
    });
}
export function browserStorageAddEvent(newEvent) {
    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data');
            upgradeDb.createObjectStore('pa_photos');
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite'); // Use 'readwrite' mode for updates
        const store = tx.objectStore('pa_data');

        // Retrieve existing events
        return store.get("Events").then(function (existingEvents) {
            const events = existingEvents || []; // Ensure we have an array

            // Check if an event with the same ID already exists
            const eventExists = events.some(event => event.id === newEvent.id);

            if (eventExists) {
                console.log("Event with the same ID already exists. Skipping addition.");
                return true; // Return the existing events unchanged
            }

            // Add the new event to the array
            events.push(newEvent);

            // Store the updated array back into the object store
            return store.put(events, "Events").then(function () {
                console.log("New event added successfully.");
                return true; // Return the updated events array
            });
        });
    }).catch(function (error) {
        console.error("An error occurred:", error);
        throw error; // Re-throw the error to be caught by the caller
    });
}
export function browserStorageUpdateAllEvents(_family_events) {
    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data');
            upgradeDb.createObjectStore('pa_photos');
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite'); // Use 'readwrite' mode for updates
        const store = tx.objectStore('pa_data');

        // Store the updated _family_events array directly into the object store
        return store.put(_family_events, "Events").then(function () {
            console.log("Events collection updated successfully.");
            return _family_events;
        });
    }).catch(function (error) {
        console.error("An error occurred:", error);
        throw error; // Re-throw the error to be caught by the caller
    });
}
export function browserStorageUpdateSpecificEvent(_event) {
    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data', { keyPath: 'id' }); // Assuming each event has a unique 'id' property
        }
        if (!upgradeDb.objectStoreNames.contains('pa_photos')) {
            upgradeDb.createObjectStore('pa_photos', { keyPath: 'id' });
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const dataStore = tx.objectStore('pa_data');

        // Get the events array by key "Events"
        return dataStore.get('Events').then(eventsArray => {
            if (!eventsArray) {
                eventsArray = [];
            }

            // Find the index of the event if it exists
            const eventIndex = eventsArray.findIndex(event => event.id === _event.id);

            console.log("eventIndex : "+eventIndex);
            console.log("the updated event : ",_event);

            const getConvertedEvent = (event) => {
                return event.title ? event : convertEventVersion1To2(event);
            };

            if (eventIndex !== -1) {
                // Update the existing event
                eventsArray[eventIndex] = getConvertedEvent(_event);
            } else {
                // Add the new event
                eventsArray.push(getConvertedEvent(_event));
            }

            console.log("eventsArray : ", eventsArray);

            const sanitizedEventsArray = JSON.parse(JSON.stringify(eventsArray));

            // Put the updated events array back into the object store
            return dataStore.put(sanitizedEventsArray, 'Events').then(() => {
                console.log(`Event with ID ${_event.id} added/updated successfully.`);
                return true;
            });
        });
    }).catch(function (error) {
        console.error("An error occurred:", error);
        throw error; // Re-throw the error to be caught by the caller
    });
}
export function browserStorageUpdateSpecificEventField(_event_id, _field_name, _field_value) {
    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data', { keyPath: 'id' }); // Assuming each event has a unique 'id' property
        }
        if (!upgradeDb.objectStoreNames.contains('pa_photos')) {
            upgradeDb.createObjectStore('pa_photos', { keyPath: 'id' });
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const dataStore = tx.objectStore('pa_data');

        // Get the events array by key "Events"
        return dataStore.get('Events').then(eventsArray => {
            if (!eventsArray) {
                eventsArray = [];
            }

            // Find the index of the event if it exists
            const eventIndex = eventsArray.findIndex(event => event.id === _event_id);
            console.log("eventIndex : "+eventIndex);

            eventsArray[eventIndex][_field_name] = _field_value;

            console.log("eventsArray : ", eventsArray);

            const sanitizedEventsArray = JSON.parse(JSON.stringify(eventsArray));

            // Put the updated events array back into the object store
            return dataStore.put(sanitizedEventsArray, 'Events').then(() => {
                console.log(`Event with ID ${_event_id} added/updated successfully.`);
                return true;
            });
        });
    }).catch(function (error) {
        console.error("An error occurred:", error);
        throw error; // Re-throw the error to be caught by the caller
    });
}
export function browserStorageReplacePlanningWithEvent(newly_created_event) {
    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data', { keyPath: 'id' }); // Assuming each event has a unique 'id' property
        }
        if (!upgradeDb.objectStoreNames.contains('pa_photos')) {
            upgradeDb.createObjectStore('pa_photos', { keyPath: 'id' });
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const dataStore = tx.objectStore('pa_data');

        // Get the events array by key "Events"
        return dataStore.get('Events').then(eventsArray => {
            if (!eventsArray) {
                eventsArray = [];
            }

            // Find the index of the event if it exists
            const eventIndex = eventsArray.findIndex(event => event.temp_id === newly_created_event.temp_id);

            console.log("eventIndex : "+eventIndex);

            if (eventIndex !== -1) {
                // Update the existing event
                eventsArray[eventIndex] = newly_created_event;
            } else {
                // Add the new event
                eventsArray.push(newly_created_event);
            }

            console.log("eventsArray : ", eventsArray);

            const sanitizedEventsArray = JSON.parse(JSON.stringify(eventsArray));

            // Put the updated events array back into the object store
            return dataStore.put(sanitizedEventsArray, 'Events').then(() => {
                console.log(`Event with ID ${newly_created_event.id} added/updated successfully.`);
                return true;
            });
        });
    }).catch(function (error) {
        console.error("An error occurred:", error);
        throw error; // Re-throw the error to be caught by the caller
    });
}
export function removeEventsFromDatabase() {
    return openDb('pa', 1, function(upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data');
            upgradeDb.createObjectStore('pa_photos');
        }
    }).then(function(db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const store = tx.objectStore('pa_data');

        // Delete the key/value pair for 'Events'
        return store.delete('Events');
    }).then(function() {
        console.log('Events removed from pa_data store.');
    }).catch(function(error) {
        console.error('An error occurred while removing events:', error);
        throw error; // Re-throw the error to be caught by the caller
    });
}

export function getAllTemplatesFromDatabase() {
    console.log('Fetching all templates from pa_data');

    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data');
            upgradeDb.createObjectStore('pa_photos');
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readonly');
        const store = tx.objectStore('pa_data');

        return store.get('Templates');
    }).then(templates => {
        console.log('Templates found:', templates);
        return templates || []; // Return an empty array if no templates exist
    }).catch(error => {
        console.error("An error occurred while fetching templates:", error);
        throw error;
    });
}
export function getTemplateFromDatabase(templateId) {
    console.log('Fetching template:', templateId);

    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data');
            upgradeDb.createObjectStore('pa_photos');
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readonly');
        const store = tx.objectStore('pa_data');

        return store.get('Templates');
    }).then(templates => {
        if (templates) {
            const template = templates.find(tmpl => tmpl.template_id === templateId);
            if (template) {
                console.log('Template found:', template);
                return template;
            } else {
                console.log('Template not found with ID:', templateId);
                return null;
            }
        } else {
            console.log('No templates found.');
            return null;
        }
    }).catch(error => {
        console.error("An error occurred while fetching the template:", error);
        throw error;
    });
}
export function browserStorageAddTemplate(newTemplate) {
    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data');
            upgradeDb.createObjectStore('pa_photos');
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const store = tx.objectStore('pa_data');

        return store.get('Templates').then(existingTemplates => {
            console.log("existingTemplates : ", existingTemplates);

            const templates = existingTemplates || []; // Ensure we have an array

            // Check if a template with the same ID already exists
            const templateExists = templates.some(template => template.id === newTemplate.id);

            if (templateExists) {
                console.log("Template with the same ID already exists. Skipping addition.");
                return templates; // Return the existing templates unchanged
            }

            // Add the new template to the array
            templates.push(JSON.parse(JSON.stringify(newTemplate)));

            // Store the updated array back into pa_data
            return store.put(templates, "Templates").then(() => {
                console.log("New template added successfully.");
                return templates;
            });
        });
    }).catch(error => {
        console.error("An error occurred while adding a template:", error);
        throw error;
    });
}
export function browserStorageUpdateTemplate(updatedTemplate) {

    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data');
            upgradeDb.createObjectStore('pa_photos');
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const store = tx.objectStore('pa_data');

        return store.get('Templates').then(templatesArray => {
            if (!templatesArray) {
                templatesArray = [];
            }
            console.log("templatesArray : ",templatesArray);

            // Find the index of the template if it exists
            const templateIndex = templatesArray.findIndex(template => template.template_id === updatedTemplate.template_id);
            console.log("templateIndex : ",templateIndex);

            if (templateIndex !== -1) {
                // Update the existing template
                templatesArray[templateIndex] = JSON.parse(JSON.stringify(updatedTemplate));
            } else {
                // Add the new template
                templatesArray.push(JSON.parse(JSON.stringify(updatedTemplate)));
            }

            console.log("templatesArray after: ",templatesArray);

            // Put the updated templates array back into pa_data
            return store.put(templatesArray, 'Templates').then(() => {
                console.log(`Template with ID ${updatedTemplate.template_id} added/updated successfully.`);
                return true;
            });
        });
    }).catch(error => {
        console.error("An error occurred while updating the template:", error);
        throw error;
    });
}
export function browserStorageUpdateTemplates(changesArray) {
    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data');
            upgradeDb.createObjectStore('pa_photos');
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const store = tx.objectStore('pa_data');

        return store.get('Templates').then(templatesArray => {
            if (!templatesArray) {
                templatesArray = [];
            }

            console.log("Existing Templates:", templatesArray);
            console.log("Received Changes:", changesArray);

            // Convert templatesArray to a Map for quick lookup
            let templatesMap = new Map(templatesArray.map(template => [template.template_id, template]));

            // Process each change
            changesArray.forEach(change => {
                const { template_id, change_type, data } = change;

                if (change_type === "new" || change_type === "updated") {
                    // Add or update the template
                    templatesMap.set(template_id, JSON.parse(JSON.stringify(data)));
                } else if (change_type === "deleted") {
                    // Remove the template if it exists
                    templatesMap.delete(template_id);
                }
            });

            // Convert back to an array
            const updatedTemplatesArray = Array.from(templatesMap.values());

            console.log("Updated Templates Array:", updatedTemplatesArray);

            // Store the updated templates in IndexedDB
            return store.put(updatedTemplatesArray, 'Templates').then(() => {
                console.log("Templates successfully updated in IndexedDB.");
                return true;
            });
        });
    }).catch(error => {
        console.error("An error occurred while updating templates:", error);
        throw error;
    });
}
export function removeTemplateFromDatabase(templateId) {
    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data');
            upgradeDb.createObjectStore('pa_photos');
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const store = tx.objectStore('pa_data');

        return store.get('Templates').then(templates => {
            if (!templates) return false;

            // Filter out the template with the matching ID
            const updatedTemplates = templates.filter(tmpl => tmpl.template_id !== templateId);

            // Store the updated templates array back into pa_data
            return store.put(updatedTemplates, 'Templates').then(() => {
                console.log(`Template with ID ${templateId} removed successfully.`);
                return true;
            });
        });
    }).catch(error => {
        console.error("An error occurred while removing the template:", error);
        throw error;
    });
}


export function addEventActivity(_event_id, _activity){

    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data', { keyPath: 'id' }); // Assuming each event has a unique 'id' property
        }
        if (!upgradeDb.objectStoreNames.contains('pa_photos')) {
            upgradeDb.createObjectStore('pa_photos', { keyPath: 'id' });
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const dataStore = tx.objectStore('pa_data');

        // Get the events array by key "Events"
        return dataStore.get('Events').then(eventsArray => {
            if (!eventsArray) {
                eventsArray = [];
            }

            // Find the event by ID
            const eventIndex = eventsArray.findIndex(event => event.id === _event_id);

            if (eventIndex !== -1) {
                // Get the specific event
                const event = eventsArray[eventIndex];

                //add the Activity to the Event
                event.activities.push(_activity);

                // Update the event in the events array
                eventsArray[eventIndex] = event;

                // Put the updated events array back into the object store
                return dataStore.put(eventsArray, 'Events').then(() => {
                    console.log(`Activity with ID ${_activity.id} in Event with ID ${_event_id} added/updated successfully.`);
                    return true;
                });
            } else {
                console.error(`Event with ID ${_event_id} not found.`);
                return false;
            }
        });
    }).catch(function (error) {
        console.error("An error occurred:", error);
        throw error; // Re-throw the error to be caught by the caller
    });
}
export function updateEventActivity(_event_id, _activity){

    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data', { keyPath: 'id' }); // Assuming each event has a unique 'id' property
        }
        if (!upgradeDb.objectStoreNames.contains('pa_photos')) {
            upgradeDb.createObjectStore('pa_photos', { keyPath: 'id' });
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const dataStore = tx.objectStore('pa_data');

        // Get the events array by key "Events"
        return dataStore.get('Events').then(eventsArray => {
            if (!eventsArray) {
                eventsArray = [];
            }

            // Find the event by ID
            const eventIndex = eventsArray.findIndex(event => event.id === _event_id);

            if (eventIndex !== -1) {
                // Get the specific event
                const event = eventsArray[eventIndex];

                // Find the index of the activity within the event's activities array
                const activityIndex = event.activities.findIndex(activity => activity.id === _activity.id);

                if (activityIndex !== -1) {
                    // Update the existing activity
                    event.activities[activityIndex] = _activity;
                } else {
                    // Add the new activity
                    event.activities.push(_activity);
                }

                // Update the event in the events array
                eventsArray[eventIndex] = event;

                // Put the updated events array back into the object store
                return dataStore.put(eventsArray, 'Events').then(() => {
                    console.log(`Activity with ID ${_activity.id} in Event with ID ${_event_id} added/updated successfully.`);
                    return true;
                });
            } else {
                console.error(`Event with ID ${_event_id} not found.`);
                return false;
            }
        });
    }).catch(function (error) {
        console.error("An error occurred:", error);
        throw error; // Re-throw the error to be caught by the caller
    });
}
export function deleteEventActivity(_event_id, _activity_id) {
    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data', { keyPath: 'id' }); // Assuming each event has a unique 'id' property
        }
        if (!upgradeDb.objectStoreNames.contains('pa_photos')) {
            upgradeDb.createObjectStore('pa_photos', { keyPath: 'id' });
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const dataStore = tx.objectStore('pa_data');

        // Get the events array by key "Events"
        return dataStore.get('Events').then(eventsArray => {
            if (!eventsArray) {
                eventsArray = [];
            }

            // Find the event by ID
            const eventIndex = eventsArray.findIndex(event => event.id === _event_id);

            if (eventIndex !== -1) {
                // Get the specific event
                const event = eventsArray[eventIndex];

                // Filter out the activity with the specified ID
                const updatedActivities = event.activities.filter(activity => activity.id !== _activity_id);

                // Update the event with the modified activities array
                event.activities = updatedActivities;

                // Update the event in the events array
                eventsArray[eventIndex] = event;

                // Put the updated events array back into the object store
                return dataStore.put(eventsArray, 'Events').then(() => {
                    console.log(`Activity with ID ${_activity_id} in Event with ID ${_event_id} deleted successfully.`);
                    return true;
                });
            } else {
                console.error(`Event with ID ${_event_id} not found.`);
                return false;
            }
        });
    }).catch(function (error) {
        console.error("An error occurred:", error);
        throw error; // Re-throw the error to be caught by the caller
    });
}



export function addEventCoverImageFullSizeAndThumbnail(_event_id, _file_name, _full_size_image_data, _thumbnail_data) {
    console.log('addEventCoverImageFullSizeAndThumbnail');

    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data', { keyPath: 'id' }); // Assuming each event has a unique 'id' property
        }
        if (!upgradeDb.objectStoreNames.contains('pa_photos')) {
            upgradeDb.createObjectStore('pa_photos', { keyPath: 'id' });
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const dataStore = tx.objectStore('pa_data');

        // Get the events array by key "Events"
        return dataStore.get('Events').then(eventsArray => {
            if (!eventsArray) {
                eventsArray = [];
            }

            // Find the event by ID
            const eventIndex = eventsArray.findIndex(event => event.id === _event_id);

            if (eventIndex !== -1) {
                // Get the specific event
                const event = eventsArray[eventIndex];
                event.file_name = _file_name;
                event.cover_image_full_data = _full_size_image_data;
                event.cover_image_thumbnail = _thumbnail_data;

                // Update the event in the events array
                eventsArray[eventIndex] = event;

                // Put the updated events array back into the object store
                return dataStore.put(eventsArray, 'Events').then(() => {
                    console.log(`Updated the Event cover image full size and thumbnail`);
                    return true;
                });
            } else {
                console.error(`Event with ID ${_event_id} not found.`);
                return false;
            }
        });
    }).catch(function (error) {
        console.error("An error occurred:", error);
        throw error; // Re-throw the error to be caught by the caller
    });
}
export function addEventCoverImageThumbnail(_event_id, _image_data) {
    console.log('addEventCoverImageThumbnail');

    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data', { keyPath: 'id' }); // Assuming each event has a unique 'id' property
        }
        if (!upgradeDb.objectStoreNames.contains('pa_photos')) {
            upgradeDb.createObjectStore('pa_photos', { keyPath: 'id' });
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const dataStore = tx.objectStore('pa_data');

        // Get the events array by key "Events"
        return dataStore.get('Events').then(eventsArray => {
            if (!eventsArray) {
                eventsArray = [];
            }

            // Find the event by ID
            const eventIndex = eventsArray.findIndex(event => event.id === _event_id);

            if (eventIndex !== -1) {
                // Get the specific event
                const event = eventsArray[eventIndex];
                event.cover_image_thumbnail = _image_data;

                // Update the event in the events array
                eventsArray[eventIndex] = event;

                // Put the updated events array back into the object store
                return dataStore.put(eventsArray, 'Events').then(() => {
                    console.log(`Updated the Event cover image thumbnail`);
                    return true;
                });
            } else {
                console.error(`Event with ID ${_event_id} not found.`);
                return false;
            }
        });
    }).catch(function (error) {
        console.error("An error occurred:", error);
        throw error; // Re-throw the error to be caught by the caller
    });
}



export function addEventPhoto(_photo) {
    console.log('addEventPhoto :', _photo);

    return new Promise(function (resolve, reject) {
        openDb('pa', 1, function (upgradeDb) {
            if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                upgradeDb.createObjectStore('pa_data');
            }
            if (!upgradeDb.objectStoreNames.contains('pa_photos')) {
                upgradeDb.createObjectStore('pa_photos');
            }
        })
            .then(function (db) {
                const tx = db.transaction('pa_photos', 'readwrite');
                const photosStore = tx.objectStore('pa_photos');

                photosStore.get(_photo["event_id"])
                    .then(function (existingPhotosArray) {
                        let updatedPhotos = existingPhotosArray || [];

                        // 🔁 Replace photo if file_name already exists
                        const existingIndex = updatedPhotos.findIndex(photo => photo.file_name === _photo.file_name);

                        if (existingIndex !== -1) {
                            updatedPhotos[existingIndex] = _photo; // Replace existing
                        } else {
                            updatedPhotos.push(_photo); // Add new
                        }

                        // ✅ Save the updated array
                        return photosStore.put(updatedPhotos, _photo["event_id"]);
                    })
                    .then(function () {
                        console.log('Photo added/updated (by file_name) for event_id:', _photo["event_id"]);
                        resolve(true);
                    })
                    .catch(function (error) {
                        console.error('Error storing or updating photo:', error);
                        reject(error);
                    });
            })
            .catch(function (error) {
                console.error('Database error:', error);
                reject(error);
            });
    });
}


export function addEventPhotoThumbnail(_photo) {
    console.log('addEventPhotoThumbnail');

    return new Promise(function(resolve, reject) {
        openDb('pa', 1, function(upgradeDb) {

            console.log('making a new object store');
            if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                upgradeDb.createObjectStore('pa_data');
                upgradeDb.createObjectStore('pa_photos');
            }

        }).then(function(db) {
            const tx = db.transaction('pa_photos', 'readwrite');
            const photosStore = tx.objectStore('pa_photos');

            // Retrieve the existing array of photos for the event_id
            photosStore.get(_photo["event_id"]).then(function(existingPhotosArray) {
                existingPhotosArray = existingPhotosArray || []; // If no array exists, initialize to empty array

                //find the Photo and update the thumbnail data
                let photoUpdated = false;
                for (let photo of existingPhotosArray) {
                    if (photo.event_id === _photo["event_id"] && photo.attendee_id === _photo["attendee_id"] && photo.id === _photo["id"]) {
                        photo.thumbnail_data = _photo["thumbnail_data"];
                        photoUpdated = true;
                        break;
                    }
                }

                if (!photoUpdated){
                    //didn't find the photo to update so need to add it instead
                    existingPhotosArray.push(_photo);
                }

                // Store the updated array back in the database
                photosStore.put(existingPhotosArray, _photo["event_id"]).then(function() {
                    console.log('Photo added to the array for event_id ' + _photo["event_id"]);
                    resolve(true);
                }).catch(function(error) {
                    console.error('Error storing updated array:', error);
                    reject(error);
                });
            }).catch(function(error) {
                console.error('Error retrieving existing array:', error);
                reject(error);
            });


            // If entry doesn't exist, create it
            tx.oncomplete = function(event) {
                console.log('tx.oncomplete: ' + event);
                if (!event.target.result) {
                    const newPhotosArray = [_photo];
                    const addRequest = photosStore.put(newPhotosArray, _photo["event_id"]);
                    addRequest.onsuccess = function(event) {
                        console.log('New array created for event_id ' + _photo["event_id"]);
                        resolve(true);
                    };
                    addRequest.onerror = function(event) {
                        console.error('Error creating new array:', event.target.error);
                        reject(event.target.error);
                    };
                }
            };
        }).catch(function(error) {
            console.error('Error:', error);
            reject(error);
        });
    });
}

export function getAllPhotosForEvent(eventId) {
    return new Promise(function(resolve, reject) {
        openDb('pa', 1).then(function (db) {
            console.log("--------------- seeing if pa_photos store exists");
            // Check if 'pa_photos' object store exists
            if (!db.objectStoreNames.contains('pa_photos')) {
                console.log("--------------- does NOT exist");
                // Close the database to allow reopening with 'pa_photos' object store
                db.close();
                // Reopen the database with 'pa_photos' object store
                return openDb('pa', 2, function (upgradeDb) {
                    // Create 'pa_photos' object store
                    console.log("--------------- Creating store");
                    upgradeDb.createObjectStore('pa_photos');
                });
            } else {
                console.log("--------------- DOES exist");
                return db; // Return the existing database if 'pa_photos' object store exists
            }
        }).then(function(db) {
            const tx = db.transaction('pa_photos', 'readonly');
            const photosStore = tx.objectStore('pa_photos');

            // Retrieve the array of photos for the specified event_id
            const getRequest = photosStore.get(eventId);

            getRequest.then(function(result) {
                if (result === undefined) {
                    resolve([]); // Resolve with an empty array if no photos found
                } else {
                    resolve(result); // Resolve with the array of photos
                }
            }).catch(function(error) {
                console.error('Error retrieving photos:', error);
                reject(error);
            });
        }).catch(function(error) {
            console.error('Error:', error);
            reject(error);
        });
    });
}
export function getAllPhotosForEventAttendee(eventId, attendeeId) {
    return new Promise(function(resolve, reject) {
        openDb('pa', 1).then(function (db) {
            console.log("--------------- seeing if pa_photos store exists");
            // Check if 'pa_photos' object store exists
            if (!db.objectStoreNames.contains('pa_photos')) {
                console.log("--------------- does NOT exist");
                // Close the database to allow reopening with 'pa_photos' object store
                db.close();
                // Reopen the database with 'pa_photos' object store
                return openDb('pa', 2, function (upgradeDb) {
                    // Create 'pa_photos' object store
                    console.log("--------------- Creating store");
                    upgradeDb.createObjectStore('pa_photos');
                });
            } else {
                console.log("--------------- DOES exist");
                return db; // Return the existing database if 'pa_photos' object store exists
            }
        }).then(function(db) {
            const tx = db.transaction('pa_photos', 'readonly');
            const photosStore = tx.objectStore('pa_photos');

            // Retrieve the array of photos for the specified event_id
            const getRequest = photosStore.get(eventId);

            getRequest.then(function(result) {
                if (result === undefined) {
                    resolve([]); // Resolve with an empty array if no photos found
                } else {
                    resolve(result.filter(photo => photo.attendee_id === attendeeId)); // Resolve with the array of photos
                }
            }).catch(function(error) {
                console.error('Error retrieving photos:', error);
                reject(error);
            });
        }).catch(function(error) {
            console.error('Error:', error);
            reject(error);
        });
    });
}
export function getSpecificEventPhotoDetails(eventId, _photo_id) {
    console.log('getSpecificEventPhotoDetails');
    return new Promise(function(resolve, reject) {
        openDb('pa', 1, function(upgradeDb) {

            console.log('making a new object store');
            if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                upgradeDb.createObjectStore('pa_data');
                upgradeDb.createObjectStore('pa_photos');
            }

        }).then(function(db) {
            const tx = db.transaction('pa_data', 'readonly');
            const dataStore = tx.objectStore('pa_data');

            // get the Event from the Events array
            const getRequest = dataStore.get('Events');
            getRequest.then(function(result) {
                const event = result.find(evt => evt.id === eventId);
                console.log('event:', event);

                if (!event) {
                    return reject(`Event with ID ${eventId} not found`);
                }

                console.log('Found the Event; looking for photo id:', _photo_id);

                // Get the specific photo from the list of photos in the event
                const photo = event.photos.find(p => p.id === _photo_id);
                console.log('photo:', photo);

                if (!photo) {
                    return reject(`Photo with ID ${_photo_id} not found in Event ${eventId}`);
                }

                resolve(photo);
            }).catch(function(error) {
                console.error('Error retrieving photos:', error);
                reject(error);
            });

        }).catch(function(error) {
            console.error('Error:', error);
            reject(error);
        });
    });
}
export function getSpecificPhotoForEvent(eventId, _photo_id) {
    return new Promise(function(resolve, reject) {
        openDb('pa', 1, function(upgradeDb) {

            console.log('making a new object store');
            if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                upgradeDb.createObjectStore('pa_data');
                upgradeDb.createObjectStore('pa_photos');
            }

        }).then(function(db) {
            const tx = db.transaction('pa_photos', 'readonly');
            const photosStore = tx.objectStore('pa_photos');

            // Retrieve the array of photos for the specified event_id
            const getRequest = photosStore.get(eventId);

            getRequest.then(function(result) {
                if (result === undefined) {
                    resolve(null); // Resolve with an empty array if no photos found
                } else {
                    resolve(result.find((_photo) => _photo.id === _photo_id)); // Resolve with the specific photo
                }
            }).catch(function(error) {
                console.error('Error retrieving photos:', error);
                reject(error);
            });
        }).catch(function(error) {
            console.error('Error:', error);
            reject(error);
        });
    });
}
export function updatePhotoInProgress(eventId, _photo_id) {
    console.log("updatePhotoInProgress : "+eventId+" : "+_photo_id);

    return new Promise(function(resolve, reject) {
        openDb('pa', 1, function(upgradeDb) {

            console.log('making a new object store');
            if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                upgradeDb.createObjectStore('pa_data');
                upgradeDb.createObjectStore('pa_photos');
            }

        }).then(function(db) {
            console.log("got the db : "+db);

            const tx = db.transaction('pa_photos', 'readwrite');
            const photosStore = tx.objectStore('pa_photos');

            console.log("photosStore : "+photosStore);

            // Retrieve the array of photos by event ID
            photosStore.get(eventId).then(function(photoArray) {

                const photo = photoArray.find(photo => photo.id === _photo_id);

                console.log("the photo to update : "+photo);

                if (photo) {
                    // Update the property value
                    photo.full_size_uploading_in_progress = true;

                    // Put the updated photo object back into the object store
                    photosStore.put(photoArray, eventId).then(function(result) {
                        console.log("saving updated photo : "+result);
                        resolve(true);
                    });


                } else {
                    // Photo object not found, reject with an error
                    const error = new Error('Photo not found');
                    console.error('Error updating photo:', error);
                    reject(error);
                }
            });

        }).catch(function(error) {
            console.error('Error:', error);
            reject(error);
        });
    });
}
export function addEventAttendeePhotoToBrowserStorage(eventId, _photo) {
    console.log("addEventAttendeePhotoToBrowserStorage : "+eventId);

    return new Promise(function(resolve, reject) {
        openDb('pa', 1, function(upgradeDb) {

            console.log('making a new object store');
            if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                upgradeDb.createObjectStore('pa_data');
                upgradeDb.createObjectStore('pa_photos');
            }

        }).then(function(db) {

            const tx = db.transaction('pa_data', 'readwrite');
            const dataStore = tx.objectStore('pa_data');

            // Retrieve the array of photos by event ID
            dataStore.get("Events").then(function(eventsArray) {
                const _event = eventsArray.find(event => event.id === eventId);
                if (_event) {
                    //add the photo to the Event
                    _event['photos'].push(_photo);

                    // Put the updated Events array back into the object store
                    dataStore.put(eventsArray, "Events").then(function(result) {
                        console.log("successfully added photo to Event : "+result);
                        resolve(true);
                    });


                } else {
                    // Event object not found, reject with an error
                    const error = new Error('Event not found');
                    console.error('Error adding Event photo:', error);
                    reject(error);
                }
            });

        }).catch(function(error) {
            console.error('Error:', error);
            reject(error);
        });
    });
}

export function updateEventAttendeePhotoInBrowserStorageUploadingInProgress(eventId, _photo_id, _in_progress ) {
    console.log("updateEventAttendeePhotoInBrowserStorageUploadingInProgress : "+eventId+" : "+_photo_id);

    return new Promise(function(resolve, reject) {
        openDb('pa', 1, function(upgradeDb) {

            console.log('making a new object store');
            if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                upgradeDb.createObjectStore('pa_data');
                upgradeDb.createObjectStore('pa_photos');
            }

        }).then(function(db) {

            const tx = db.transaction('pa_data', 'readwrite');
            const _dataStore = tx.objectStore('pa_data');

            // Retrieve the array of photos by event ID
            _dataStore.get("Events").then(function(eventsArray) {
                const _event = eventsArray.find(event => event.id === eventId);
                if (_event) {
                    //update the Event attendee photo
                    const photo = _event['photos'].find(photo => photo.id === _photo_id);

                    if (photo) {
                        // Update the property value
                        photo.full_size_uploading_in_progress = _in_progress;

                        // Put the updated photo object back into the object store
                        _dataStore.put(eventsArray, "Events").then(function(result) {
                            console.log("saving updated Events : "+result);
                            resolve(true);
                        });

                    } else {
                        // Photo object not found, reject with an error
                        const error = new Error('Photo not found');
                        console.error('Error updating Event photo:', error);
                        reject(error);
                    }

                } else {
                    // Event object not found, reject with an error
                    const error = new Error('Event not found');
                    console.error('Error adding Event photo:', error);
                    reject(error);
                }
            });

        }).catch(function(error) {
            console.error('Error:', error);
            reject(error);
        });
    });
}
export function updateEventAttendeePhotoInBrowserStoragePaDataPrivate(eventId, _photo_id, _private ) {
    console.log("updateEventAttendeePhotoInBrowserStoragePaDataPrivate : "+eventId+" : "+_photo_id);

    return new Promise(function(resolve, reject) {
        openDb('pa', 1, function(upgradeDb) {

            console.log('making a new object store');
            if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                upgradeDb.createObjectStore('pa_data');
                upgradeDb.createObjectStore('pa_photos');
            }

        }).then(function(db) {

            const tx = db.transaction('pa_data', 'readwrite');
            const _dataStore = tx.objectStore('pa_data');

            // Retrieve the array of photos by event ID
            _dataStore.get("Events").then(function(eventsArray) {
                const _event = eventsArray.find(event => event.id === eventId);
                if (_event) {
                    //update the Event attendee photo
                    const photo = _event['photos'].find(photo => photo.id === _photo_id);

                    if (photo) {
                        // Update the property value
                        photo.private = _private;

                        // Put the updated photo object back into the object store
                        _dataStore.put(eventsArray, "Events").then(function(result) {
                            console.log("saving updated Events : "+result);
                            resolve(true);
                        });

                    } else {
                        // Photo object not found, reject with an error
                        const error = new Error('Photo not found');
                        console.error('Error updating Event photo:', error);
                        reject(error);
                    }

                } else {
                    // Event object not found, reject with an error
                    const error = new Error('Event not found');
                    console.error('Error adding Event photo:', error);
                    reject(error);
                }
            });

        }).catch(function(error) {
            console.error('Error:', error);
            reject(error);
        });
    });
}
export function updateEventAttendeePhotoInBrowserStoragePaPhotosPrivate(eventId, _photo_id, _private ) {
    console.log("updateEventAttendeePhotoInBrowserStoragePaPhotosPrivate : "+eventId+" : "+_photo_id);

    return new Promise(function(resolve, reject) {
        openDb('pa', 1, function(upgradeDb) {

            console.log('making a new object store');
            if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                upgradeDb.createObjectStore('pa_data');
                upgradeDb.createObjectStore('pa_photos');
            }

        }).then(function(db) {

            const tx = db.transaction('pa_photos', 'readwrite');
            const _photosStore = tx.objectStore('pa_photos');

            // Retrieve the array of photos by event ID
            _photosStore.get(eventId).then(function(photosArray) {
                const _photo = photosArray.find(photo => photo.id === _photo_id);

                //update the Event attendee photo
                if (_photo) {
                    // Update the property value
                    _photo.private = _private;

                    // Put the updated photo object back into the object store
                    _photosStore.put(photosArray, eventId).then(function(result) {
                        console.log("saving updated Photo : "+result);
                        resolve(true);
                    });

                } else {
                    // Photo object not found, reject with an error
                    const error = new Error('Photo not found');
                    console.error('Error updating Event photo:', error);
                    reject(error);
                }

            });

        }).catch(function(error) {
            console.error('Error:', error);
            reject(error);
        });
    });
}

export function updateEventAttendeePhotoInBrowserStoragePaDataSharable(eventId, _photo_id, _sharable, _share_with ) {
    console.log("updateEventAttendeePhotoInBrowserStoragePaDataSharable : "+eventId+" : "+_photo_id);

    return new Promise(function(resolve, reject) {
        openDb('pa', 1, function(upgradeDb) {

            console.log('making a new object store');
            if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                upgradeDb.createObjectStore('pa_data');
                upgradeDb.createObjectStore('pa_photos');
            }

        }).then(function(db) {

            const tx = db.transaction('pa_data', 'readwrite');
            const _dataStore = tx.objectStore('pa_data');

            // Retrieve the array of photos by event ID
            _dataStore.get("Events").then(function(eventsArray) {
                const _event = eventsArray.find(event => event.id === eventId);
                if (_event) {
                    //update the Event attendee photo
                    const photo = _event['photos'].find(photo => photo.id === _photo_id);

                    if (photo) {
                        // Update the property value
                        photo.sharable = _sharable;
                        photo.share_with = _share_with;

                        // Put the updated photo object back into the object store
                        _dataStore.put(eventsArray, "Events").then(function(result) {
                            console.log("saving updated Events : "+result);
                            resolve(true);
                        });

                    } else {
                        // Photo object not found, reject with an error
                        const error = new Error('Photo not found');
                        console.error('Error updating Event photo:', error);
                        reject(error);
                    }

                } else {
                    // Event object not found, reject with an error
                    const error = new Error('Event not found');
                    console.error('Error adding Event photo:', error);
                    reject(error);
                }
            });

        }).catch(function(error) {
            console.error('Error:', error);
            reject(error);
        });
    });
}
export function updateEventAttendeePhotoInBrowserStoragePaPhotosSharable(eventId, _photo_id, _sharable, _share_with ) {
    console.log("updateEventAttendeePhotoInBrowserStoragePaPhotosSharable : "+eventId+" : "+_photo_id);

    return new Promise(function(resolve, reject) {
        openDb('pa', 1, function(upgradeDb) {

            console.log('making a new object store');
            if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                upgradeDb.createObjectStore('pa_data');
                upgradeDb.createObjectStore('pa_photos');
            }

        }).then(function(db) {

            const tx = db.transaction('pa_photos', 'readwrite');
            const _photosStore = tx.objectStore('pa_photos');

            // Retrieve the array of photos by event ID
            _photosStore.get(eventId).then(function(photosArray) {
                const _photo = photosArray.find(photo => photo.id === _photo_id);

                //update the Event attendee photo
                if (_photo) {
                    // Update the property value
                    _photo.sharable = _sharable;
                    _photo.share_with = _share_with;

                    // Put the updated photo object back into the object store
                    _photosStore.put(photosArray, eventId).then(function(result) {
                        console.log("saving updated Photo : "+result);
                        resolve(true);
                    });

                } else {
                    // Photo object not found, reject with an error
                    const error = new Error('Photo not found');
                    console.error('Error updating Event photo:', error);
                    reject(error);
                }

            });

        }).catch(function(error) {
            console.error('Error:', error);
            reject(error);
        });
    });
}

export function updateEventPhoto(event_id, _updated_photo) {
    console.log("updateEventPhoto");

    return new Promise(function(resolve, reject) {
        openDb('pa', 1, function(upgradeDb) {

            console.log('making a new object store');
            if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                upgradeDb.createObjectStore('pa_data');
                upgradeDb.createObjectStore('pa_photos');
            }

        }).then(function(db) {
            const tx = db.transaction('pa_photos', 'readwrite');
            const photosStore = tx.objectStore('pa_photos');

            // Retrieve the array of photos for the specified event_id
            const getRequest = photosStore.get(event_id);

            getRequest.then(function(eventPhotos) {
                console.log("eventPhotos : "+eventPhotos);

                if (eventPhotos === undefined) {
                    // If eventPhotos is undefined, initialize it with the updated photo object
                    const updatedEventPhotos = [_updated_photo];
                    const putRequest = photosStore.put(updatedEventPhotos, event_id);
                    putRequest.onsuccess = function(event) {
                        console.log('Event with id ' + event_id + ' not found. New entry created.');
                        resolve(_updated_photo);
                    };
                    putRequest.onerror = function(event) {
                        console.error('Error creating new entry:', event.target.error);
                        reject(event.target.error);
                    };
                } else {
                    // Find the index of the photo object by its id
                    const photoIndex = eventPhotos.findIndex(photo => photo.id === _updated_photo.id);

                    if (photoIndex !== -1) {
                        // Replace the existing photo object with the updated one
                        eventPhotos[photoIndex] = _updated_photo;
                    } else {
                        // Add the updated photo object to the array
                        eventPhotos.push(_updated_photo);
                    }

                    // Put the updated eventPhotos array back into the object store
                    const putRequest = photosStore.put(eventPhotos, event_id);
                    putRequest.onsuccess = function(event) {
                        console.log('Photo updated:', _updated_photo);
                        resolve(_updated_photo);
                    };
                    putRequest.onerror = function(event) {
                        console.error('Error updating photo:', event.target.error);
                        reject(event.target.error);
                    };
                }
            }).catch(function(error) {
                console.error('Error retrieving photos for event:', error);
                reject(error);
            });
        }).catch(function(error) {
            console.error('Error:', error);
            reject(error);
        });
    });
}
export function addEventMessage(_event_id, _message){

    return openDb('pa', 1, function (upgradeDb) {
        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
            upgradeDb.createObjectStore('pa_data', { keyPath: 'id' }); // Assuming each event has a unique 'id' property
        }
        if (!upgradeDb.objectStoreNames.contains('pa_photos')) {
            upgradeDb.createObjectStore('pa_photos', { keyPath: 'id' });
        }
    }).then(function (db) {
        const tx = db.transaction('pa_data', 'readwrite');
        const dataStore = tx.objectStore('pa_data');

        // Get the events array by key "Events"
        return dataStore.get('Events').then(eventsArray => {
            if (!eventsArray) {
                eventsArray = [];
            }

            // Find the event by ID
            const eventIndex = eventsArray.findIndex(event => event.id === _event_id);

            if (eventIndex !== -1) {
                // Get the specific event
                const event = eventsArray[eventIndex];

                // Check if the message already exists in the event
                const messageExists = event.messages && event.messages.some(msg => msg.id === _message.id);

                if (!messageExists) {
                    // Add the Message to the Event if it doesn't already exist
                    event.messages.push(_message);

                    // Find the latest timestamp from the Event messages and update
                    if (event.messages.length > 0) {
                         // Start with 0 or an initial very old timestamp
                        event.latest_message_timestamp = event.messages.reduce((latest, msg) => {
                            return latest > msg.timestamp ? latest : msg.timestamp;
                        }, 0);
                    }

                    // Update the event in the events array
                    eventsArray[eventIndex] = event;

                    // Put the updated events array back into the object store
                    return dataStore.put(eventsArray, 'Events').then(() => {
                        console.log(`Message with ID ${_message.id} in Event with ID ${_event_id} added successfully.`);
                        return true;
                    });
                } else {
                    console.log(`Message with ID ${_message.id} already exists in Event with ID ${_event_id}.`);
                    return false;
                }
            } else {
                console.error(`Event with ID ${_event_id} not found.`);
                return false;
            }
        });
    }).catch(function (error) {
        console.error("An error occurred:", error);
        throw error; // Re-throw the error to be caught by the caller
    });
}