import React, { useEffect, useState } from 'react';
import {Checkbox, ListItem} from "@mui/material";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function ActivityAddLocationTab(props) {


    useEffect(() => {
        const meals = usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals || [];
        const lodging = usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging || [];
        const activities = usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities || [];

        // Combine all locations
        const allLocations = [
            ...meals.map((meal) => meal.location),
            ...lodging.map((lodging) => lodging.location),
            ...activities.map((activity) => activity.location),
        ];

        // Filter unique locations by Google Place ID or another unique property
        const unique = Array.from(
            new Map(
                allLocations
                    .filter((location) => location.name) // Exclude locations without a name
                    .map((location) => [location.google_place_id, location])
            ).values()
        );

        // Sort the unique locations alphabetically by name
        const sortedUnique = unique.sort((a, b) => {
            const nameA = (a.name || "").toLowerCase();
            const nameB = (b.name || "").toLowerCase();
            return nameA.localeCompare(nameB);
        });

        // Separate the matching location from the rest
        const matchingLocation = sortedUnique.filter(
            (location) =>
                selectedActivityLocation &&
                location.google_place_id === selectedActivityLocation.google_place_id
        );
        const otherLocations = sortedUnique.filter(
            (location) =>
                !selectedActivityLocation ||
                location.google_place_id !== selectedActivityLocation.google_place_id
        );

        // Combine matching location(s) on top with the rest alphabetically sorted
        const finalLocationList = [...matchingLocation, ...otherLocations];

        setUniqueLocations(finalLocationList);
    }, []);

    const [uniqueLocations, setUniqueLocations] = useState([]);
    const [selectedActivityLocation, setSelectedActivityLocation] = useState(props.activityLocation || {});

    useEffect(() => {
        console.log("selectedActivityLocation : ", selectedActivityLocation);
        props.setActivityLocation(selectedActivityLocation);
    }, [selectedActivityLocation]);

    function handleLocationSelect(location) {
        setSelectedActivityLocation((prevLocation) =>
            prevLocation?.google_place_id === location.google_place_id ? {} : location
        );
    }


    return (
        <Box style={{ flex: '1' }}>
            <List dense>
                {uniqueLocations.length > 0 ? (
                    uniqueLocations.map((location, index) => (
                        <ListItem key={index} sx={{ alignItems: "flex-start" }} button>
                            <Checkbox
                                checked={selectedActivityLocation?.google_place_id === location.google_place_id}
                                onChange={() => handleLocationSelect(location)}
                                sx={{ padding: "0 8px" }}
                            />
                            <ListItemText
                                primary={location.name || "Unnamed Location"}
                                secondary={location.address || "No Address Available"}
                            />
                        </ListItem>
                    ))
                ) : (
                    <Typography variant="body2" sx={{ textAlign: "center", color: "gray" }}>
                        No Event locations to choose from yet
                    </Typography>
                )}
            </List>

        </Box>
    );
}

export default ActivityAddLocationTab;
