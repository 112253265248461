
import Location from "./Location";
import PAAttendee from "./Attendee";
import TimeTable from "./TimeTable";

class Meal {
    constructor(){

        const _temp = PAAttendee.generateRandomString(10);
        this.temp_id = _temp;
        this.id = _temp;

        this.created_by = "";

        this.name = "";

        this.time_table = new TimeTable();

        this.date = "";
        this.time = "";

        this.day = "";

        this.location = new Location();

        this.phone = "";
        this.booked_through = "";
        this.confirmation_number = "";
        this.reservation_number = "";
        this.reservation_name = "";
        this.notes = "";

        this.eat_in = false;
        this.eat_in_details = "";

        this.attendees = [];	//array of strings

        this.photos = [];
        this.attendee_photos = [];

        this.amount_total = 0.00;
        this.currency = "USD";
        this.assign_equal = true;

        this.Place = {};

        this.status = 0;
    }

}

export default Meal;
