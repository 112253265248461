import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';

import {
    Autocomplete,
    Avatar, Button, ButtonGroup,
    Card,
    CardActions,
    CardContent, CardHeader,
    CardMedia,
    Checkbox,
    Chip, Dialog, DialogActions, DialogContent, DialogContentText,
    ListItem,
    ListItemAvatar, Menu, MenuItem, MenuList, Tab, TextField, Tooltip
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import currencyList from "../../../sampleData/currencyList";
import Box from "@mui/material/Box";
import './activities.css';
import ListItemIcon from "@mui/material/ListItemIcon";
import {
    Timeline,
    TimelineContent,
    TimelineItem,
    timelineItemClasses, TimelineOppositeContent, timelineOppositeContentClasses,
} from "@mui/lab";
import {editChipStyle} from "../../ButtonStyle/Edit";
import {addChipStyle} from "../../ButtonStyle/Add";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {formatName, formatTime, stringAvatar, stringToColor} from "../../Helpers/TextStyling";

import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import ActivityLocationTab from "./ActivityLocationTab";

dayjs.extend(duration);


const CustomTimelineDot = ({ beginTime }) => {
    // Ensure the beginTime is valid before rendering
    if (!beginTime) return null;

    // Display the begin_time in a rectangle box
    return (
        <Box
            sx={{
                width: 'auto', // Set width to auto to accommodate the time length
                height: 40, // Adjust height as needed
                px: 1, // Add horizontal padding for a rectangular look
                borderRadius: 4, // Small border radius for slightly rounded corners (or remove for a full rectangle)
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="body2" noWrap>
                {beginTime} {/* Show the provided begin_time */}
            </Typography>
        </Box>
    );
};



function ActivitiesCard(props){

    const [anchorChangeCurrencyEl, setAnchorChangeCurrencyEl] = useState(null);

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Account, Attendees, SampleAttendees } = dataArray;

    const [attendeesReferenceArray, setAttendeesReferenceArray] = useState(
        dataArray.authenticated ? [...SampleAttendees, ...Attendees] : SampleAttendees
    );

    useEffect(() => {
        setAttendeesReferenceArray(
            dataArray.authenticated
                ? [...SampleAttendees, ...Attendees] // ✅ Merge SampleAttendees with Attendees on login
                : SampleAttendees
        );
    }, [dataArray.authenticated, Attendees, SampleAttendees]);

    const [ selectedAttendeesArray, setSelectedAttendeesArray ] = useState( props.activity.attendees);

    const [eventActivity, setEventActivity] = useState(props.activity);
    const [selectedAction, setSelectedAction] = useState("");
    const [activityTabSelected, setActivityTabSelected] = useState("");

    const fetchedRef = useRef(false);

    useEffect(() => {
        const businessAuth = usePA_GlobalStore.getState().PA_GlobalObject.Planning.business_authorizations || {};

        // 🌟 Load Activity Limit (if it exists)
        setUseActivityLimit(!!businessAuth['categoryLimits']?.activities || false);
        setActivityLimit(businessAuth['categoryLimits']?.activities || "");

        // 🌟 Load Individual Attendee Limits (if they exist)
        if (businessAuth.individualLimits) {
            setIndividualLimits(businessAuth.individualLimits);
        }
    }, [PA_GlobalObject.Planning.business_authorizations]);
    useEffect(() => {
        console.log("props.activity has been updated --- : ", props.activity);

        if (props.activity.attendees && props.activity.attendees.length > 0) {
            console.log("initialSelectedAttendees --- : ");

            const initialSelectedAttendees = props.activity.attendees
                .map((activityAttendee) => {
                    const foundAttendee = attendeesReferenceArray.find(
                        (attendee) => String(attendee.id) === String(activityAttendee.id) // Ensure type match
                    );
                    console.log("Searching for ID:", activityAttendee.id, "Found:", foundAttendee);
                    return foundAttendee;
                })
                .filter(Boolean); // Remove undefined values

            console.log("Filtered initialSelectedAttendees:", initialSelectedAttendees);

            setSelectedAttendeesArray(initialSelectedAttendees);

            const businessAuth = usePA_GlobalStore.getState().PA_GlobalObject.Planning.business_authorizations || {};

            // 🌟 Load Activity Limit (if it exists)
            setUseActivityLimit(!!businessAuth['categoryLimits']?.activities || false);
            setActivityLimit(businessAuth['categoryLimits']?.activities || "");

            // 🌟 Load Individual Attendee Limits (if they exist)
            if (businessAuth.individualLimits) {
                setIndividualLimits(businessAuth.individualLimits);
            }
        }

        const fetchPhotos = async () => {
            // Ensure no redundant fetch
            if (
                fetchedRef.current ||
                (props.activity.photos && props.activity.photos.length > 0) ||
                !props.activity.location.google_place_id
            ) {
                return; // Exit if photos already exist, have been fetched, or no place ID is available
            }

            fetchedRef.current = true; // Mark as fetched to prevent duplicate fetches

            const request = {
                placeId: props.activity.location.google_place_id,
                fields: ['photo'],
            };

            try {
                const results = await new Promise((resolve, reject) => {
                    const _global_map_service = new window.google.maps.places.PlacesService(
                        props._global_map_ref.current
                    );
                    _global_map_service.getDetails(request, (place, status) => {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                            resolve(place);
                        } else {
                            reject(status);
                        }
                    });
                });

                if (results.photos) {
                    const newPhotos = results.photos.map(photo =>
                        photo.getUrl({ maxWidth: 175, maxHeight: 175 })
                    );

                    // Update state without mutating props
                    setEventActivity(prev => ({ ...prev, photos: newPhotos }));
                } else {
                    console.log("No photos available for this activity.");
                }
            } catch (error) {
                console.error("Error fetching photos for activity:", error);
            }
        };

        fetchPhotos();
    }, [props.activity]);
    useEffect(() => {
        console.log("0000000000000000 : ", selectedAttendeesArray);
    }, [selectedAttendeesArray]);

    const [beginDate, setBeginDate] = useState(
        eventActivity.time_table.dates[0]?.date || ""
    );
    const [beginTime, setBeginTime] = useState(
        eventActivity.time_table.dates[0]?.['begin_time'] || eventActivity.time_table.days[0]?.['begin_time'] || ''
    );

    const [endDate, setEndDate] = useState(
        eventActivity.time_table.dates[eventActivity.time_table.dates.length - 1]?.date || ""
    );
    const [endTime, setEndTime] = useState(
        eventActivity.time_table.dates[eventActivity.time_table.dates.length - 1]?.['end_time']  || eventActivity.time_table.days[eventActivity.time_table.days.length - 1]?.['end_time'] || ""
    );
    const [activityDaysCount, setActivityDaysCount] = useState(
        usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates[0] ?
            usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates.length-1
            :
            usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days[0] ?
                usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days.length-1
                :
                1);
    const [selectedActivityBeginDay, setSelectedActivityBeginDay] = useState(() => {
        const firstDay = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days?.[0];
        return firstDay ? { day_index: Object.keys(firstDay)[0], day: Object.values(firstDay)[0] } : {};
    });
    const [selectedActivityEndDay, setSelectedActivityEndDay] = useState(() => {
        const lastDay = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days?.[usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days.length - 1];
        return lastDay ? { day_index: Object.keys(lastDay)[0], day: Object.values(lastDay)[0] } : {};
    });
    const [allDay, setAllDay] = useState(false); // Toggle for all-day activity

    
    const [tabValue, setTabValue] = useState(
        props.activity?.time_table?.dates?.length > 0 || !props.activity?.time_table?.days?.length
            ? "date"
            : "day"
    );

    const [showCalendarSelectBeginDate, setShowCalendarSelectBeginDate] = useState(false);
    const [showCalendarSelectEndDate, setShowCalendarSelectEndDate] = useState(false);
    const [showClockSelectBeginTime, setShowClockSelectBeginTime] = useState(false);
    const [showClockSelectEndTime, setShowClockSelectEndTime] = useState(false);
    const [showSelectActivityBeginDay, setShowSelectActivityBeginDay] = useState(false);


    const [showPriceOverride, setShowPriceOverride] = useState(false);
    const [checkShowPriceChanges, setCheckShowPriceChanges] = useState(false);
    const [showPriceChangesUpdateButton, setShowPriceChangesUpdateButton] = useState(false);


    useEffect(() => {
        console.log("eventActivity has been updated --- : ");

        set_PA_GlobalObject((prev) => {
            const updatedActivities = prev.Planning.activities.map((activity) =>
                activity.id === eventActivity.id
                    ? { ...activity, ...eventActivity } // Correctly spread the existing activities and update it with eventActivity
                    : activity // Keep the other activities unchanged
            );

            return {
                Planning: {
                    ...prev.Planning, // Retain all existing fields in Planning
                    activities: updatedActivities, // Update only the specific activities in the array
                },
            };
        });
    }, [eventActivity]);

    useEffect(()=> {

        if (Attendees !== null){
            Attendees.sort((a, b) => a.lname.localeCompare(b.lname));

            // Move activity attendees to the top of the list
            for (let i = eventActivity.attendees.length - 1; i >= 0; i--) {
                const activityAttendee = eventActivity.attendees[i];
                const index = Attendees.findIndex(attendee => attendee.id === activityAttendee.id);
                if (index !== -1) {
                    const removedAttendee = Attendees.splice(index, 1)[0];
                    Attendees.unshift(removedAttendee);
                }
            }

        }

    }, [Attendees]);

    useEffect(()=> {
        console.log("..... checkShowPriceChanges");

        /*
            compare new pricing to see if anything needs to be saved
            this can be a change to the total price or the price breakdown
            for each Attendee
         */

        console.log("comparing props.activity[amount_total] : "+props.activity["amount_total"]+" and "+eventActivity["amount_total"]);
        if (props.activity["amount_total"] !== eventActivity["amount_total"] || props.activity["currency"] !== eventActivity["currency"]){
            //turn on the button

            console.log("the total cost or currency has changed");

            setShowPriceChangesUpdateButton(true);

        } else if (
            eventActivity["attendees"].some((_attendee, index) => {
                const initialAttendee = props.activity["attendees"].find(a => a.id === _attendee.id);

//                console.log("comparing attendee costs : "+_attendee.cost);
//                console.log("comparing initialAttendee costs : "+initialAttendee["cost"]);

                return initialAttendee && parseFloat(_attendee.cost) !== parseFloat(initialAttendee.cost);
            })) {
            //turn on the button

            console.log("an attendee cost has changed");

            setShowPriceChangesUpdateButton(true);
        } else {
            //no changes have been made
            console.log("no pricing changes to save");

            setShowPriceChangesUpdateButton(false);
        }

        setCheckShowPriceChanges(false);

    }, [checkShowPriceChanges]);

    function handlePromptShowActivityBeginDateCalendar(){
        //toggle the button
        setShowCalendarSelectBeginDate(!showCalendarSelectBeginDate);
    }
    function handlePromptShowActivityEndDateCalendar(){
        //toggle the button
        setShowCalendarSelectEndDate(!showCalendarSelectEndDate);
    }

    const handleTabChange  = (event, newValue) => {
        console.log("handleTabChange ");
        setTabValue(newValue);
    };

    function handleBeginDateChange(begin_date) {
        if (!begin_date || !dayjs(begin_date).isValid()) {
            console.warn("Invalid begin_date:", begin_date);
            return; // Prevent setting an invalid date
        }

        const formattedBeginDate = dayjs(begin_date).format('YYYY-MM-DD');
        setBeginDate(formattedBeginDate);

        if (!endDate || dayjs(begin_date).isAfter(dayjs(endDate))) {
            setEndDate(formattedBeginDate);
        }
    }

    function handleEndDateChange(end_date){
        setEndDate(dayjs(end_date).format('YYYY-MM-DD'));
    }
    function handlePromptShowActivityBeginTime(){
        //toggle the button
        //change button back to 'Edit'
        setShowClockSelectBeginTime(!showClockSelectBeginTime);
    }
    function handlePromptShowActivityEndTime(){
        //toggle the button

        //change button back to 'Edit'
        setShowClockSelectEndTime(!showClockSelectEndTime);
    }

    function handleBeginTimeChange(begin_time){
        console.log("begin_time : "+dayjs(begin_time).format('h:mm A'));
        setBeginTime(dayjs(begin_time).format('h:mm A'));
    }
    function handleEndTimeChange(end_time){
        console.log("end_time : "+dayjs(end_time).format('h:mm A'));
        setEndTime(dayjs(end_time).format('h:mm A'));
    }

    useEffect(() => {
        buildTimeTableForActivity();
    }, [beginDate, endDate, beginTime, endTime]);

    function buildTimeTableForActivity() {
        console.log("createTimeTableForActivity");

        console.log("beginDate:", beginDate);
        console.log("endDate:", endDate);
        console.log("selectedActivityBeginDay:", selectedActivityBeginDay);
        console.log("activityDaysCount:", activityDaysCount);
        console.log("beginTime:", beginTime);
        console.log("endTime:", endTime);

        // Parse begin and end dates if available
        const start = beginDate ? dayjs(beginDate) : null;
        const end = endDate ? dayjs(endDate) : start; // Default to start if no end date

        // Calculate total days if dates exist
        let totalDays = start && end ? end.diff(start, "days") + 1 : 0;
        console.log("Total Days Calculated:", totalDays);

        /*
            if there are no dates then just make the one entry
         */
        let dateEntries = []; // Preserve existing entries
        if (totalDays > 0){
            //make an entry for each date with the first having any begin_time and the last having the end_time
            let currentDate = dayjs(beginDate);

            for (let i = 0; i < totalDays; i++) {
                dateEntries.push({
                    date: currentDate.format("YYYY-MM-DD"),
                    dayOfWeek: currentDate.format("dddd"),
                    'all-day': allDay,
                    'begin_time': i === 0 ? beginTime : null, // Only first entry gets the begin_time
                    'end_time': i === totalDays - 1 ? endTime : null, // Only last entry gets the end_time
                    notes: "",
                });

                currentDate = currentDate.add(1, 'day'); // Move to the next date
            }

        } else {
            //make a single entry with just any times
            dateEntries.push({
                date: beginDate ? dayjs(beginDate).format("YYYY-MM-DD") : null,
                dayOfWeek: beginDate ? dayjs(beginDate).format("dddd") : '',
                'all-day': allDay,
                'begin_time':  beginTime ? beginTime : null,
                'end_time': endTime ? endTime : null,
                notes: "",
            });

        }

        console.log("---- UPDATED time_table"+ JSON.stringify(dateEntries, null, '\t'));


        // **🚀 Update the activity with the new `time_table`**
        setEventActivity(prevState => ({
            ...prevState,
            time_table: {
                ...prevState.time_table, // ✅ Keep all existing properties
                dates: dateEntries // ✅ Replace only the dates
            }
        }));
    }

    const handlePromptShowActivityBeginDaySelect  = () => {
        console.log("handlePromptShowActivityBeginDaySelect ");
        setShowSelectActivityBeginDay(!showSelectActivityBeginDay);
    };
    const handleActivityBeginDayChange = (dayKey, dayName) => {
        console.log("handleActivityBeginDayChange", dayKey, ":", dayName);

        // Set the selected meal day in state
        setSelectedActivityBeginDay(dayKey);

        setEventActivity(prevState => {
            // Only allow one day in the time_table.days array
            const updatedDays = [{ [dayKey]: dayName, 'all-day': false, notes: "" }];

            console.log("updatedDays", JSON.stringify(updatedDays, null, '\t'));

            // Return the updated state with the new day replacing any existing day
            return {
                ...prevState,
                time_table: {
                    ...prevState.time_table,
                    days: updatedDays, // Replace with the new selected day
                },
            };
        });
    };
    const renderActivityBeginDayLayout = () => {
        const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]; // Week layout starting with Sunday

        // Get all planned days from Planning.time_table.days
        const planningDays = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days || [];

        // Create an array to store all possible day objects (even for multi-week events)
        const calendarDays = [];

        planningDays.forEach((dayObj, index) => {
            const dayKey = Object.keys(dayObj)[0]; // e.g., 'day 1'
            const dayName = Object.values(dayObj)[0]; // e.g., 'Wednesday'
            const dayOfWeekIndex = daysOfWeek.indexOf(dayName.slice(0, 2)); // Index for Su-Sa layout (0-6)

            // Add the day to the correct slot (considering multiple weeks)
            calendarDays.push({
                key: dayKey,
                name: dayName,
                index: index + 1, // Index representing day in Planning (1-based index)
                isSelected: dayKey === selectedActivityBeginDay // Set the selected status based on the selectedActivityBeginDay
            });
        });

        // Organize into weeks (rows of 7 days)
        const weeks = [];
        let currentWeek = Array(7).fill(null);

        calendarDays.forEach((dayObj) => {
            const dayOfWeekIndex = daysOfWeek.indexOf(dayObj.name.slice(0, 2));
            currentWeek[dayOfWeekIndex] = dayObj;

            // When the week is complete (Saturday), push the current week to the weeks array
            if (dayOfWeekIndex === 6) {
                weeks.push(currentWeek);
                currentWeek = Array(7).fill(null);
            }
        });

        // Push the final incomplete week if it exists
        if (currentWeek.some(day => day !== null)) {
            weeks.push(currentWeek);
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '20px' }}>
                {/* Iterate over weeks */}
                {weeks.map((week, weekIndex) => (
                    <Box key={weekIndex} sx={{ display: 'flex', gap: '5px' }}>
                        {/* Iterate over each day in the week */}
                        {week.map((day, dayIndex) => {
                            if (day) {
                                return (
                                    <Chip
                                        key={dayIndex}
                                        label={
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography variant="body2">{day.name.slice(0, 2)}</Typography> {/* Two-letter day */}
                                            </div>
                                        }
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            backgroundColor: day.isSelected
                                                ? 'rgba(0, 123, 255, 0.6)' // Highlight selected day
                                                : 'rgba(0, 123, 255, 0.2)',
                                        }}
                                        clickable
                                        onClick={() => handleActivityBeginDayChange(day.key, day.name)} // Pass full day object on click
                                    />
                                );
                            } else {
                                return <Box key={dayIndex} sx={{ width: '40px', height: '40px' }} />;
                            }
                        })}
                    </Box>
                ))}
            </Box>
        );
    };

    const handleParticipantsToggle = (_attendee_id) => () => {
        console.log("_attendee_id : "+_attendee_id);

        //see if this Attendee needs to be added or removed
        const index = eventActivity["attendees"].findIndex(item => item.id === _attendee_id);

        let updatedList = [...eventActivity["attendees"]];

        if (index !== -1) {
            // Remove attendee from activity list
            updatedList.splice(index, 1);

        } else {

            // Find the attendee in the reference array
            const newAttendee = attendeesReferenceArray.find(
                (attendee) => String(attendee.id) === String(_attendee_id));
//            const newAttendee = attendeesReferenceArray.find(att => att.id === _attendee_id);

            if (!newAttendee) {
                console.error(`Attendee with ID ${_attendee_id} not found.`);
                return;
            }

            // Add to global context if not already present
            const globalAttendees = usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees;
            if (!globalAttendees.some(att => att.id === _attendee_id)) {
                set_PA_GlobalObject(prev => ({
                    Planning: {
                        ...prev.Planning,
                        attendees: [...prev.Planning.attendees, newAttendee],
                    },
                }));
            }

            // Add the attendee to the meal attendee list
            const attendeeWithCost = {
                id: _attendee_id,
                cost: 0,
                is_business_expense: false,
                business_expense_amount_authorized: 0,
                business_expense_amount_authorized_by: "",
                business_expense_amount_authorized_timestamp: 0,
                business_expense_amount: 0,
                business_expense_paid: 0,
                business_expense_reference_id: "",
            };
            updatedList = [...eventActivity.attendees, attendeeWithCost];
        }

        if (eventActivity["assign_equal"]) {

            const activityCost = parseFloat(eventActivity["amount_total"]);
            const numberOfAttendees = updatedList.length;
            const baseCostPerAttendee = parseFloat(Math.floor((activityCost / numberOfAttendees) * 100) / 100).toFixed(2);
            const remainder = Math.round((activityCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;

            //go through and update the per Attendee cost
            updatedList.forEach((attendee, i) => {
                // Add the base cost to the attendee
                attendee.cost = baseCostPerAttendee;

                // Distribute any remainder to the last attendee
                if (i === numberOfAttendees - 1) {
                    attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                }
            });

        }

        setEventActivity(prevState => ({
            ...prevState,
            attendees: updatedList
        }));


        setSelectedAttendeesArray((prevSelected) => {
            const isAlreadySelected = prevSelected.some((attendee) => attendee.id === _attendee_id);

            if (isAlreadySelected) {
                // Remove attendee
                return prevSelected.filter((attendee) => attendee.id !== _attendee_id);
            } else {
                // Find the attendee from reference array and add it
                const newAttendee = attendeesReferenceArray.find((att) => att.id === _attendee_id);
                return newAttendee ? [...prevSelected, newAttendee] : prevSelected;
            }
        });

    };

    const [activityCost, setActivityCost] = useState(eventActivity.amount_total);
    function handleCostOverride(newValue) {
        setActivityCost(newValue);
    }
    function calculateAttendeeCosts(mealCost, attendees) {
        const numberOfAttendees = attendees.length;

        if (numberOfAttendees === 0) return []; // Return empty if no attendees

        const baseCostPerAttendee = parseFloat(
            Math.floor((mealCost / numberOfAttendees) * 100) / 100
        ).toFixed(2);

        const totalAssignedCost = parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2);
        const remainder = Math.round((mealCost - totalAssignedCost) * 100) / 100;

        // Update the attendees array with calculated costs
        return attendees.map((attendee, index) => ({
            ...attendee,
            cost:
                index === numberOfAttendees - 1 // Add remainder to the last attendee
                    ? (parseFloat(baseCostPerAttendee) + parseFloat(remainder)).toFixed(2)
                    : baseCostPerAttendee,
        }));
    }
    function toggleShowOverride(){
        console.log("toggleShowOverride");

        if (showPriceOverride) {
            // They are toggling to "off" - update attendee costs if necessary
            const updatedAttendees = eventActivity["assign_equal"]
                ? calculateAttendeeCosts(parseFloat(activityCost), eventActivity["attendees"])
                : eventActivity["attendees"];

            setEventActivity((prevState) => ({
                ...prevState,
                amount_total: activityCost, // Update the total meal cost
                attendees: updatedAttendees, // Update attendees with recalculated costs
            }));
        }

        if (showPriceOverride){
            //they are toggling to off so update Attendee costs

            if (eventActivity["assign_equal"]) {
                //update the amount assigned to each Occupant

                let updatedList = [...eventActivity["attendees"]];
                let activityCost = parseFloat(eventActivity["amount_total"]);
                const numberOfAttendees = updatedList.length;
                const baseCostPerAttendee = parseFloat(Math.floor((activityCost / numberOfAttendees) * 100) / 100).toFixed(2);
                console.log("baseCostPerAttendee : " + baseCostPerAttendee);
                console.log("participants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
                const remainder = Math.round((activityCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                console.log("remainder : " + remainder);

                //go through and update the per Attendee cost
                updatedList.forEach((attendee, i) => {
                    // Add the base cost to the attendee
                    attendee.cost = baseCostPerAttendee;

                    // Distribute any remainder to the last attendee
                    if (i === numberOfAttendees - 1) {
                        attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                    }

                });

            }
        }

        setShowPriceOverride(!showPriceOverride);
    }

    const openChangeCurrency = Boolean(anchorChangeCurrencyEl);
    const handleOpenChangeCurrency = (event) => {
        setAnchorChangeCurrencyEl(event.currentTarget);
    };
    const handleChangeCurrencyClick = (event, code) => {
        console.log("changing currency to : "+code);
        const _new_currency_code = code;
        setEventActivity(prevState => ({
            ...prevState,
            currency: _new_currency_code
        }));

        //recalculate to see if that changes the Attendees total breakdown
        setCheckShowPriceChanges(true);


        handleChangeCurrencyClose();
    };
    const handleChangeCurrencyClose = () => {
        setAnchorChangeCurrencyEl(null);
    };

    function handleToggleAssignEqual(){
        console.log("handleToggleAssignEqual");

        if (eventActivity["assign_equal"]){
            //they are changing to custom
            console.log("selecting custom costs");

            setEventActivity(prevState => ({
                ...prevState,
                assign_equal: false
            }));

        } else {
            //they are changing to equal
            console.log("selecting equal costs");

            let updatedList = [...eventActivity["attendees"]];

            const activityCost = parseFloat(eventActivity["amount_total"]);
            const numberOfAttendees = updatedList.length;
            const baseCostPerAttendee = parseFloat(Math.floor((activityCost / numberOfAttendees) * 100) / 100).toFixed(2);
            console.log("baseCostPerAttendee : " + baseCostPerAttendee);
            console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
            const remainder = Math.round((activityCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
            console.log("remainder : " + remainder);

            //go through and update the per Attendee cost
            updatedList.forEach((attendee, i) => {
                // Add the base cost to the attendee
                attendee.cost = baseCostPerAttendee;

                // Distribute any remainder to the last attendee
                if (i === numberOfAttendees - 1) {
                    attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                }
            });

            setEventActivity(prevState => ({
                ...prevState,
                attendees: updatedList,
                assign_equal: true
            }));

        }

        setCheckShowPriceChanges(true);

    }

    function handleCustomOccupantCost(_attendee_id, e){
        console.log("handleCustomOccupantCost : "+_attendee_id+" : "+e.target.value);


        //update this state variable
        setEventActivity(prevState => ({
            ...prevState,
            attendees: prevState.attendees.map(attendee => {
                if (attendee.id === _attendee_id) {
                    // Update the cost of the specific attendee
                    console.log("setting custom cost jjhgjhgjgjh : "+ (isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)));
                    return { ...attendee, cost: e.target.value };
                }
                return attendee;
            })
        }));

        console.log("check to see if any pricing changes were made");
        setCheckShowPriceChanges(true);
    }

    const [editModeMap, setEditModeMap] = useState({});
    const [useActivityLimit, setUseActivityLimit] = useState(false); // Enable/Disable lodging limits
    const [activityLimit, setActivityLimit] = useState(""); // Holds the limit value
    const [individualLimits, setIndividualLimits] = useState(
        usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.reduce((acc, attendee) => {
            acc[attendee.id] = {
                activity: "",
            };
            return acc;
        }, {})
    );
    const handleBusinessExpenseToggle = (attendeeId, isChecked) => {
        setEventActivity((prevActivity) => ({
            ...prevActivity,
            attendees: prevActivity.attendees.map((att) =>
                att.id === attendeeId
                    ? {
                        ...att,
                        is_business_expense: isChecked,
                        business_expense_amount: isChecked ? att.cost : 0, // Sync with cost if toggled on
                    }
                    : att
            ),
        }));
    };
    const handleBusinessExpenseAmountEdit = (attendeeId) => {
        setEditModeMap((prev) => ({
            ...prev,
            [attendeeId]: !prev[attendeeId], // Toggle edit mode
        }));
    };
    const handleBusinessExpenseAmountChange = (attendeeId, value) => {
        // Use a regex to allow only valid decimal numbers
        const validInput = value.match(/^\d*\.?\d{0,2}$/); // Allows up to two decimal places
        if (!validInput) return;

        setEventActivity((prevActivity) => ({
            ...prevActivity,
            attendees: prevActivity.attendees.map((att) =>
                att.id === attendeeId
                    ? {
                        ...att,
                        business_expense_amount: value, // Keep raw string for input control
                    }
                    : att
            ),
        }));
    };
    const handleBusinessExpenseEnableOnEdit = (attendeeId) => {
        setEventActivity((prevActivity) => ({
            ...prevActivity,
            attendees: prevActivity.attendees.map((att) =>
                att.id === attendeeId
                    ? {
                        ...att,
                        is_business_expense: true, // Automatically toggle checkbox
                    }
                    : att
            ),
        }));
    };


    function handleSaveActivityCostsAssignment(event){
        setShowPriceChangesUpdateButton(false);
    }


    const [needToSaveSettings, setNeedToSaveSettings] = useState(false);
    const [activityTitle, setActivityTitle] = useState(eventActivity.title || "");
    const [locationName, setLocationName] = useState(eventActivity['location'].name || "");
    const [bookedThrough, setBookedThrough] = useState(eventActivity.booked_through);
    const [confirmationNumber, setConfirmationNumber] = useState(eventActivity.confirmation_number);
    const [reservationName, setReservationName] = useState(eventActivity.reservation_name);
    const [reservationNumber, setReservationNumber] = useState(eventActivity.reservation_number);
    const [website, setWebsite] = useState(eventActivity.website);
    const [bring, setBring] = useState(eventActivity.bring);
    const [details, setDetails] = useState(eventActivity.details);
    const [notes, setNotes] = useState(eventActivity.notes);

    // Store the initial values using useRef
    const initialValues = useRef({
        activityTitle: eventActivity.title || "",
        locationName: eventActivity['location'].name || "",
        bookedThrough: eventActivity.booked_through || "",
        confirmationNumber: eventActivity.confirmation_number || "",
        reservationName: eventActivity.reservation_name || "",
        reservationNumber: eventActivity.reservation_number || "",
        website: eventActivity.website || "",
        bring: eventActivity.bring || "",
        details: eventActivity.details || "",
        notes: eventActivity.notes || ""
    });

    // Check if any value has changed
    useEffect(() => {
        const hasChanges =
            activityTitle !== initialValues.current.activityTitle ||
            locationName !== initialValues.current.locationName ||
            bookedThrough !== initialValues.current.bookedThrough ||
            confirmationNumber !== initialValues.current.confirmationNumber ||
            reservationName !== initialValues.current.reservationName ||
            reservationNumber !== initialValues.current.reservationNumber ||
            website !== initialValues.current.website ||
            bring !== initialValues.current.bring ||
            details !== initialValues.current.details ||
            notes !== initialValues.current.notes;

        setNeedToSaveSettings(hasChanges);
    }, [activityTitle, locationName, bookedThrough, confirmationNumber, reservationName,
        reservationNumber, website, bring, details, notes]);

    function handleTitleChange(event) {
        setActivityTitle(event.target.value);
    }
    function handleLocationNameChange(event) {
        setLocationName(event.target.value);
    }
    function handleBookedThroughChange(event){
        setBookedThrough(event.target.value);
    }
    function handleConfirmationNumberChange(event){
        setConfirmationNumber(event.target.value);
    }
    function handleReservationNameChange(event){
        setReservationName(event.target.value);
    }
    function handleReservationNumberChange(event){
        setReservationNumber(event.target.value);
    }
    function handleWebsiteChange(event){
        setWebsite(event.target.value);
    }
    function handleBringChange(event){
        setBring(event.target.value);
    }
    function handleDetailsChange(event){
        setDetails(event.target.value);
    }
    function handleNotesChange(event){
        setNotes(event.target.value);
    }
    function saveEventActivitySettings() {
        setEventActivity((prevState) => ({
            ...prevState,
            title: activityTitle,
            location: {
                ...prevState.location, // Preserve existing location details
                name: locationName, // Update the activity location name
            },
            booked_through: bookedThrough,
            confirmation_number: confirmationNumber,
            reservation_name: reservationName,
            reservation_number: reservationNumber,
            website: website,
            bring: bring,
            details: details,
            notes: notes
        }));

        // Reset the initial values to the new saved state
        initialValues.current = {
            activityTitle,
            locationName,
            bookedThrough,
            confirmationNumber,
            reservationName,
            reservationNumber,
            website,
            bring,
            details,
            notes
        };

        setNeedToSaveSettings(false);
    }



    const handleFooterItemClick = (_item) => {
        if (_item === 'Delete'){
            //prompt for confirmation
            props.onDeleteActivity();

        } else {
            setSelectedAction(_item);
            activityTabSelected === _item ? setActivityTabSelected("") : setActivityTabSelected(_item);
        }
        setAnchorActivityListItemMenu(null);
    };

    const [openDialogDateRange, setOpenDialogDateRange] = useState(false);
    function handleDialogDateRangeClose() {
        setOpenDialogDateRange(false);
    }
    function handleDialogDateRangeOK(){
        setOpenDialogDateRange(false);
    }

    const calculateElapsedTime = (beginDate, endDate, beginTime, endTime, daysCount = null) => {
        console.log("calculateElapsedTime");

        // Ensure beginDate and endDate are correctly parsed
        beginDate = beginDate ? dayjs(beginDate) : null;
        endDate = endDate ? dayjs(endDate) : beginDate; // Default to beginDate if endDate is missing

        // Ensure beginTime and endTime are correctly parsed (handle null case)
        const parsedBeginTime = beginTime ? dayjs(beginTime, "h:mm A") : null;
        const parsedEndTime = endTime ? dayjs(endTime, "h:mm A") : null;

        // If either date or time is missing, return '0 m'
        if (!beginDate || !parsedBeginTime || !parsedEndTime) {
            console.warn("Missing required date/time values.");
            return "0 m";
        }

        // Apply parsed time to beginDate and endDate
        const beginDateTime = beginDate.hour(parsedBeginTime.hour()).minute(parsedBeginTime.minute());
        const endDateTime = endDate.hour(parsedEndTime.hour()).minute(parsedEndTime.minute());

        console.log("beginDateTime:", beginDateTime.format());
        console.log("endDateTime:", endDateTime.format());

        // Calculate elapsed duration in milliseconds
        const elapsedMilliseconds = endDateTime.diff(beginDateTime);

        if (elapsedMilliseconds < 0) {
            console.warn("End time is before the start time, returning '0 m'.");
            return "0 m"; // Prevent negative durations
        }

        // Convert milliseconds into a duration object
        const elapsed = dayjs.duration(elapsedMilliseconds);

        console.log("elapsed duration:", elapsed.asMilliseconds(), "ms");

        const days = Math.floor(elapsed.asDays());
        const hours = elapsed.hours();
        const minutes = elapsed.minutes();

        console.log("days:", days, "hours:", hours, "minutes:", minutes);

        // Construct output
        const elapsedTimeComponents = [];
        if (days > 0) elapsedTimeComponents.push(`${days} day${days === 1 ? '' : 's'}`);
        if (hours > 0) elapsedTimeComponents.push(`${hours} hr${hours === 1 ? '' : 's'}`);
        if (minutes > 0) elapsedTimeComponents.push(`${minutes} min${minutes === 1 ? '' : 's'}`);

        return elapsedTimeComponents.join(" ") || "0 m";
    };
    // At the top of your component (or right before return if it uses eventActivity):
    const duration = useMemo(() => {
        const { time_table } = eventActivity;

        if (time_table?.dates?.length > 0) {
            const firstEntry = time_table.dates[0];
            const lastEntry = time_table.dates[time_table.dates.length - 1];

            if (firstEntry['all-day']) return 'All Day';

            const beginDate = dayjs(firstEntry.date, "YYYY-MM-DD");
            const endDate = dayjs(lastEntry.date, "YYYY-MM-DD");
            const beginTime = firstEntry['begin_time'] ? dayjs(firstEntry['begin_time'], "h:mm A") : null;
            const endTime = lastEntry['end_time'] ? dayjs(lastEntry['end_time'], "h:mm A") : null;

            if (!beginTime?.isValid() || !endTime?.isValid()) return '';

            return calculateElapsedTime(
                beginDate,
                endDate,
                beginTime.format('h:mm A'),
                endTime.format('h:mm A')
            );
        }

        if (time_table?.days?.length > 0) {
            const firstDayEntry = time_table.days[0];
            const lastDayEntry = time_table.days[time_table.days.length - 1];

            if (firstDayEntry['all-day']) {
                return time_table.days.length === 1 ? 'All Day' : `${time_table.days.length} Days`;
            }

            const beginTime = firstDayEntry['begin_time'] ? dayjs(firstDayEntry['begin_time'], "h:mm A") : null;
            const endTime = lastDayEntry['end_time'] ? dayjs(lastDayEntry['end_time'], "h:mm A") : null;

            if (!beginTime?.isValid() || !endTime?.isValid()) return '';

            const daysCount = time_table.days.length;

            return calculateElapsedTime(
                dayjs().startOf('day'),
                dayjs().startOf('day').add(daysCount - 1, 'day'),
                beginTime,
                endTime,
                daysCount
            );
        }

        return '';
    }, [eventActivity]);


    const [anchorActivityListItemMenu, setAnchorActivityListItemMenu] = useState(null);
    const handleActivityListItemMenuOpenMenu = (event) => {
        setAnchorActivityListItemMenu(event.currentTarget);
    };
    const handleActivityListItemMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorActivityListItemMenu(event.currentTarget);

    };
    const handleActivityListItemMenuClose = () => {
        setAnchorActivityListItemMenu(null);
    };


    return (


        <div>

            <Card
                sx={{
                    borderRadius: '5px',
                    border: '1px solid #f09637'
                }}
            >

                {/* Add any photos if they exist */}
                {
                    (eventActivity['attendee_photos'] && eventActivity['attendee_photos'].length > 0
                        ? eventActivity['attendee_photos']
                        : eventActivity['photos'])?.length > 0 && (
                        <div className="activities-suggestions-add-list-item-photos">
                            <div className="horizontal-scroll-container">
                                {
                                    (eventActivity['attendee_photos'] && eventActivity['attendee_photos'].length > 0
                                            ? eventActivity['attendee_photos']
                                            : eventActivity['photos']
                                    ).map((photo, index) => (
                                        <CardMedia
                                            key={index}
                                            component="img"
                                            alt={`Photo ${index + 1}`}
                                            image={photo.thumbnail_data || photo.full_data || photo} // Adjust if needed
                                            className="photo"
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    )
                }

                <CardHeader
                    title={(() => {
                        const { time_table } = eventActivity;

                        if (!time_table?.dates?.length) {
                            return ''; // 🛑 Prevents displaying empty or invalid data
                        }

                        // Extract the first and last dates safely
                        const firstDate = time_table.dates[0]?.date || null;
                        const lastDate = time_table.dates[time_table.dates.length - 1]?.date || null;

                        // Validate the dates before parsing
                        const startDateObj = firstDate ? dayjs(firstDate, "YYYY-MM-DD") : null;
                        const endDateObj = lastDate ? dayjs(lastDate, "YYYY-MM-DD") : null;

                        if (!startDateObj?.isValid() || !endDateObj?.isValid()) {
                            return ''; // 🛑 Prevents "Invalid Date" from appearing
                        }

                        // ✅ Format the day and date
                        const formattedStartDate = startDateObj.format('ddd, MMM D'); // e.g., "Fri, Mar 4"
                        const formattedEndDate = !startDateObj.isSame(endDateObj, 'day')
                            ? ` - ${endDateObj.format('ddd, MMM D')}` // e.g., "Sat, Mar 5"
                            : ''; // No need to repeat if it's the same date

                        return `${formattedStartDate}${formattedEndDate}`;

                    })()}

                    action={
                        <IconButton
                            aria-label="settings"
                            onClick={handleActivityListItemMenuOpenMenu}
                        >
                            <i
                                className="icon-menu2"
                                style={{fontSize: '16px'}}
                            />
                        </IconButton>
                    }
                    sx={{
                        padding: eventActivity['photos'] && eventActivity['photos'].length > 0 ? '0 10px 0 10px':'10px 10px 0 10px',
                        "& .MuiCardHeader-title": {
                            fontSize: "1rem",  // Set font size for the title
                        },
                    }}
                    titleTypographyProps={{ fontSize: '18px' }}
                />
                <Menu
                    sx={{ width: 320, maxWidth: '100%' }}
                    anchorEl={anchorActivityListItemMenu}
                    id="activity-item-menu"
                    open={Boolean(anchorActivityListItemMenu)}
                    onClose={handleActivityListItemMenuClose}

                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuList
                        dense
                        sx={{ width: 320, maxWidth: '100%' }}
                    >
                        <MenuItem
                            data-id="participants"
                            onClick={() => handleFooterItemClick('Participants')}
                        >
                            <ListItemIcon sx={{color:'#428ce2'}}>
                                <i className="icon-group" title="Participants"/>
                            </ListItemIcon>
                            <ListItemText>Participants</ListItemText>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {eventActivity['attendees'].length}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            data-id="costs"
                            onClick={() => handleFooterItemClick('Costs')}
                        >
                            <ListItemIcon sx={{color:'#428ce2'}}>
                                <i className="icon-wallet" title="Costs"/>
                            </ListItemIcon>
                            <ListItemText>Costs</ListItemText>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {parseFloat(eventActivity['amount_total']).toFixed(2)} {eventActivity['currency']}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            data-id="photos"
                            onClick={() => handleFooterItemClick('Photos')}
                            disabled={!dataArray.authenticated}
                        >
                            <ListItemIcon sx={{color:'#428ce2'}}>
                                <i className="icon-picture2" title="Photos"/>
                            </ListItemIcon>
                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <ListItemText primary="Photos" />

                                {dataArray.authenticated ? (
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {eventActivity['attendee_photos']?.filter(
                                            (photo) => !photo.private || photo.attendee_id === Account.id
                                        ).length}
                                    </Typography>
                                ) : (
                                    <i className="icon-lock" style={{ fontSize: '16px', color: '#999' }} title="Login required" />
                                )}
                            </Box>
                        </MenuItem>
                        <MenuItem
                            data-id="dates"
                            onClick={() => handleFooterItemClick('Dates')}
                        >
                            <ListItemIcon sx={{color:'#428ce2'}}>
                                <i className="icon-calendar3" title="Dates"/>
                            </ListItemIcon>
                            <ListItemText>Dates / Days</ListItemText>
                        </MenuItem>
                        <MenuItem
                            data-id="location"
                            onClick={() => handleFooterItemClick('Location')}
                        >
                            <ListItemIcon sx={{color:'#428ce2'}}>
                                <i className="icon-map-marker" title="Location"/>
                            </ListItemIcon>
                            <ListItemText>Location</ListItemText>
                        </MenuItem>
                        <MenuItem
                            data-id="details"
                            onClick={() => handleFooterItemClick('Details')}
                        >
                            <ListItemIcon sx={{color:'#428ce2'}}>
                                <i className="icon-checklist2" title="Details"/>
                            </ListItemIcon>
                            <ListItemText>Details</ListItemText>
                        </MenuItem>
                        <MenuItem
                            data-id="delete"
                            onClick={() => handleFooterItemClick('Delete')}
                        >
                            <ListItemIcon sx={{color:'#F00'}}>
                                <i className="icon-trash" title="Delete"/>
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Menu>

                {/* Dates (beneath CardHeader) */}
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '0 16px 0 10px' }}>
                    {duration && (
                        <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                            Duration:&nbsp;{duration}
                        </Typography>
                    )}
                </Box>

                {/* show the Activity timeline */}
                <CardContent sx={{padding:0}}>

                    <Timeline
                        sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.45,
                            },
                            [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                            },
                            paddingBottom: '10px',
                            margin: 0
                        }}
                    >
                        <TimelineItem sx={{marginTop:0, minHeight:0}}>
                            <TimelineOppositeContent>
                                {
                                    eventActivity.time_table?.dates?.length > 0 ? (
                                        eventActivity.time_table.dates[0]['all-day'] ? (
                                            <Typography variant="subtitle2" color="textSecondary" component="div">
                                                All Day
                                            </Typography>
                                        ) : (
                                            <>
                                                {eventActivity.time_table.dates[0].begin_time && (
                                                    <Typography variant="subtitle2" color="textSecondary" component="div">
                                                        {formatTime(eventActivity.time_table.dates[0].begin_time)}
                                                    </Typography>
                                                )}
                                                {eventActivity.time_table.dates[eventActivity.time_table.dates.length - 1].end_time && (
                                                    <Typography variant="subtitle2" color="textSecondary" component="div">
                                                        {formatTime(eventActivity.time_table.dates[eventActivity.time_table.dates.length - 1].end_time)}
                                                    </Typography>
                                                )}
                                            </>
                                        )
                                    ) : eventActivity.time_table?.days?.length > 0 ? (
                                        eventActivity.time_table.days[0]['all-day'] ? (
                                            <Typography variant="subtitle2" color="textSecondary" component="div">
                                                All Day
                                            </Typography>
                                        ) : (
                                            <>
                                                {eventActivity.time_table.days[0].begin_time && (
                                                    <Typography variant="subtitle2" color="textSecondary" component="div">
                                                        {formatTime(eventActivity.time_table.days[0].begin_time)}
                                                    </Typography>
                                                )}
                                                {eventActivity.time_table.days[eventActivity.time_table.days.length - 1].end_time && (
                                                    <Typography variant="subtitle2" color="textSecondary" component="div">
                                                        {formatTime(eventActivity.time_table.days[eventActivity.time_table.days.length - 1].end_time)}
                                                    </Typography>
                                                )}
                                            </>
                                        )
                                    ) : null
                                }
                            </TimelineOppositeContent>
                            <TimelineContent
                                sx={{
                                    borderLeft: '1px solid #428ce2',
                                    padding: '0 !important'
                                }}
                            >
                                <Typography sx={{ mb: 1.5, paddingLeft:'5px'}} variant="body2">
                                    {eventActivity['title']}
                                </Typography>
                                <Typography sx={{ fontSize:'12px', paddingLeft:'5px' }} color="text.secondary">
                                    {eventActivity['location'].name}
                                </Typography>
                                <Typography sx={{ fontSize:'12px', paddingLeft:'5px' }} color="text.secondary">
                                    {eventActivity['location'].address}
                                </Typography>
                                <Typography sx={{ mb: 1.5, fontSize:'12px', paddingLeft:'5px' }} color="text.secondary">
                                    {eventActivity['location'].city} {eventActivity['location'].state} {eventActivity['location'].zip}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>

                    {/* show Notes if there are any */}
                    {
                        eventActivity.notes && eventActivity.notes.length > 0 ? (
                            <Typography variant="caption" sx={{display:"block", marginLeft:"20px"}} color="text.secondary">
                                Notes: {eventActivity.notes}
                            </Typography>
                         ): null
                    }
                    {/* show Details if there are any */}
                    {
                        eventActivity.details && eventActivity.details.length > 0 ? (
                            <Typography variant="caption" sx={{display:"block", marginLeft:"20px"}} color="text.secondary">
                                Details: {eventActivity.details}
                            </Typography>
                        ): null
                    }
                    {/* show the Bring list if there are any items*/}
                    {
                        eventActivity.bring && eventActivity.bring.length > 0 && (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, marginLeft:"20px" }}>
                                <Typography variant="caption" color="text.secondary" sx={{ width: '100%' }}>
                                    Bring:
                                </Typography>
                                {(typeof eventActivity.bring === 'string'
                                        ? eventActivity.bring.split('\n')
                                        : eventActivity.bring
                                )
                                    .map(item => item.trim())                      // Trim whitespace
                                    .filter(item => item.length > 0)               // Remove empty items
                                    .map((item, index) => (
                                        <Chip key={index} label={item} />
                                    ))}
                            </Box>
                        )
                    }


                </CardContent>

                {/* show the Participants in this Activity */}
                <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                        {
                            eventActivity.attendees.map((_a, index) => {
                                const attendee = attendeesReferenceArray.find((_att) => _att.id === _a.id);

                                if (!attendee) return null; // ⛔️ Safeguard

                                const fullName = `${formatName(attendee.fname)} ${formatName(attendee.lname)}`;

                                return (
                                    <Tooltip key={index} title={fullName} arrow>
                                        {
                                            !attendee.avatar || !attendee.avatar.preview ?  (
                                                <Avatar
                                                    {...stringAvatar(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}
                                                    sx={{
                                                        zIndex: index,
                                                        marginRight: index !== eventActivity['attendees'].length - 1 ? '-7px' : 0,
                                                        boxShadow:
                                                            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                            '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                        border: `1px solid ${stringToColor(fullName)}`,
                                                        borderRadius: '15px',
                                                        textAlign: 'center',
                                                        backgroundColor: '#fff',
                                                        color: 'rgba(0, 0, 0, 0.87)',
                                                        fontWeight: '400',
                                                        fontSize: '14px',
                                                        width: '25px',
                                                        height: '25px',
                                                    }}
                                                />
                                            ) : (
                                                <Avatar
                                                    src={attendee.avatar.preview}
                                                    sx={{
                                                        zIndex: index,
                                                        marginRight: index !== eventActivity['attendees'].length - 1 ? '-7px' : 0,
                                                        boxShadow:
                                                            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                            '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                        border: `1px solid ${stringToColor(fullName)}`,
                                                        borderRadius: '15px',
                                                        textAlign: 'center',
                                                        backgroundColor: '#fff',
                                                        color: 'rgba(0, 0, 0, 0.87)',
                                                        fontWeight: '400',
                                                        fontSize: '14px',
                                                        width: '25px',
                                                        height: '25px',
                                                    }}
                                                />
                                            )
                                        }

                                    </Tooltip>
                                );
                            })
                        }

                    </Box>
                </CardActions>

                {/* this is the area that shows/hides the selected configuration options */}
                <CardContent sx={{padding:'20px 0', display:activityTabSelected?'block':'none'}}>
                    {
                        selectedAction === "Dates" ? (
                            <Box className="activities-suggestions-add-list-item-footer-dates-holder">

                                <Grid item sx={{maxWidth: '100%'}} >
                                    <TabContext sx={{ position:'relative'}} value={tabValue}>
                                        <TabList
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: 'divider',
                                                backgroundColor: 'rgba(251, 251, 251, 0.8)',
                                                backdropFilter: 'blur(6px)',
                                            }}
                                            centered
                                            variant="fullWidth"
                                            onChange={handleTabChange}
                                            aria-label="Meal Date / Day"
                                        >
                                            <Tab
                                                label="Date"
                                                value="date"
                                            />
                                            <Tab
                                                label="Day"
                                                value="day"
                                            />
                                        </TabList>
                                        <TabPanel value="date" sx={{padding: '24px 0'}}>
                                            <div
                                                className="activities-suggestions-add-list-item-footer-dates-checkin"
                                            >
                                                Begin Date :&nbsp;
                                                {
                                                    eventActivity['time_table'].dates[0]?.date
                                                        ? dayjs(eventActivity['time_table'].dates[0].date, 'YYYY-MM-DD').isValid()
                                                            ? dayjs(eventActivity['time_table'].dates[0].date, 'YYYY-MM-DD').format('D MMM, YYYY')
                                                            : '' // If invalid date
                                                        : '' // If no date exists
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showCalendarSelectBeginDate ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowActivityBeginDateCalendar}
                                                />
                                            </div>
                                            <div
                                                className="activities-suggestions-add-list-item-footer-dates-checkin-input"
                                                style={{display: showCalendarSelectBeginDate ? 'block' : 'none'}}>
                                                <DatePicker
                                                    sx={{width: '100%'}}
                                                    label="Begin Date"
                                                    value={dayjs(eventActivity['time_table'].dates[0]?.date, 'YYYY-MM-DD')}
                                                    onChange={handleBeginDateChange}
                                                />
                                            </div>

                                            {/* for the begin time */}
                                            <div
                                                className="activities-suggestions-add-list-item-footer-times-begin"
                                            >
                                                Begin Time :&nbsp;
                                                {
                                                    beginTime || ''
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showClockSelectBeginTime ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowActivityBeginTime}
                                                />
                                            </div>
                                            <div
                                                className="activities-suggestions-add-list-item-footer-times-begin-input"
                                                style={{display: showClockSelectBeginTime ? 'block' : 'none'}}>
                                                <TimePicker
                                                    sx={{width: '100%'}}
                                                    label="Begin Time"
                                                    value={dayjs(beginTime, 'h:mm A')}
                                                    onChange={handleBeginTimeChange}
                                                />
                                            </div>


                                            <div
                                                className="activities-suggestions-add-list-item-footer-dates-checkout"
                                            >
                                                End Date :&nbsp;
                                                {
                                                    eventActivity['time_table'].dates[eventActivity['time_table'].dates.length - 1]?.date
                                                        ? dayjs(eventActivity['time_table'].dates[eventActivity['time_table'].dates.length - 1].date, 'YYYY-MM-DD').isValid()
                                                            ? dayjs(eventActivity['time_table'].dates[eventActivity['time_table'].dates.length - 1].date, 'YYYY-MM-DD').format('D MMM, YYYY')
                                                            : '' // If invalid date
                                                        : '' // If no date exists
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showCalendarSelectEndDate ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowActivityEndDateCalendar}
                                                />
                                            </div>
                                            <div
                                                className="activities-suggestions-add-list-item-footer-dates-checkout-input"
                                                style={{display: showCalendarSelectEndDate ? 'block' : 'none'}}>
                                                <DatePicker
                                                    sx={{width: '100%'}}
                                                    label="End Date"
                                                    value={dayjs(eventActivity['end_date'], 'MM/DD/YYYY')}
                                                    minDate={dayjs(eventActivity['begin_date'], 'MM/DD/YYYY')}
                                                    onChange={handleEndDateChange}
                                                />
                                            </div>

                                            {/* for the end time */}
                                            <div
                                                className="activities-suggestions-add-list-item-footer-times-end"
                                            >
                                                End Time :&nbsp;
                                                {
                                                    endTime || ''
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showClockSelectEndTime ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowActivityEndTime}
                                                />
                                            </div>
                                            <div
                                                className="activities-suggestions-add-list-item-footer-times-end-input"
                                                style={{display: showClockSelectEndTime ? 'block' : 'none'}}>
                                                <TimePicker
                                                    sx={{width: '100%'}}
                                                    label="End Time"
                                                    value={dayjs(endTime, 'h:mm A') || ''}
                                                    onChange={handleEndTimeChange}
                                                />
                                            </div>

                                        </TabPanel>

                                        <TabPanel value="day" sx={{padding: '24px 0'}}>
                                            <div
                                                className="activities-suggestions-add-list-item-footer-date"
                                            >
                                                Begin Day :&nbsp;
                                                {
                                                    (() => {
                                                        const {time_table} = eventActivity;

                                                        // If no dates, check for time_table.days
                                                        if (time_table?.days?.length > 0) {
                                                            return time_table.days[0][Object.keys(time_table.days[0])[0]]; // Display day (e.g., 'Day 1')
                                                        }

                                                        // If neither, display nothing
                                                        return '';
                                                    })()
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showSelectActivityBeginDay ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowActivityBeginDaySelect}
                                                />
                                            </div>

                                            <div
                                                className="activities-suggestions-add-list-item-footer-date-input"
                                                style={{display: showSelectActivityBeginDay ? 'block' : 'none'}}
                                            >

                                                {
                                                    renderActivityBeginDayLayout()
                                                }

                                            </div>
                                        </TabPanel>
                                    </TabContext>
                                </Grid>

                            </Box>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Location" ? (
                            <div className="activities-suggestions-add-list-item-footer-location-holder">
                                <Grid container spacing={2} direction="column" sx={{marginTop: '0'}}>
                                    <ActivityLocationTab
                                        eventActivity={eventActivity}
                                    />
                                </Grid>
                            </div>
                        ) : (
                            <></>
                        )
                    }

                    {
                        selectedAction === "Participants" && (
                            <List dense sx={{ width: '100%' }}>
                                {selectedAttendeesArray
                                    .sort((a, b) =>
                                        `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(
                                            `${formatName(b.lname)}, ${formatName(b.fname)}`
                                        )
                                    )
                                    .map((_attendee, index) => (
                                        <div key={index}>
                                            <ListItem
                                                secondaryAction={
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={handleParticipantsToggle(_attendee.id)}
                                                        checked={selectedAttendeesArray.some(_att => _att.id === _attendee.id)}
                                                        inputProps={{ 'aria-labelledby': index }}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemAvatar>
                                                        {
                                                            _attendee.isSample ? (
                                                                <Chip
                                                                    label="SMPL"
                                                                    size="small"
                                                                    sx={{
                                                                        bgcolor: "#f0f0f0",
                                                                        color: "#666",
                                                                        fontWeight: "bold",
                                                                        fontSize: "11px",
                                                                        height: "16px",
                                                                        borderRadius: "5px",
                                                                        border: "1px solid #ddd",
                                                                        "& > .MuiChip-label": {
                                                                            padding: "0 4px",  // Optional: Adjust color for better readability
                                                                        },
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}
                                                                    sx={{
                                                                        boxShadow: '' +
                                                                            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                                            '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                                        border: `1px solid ${stringToColor(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}`,
                                                                        borderRadius: '10px',
                                                                        textAlign: 'center',
                                                                        fontWeight: '700',
                                                                        backgroundColor: '#FFF',
                                                                        color: 'black',
                                                                        fontSize: '15px',
                                                                        width: '30px',
                                                                        height: '30px',
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        id={index}
                                                        primary={`${formatName(_attendee.lname)}, ${formatName(_attendee.fname)}`}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    ))
                                }

                                {/* Optional autocomplete for adding attendees */}
                                <Box mt={6} mb={1}>
                                    <Divider />
                                </Box>

                                {/* Autocomplete for adding attendees */}
                                <Autocomplete
                                    multiple
                                    options={[
                                        // Event Attendees (Already in the Event but not selected)
                                        ...usePA_GlobalStore
                                            .getState()
                                            .PA_GlobalObject.Planning.attendees
                                            .filter(
                                                (eventAttendee) =>
                                                    !eventAttendee.planner && // Exclude planners
                                                    !selectedAttendeesArray.some((selected) => selected.id === eventAttendee.id)
                                            )
                                            .sort((a, b) =>
                                                `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(
                                                    `${formatName(b.lname)}, ${formatName(b.fname)}`
                                                )
                                            )
                                            .map((attendee) => ({ ...attendee, group: "Event Attendees" })),

                                        // Non-Event Attendees: First show isSample=false, then isSample=true
                                        ...attendeesReferenceArray
                                            .filter(
                                                (referenceAttendee) =>
                                                    !referenceAttendee.planner && // Exclude planners
                                                    !usePA_GlobalStore
                                                        .getState()
                                                        .PA_GlobalObject.Planning.attendees.some(
                                                            (eventAttendee) => eventAttendee.id === referenceAttendee.id
                                                        ) &&
                                                    !selectedAttendeesArray.some((selected) => selected.id === referenceAttendee.id)
                                            )
                                            .sort((a, b) => {
                                                // First sort by `isSample`, pushing samples to the bottom
                                                if (a.isSample !== b.isSample) {
                                                    return a.isSample ? 1 : -1; // Non-sample attendees come first
                                                }
                                                // Then sort alphabetically
                                                return `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(
                                                    `${formatName(b.lname)}, ${formatName(b.fname)}`
                                                );
                                            })
                                            .map((attendee) => ({
                                                ...attendee,
                                                group: attendee.isSample ? "Sample Attendees" : "Other Contacts",
                                            })),
                                    ]}
                                    groupBy={(option) => option.group} // Group by category
                                    getOptionLabel={(option) => `${formatName(option.lname)}, ${formatName(option.fname)}`}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <div
                                                style={{
                                                    padding: "4px 10px",
                                                    fontWeight: "bold",
                                                    backgroundColor: "#f0f0f0",
                                                }}
                                            >
                                                {params.group}
                                            </div>
                                            <ul style={{ padding: 0, margin: 0 }}>{params.children}</ul>
                                        </li>
                                    )}
                                    renderOption={(props, option) => (
                                        <ListItem {...props} key={option.id}>
                                            <ListItemAvatar>
                                                {
                                                    option.isSample ? (
                                                        <Chip
                                                            label="SMPL"
                                                            size="small"
                                                            sx={{
                                                                bgcolor: "#f0f0f0",
                                                                color: "#666",
                                                                fontWeight: "bold",
                                                                fontSize: "11px",
                                                                height: "16px",
                                                                borderRadius: "5px",
                                                                border: "1px solid #ddd",
                                                                "& > .MuiChip-label": {
                                                                    padding: "0 4px",
                                                                },
                                                            }}
                                                        />
                                                    ) : (
                                                        <Avatar
                                                            {...stringAvatar(`${formatName(option.fname)}, ${formatName(option.lname)}`)}
                                                            sx={{
                                                                boxShadow:
                                                                    "0px 3px 1px -2px rgba(0, 0, 0, 0.2), " +
                                                                    "0px 2px 2px 0px rgba(0, 0, 0, 0.14), " +
                                                                    "0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                                                                border: `1px solid ${stringToColor(`${formatName(option.fname)}, ${formatName(option.lname)}`)}`,
                                                                borderRadius: "10px",
                                                                textAlign: "center",
                                                                fontWeight: "700",
                                                                backgroundColor: "#FFF",
                                                                color: "black",
                                                                fontSize: "15px",
                                                                width: "30px",
                                                                height: "30px",
                                                            }}
                                                        />
                                                    )
                                                }
                                            </ListItemAvatar>
                                            <ListItemText primary={`${formatName(option.lname)}, ${formatName(option.fname)}`} />
                                        </ListItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Select Attendees"
                                            placeholder="Search Attendees"
                                        />
                                    )}
                                    onChange={(event, newValue) => {
                                        newValue.forEach((newAttendee) => {
                                            handleParticipantsToggle(newAttendee.id)();
                                        });
                                    }}
                                />
                            </List>
                        )
                    }

                    {
                        selectedAction === "Costs" ? (
                            <div className="activities-suggestions-add-list-item-footer-costs-holder">
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {/* Left-aligned Cost Summary */}
                                    <Typography variant="body2">Cost Summary</Typography>

                                    {/* Right-aligned Receipt Icon */}
                                    {
                                        usePA_GlobalStore.getState().PA_GlobalObject.Planning['receipts'].some(_receipt => _receipt.section_item === eventActivity.id) && (
                                            <i className="icon-receipt" />
                                        )
                                    }
                                </Box>

                                <div
                                    className="activities-suggestions-add-list-item-footer-costs-total-holder"
                                >
                                    <div style={{cursor:'pointer'}} onClick={toggleShowOverride}>
                                        <Chip
                                            sx={editChipStyle}
                                            label={showPriceOverride?'Done':'Edit'}
                                            size="small"
                                        />
                                    </div>
                                    <div className="activities-suggestions-add-list-item-footer-costs-total">
                                        Total:&nbsp;
                                        {parseFloat(eventActivity['amount_total']).toFixed(2)}
                                        <IconButton
                                            sx={{fontSize: '12px', color:'initial'}}
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={openChangeCurrency ? 'long-menu' : undefined}
                                            aria-expanded={openChangeCurrency ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleOpenChangeCurrency}
                                        >
                                            {eventActivity['currency']}
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorChangeCurrencyEl}
                                            open={openChangeCurrency}
                                            onClose={handleChangeCurrencyClose}
                                        >
                                            {currencyList.map((option) => (
                                                <MenuItem sx={{fontSize:'12px'}} key={option.code} selected={option.code === eventActivity['currency']} onClick={(e)=>handleChangeCurrencyClick(e, option.code)}>
                                                    {option.code} : {option.name}
                                                </MenuItem>
                                            ))}
                                        </Menu>

                                    </div>
                                </div>
                                <div
                                    className="activities-suggestions-add-list-item-footer-costs-override-holder"
                                    style={{display:showPriceOverride?'block':'none'}}
                                >
                                    <div
                                        className="activities-suggestions-add-list-item-footer-costs-override-input-holder"
                                    >
                                        <TextField
                                            label="Activity Total"
                                            value={activityCost}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;

                                                // Allow only numbers, commas, and decimal points
                                                const regex = /^[0-9.,]*$/;
                                                if (regex.test(inputValue)) {
                                                    const formattedValue = inputValue.replace(/,/g, ""); // Remove commas for consistency
                                                    handleCostOverride(formattedValue); // Call your existing function with the cleaned value
                                                }
                                            }}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>

                                <Divider />

                                {/* _new_activity is the Assignment area */}
                                <div className="activities-suggestions-add-list-item-footer-cost-assignment-holder" >
                                    <Typography variant="body2">
                                        Cost Assignment
                                    </Typography>
                                    {
                                        usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.length === 0 ? (
                                            <div
                                                className="activities-suggestions-add-list-item-footer-cost-assignment-no-attendees"
                                            >
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                    <i className="icon-warning" style={{color:'#FF0000'}} /> Add Attendees to Event first
                                                </Typography>
                                            </div>
                                        ) : (

                                            <div className="activities-suggestions-add-list-item-footer-cost-assignment-holder">
                                                {/* _new_activity is the toggle section */}
                                                <div className="activities-suggestions-add-list-item-footer-cost-assignment-toggle-holder">
                                                    <ButtonGroup variant="text" aria-label="outlined button group">
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: eventActivity['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Divide Evenly
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: !eventActivity['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Custom
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>

                                                {/* _new_activity is the attendee section */}
                                                <List dense sx={{ width: '100%'}} >
                                                    {
                                                        eventActivity['attendees']
                                                            .sort((a, b) => {
                                                                const _a = attendeesReferenceArray.find((_att) => _att.id === a.id) || { lname: '', fname: '' }; // Default to empty attendee
                                                                const _b = attendeesReferenceArray.find((_att) => _att.id === b.id) || { lname: '', fname: '' };

                                                                // Safeguard against undefined lname
                                                                return _a.lname.localeCompare(_b.lname);
                                                            })
                                                            .map((_attendee, index) => {
                                                                const attendee = attendeesReferenceArray.find((_att) => _att.id === _attendee.id);

                                                                return (
                                                                    <div
                                                                        className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-cost-holder"
                                                                        key={index}
                                                                    >
                                                                        <ListItem
                                                                            className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item"
                                                                            secondaryAction={
                                                                                eventActivity['assign_equal'] ? (
                                                                                    <span
                                                                                        className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-cost">
                                                                                        {parseFloat(_attendee.cost || 0).toFixed(2)}&nbsp;{eventActivity['currency']}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-edit-cost">
                                                                                        <TextField
                                                                                            label={`${formatName(attendee.lname)}, ${formatName(attendee.fname)}`}
                                                                                            value={_attendee.cost}
                                                                                            onChange={(e) => handleCustomOccupantCost(_attendee.id, e)}
                                                                                            variant="standard"
                                                                                        />
                                                                                    </span>
                                                                                )
                                                                            }
                                                                            disablePadding
                                                                        >
                                                                            <ListItemAvatar>
                                                                                {attendee && (
                                                                                    <Avatar
                                                                                        {...stringAvatar(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}
                                                                                        sx={{
                                                                                            boxShadow: '' +
                                                                                                '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                                                                '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                                                                '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                                                            border: `1px solid ${stringToColor(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}`,
                                                                                            borderRadius: '5px',
                                                                                            textAlign: 'center',
                                                                                            backgroundColor: '#FFF',
                                                                                            color: 'black',
                                                                                            fontSize: '14px',
                                                                                            width: '25px',
                                                                                            height: '25px',
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </ListItemAvatar>

                                                                            <ListItemText
                                                                                sx={{display: eventActivity['assign_equal'] ? 'block' : 'none'}}
                                                                                id={index}
                                                                                primary={attendee ? `${formatName(attendee.lname)}, ${formatName(attendee.fname)}` : ''}
                                                                            />
                                                                        </ListItem>


                                                                        {/* Business Expense Section */}
                                                                        <div
                                                                            className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item"
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'space-between',
                                                                                width: '100%' // Ensure full width usage
                                                                            }}
                                                                        >
                                                                            {/* Left Section: Business Expense */}
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                flexShrink: 0 // Prevents shrinking
                                                                            }}>
                                                                                <Checkbox
                                                                                    checked={_attendee.is_business_expense || false}
                                                                                    onChange={(e) => handleBusinessExpenseToggle(_attendee.id, e.target.checked)}
                                                                                    sx={{ padding: 0, marginRight: '15px' }}
                                                                                />
                                                                                <span>Business<br />Expense</span>
                                                                            </div>

                                                                            {/* Middle Section: TextField or Amount */}
                                                                            <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', marginRight: '10px' }}>

                                                                                {editModeMap[_attendee.id] ? (
                                                                                    <TextField
                                                                                        label="Amount"
                                                                                        value={_attendee.business_expense_amount === 0 ? "" : _attendee.business_expense_amount}
                                                                                        onChange={(e) => {
                                                                                            const inputValue = e.target.value;

                                                                                            // Allow only numbers, commas, and decimal points
                                                                                            const regex = /^[0-9.,]*$/;
                                                                                            if (regex.test(inputValue)) {
                                                                                                const formattedValue = inputValue.replace(/,/g, ""); // Remove commas for consistency
                                                                                                handleBusinessExpenseAmountChange(_attendee.id, formattedValue); // Call the handler with cleaned value
                                                                                            }
                                                                                        }}
                                                                                        variant="standard"
                                                                                        onFocus={() => handleBusinessExpenseEnableOnEdit(_attendee.id)}
                                                                                        sx={{
                                                                                            minWidth: '80px',
                                                                                            maxWidth: '100px',
                                                                                            textAlign: 'right' // Ensures text inside input aligns right
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <span
                                                                                        className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-cost"
                                                                                    >
                                                                                    {parseFloat(_attendee.business_expense_amount || 0).toFixed(2)}&nbsp;{eventActivity['currency']}
                                                                                </span>
                                                                                )}
                                                                            </div>

                                                                            {/* Right Section: Edit/Check Icon */}
                                                                            <div>
                                                                                {editModeMap[_attendee.id] ? (
                                                                                    <i className='icon-check'
                                                                                       style={{ cursor: 'pointer', fontSize: '16px' }}
                                                                                       onClick={() => handleBusinessExpenseAmountEdit(_attendee.id)}
                                                                                    />
                                                                                ) : (
                                                                                    <i className='icon-pencil'
                                                                                       style={{ cursor: 'pointer', fontSize: '14px' }}
                                                                                       onClick={() => handleBusinessExpenseAmountEdit(_attendee.id)}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        {/*  if there are any business authorization limits put them here  */}
                                                                        {(useActivityLimit || individualLimits[_attendee.id]?.activities) && (
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="textSecondary"
                                                                                sx={{ mt: 1, fontStyle: 'italic', textAlign: 'right', fontSize:'12px' }}
                                                                            >
                                                                                Auth:
                                                                                <strong>
                                                                                    {individualLimits[_attendee.id]?.activities
                                                                                        ? `${parseFloat(individualLimits[_attendee.id].activities || 0).toLocaleString()}`
                                                                                        : `${parseFloat(activityLimit || 0).toLocaleString()}`}
                                                                                    &nbsp;{eventActivity['currency']} </strong> /Activity
                                                                            </Typography>
                                                                        )}

                                                                        {/* Divider */}
                                                                        <Divider/>

                                                                    </div>

                                                                );
                                                            })
                                                    }
                                                </List>

                                                {/* show the unassigned */}
                                                <div className="activities-suggestions-add-list-item-footer-cost-assignment-unassigned-holder">
                                                    <Typography sx={{ mb: 1.5, flex:'1' }}  variant="body2">
                                                        Unassigned Amount
                                                    </Typography>
                                                    <span className="activities-suggestions-add-list-item-footer-cost-assignment-unassigned-amount">
                                                    {
                                                        (parseFloat(eventActivity['amount_total']) - eventActivity['attendees'].reduce((total, attendee) => {
                                                            const cost = parseFloat(attendee['cost']);
                                                            // If cost is NaN (including when it's an empty string), use 0 instead
                                                            return total + (isNaN(cost) ? 0 : cost);
                                                        }, 0)).toFixed(2)
                                                    }
                                                        &nbsp;
                                                        {
                                                            eventActivity['currency']
                                                        }
                                                    </span>

                                                </div>

                                                {/* show the update button if changes were made */}

                                                {
                                                    showPriceChangesUpdateButton ? (
                                                        <div style={{cursor: 'pointer'}} onClick={handleSaveActivityCostsAssignment}>
                                                            <Chip
                                                                sx={addChipStyle}
                                                                label='Save Changes'
                                                                size="small"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }


                                            </div>

                                        )
                                    }

                                </div>

                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Details" ? (
                            <Grid container spacing={2} direction="column" sx={{ marginTop: '0' }}>
                                <Grid item style={{ flex: '1' }}>
                                    <Box display="flex" justifyContent="flex-end" mb={2}>
                                        {
                                            needToSaveSettings ? (
                                                <Chip
                                                    sx={addChipStyle}
                                                    label="Save"
                                                    size="small"
                                                    onClick={saveEventActivitySettings}
                                                />
                                            ) : (
                                                <Chip
                                                    sx={{
                                                        backgroundColor: "#e0e0e0",
                                                        color: "#757575",
                                                    }} // Example style for inactive save
                                                    label="Save"
                                                    size="small"
                                                />
                                            )
                                        }
                                    </Box>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_title"
                                                label="Title"
                                                value={activityTitle}
                                                onChange={handleTitleChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_location_name"
                                                label="Location Name"
                                                value={locationName}
                                                onChange={handleLocationNameChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_booked_through"
                                                label="Booked Through"
                                                value={bookedThrough}
                                                onChange={handleBookedThroughChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_confirmation_number"
                                                label="Confirmation Number"
                                                value={confirmationNumber}
                                                onChange={handleConfirmationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_reservation_name"
                                                label="Reservation Name"
                                                value={reservationName}
                                                onChange={handleReservationNameChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_reservation_number"
                                                label="Reservation Number"
                                                value={reservationNumber}
                                                onChange={handleReservationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_website"
                                                label="Website"
                                                value={website}
                                                onChange={handleWebsiteChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_bring"
                                                label="Bring"
                                                multiline
                                                rows={4}
                                                value={bring}
                                                onChange={handleBringChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_details"
                                                label="Details"
                                                multiline
                                                rows={4}
                                                value={details}
                                                onChange={handleDetailsChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_notes"
                                                label="Notes"
                                                multiline
                                                rows={4}
                                                value={notes}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Photos" ? (
                            <Box>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>Photos</Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {eventActivity['attendee_photos']?.length > 0 ? (
                                        eventActivity['attendee_photos']
                                            .filter(photo => !photo.private || photo.attendee_id === Account.id)
                                            .map((photo, index) => (
                                                <img
                                                    key={index}
                                                    src={photo.thumbnail_data || photo.full_data}
                                                    alt={photo.caption || 'Attendee Photo'}
                                                    style={{ width: 50, height: 50, marginRight: 8, borderRadius: 4 }}
                                                />
                                            ))
                                    ) : null}
                                </Typography>
                            </Box>
                        ) : (
                            <></>
                        )
                    }
                </CardContent>

            </Card>

            <Dialog
                open={openDialogDateRange}
                onClose={handleDialogDateRangeClose}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The date for the Activity is outside of the date range for the Event.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDateRangeOK} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default ActivitiesCard;
