import React, { useEffect, useState } from 'react';
import {Tab, TextField, Checkbox, Typography, ListItem} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Grid from '@mui/material/Grid2';
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

function ActivityLocationTab(props) {
    const [eventActivity, setEventActivity] = useState(props.eventActivity);
    const [selectedTab, setSelectedTab] = useState('locations');
    const [manualLocation, setManualLocation] = useState({
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: ''
    });

    // Update activity when props change
    useEffect(() => {
        setEventActivity(props.eventActivity);
    }, [props.eventActivity]);

    // Generate unique locations from event data
    useEffect(() => {
        const { meals, lodging, activities } = usePA_GlobalStore.getState().PA_GlobalObject.Planning || {};
        const locations = new Set();

        const addLocation = (location) => {
            if (location && location.city && location.state) {
                locations.add(`${location.name} | ${location.address} | ${location.city}, ${location.state}`);
            }
        };

        meals?.forEach((meal) => addLocation(meal.location));
        lodging?.forEach((lodging) => addLocation(lodging.location));
        activities?.forEach((activity) => addLocation(activity.location));

        setUniqueLocations([...locations]);
    }, []);

    // Handle tab change
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // Handle manual location input change
    const handleManualInputChange = (field, value) => {
        setManualLocation((prev) => ({
            ...prev,
            [field]: value
        }));
    };

    const [uniqueLocations, setUniqueLocations] = useState([]);
    const [selectedActivityLocation, setSelectedActivityLocation] = useState(eventActivity.location || {});

    // Extract unique locations on component mount
    useEffect(() => {
        const meals = usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals || [];
        const lodging = usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging || [];
        const activities = usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities || [];

        // Combine all locations
        const allLocations = [
            ...meals.map((meal) => meal.location),
            ...lodging.map((lodging) => lodging.location),
            ...activities.map((activity) => activity.location),
        ];

        // Filter unique locations by Google Place ID or another unique property
        const unique = Array.from(
            new Map(
                allLocations
                    .filter((location) => location.name) // Exclude locations without a name
                    .map((location) => [location.google_place_id, location])
            ).values()
        );

        // Sort the unique locations alphabetically by name
        const sortedUnique = unique.sort((a, b) => {
            const nameA = (a.name || "").toLowerCase();
            const nameB = (b.name || "").toLowerCase();
            return nameA.localeCompare(nameB);
        });

        // Separate the matching location from the rest
        const matchingLocation = sortedUnique.filter(
            (location) =>
                selectedActivityLocation &&
                location.google_place_id === selectedActivityLocation.google_place_id
        );
        const otherLocations = sortedUnique.filter(
            (location) =>
                !selectedActivityLocation ||
                location.google_place_id !== selectedActivityLocation.google_place_id
        );

        // Combine matching location(s) on top with the rest alphabetically sorted
        const finalLocationList = [...matchingLocation, ...otherLocations];

        setUniqueLocations(finalLocationList);
    }, [selectedActivityLocation]);

    function handleLocationSelect(location) {
        console.log("location : ", location);
        setSelectedActivityLocation(location);

        // Update the eventMeal with the selected location
        setEventActivity((prevActivity) => ({
            ...prevActivity,
            location,
        }));
    }


    return (
        <Grid item style={{ flex: '1' }}>
            <TabContext value={selectedTab}>
                <TabList
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        backgroundColor: 'rgba(251, 251, 251, 0.8)',
                        backdropFilter: 'blur(6px)',
                    }}
                    centered
                    variant="fullWidth"
                    onChange={handleTabChange}
                    aria-label="Activity Location"
                >
                    <Tab label="Find" value="find" />
                    <Tab label="Locations" value="locations" />
                </TabList>

                {/* Find Tab */}
                <TabPanel value="find" sx={{ padding: '24px 0' }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Enter Location Details
                    </Typography>
                    <Grid container spacing={2}>
                        {['name', 'address', 'city', 'state', 'zip', 'country'].map((field) => (
                            <Grid item xs={12} sm={6} key={field}>
                                <TextField
                                    label={field.charAt(0).toUpperCase() + field.slice(1)}
                                    variant="outlined"
                                    fullWidth
                                    value={manualLocation[field]}
                                    onChange={(e) => handleManualInputChange(field, e.target.value)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </TabPanel>

                {/* Locations Tab */}
                <TabPanel value="locations" sx={{ padding: '24px 0' }}>
                    <List dense>
                        {uniqueLocations.length > 0 ? (
                            uniqueLocations.map((location, index) => (
                                <ListItem
                                    key={index}
                                    sx={{ alignItems: "flex-start" }}
                                    onClick={() => handleLocationSelect(location)}
                                    button
                                >
                                    <Checkbox
                                        checked={
                                            selectedActivityLocation?.google_place_id === location.google_place_id
                                        }
                                        onChange={() => handleLocationSelect(location)}
                                        sx={{ padding: "0 8px" }}
                                    />
                                    <ListItemText
                                        primary={location.name || "Unnamed Location"}
                                        secondary={location.address || "No Address Available"}
                                    />
                                </ListItem>
                            ))
                        ) : (
                            <Typography variant="body2" sx={{ padding: "10px", textAlign: "center", color: "gray" }}>
                                No Event locations to choose from yet
                            </Typography>
                        )}
                    </List>
                </TabPanel>
            </TabContext>
        </Grid>
    );
}

export default ActivityLocationTab;
