
import PAAttendee from "./Attendee";
import dayjs from "dayjs";

class SupplyItem {

    constructor() {

        this.id = dayjs().valueOf();
        this.name = "";
        this.category = "";
        this.sub_category = "";

        this.assigned_by = "";
        this.assigned_to = [];
        this.private = false;
        this.essential = false;
        this.essential_id = 0;
        this.notes = "";

        this.status = 1;

    }

}

export default SupplyItem;
