import React, {useContext, useEffect, useState, useRef } from 'react';
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";
import {getAllPhotosForEvent, getSpecificPhotoForEvent} from '../../../Services/BrowserDatabaseFunctions';
import './photos.css';

import {Chip, Dialog, DialogContent, ImageList, ImageListItem, ImageListItemBar, Tooltip} from "@mui/material";
import Badge from "@mui/material/Badge";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import {updateChipStyle} from "../../ButtonStyle/Update";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";



function PhotosList(props){

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject} = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Account } = dataArray;

    const [photos, setPhotos] = useState(PA_GlobalObject.Planning.photos || []);
    const [selectedAttendee, setSelectedAttendee] = useState(Account.id);
    const selectedChipRef = useRef(null);

    useEffect(()=> {
        console.log("Planning.photos updated : ", usePA_GlobalStore.getState().PA_GlobalObject.Planning.photos);

        /*
            go through the photos and see if they're in browser storage or get from server
         */
        const updatePhotos = async () => {
            if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.photos
                && usePA_GlobalStore.getState().PA_GlobalObject.Planning.photos.length > 0) {
                console.log("Planning.photos.length : "+usePA_GlobalStore.getState().PA_GlobalObject.Planning.photos.length);

                const updatedPhotos = await Promise.all(usePA_GlobalStore.getState().PA_GlobalObject.Planning.photos
                        .map(async (item) => {

                            console.log("getSpecificPhotoForEvent : "+usePA_GlobalStore.getState().PA_GlobalObject.Planning.id+" : "+item.id);

                            const cachedPhoto = await getSpecificPhotoForEvent(usePA_GlobalStore.getState().PA_GlobalObject.Planning.id, item.id);

                            console.log("cachedPhoto : ", cachedPhoto);

                            if (cachedPhoto) {
                                return {...item, thumbnail_data: cachedPhoto.thumbnail_data};
                            } else {
                                //send the request to get the photo data
                                console.log()
                                ;
                                const _send_string = {};
                                _send_string.action = "E_getEventThumbnailImage";
                                _send_string.event_id = usePA_GlobalStore.getState().PA_GlobalObject.Planning.id;
                                _send_string.attendee_id = item.attendee_id;
                                _send_string.photo_id = item.id;
                                _send_string.file_name = item.filename;

//                            dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");

                                return {...item, src: ""};
                            }
                        })
                );

                setPhotos(updatedPhotos);
            }
        };

        updatePhotos();

    }, [PA_GlobalObject.Planning.photos]);

    const handleChipClick = (_attendee_id) => {
        setSelectedAttendee(_attendee_id);
    };
    useEffect(() => {
        if (selectedChipRef.current) {
            selectedChipRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
            });
        }
    }, [selectedAttendee]);

    const attendeePhotosCount = (usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees || []).map((att) => ({
        ...att,
        photoCount: (usePA_GlobalStore.getState().PA_GlobalObject.Planning.photos || []).reduce((count, item) => count + (item.attendee_id.includes(att.id) ? 1 : 0), 0),
    }));

    const sortedAttendees = attendeePhotosCount.sort((a, b) => {
        if (b.photoCount === a.photoCount) {
            return a.fname.localeCompare(b.fname);
        }
        return b.photoCount - a.photoCount;
    });

    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }

    function handlePhotosAddMenuClick(){
        navigate('/Planner/Photos/AddNew');
    }

    function handleEditPhoto(photoId){

    }
    function handleDeletePhoto(photoId) {
        set_PA_GlobalObject((prev) => {
            const updatedPhotos = prev.Planning.photos.filter(photo => photo.id !== photoId);

            // Sync local state too
            setPhotos(updatedPhotos);

            return {
                ...prev,
                Planning: {
                    ...prev.Planning,
                    photos: updatedPhotos,
                },
            };
        });
    }

    /*
        see if we need to get the full size images from the server
     */


    function checkForFullSizeImages(){

        // Prepare images for the gallery
        getAllPhotosForEvent(usePA_GlobalStore.getState().PA_GlobalObject.Planning.id).then((_photos) => {

            if (_photos && _photos.length > 0) {
                //see if we have the full size images
                for (const _photo in _photos){
                    if (_photo.full_data === ""){
                        //get full size data from server

                        const _send_string = {};
                        _send_string.action = "E_getEventFullSizeImage";
                        _send_string.event_id = usePA_GlobalStore.getState().PA_GlobalObject.Planning.id;
                        _send_string.attendee_id = _photo.attendee_id;
                        _send_string.photo_id = _photo.id;
                        _send_string.file_name = _photo.filename;

                        dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");
                    }

                }

            }

        });

    }

    const [openImage, setOpenImage] = useState(false);
    const [imageSrc, setImageSrc] = useState("");

    const handleOpenImage = (src) => {
        setImageSrc(src);
        setOpenImage(true);
    };

    const handleCloseImage = () => {
        setOpenImage(false);
    };


    return (

        <div className="photos-list-container">

            {/* show the header */}
            <div
                className="photos-list-header"
            >
                <div className="photos-list-header-text">
                    <h5>Photos</h5>
                </div>
                <div className="photos-list-header-chip-holder">
                    <Chip
                        sx={updateChipStyle}
                        label="Add"
                        size="small"
                        onClick={handlePhotosAddMenuClick}
                    />
                </div>
            </div>

            {/* show the horizontal list of Attendees */}
            <div className="photos-list-attendee-list-container">
                {
                    sortedAttendees.length > 0 ? (
                        sortedAttendees
                            .map((_att, index) => (
                                <Badge
                                    key={index}
                                    badgeContent={
                                        usePA_GlobalStore.getState().PA_GlobalObject.Planning.photos.reduce((count, item) => {
                                            // Check if the photo meets the criteria
                                            const isOwner = item.attendee_id === Account.id; // Replace `yourUserId` with your user ID variable
                                            const isPublic = item.private === false;
                                            const isAttendee = item.attendee_id.includes(_att.id);

                                            // Increment the count if conditions are met
                                            return count + (isAttendee && (isOwner || isPublic) ? 1 : 0);
                                        }, 0)
                                    }
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: 'white', // Customize the dot color here
                                            border: '1px solid #ddd', // Make it a circle
                                            width: '16px', // Adjust the dot size as needed
                                            height: '16px',
                                            borderRadius: '50%', // Make it a circle
                                            top: '10px', // Adjust top and right values to move the dot
                                            right: '10px', // Adjust top and right values to move the dot
                                        },
                                    }}
                                >
                                    <Chip
                                        ref={selectedAttendee === _att.id ? selectedChipRef : null}
                                        sx={selectedAttendee === _att.id ? selectedChipStyle : unselectedChipStyle}
                                        label={capitalizeFirstLetter(_att.nickname)}
                                        clickable
                                        onClick={() => handleChipClick(_att.id)}
                                        style={{margin: '0.5rem'}}
                                    />
                                </Badge>
                            ))

                    ) : (

                        <div style={{padding: '20px'}}>

                        </div>
                    )

                }
            </div>

            {/* show the list of photos */}
            <div className="photos-list-items-container">
                {photos && photos.length > 0 ? (
                    <ImageList gap={8}>
                        {photos
                            .filter(
                                (photo) =>
                                    photo.attendee_id === selectedAttendee &&
                                    (photo.attendee_id === Account.id || !photo.private)
                            )
                            .map((item, index) => (
                                <ImageListItem key={item.id} sx={{position: "relative", maxWidth: 150, backgroundColor: '#eee'}}>
                                    {/* Image */}
                                    <img
                                        src={item.thumbnail_data || item.full_data}
                                        alt={item.caption || "Photo"}
                                        loading="lazy"
                                        style={{ cursor: "pointer", objectFit: "cover", width: "100%" }}
                                        onClick={() => handleOpenImage(item.full_data || item.thumbnail_data)}
                                    />

                                    {/* Overlay Details (Caption & Icons) */}
                                    <ImageListItemBar
                                        position="below"
                                        title={
                                            <Tooltip title={item.caption || ''} placement="top" arrow>
                                              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block' }}>
                                                {item.caption || ''}
                                              </span>
                                            </Tooltip>
                                        }
                                        sx={{
                                            fontSize: '14px',
                                            '& .MuiImageListItemBar-title': {
                                                fontSize: '14px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            },
                                            '& .MuiImageListItemBar-subtitle': {
                                                fontSize: '12px',
                                            }
                                        }}
                                        subtitle={
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
                                                {/* Private/Public icon */}
                                                <i
                                                    style={{color: item.private ? "green" : "orange", fontSize: '16px'}}
                                                    className={item.private ? "icon-eye-cross" : "icon-eye"}
                                                    title={item.private ? "Only you can see" : "Shared with all Attendees"}
                                                />

                                                {/* Sharable icon */}
                                                {item.sharable && (
                                                    <span>
                                                          <Tooltip
                                                              title={
                                                                  item.share_with && item.share_with.length > 0
                                                                      ? item.share_with.join(", ")
                                                                      : "No shared emails"
                                                              }
                                                              placement="top"
                                                              arrow
                                                          >
                                                              <i
                                                                  style={{ color: "blue", fontSize: '14px', cursor: 'pointer' }}
                                                                  className="icon-link"
                                                                  title=""
                                                              />
                                                          </Tooltip>
                                                          <span style={{ fontSize: '11px', marginLeft: '4px' }}>
                                                            {item.share_with.length}
                                                          </span>
                                                        </span>
                                                )}

                                                {/* Edit icon */}
                                                <IconButton
                                                    onClick={() => handleEditPhoto(item.id)}
                                                    size="small"
                                                    sx={{ padding: 0, color:'blue', fontSize: '13px' }}
                                                >
                                                    <i className="icon-pencil" title="Edit" />
                                                </IconButton>

                                                {/* Delete icon */}
                                                <IconButton
                                                    onClick={() => handleDeletePhoto(item.id)}
                                                    size="small"
                                                    sx={{ padding: 0, color:'red', fontSize: '15px' }}
                                                >
                                                    <i className="icon-trash" title="Delete" />
                                                </IconButton>
                                            </Box>
                                        }
                                    />
                                </ImageListItem>
                            ))}
                    </ImageList>
                ) : null}
            </div>


            {/* Dialog for Full-Screen Image */}
            <Dialog open={openImage} onClose={handleCloseImage} maxWidth="md" fullWidth>
                <DialogContent sx={{textAlign: "center"}}>
                    <img src={imageSrc} alt="Photo" style={{maxWidth: "100%", maxHeight: "80vh"}}/>
                </DialogContent>
            </Dialog>

        </div>

    )

}

export default PhotosList;
