import dayjs from "dayjs";


class Photo {
    constructor(){

        this.id = dayjs().valueOf();
        this.event_id = "";
        this.attendee_id = "";
        this.file_name = "";
        this.file_type = "";
        this.file_size = 0;
        this.height = 0;
        this.width = 0;
        this.thumbnail_data = "";
        this.full_data = "";
        this.caption = "";
        this.private = false;
        this.sharable = false;
        this.share_with = [];
        this.link_with_sections = [];
        this.photo_album = false;
        this.full_size_uploaded = false;
        this.thumbnail_finished = false;
        this.last_modified = 0;
        this.status = 0;
    }

}

export default Photo;
