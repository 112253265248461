import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Menu,
    MenuItem,
    MenuList,
    Paper
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import './lodging.css';

import lodgingHeaderImage from '../../../graphics/th-513411954.jpg';
import LodgingCard from "./LodgingCard";
import dayjs from "dayjs";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import Box from "@mui/material/Box";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import InfoLodging from "../../Dialogs/InfoLodging";


function LodgingList(props){

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const [eventLodging, setEventLodging] = useState([]);

    const [selectedLodgingUnit , setSelectedLodgingUnit ] = useState("dates");
    const [viewType, setViewType] = useState(
        usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0 ?
            'dates'
            : usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0 ?
                'days' : 'dates'); // Either 'dates' or 'days'
    const [eventHasDates, setEventHasDates] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0);
    const [eventHasDays, setEventHasDays] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0);
    const [selectedDate, setSelectedDate] = useState("all"); // For selected date
    const [selectedDay, setSelectedDay] = useState("all"); // For selected day

    const [selectedLodgingForDates , setSelectedLodgingForDates ] = useState("");
    const [selectedLodgingForDays , setSelectedLodgingForDays ] = useState("");

    const handleToggleViewType = () => {
        console.log("handleToggleViewType : " + viewType);

        const planning = usePA_GlobalStore.getState().PA_GlobalObject.Planning;
        const eventDays = planning.time_table?.days || [];
        const eventDates = planning.time_table?.dates || [];

        if (viewType === 'dates') {
            console.log("Changing Chips to days...");

            if (selectedDate === 'all') {
                setSelectedDay('all'); // Show all meals if "all" was selected
            } else {
                // Find the corresponding day for the selected date
                const correspondingDay = eventDates.find(dateEntry => dateEntry.date === selectedDate);
                if (correspondingDay) {
                    const dayIndex = eventDates.indexOf(correspondingDay);
                    const correspondingDayKey = Object.keys(eventDays[dayIndex] || {})[0] || 'all';
                    setSelectedDay(correspondingDayKey);
                } else {
                    setSelectedDay('all'); // Fallback
                }
            }

            setViewType('days');

        } else if (viewType === 'days') {
            console.log("Changing Chips to dates...");

            if (selectedDay === 'all') {
                setSelectedDate('all'); // Show all meals if "all" was selected
            } else {
                // Find the corresponding date for the selected day
                const correspondingDayEntry = eventDays.find(dayEntry => Object.keys(dayEntry)[0] === selectedDay);
                if (correspondingDayEntry) {
                    const dayIndex = eventDays.indexOf(correspondingDayEntry);
                    const correspondingDate = eventDates[dayIndex]?.date || 'all';
                    setSelectedDate(correspondingDate);
                } else {
                    setSelectedDate('all'); // Fallback
                }
            }

            setViewType('dates');
        }
    };

    useEffect(() => {
        console.log("usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging : ", usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging);

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging !== eventLodging){
            setEventLodging([...usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging]);
            setEventHasDates(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0);
            setEventHasDays(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0);

            if (viewType !== 'all'){
//                setViewType('all');
            }
        }
    }, [PA_GlobalObject.Planning.lodging]);


    useEffect(() => {
        console.log(" ------ eventLodging updated!!!! : ", eventLodging);



    }, [eventLodging]);

    useEffect(() => {

    }, [selectedLodgingUnit]);

    const updateLodgingDetails = (updatedLodging) => {
        console.log("updateLodgingDetails from LodgingCard : ", updatedLodging);

        // Find the index of the updated lodging in eventLodging
        const lodgingIndex = eventLodging.findIndex(
            (lodging) => lodging.id === updatedLodging.id || lodging.temp_id === updatedLodging.temp_id
        );

        console.log("lodgingIndex : "+lodgingIndex);

        if (lodgingIndex !== -1) {
            // If the lodging is found, update it in the eventLodging array
            const updatedEventLodgingArray = [...eventLodging];
            updatedEventLodgingArray[lodgingIndex] = updatedLodging;
            setEventLodging(updatedEventLodgingArray);
        }

    };

    /*
        this is for the Menu list in the header
     */
    const [anchorLodgingListMenu, setAnchorLodgingListMenu] = useState(null);
    const handleLodgingListMenuOpenMenu = (event) => {
        setAnchorLodgingListMenu(event.currentTarget);
    };
    const handleLodgingListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorLodgingListMenu(event.currentTarget);

        if (dataId === "new-entry"){
            navigate('/Planner/Lodging/AddNew');
        } else if (dataId === "from-pdf"){
            navigate('/Planner/Lodging/AddFromPDF');
        }
    };
    const handleLodgingListMenuClose = () => {
        setAnchorLodgingListMenu(null);
    };

    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }

    function handleLodgingChipClick(_lodging_id){

    }

    const [lodgingToDelete, setLodgingToDelete] = useState(null);  // Holds the lodging to delete for confirmation dialog
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);  // Controls the confirmation dialog visibility
    const handleDeleteLodging = (lodgingId) => {
        // Remove the lodging with the given ID
        const updatedLodging = eventLodging.filter(lodging => lodging.id !== lodgingId);

        // You can call a function to persist this change or update parent state
        setEventLodging(updatedLodging);


        //this updates the lodging array at the Global level
        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                lodging: updatedLodging, // Update only the title
            },
        }));

        closeDeleteDialog();

    };
    const openDeleteDialog = (lodging) => {
        setLodgingToDelete(lodging);  // Set the lodging to be deleted
        setDeleteDialogOpen(true);  // Open the confirmation dialog
    };
    const closeDeleteDialog = () => {
        setLodgingToDelete(null);  // Reset the lodging to be deleted
        setDeleteDialogOpen(false);  // Close the confirmation dialog
    };


    const [isLodgingInfoDialogOpen, setLodgingInfoDialogOpen] = useState(false);


    return (
        <div className="lodging-list-container">

            {/* show the header */}
            <div
                className="lodging-list-header"
            >
                <div className="lodging-list-header-text">
                    <h5>Lodging</h5>
                </div>

                <div className="lodging-list-header-info-icon-holder">
                    <i
                        className="icon-info-circle"
                        style={{fontSize: '20px', padding: '5px'}}
                        onClick={() => setLodgingInfoDialogOpen(!isLodgingInfoDialogOpen)}
                    />
                </div>

                <div className="lodging-list-header-controls">
                    {/* Toggle for Dates/Days */}
                    <Box sx={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
                        {/* Icon Button for Dates */}
                        <IconButton
                            type="button"
                            sx={{
                                padding: '3px',
                                backgroundColor: viewType === 'dates' ? '#FFF' : '',
                                boxShadow: '' +
                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                border: '1px solid #0096ff',
                                borderRadius: '10px',
                                textAlign: 'center',
                                color: 'black', // Text color (adjust as needed)
                                '&:hover': {
                                    background: '#cbe0f7', // Reverse the gradient on hover
                                },
                            }}
                            aria-label="toggle to dates"
                            onClick={handleToggleViewType}
                        >
                            <Typography variant="caption">Dates</Typography>
                        </IconButton>

                        {/* Divider between buttons */}
                        <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>

                        {/* Icon Button for Days */}
                        <IconButton
                            type="button"
                            sx={{
                                padding: '3px',
                                backgroundColor: viewType === 'days' ? '#FFF' : '',
                                boxShadow: '' +
                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                border: '1px solid #0096ff',
                                borderRadius: '10px',
                                textAlign: 'center',
                                color: 'black', // Text color (adjust as needed)
                                '&:hover': {
                                    background: '#cbe0f7', // Reverse the gradient on hover
                                },
                            }}
                            aria-label="toggle to days"
                            onClick={handleToggleViewType}
                        >
                            <Typography variant="caption">Days</Typography>
                        </IconButton>
                    </Box>

                    {/* Menu Chip */}
                    <Chip
                        sx={menuChipStyle}
                        label={<i className="icon-menu2" style={{fontSize: '16px'}}/>}
                        size="small"
                        onClick={handleLodgingListMenuOpenMenu}
                    />
                    <Menu
                        sx={{width: 320, maxWidth: '100%'}}
                        anchorEl={anchorLodgingListMenu}
                        id="library-menu"
                        open={Boolean(anchorLodgingListMenu)}
                        onClose={handleLodgingListMenuClose}
                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuList dense sx={{width: 320, maxWidth: '100%'}}>
                            <MenuItem data-id="new-entry" onClick={handleLodgingListMenuClick}>
                                <ListItemText>New Entry</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handlePromptChangeClose}>
                                <ListItemText>Close</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>


            {/* show the list of lodging dates/days */}
            <div className="lodging-list-timetable-list-container">
                {
                    eventLodging && eventLodging.length > 0 ? (
                        (() => {
                            const allChips = [];

                            // Step 1: Add lodging Chips to the allChips array
                            eventLodging.forEach((lodging, index) => {

                                const timeTable = lodging.time_table;

                                if (selectedLodgingUnit === 'dates' && timeTable?.dates?.length > 0) {
                                    const firstDate = dayjs(timeTable.dates[0].date);
                                    const lastDate = dayjs(timeTable.dates[timeTable.dates.length - 1].date);

                                    const formattedFirstDay = firstDate.format('ddd'); // e.g., "Wed"
                                    const formattedFirstDate = firstDate.format('M/D'); // e.g., "8/19"
                                    const formattedLastDay = lastDate.format('ddd'); // e.g., "Fri"
                                    const formattedLastDate = lastDate.format('M/D'); // e.g., "8/21"

                                    allChips.push({
                                        date: firstDate, // Use the first lodging date for sorting
                                        component: (
                                            <Box key={index}
                                                 sx={{
                                                     position: 'relative',
                                                     display: 'inline-block',
                                                     marginTop: '5px',
                                                     marginRight: '10px'
                                                 }}
                                            >
                                                {/* Chip Component */}
                                                <Chip
                                                    label={
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}>
                                                            <span style={{textAlign: 'center', marginRight: '5px'}}>
                                                                <Typography variant="body2">{formattedFirstDay}</Typography>
                                                                <Typography
                                                                    variant="caption">{formattedFirstDate}</Typography>
                                                            </span>
                                                            <span style={{margin: '0 5px'}}>
                                                                <Typography variant="body2">-</Typography>
                                                            </span>
                                                            <span style={{textAlign: 'center', marginLeft: '5px'}}>
                                                                <Typography variant="body2">{formattedLastDay}</Typography>
                                                                <Typography
                                                                    variant="caption">{formattedLastDate}</Typography>
                                                            </span>
                                                        </div>
                                                    }
                                                    clickable
                                                    sx={
                                                        selectedLodgingForDates === lodging.id ? selectedChipStyle : unselectedChipStyle
                                                    }
                                                    onClick={() => handleLodgingChipClick(lodging.id)}
                                                    style={{margin: '0.2rem', height: '40px'}}
                                                />

                                                {/* Icon Component Positioned Outside the Chip */}
                                                <i
                                                    className="icon-check"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '-5px', // Position the icon above the Chip
                                                        right: '-5px',
                                                        fontSize: '12px',
                                                        color: 'green',
                                                        zIndex: 1,
                                                        borderColor: 'green',
                                                        backgroundColor: 'white',  // White background for the circle
                                                        borderRadius: '35%',       // Make it a circle
                                                        padding: '2px',            // Space between the icon and the border
                                                        border: '1px solid',
                                                        fontWeight: '800',
                                                        textShadow: '0 0 0 green, 0 0 0 green',
                                                    }}
                                                />
                                            </Box>
                                        )
                                    });
                                    console.log('added a Chip : ', allChips);
                                }
                            });

                            // Step 2: Add placeholder Chips for missing event dates
                            let allLodgingDates = [];

                            eventLodging.forEach(lodging => {
                                if (lodging.time_table?.dates?.length > 0) {
                                    lodging.time_table.dates.forEach(dateObj => allLodgingDates.push(dateObj.date));
                                }
                            });

                            if (usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table']?.dates?.length > 0) {

                                usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table']?.dates?.forEach(eventDateObj => {
                                    const eventDate = dayjs(eventDateObj.date, "YYYY-MM-DD");

                                    const lodgingDateExists = allLodgingDates.some(lodgingDate =>
                                        dayjs(lodgingDate).isSame(eventDate, 'day')
                                    );

                                    if (!lodgingDateExists) {
                                        const formattedDay = eventDate.format('ddd');
                                        const formattedDate = eventDate.format('M/D');

                                        allChips.push({
                                            date: eventDate, // Use the event date for sorting
                                            component: (
                                                <Box key={`placeholder-${eventDateObj.date}`}
                                                     sx={{
                                                         position: 'relative',
                                                         display: 'inline-block',
                                                         marginTop: '5px',
                                                         marginRight: '10px'
                                                     }}
                                                >
                                                    {/* Placeholder Chip Component */}
                                                    <Chip
                                                        label={
                                                            <div style={{ textAlign: 'center' }}>
                                                                <Typography variant="body2">{formattedDay}</Typography>
                                                                <Typography variant="caption">{formattedDate}</Typography>
                                                            </div>
                                                        }
                                                        sx={unselectedChipStyle} // Placeholder style for Chips
                                                        style={{ margin: '0.2rem', height: '40px' }}
                                                    />

                                                    {/* Icon Component Positioned Outside the Chip */}
                                                    <i
                                                        className="icon-warning"
                                                        style={{
                                                            position: 'absolute',
                                                            top: '-2px', // Position the icon above the Chip
                                                            right: '-7px',
                                                            fontSize: '10px',
                                                            color: '#10318f',
                                                            zIndex: 1,
                                                            backgroundColor: 'white',  // Green background for the circle
                                                            borderRadius: '50%',       // Make it a circle
                                                            padding: '3px',            // Space between the icon and the border
                                                            border: '1px solid #F00'
                                                        }}
                                                    />
                                                </Box>
                                            )
                                        });

                                    }
                                });
                            }

                            // Step 3: Sort all Chips by date
                            allChips.sort((a, b) => a.date.isBefore(b.date) ? -1 : 1);

                            // Step 4: Render the Chips in chronological order
                            return allChips.map((chipObj) => chipObj.component);
                        })()
                    ) : null
                }
            </div>


            {/* show the list of lodgings */}
            <div
                className="lodging-list-items-container"
                style={
                    !eventHasDates && !eventHasDays
                        ? {height: 'calc(100vh - 300px)'}
                        : {height: 'calc(100vh - 375px)'}
                }
            >

                {
                    eventLodging && eventLodging.length > 0 ? (
                        eventLodging
                            .sort((a, b) => {
                                const dateA = a?.time_table?.dates?.[0]?.date;
                                const dateB = b?.time_table?.dates?.[0]?.date;

                                if (dateA && dateB) {
                                    // If both have valid dates, sort by date
                                    return dayjs(dateA).diff(dayjs(dateB));
                                } else if (!dateA && !dateB) {
                                    // If both dates are undefined, sort by name
                                    return a.name.localeCompare(b.name);
                                } else {
                                    // If only one is undefined, move undefined dates to the end
                                    return dateA ? -1 : 1;
                                }
                            })
                            .map((lodgingDetails, index) => (

                                <Grid item key={lodgingDetails.id}
                                      sx={{
                                          display: 'block',
                                          marginBottom: '10px'
                                      }}
                                >

                                    <LodgingCard
                                        lodging={lodgingDetails}
                                        onDeleteLodging={() => openDeleteDialog(lodgingDetails)}
                                        _global_map_ref={props._global_map_ref}
                                    />

                                </Grid>

                            ))
                    ) : (
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ borderRadius: '5px', overflow: 'hidden' }}>
                                <Card>
                                    <CardMedia
                                        sx={{ height: 200 }}
                                        image={lodgingHeaderImage}
                                        title="Your Home Away from Home"
                                    />

                                    <CardContent sx={{ padding: '24px' }}>
                                        {/* Title */}
                                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, color: '#00897b' }}>
                                            🏨 No Lodging Yet
                                        </Typography>

                                        {/* Subhead */}
                                        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 3 }}>
                                            Comfort, cost sharing, and coordination — let’s get everyone settled in.
                                        </Typography>

                                        {/* Lodging Features */}
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            {/* Centralized Management */}
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                                                <i className="icon-home" style={{ fontSize: '24px', color: '#00897b' }} />
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                        Centralized Management
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Add and manage all lodging details in one place for easy coordination.
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Flexible Options */}
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                                                <i className="icon-office" style={{ fontSize: '24px', color: '#5c6bc0' }} />
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                        Flexible Options
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Hotels, Airbnbs, cabins, campgrounds — whatever fits your group best.
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Attendee Assignments */}
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                                                <i className="icon-group" style={{ fontSize: '24px', color: '#7e57c2' }} />
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>Attendee Assignments
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Link each attendee to their room and manage check-in/check-out smoothly.
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Split the Cost */}
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                                                <i className="icon-split" style={{ fontSize: '24px', color: '#f57c00' }} />
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                        Cost Splitting
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Enter the total cost and split it evenly — or assign custom amounts.
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Business Expense */}
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                                                <i className="icon-wallet" style={{ fontSize: '24px', color: '#37474f' }} />
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                        Business Expense?
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Mark attendees whose costs need reimbursement — we'll track it for you.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>

                                        {/* Call to Action */}
                                        <Box sx={{ mt: 4, textAlign: 'center' }}>
                                            <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#616161' }}>
                                                Where you sleep matters — let’s find your stay.
                                            </Typography>
                                            <Typography
                                                variant="button"
                                                sx={{
                                                    mt: 2,
                                                    display: 'inline-block',
                                                    color: '#fff',
                                                    backgroundColor: '#00897b',
                                                    padding: '8px 16px',
                                                    borderRadius: '8px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    // Trigger add lodging action
                                                }}
                                            >
                                                ➕ Add Lodging Details
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Paper>
                        </Grid>

                    )

                }
            </div>

            {/* Confirmation Dialog */}
            <Dialog
                open={isDeleteDialogOpen}
                onClose={closeDeleteDialog}
            >
                <DialogTitle>Delete Lodging</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the lodging "{lodgingToDelete?.name}"? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDeleteLodging(lodgingToDelete.id)} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <InfoLodging
                isOpen={isLodgingInfoDialogOpen}
                onClose={() => setLodgingInfoDialogOpen(false)}
            />
        </div>
    )

}

export default LodgingList;

