import React, {useContext, useEffect, useRef, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import MessageCard from "./MessageCard";
import {Chip, FormControlLabel, Switch, TextField} from "@mui/material";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import './messages.css';
import {updateChipStyle} from "../../ButtonStyle/Update";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";



function MessagesList() {

    const { dataArray } = useContext(PAContext);
    const { IncomingEventActions, Account } = dataArray;

    const messagesContainerRef = useRef(null);

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const [ eventMessages, setEventMessages ] = useState([]);

    useEffect(() => {
        setEventMessages(PA_GlobalObject.Planning.messages);
    },[PA_GlobalObject.Planning.messages]);

    useEffect(() => {
        //handle the incoming action
        for (let i = IncomingEventActions.length - 1; i >= 0; i--) {
            const _incoming = IncomingEventActions[i];
            // Perform operations on the current action
            console.log('Action:', JSON.stringify(_incoming, null, '\t'));

            switch (_incoming.action) {
                case "E_getEventMessagesResults":

                    //add the messages
                    setEventMessages(prevMessages => {
                        // Create a Set of existing message IDs for quick lookup
                        const existingIds = new Set(prevMessages.map(msg => msg.id));

                        // Filter the incoming messages list to exclude any that already exist
                        const newMessages = _incoming["messages_list"].filter(msg => !existingIds.has(msg.id));

                        // Concatenate the previous messages with the new, non-duplicate messages
                        return [...prevMessages, ...newMessages];
                    });

                    // Remove the one item we just processed
                    IncomingEventActions.splice(i, 1);

                    break;

                default:

            }

        }
    }, [IncomingEventActions]);

    useEffect(() => {
        if (messagesContainerRef.current) {
            setTimeout(() => {
                messagesContainerRef.current.scrollTo({
                    top: messagesContainerRef.current.scrollHeight,
                    behavior: "smooth",
                });
            }, 100);
        }
    }, [eventMessages]); // Runs every time eventMessages updates


    const [ newMessage, setNewMessage ] = useState("");
    const [ priority, setPriority ] = useState(false);


    // Function to handle sending a message
    const handleSendMessage = () => {
        if (newMessage.trim() !== "") {
            // Code to handle sending message (e.g., API POST request)
            console.log("Sending message:", newMessage);

            const _send_string = {};
            _send_string.action = "E_sendEventMessage";
            _send_string.event_id = PA_GlobalObject.Planning.id;
            _send_string.link_with_id = 0;
            _send_string.priority = priority;
            _send_string.message = newMessage;
            _send_string.recipients = [];

            console.log(JSON.stringify(_send_string));
            dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");

            // Clear input after sending
            setNewMessage("");

            //don't leave the priority button selected
            setPriority(false);
        }
    };

    return (

        <div className="messages-list-container">

            {/* show the header */}
            <div
                className="messages-list-header"
            >
                <div className="messages-list-header-text">
                    <h5>Messages</h5>
                </div>
            </div>

            {/* show the list of Messages */}
            <div className="messages-list-items-container">
                {/* Scrollable area for messages */}
                <div
                    ref={messagesContainerRef} // Attach ref to scrollable area
                    style={{
                        maxHeight: "500px",
                        flex: 1,
                        overflowY: 'auto',
                        padding: '10px',
                    }}
                >
                    {eventMessages?.length > 0 ? (
                        eventMessages
                            .sort((a, b) => a.created - b.created)
                            .map((_message, index) => (
                                <MessageCard
                                    key={index}
                                    message={_message}
                                    align={_message.attendee_id !== Account.id ? "left" : "right"}
                                />
                            ))
                    ) : (
                        <p>No Messages Yet</p>
                    )}
                </div>
            </div>

            {/* Input box and POST button */}
            <div className="messages-list-items-footer-container">
                {/* TextField on its own line */}
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Type your message"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage();
                        }
                    }}
                />

                {/* Toggle and Button on the line below */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '10px'
                }}>
                    {/* Toggle Switch */}
                    <FormControlLabel
                        control={
                            <Switch
                                checked={priority}
                                onChange={() => setPriority(!priority)}
                                color="primary"
                            />
                        }
                        label="Priority"
                    />

                    {/* Send Button */}
                    <Chip
                        sx={updateChipStyle}
                        variant="contained"
                        label="Send"
                        onClick={handleSendMessage}
                    />
                </div>
            </div>
        </div>

    )
}

export default MessagesList;
