import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import {
    Button,
    Chip,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Menu,
    MenuItem,
    MenuList,
    Tab
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import './supplies.css';
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import _ from 'lodash';

import { allSuggestedItems } from '../../../sampleData/SupplyItemSuggestions';
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import Box from "@mui/material/Box";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";
import InfoSupplies from "../../Dialogs/InfoSupplies";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";




function SuppliesList(props) {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray, removeFirstSuppliesActions } = useContext(PAContext);
    const { Account, Attendees, SampleAttendees, SuppliesPipeline } = dataArray;

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated ? Attendees : SampleAttendees);
    useEffect(() => {
        setAttendeesReferenceArray(dataArray.authenticated ? Attendees : SampleAttendees);
    }, [dataArray.authenticated, Attendees, SampleAttendees]);

    const attendeesWithSupplyCounts = PA_GlobalObject.Planning.attendees.map((attendee) => {
        const supplyCount = PA_GlobalObject.Planning.supplies.reduce((count, item) =>
            count + ((item.assigned_to === attendee.id && (!item.private || item.assigned_to === Account.id)) ? 1 : 0), 0
        );
        return { ...attendee, supplyCount };
    });
    const attendeesWithSupplies = attendeesWithSupplyCounts.filter(attendee => attendee.supplyCount > 0);
    const attendeesWithoutSupplies = attendeesWithSupplyCounts.filter(attendee => attendee.supplyCount === 0);
    attendeesWithSupplies.sort((a, b) => b.supplyCount - a.supplyCount);
    attendeesWithoutSupplies.sort((a, b) => a.nickname.localeCompare(b.nickname));
    const sortedAttendees = [...attendeesWithSupplies, ...attendeesWithoutSupplies];


    const [selectedAttendee, setSelectedAttendee] = useState(Account.id !== undefined ? Account.id : PA_GlobalObject.Planning.attendees[0] ? PA_GlobalObject.Planning.attendees[0].id : null);
    useEffect(() => {
        console.log("PA_GlobalObject.Planning.attendees : ", PA_GlobalObject.Planning.attendees);
        console.log("selectedAttendee : ", selectedAttendee);
        if (selectedAttendee === null || selectedAttendee === undefined){
            if (PA_GlobalObject.Planning.attendees.length > 0){
                setSelectedAttendee(PA_GlobalObject.Planning.attendees[0].id);
            }
        }
    }, [PA_GlobalObject.Planning.attendees]);



    const [anchorSuppliesMenu, setAnchorSuppliesMenu] = useState(null);
    const handleSuppliesMenuOpenMenu = (event) => {
        setAnchorSuppliesMenu(event.currentTarget);
    };
    const handleSuppliesMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorSuppliesMenu(event.currentTarget);

        props.setSelectedAttendeeForSupplies(selectedAttendee);

        if (dataId === "new-entry"){
            props.setShowEventItemConfig(true);
            props.setShowEventItemExtendedConfig(true);
            setAnchorSuppliesMenu(null);
            navigate('/Planner/Supplies/AddNew');
        } else if (dataId === "from-essentials"){
            props.setShowEventItemConfig(true);
            props.setShowEventItemExtendedConfig(true);
            setAnchorSuppliesMenu(null);
            navigate('/Planner/Supplies/AddEssentials');
        } else if (dataId === "from-suggestion-list"){
            props.setShowEventItemConfig(true);
            props.setShowEventItemExtendedConfig(true);
            setAnchorSuppliesMenu(null);
            navigate('/Planner/Supplies/AddSuggestions');
        }
    };

    const handleSuppliesMenuClose = () => {
        setAnchorSuppliesMenu(null);
    };

    const [sortedGroupedItems, setSortedGroupedItems] = useState([]);




    const handleChipClick = (_attendee_id) => {
        console.log("Show supplies for : "+_attendee_id);

        setSelectedAttendee(_attendee_id);
        props.setSelectedAttendeeForSupplies(_attendee_id);

        if (PA_GlobalObject.Planning.supplies !== undefined) {
            const filteredItems = PA_GlobalObject.Planning.supplies.filter(item => item.assigned_to === _attendee_id);

            console.log("filteredItems : "+JSON.stringify(filteredItems, null, '\t'));

            // Group the filtered items by category and subcategory
            const groupedItems = _.groupBy(filteredItems, item => `${item.category}_${item.sub_category}`);

            console.log("groupedItems : "+JSON.stringify(groupedItems, null, '\t'));

            setSortedGroupedItems(groupedItems);

            setGroupedItems(groupedItems);
            // Sort the grouped items alphabetically by category and subcategory
//            setSortedGroupedItems(_.sortBy(Object.entries(groupedItems), ([key]) => key));
        }
    }


    const [groupedItems, setGroupedItems] = useState(sortedGroupedItems); // Store items in state
    useEffect(() => {
        if (PA_GlobalObject.Planning.supplies !== undefined) {
            const filteredItems = PA_GlobalObject.Planning.supplies.filter(item => item.assigned_to === selectedAttendee);

            console.log("filteredItems : "+JSON.stringify(filteredItems, null, '\t'));

            // Group the filtered items by category and subcategory
            const groupedItems = _.groupBy(filteredItems, item => `${item.category}_${item.sub_category}`);

            console.log("groupedItems : "+JSON.stringify(groupedItems, null, '\t'));

            setSortedGroupedItems(groupedItems);

            setGroupedItems(groupedItems);
            // Sort the grouped items alphabetically by category and subcategory
//            setSortedGroupedItems(_.sortBy(Object.entries(groupedItems), ([key]) => key));
        }
    }, [PA_GlobalObject.Planning.supplies]);

    useEffect(() => {
        console.log("groupedItems", groupedItems);
    }, [groupedItems]);
    const updateSupplyItems = (updatedItems) => {
        setGroupedItems(updatedItems);
    };

    const [anchorSupplyItemListMenu, setAnchorSupplyItemListMenu] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState(null);


    // Open Menu for Specific Item
    const handleSupplyItemListMenuOpenMenu = (event, itemId) => {
        setAnchorSupplyItemListMenu(event.currentTarget);
        setSelectedItemId(itemId);
    };

    // Close Menu
    const handleSupplyItemListMenuClose = () => {
        setAnchorSupplyItemListMenu(null);
        setSelectedItemId(null);
    };

    const toggleItemStatus = (itemId) => {
        // Clone the groupedItems object
        const updatedGroupedItems = { ...groupedItems };

        // Iterate through categories and update the item's status
        Object.keys(updatedGroupedItems).forEach((category) => {
            updatedGroupedItems[category] = updatedGroupedItems[category].map((item) =>
                item.id === itemId
                    ? { ...item, status: item.status === 0 ? 1 : 0 } // Toggle status
                    : item
            );
        });

        // Update state with the modified items
        updateSupplyItems(updatedGroupedItems);
    };
    const handleTogglePrivacy = (itemId, isPrivate) => {
        setGroupedItems((prevGroupedItems) => {
            const updatedGroupedItems = { ...prevGroupedItems };

            Object.keys(updatedGroupedItems).forEach((category) => {
                updatedGroupedItems[category] = updatedGroupedItems[category].map((item) =>
                    item.id === itemId ? { ...item, private: isPrivate } : item
                );
            });

            return updatedGroupedItems;
        });

        setTimeout(() => handleSupplyItemListMenuClose(), 0); // Ensure UI updates before closing the menu

        //update the Planning
        set_PA_GlobalObject((prev) => ({
            ...prev, // Keep the rest of the global object
            Planning: {
                ...prev.Planning, // Keep other fields in Planning
                supplies: prev.Planning.supplies.map((item) =>
                    item.id === itemId ? { ...item, private: isPrivate } : item // ✅ Only update privacy
                ),
            },
        }));

    };

    const handleToggleEssential = (itemId, isEssential) => {
        // Clone the groupedItems object
        const updatedGroupedItems = { ...groupedItems };

        // Iterate through categories and update the item's essential
        Object.keys(updatedGroupedItems).forEach((category) => {
            updatedGroupedItems[category] = updatedGroupedItems[category].map((item) =>
                item.id === itemId ? { ...item, essential: isEssential } : item
            );
        });


        // Update state via the function passed from the parent
        updateSupplyItems(updatedGroupedItems);

        //update the Planning
        set_PA_GlobalObject((prev) => ({
            ...prev, // Keep the rest of the global object
            Planning: {
                ...prev.Planning, // Keep other fields in Planning
                supplies: prev.Planning.supplies.map((item) =>
                    item.id === itemId ? { ...item, essential: isEssential } : item // ✅ Only update essential
                ),
            },
        }));

        handleSupplyItemListMenuClose();

        if (isEssential) {
            // ✅ Find the item inside updatedGroupedItems
            let updatedItem = null;
            Object.values(updatedGroupedItems).some((categoryItems) => {
                updatedItem = categoryItems.find((item) => item.id === itemId);
                return updatedItem; // Stops looping once item is found
            });

            if (updatedItem) {
                // ✅ Check if the item has an essential_id set
                if (updatedItem.essential_id === 0) {
                    // Need to send this to the database to add to Account Essentials
                    //and get an essentials_id
                    const _send_string = {
                        action: "E_addNewAccountEssentialItem",
                        essential_item: updatedItem,
                    };

                    dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");
                }
            }
        }
    };

    // Handle Delete Click
    const handleSupplyItemDelete = (selectedItemId) => {
        console.log(`Deleting item ID: ${selectedItemId}`);

        // Find the selected item in groupedItems
        let selectedItem = null;
        Object.values(groupedItems).forEach((category) => {
            const foundItem = category.find((item) => item.id === selectedItemId);
            if (foundItem) {
                selectedItem = foundItem;
            }
        });

        if (!selectedItem) {
            console.warn("Item not found in groupedItems!");
            return;
        }

        // Close the menu
        handleSupplyItemListMenuClose();

        // Store the full item instead of just the ID
        setSupplyItemToDelete(selectedItem);

        // Show the confirmation dialog
        setDeleteDialogOpen(true);
    };


    const [tabValue, setTabValue] = useState('supplies');
    const handleTabChange  = (event, newValue) => {
        console.log("handleTabChange ");
        setTabValue(newValue);
    };

    // Count all supply and food items across subcategories
    const totalSupplies = Object.keys(groupedItems)
        .filter(key => key.startsWith("supplies_")) // Get all keys that start with "supplies_"
        .reduce((sum, key) => sum + (groupedItems[key]?.length || 0), 0); // Count all items

    const totalFood = Object.keys(groupedItems)
        .filter(key => key.startsWith("food_")) // Get all keys that start with "food_"
        .reduce((sum, key) => sum + (groupedItems[key]?.length || 0), 0); // Count all items

    const renderSupplyItem = (item) => {
        const assignedByAttendee = attendeesReferenceArray.find(_att => _att.id === item.assigned_by);

        return (
            <Box key={item.id} sx={{ paddingBottom: '10px' }} p={1}>
                {/* Supply Item Name & Icons */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {/* Item Name */}
                    <Typography
                        sx={{
                            textDecoration: item.status === 0 ? 'line-through' : 'none',
                            flexGrow: 1,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            paddingLeft: "15px",
                            cursor: item.assigned_to === Account.id ? 'pointer' : 'default',
                            pointerEvents: item.assigned_to === Account.id ? 'auto' : 'none',
                        }}
                        variant="body2"
                        onClick={() => {
                            if (item.assigned_to === Account.id) {
                                toggleItemStatus(item.id);
                            }
                        }}
                    >
                        {capitalizeFirstLetter(item.name)}
                    </Typography>

                    {/* Right-Aligned Icons */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                        {/* Small eye cross Icon for private */}
                        {item.private && (
                            <i
                                className="icon-eye-cross"
                                style={{
                                    color: "#f44336",
                                    fontSize: "14px",
                                    opacity: 0.8,
                                }}
                            />
                        )}

                        {/* Small Heart Icon for Essentials */}
                        {item.essential && (
                            <i
                                className="icon-heart"
                                style={{
                                    color: "#f44336",
                                    fontSize: "14px",
                                    opacity: 0.8,
                                }}
                            />
                        )}

                        {/* Menu Icon */}
                        {item.assigned_to === Account.id && (
                            <i
                                className="icon-menu2 event-supplies-attendee-list-item-category-line-menu-icon"
                                style={{ cursor: "pointer" }}
                                onClick={(event) => handleSupplyItemListMenuOpenMenu(event, item.id)}
                            />
                        )}
                    </Box>
                </Box>

                {/* Menu (Now Inside the Rendered Item) */}
                <Menu
                    sx={{ width: 320, maxWidth: '100%' }}
                    anchorEl={anchorSupplyItemListMenu}
                    open={Boolean(anchorSupplyItemListMenu && selectedItemId === item.id)}
                    onClose={handleSupplyItemListMenuClose}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                >
                    <MenuList dense sx={{ width: 320, maxWidth: '100%' }}>
                        {/* Item Name Header */}
                        <Box sx={{ padding: "8px 16px", backgroundColor: "#f5f5f5", textAlign: "center" }}>
                            <Typography
                                variant="subtitle2"
                                sx={{ fontWeight: "bold", textTransform: "uppercase", color: "#333" }}
                            >
                                {item.name}
                            </Typography>
                        </Box>

                        <Divider />
                        <MenuItem
                            onClick={() => handleTogglePrivacy(item.id, false)}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize: "14px",
                                wordWrap: "break-word",    // ✅ Ensures long text wraps
                                whiteSpace: "normal",      // ✅ Prevents text from overflowing
                                maxWidth: "100%",
                            }}
                        >
                            <Box sx={{ maxWidth: "250px" }}>
                                <Typography sx={{ fontSize: "14px" }}>Is Public</Typography>
                                <Typography variant="caption" color="text.secondary">
                                    Item can be seen by all attendees.
                                </Typography>
                            </Box>
                            {!item.private && (
                                <span style={{ marginLeft: "auto" }}>
                                <i className="icon-check" />
                            </span>
                            )}
                        </MenuItem>

                        <MenuItem
                            onClick={() => handleTogglePrivacy(item.id, true)}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize: "14px",
                                wordWrap: "break-word",    // ✅ Ensures long text wraps
                                whiteSpace: "normal",      // ✅ Prevents text from overflowing
                                maxWidth: "100%",
                            }}
                        >
                            <Box sx={{ maxWidth: "250px" }}>
                                <Typography sx={{ fontSize: "14px" }}>Is Private</Typography>
                                <Typography variant="caption" color="text.secondary">
                                    Item can only be seen by you.
                                </Typography>
                            </Box>
                            {item.private && (
                                <span style={{ marginLeft: "auto" }}>
                                <i className="icon-check" />
                            </span>
                            )}
                        </MenuItem>

                        <Divider />

                        <MenuItem
                            onClick={() => handleToggleEssential(item.id, true)}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize: "14px",
                                wordWrap: "break-word",    // ✅ Ensures long text wraps
                                whiteSpace: "normal",      // ✅ Prevents text from overflowing
                                maxWidth: "100%",
                            }}
                        >
                            <Box sx={{ maxWidth: "250px" }}>
                                <Typography sx={{ fontSize: "14px" }}>My Essentials Item</Typography>
                                <Typography variant="caption" color="text.secondary">
                                    Item IS included in your Account Essentials list
                                </Typography>
                            </Box>
                            {item.essential && (
                                <span style={{ marginLeft: "auto" }}>
                                <i className="icon-check" />
                            </span>
                            )}
                        </MenuItem>

                        <MenuItem
                            onClick={() => handleToggleEssential(item.id, false)}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize: "14px",
                                wordWrap: "break-word",    // ✅ Ensures long text wraps
                                whiteSpace: "normal",      // ✅ Prevents text from overflowing
                                maxWidth: "100%",
                            }}
                        >
                            <Box sx={{ maxWidth: "250px" }}>
                                <Typography sx={{ fontSize: "14px" }}>Not Essentials Item</Typography>
                                <Typography variant="caption" color="text.secondary">
                                    Item IS NOT included in your Account Essentials list
                                </Typography>
                            </Box>
                            {!item.essential && (
                                <span style={{ marginLeft: "auto" }}>
                                <i className="icon-check" />
                            </span>
                            )}
                        </MenuItem>

                        <Divider />
                        <MenuItem onClick={() => handleSupplyItemDelete(item.id)}>
                            <ListItemText>Remove</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Menu>


                {/* Assigned By */}
                {item.assigned_by !== item.assigned_to && assignedByAttendee && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '3px' }}>
                        <Typography variant="caption" sx={{ color: 'gray', fontStyle: 'italic', textAlign: 'right' }}>
                            Assigned By: {assignedByAttendee.nickname || assignedByAttendee.fname}
                        </Typography>
                    </Box>
                )}

                {/* Notes */}
                {item.notes && (
                    <Typography variant="body2" sx={{ color: 'gray', marginTop: '5px' }}>
                        {item.notes}
                    </Typography>
                )}
            </Box>
        );
    };


    useEffect(() => {
        if (SuppliesPipeline.length === 0) return; // Prevent unnecessary runs

        SuppliesPipeline.forEach(_incoming => {
            console.log('_incoming:', JSON.stringify(_incoming, null, '\t'));

            if (_incoming.result === "ok") {
                if (_incoming.action === "E_addNewAccountEssentialItemResults") {
                    // ✅ Find the supply item and update with the newly assigned essential_id
                    setGroupedItems((prevGroupedItems) => {
                        const updatedGroupedItems = { ...prevGroupedItems };

                        Object.keys(updatedGroupedItems).forEach((category) => {
                            updatedGroupedItems[category] = updatedGroupedItems[category].map((item) =>
                                item.id === _incoming["essential_item"].supply_item_id
                                    ? { ...item, essential_id: _incoming["essential_item"].id }
                                    : item
                            );
                        });

                        return updatedGroupedItems;
                    });

                    // ✅ Update Planning in PA_GlobalObject
                    set_PA_GlobalObject((prev) => ({
                        ...prev,
                        Planning: {
                            ...prev.Planning,
                            supplies: prev.Planning.supplies.map((item) =>
                                item.id === _incoming["essential_item"].supply_item_id
                                    ? { ...item, essential_id: _incoming["essential_item"].id }
                                    : item
                            ),
                        },
                    }));
                }
            } else {
                if (_incoming.action === "E_addNewAccountEssentialItemResults") {
                    console.error("Error:", _incoming.reason);
                }
            }

            // ✅ Ensure we remove processed actions
            removeFirstSuppliesActions();
        });

    }, [SuppliesPipeline]);



    const [supplyItemToDelete, setSupplyItemToDelete] = useState(null);  // Holds the supply item to delete for confirmation dialog
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);  // Controls the confirmation dialog visibility
    const handleDeleteSupplies = (supplyId) => {
        // Create a copy of groupedItems without the deleted item
        const updatedGroupedItems = { ...groupedItems };

        Object.keys(updatedGroupedItems).forEach((category) => {
            updatedGroupedItems[category] = updatedGroupedItems[category].filter((item) => item.id !== supplyId);
        });

        // Update state with the filtered groupedItems
        updateSupplyItems(updatedGroupedItems);

        // Update the PA Global Object to remove the item from Planning.supplies
        set_PA_GlobalObject((prev) => ({
            ...prev, // Keep other properties of PA Global Object
            Planning: {
                ...prev.Planning, // Keep other fields in Planning
                supplies: prev.Planning.supplies.filter((item) => item.id !== supplyId), // ✅ Remove the item
            },
        }));

        closeDeleteDialog(); // Close the dialog after deletion
    };

    const closeDeleteDialog = () => {
        setSupplyItemToDelete(null);  // Reset the Supply Item to be deleted
        setDeleteDialogOpen(false);  // Close the confirmation dialog
    };

    const [isSuppliesInfoDialogOpen, setSuppliesInfoDialogOpen] = useState(false);


    return (

        <div className="supplies-list-container" >
            {/* show the header */}
            <div className="supplies-list-header">
                <div className="supplies-list-header-text">
                    <h5>Supplies</h5>
                </div>

                <div className="supplies-list-header-info-icon-holder">
                    <i
                        className="icon-info-circle"
                        style={{fontSize: '20px', padding: '5px'}}
                        onClick={() => setSuppliesInfoDialogOpen(!isSuppliesInfoDialogOpen)}
                    />
                </div>

                <div className="supplies-list-header-chip-holder">

                    {
                        PA_GlobalObject.Planning.attendees && PA_GlobalObject.Planning.attendees.length > 0 ? (
                            <>

                                <Chip
                                    sx={menuChipStyle}
                                    label={
                                        <i className="icon-menu2" style={{fontSize: '16px'}}/>
                                    }
                                    size="small"
                                    onClick={handleSuppliesMenuOpenMenu}
                                />
                                <Menu
                                    sx={{width: 320, maxWidth: '100%'}}
                                    anchorEl={anchorSuppliesMenu}
                                    id="library-menu"
                                    open={Boolean(anchorSuppliesMenu)}
                                    onClose={handleSuppliesMenuClose}

                                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                >
                                    <MenuList
                                        dense
                                        sx={{width: 320, maxWidth: '100%'}}
                                    >
                                        <MenuItem
                                            data-id="from-essentials"
                                            onClick={dataArray.authenticated ? handleSuppliesMenuClick : null}
                                            disabled={!dataArray.authenticated || dataArray.Account.essentials === undefined}
                                        >
                                            <ListItemText>From My Essentials</ListItemText>
                                            <Typography variant="body2" color="text.secondary">
                                                {
                                                    dataArray.Account.essentials === undefined ? '' : dataArray.Account.essentials.length
                                                }
                                            </Typography>
                                            {
                                                !dataArray.authenticated &&
                                                <i className="icon-lock2" style={{marginLeft: '8px', fontSize: '16px'}}/>
                                            }
                                        </MenuItem>
                                        <MenuItem data-id="from-suggestion-list" onClick={handleSuppliesMenuClick}>
                                            <ListItemText>From Suggestions</ListItemText>
                                            <Typography variant="body2" color="text.secondary">
                                                {
                                                    allSuggestedItems.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem data-id="new-entry" onClick={handleSuppliesMenuClick}>
                                            <ListItemText>New Entry</ListItemText>
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </>
                        ) : (
                            <></>
                        )
                    }

                </div>
            </div>

            {/* show the list of attendees */}
            <div className="supplies-list-attendee-list-container">
                {
                    sortedAttendees.length > 0 ? (
                        sortedAttendees.map((attendee, index) => (
                            <Badge
                                key={index}
                                badgeContent={attendee.supplyCount > 0 ? attendee.supplyCount : null}
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: 'white',
                                        border: '1px solid #ddd',
                                        width: '16px',
                                        height: '16px',
                                        borderRadius: '50%',
                                        top: '10px',
                                        right: '10px',
                                    },
                                }}
                            >
                                <Chip
                                    sx={selectedAttendee === attendee.id ? selectedChipStyle : unselectedChipStyle}
                                    label={capitalizeFirstLetter(attendee.nickname)}
                                    clickable
                                    onClick={() => handleChipClick(attendee.id)}
                                    style={{margin: '0.5rem'}}
                                />
                            </Badge>
                        ))
                    ) : (
                        <div style={{padding: '20px'}}>
                            <i className="icon-warning" style={{fontSize: '11px', color: 'red', marginRight: '10px'}}/>
                            <Typography variant="caption">Need to add some Attendees first</Typography>
                        </div>
                    )
                }
            </div>

            {/* show the SUPPLIES / FOOD tabs if there are items in both categories; otherwise show nothing */}
            <div className="supplies-list-items-container">
                <TabContext sx={{ position:'relative'}} value={tabValue}>
                    <TabList
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            backgroundColor: 'rgba(251, 251, 251, 0.8)',
                            backdropFilter: 'blur(6px)',
                        }}
                        centered
                        variant="fullWidth"
                        onChange={handleTabChange}
                        aria-label="Supplies / Food"
                    >
                        <Tab
                            value="supplies"
                            label={
                                <Box
                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    Supplies
                                    {totalSupplies > 0 && ( // Only show badge if totalSupplies > 0
                                        <Badge
                                            badgeContent={totalSupplies}
                                            sx={{
                                                '& > *': {
                                                    position: 'absolute',
                                                    top: '-10px',
                                                    right: '-10px',
                                                    backgroundColor: 'white',
                                                    color: 'green',
                                                    borderRadius: '35%',
                                                    width: '15px',
                                                    height: '15px',
                                                    fontSize: '12px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    zIndex: 1,
                                                    border: '1px solid',
                                                    fontWeight: '700',
                                                },
                                            }}
                                        />
                                    )}
                                </Box>
                            }
                        />
                        <Tab
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    Food
                                    {totalFood > 0 && ( // Only show badge if totalFood > 0
                                        <Badge
                                            badgeContent={totalFood}
                                            sx={{
                                                '& > *': {
                                                    position: 'absolute',
                                                    top: '-10px',
                                                    right: '-10px',
                                                    backgroundColor: 'white',
                                                    color: 'green',
                                                    borderRadius: '35%',
                                                    width: '15px',
                                                    height: '15px',
                                                    fontSize: '12px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    zIndex: 1,
                                                    border: '1px solid',
                                                    fontWeight: '700',
                                                },
                                            }}
                                        />
                                    )}
                                </Box>
                            }
                            value="food"
                        />
                    </TabList>
                    <TabPanel value="supplies" sx={{ padding: '24px 0' }}>
                        {Object.keys(groupedItems)
                            .filter(key => key.startsWith("supplies_"))
                            .sort((a, b) =>
                                a.replace(/^supplies_/, '').replace(/_/g, ' ')
                                    .localeCompare(
                                        b.replace(/^supplies_/, '').replace(/_/g, ' ')
                                    )
                            )
                            .map((categoryKey) => (
                                <Box key={categoryKey}>
                                    <Typography sx={{ backgroundColor: '#e8f2ed', paddingLeft: '10px' }} variant="subtitle1" gutterBottom>
                                        {categoryKey.replace(/^supplies_/, '').replace(/_/g, ' ')}
                                    </Typography>

                                    {groupedItems[categoryKey]
                                        .sort((a, b) => (a.name?.toLowerCase() ?? "").localeCompare(b.name?.toLowerCase() ?? ""))
                                        .map((item) => renderSupplyItem(item))}
                                </Box>
                            ))}

                    </TabPanel>
                    <TabPanel value="food" sx={{ padding: '24px 0' }}>
                        {Object.keys(groupedItems)
                            .filter(key => key.startsWith("food_")) // Only show food-related categories
                            .sort((a, b) =>
                                a.replace(/^food_/, '').replace(/_/g, ' ')
                                    .localeCompare(
                                        b.replace(/^food_/, '').replace(/_/g, ' ')
                                    )
                            )
                            .map((categoryKey) => (
                                <Box key={categoryKey}>
                                    {/* Category Header */}
                                    <Typography sx={{ backgroundColor: '#e8f2ed', paddingLeft: '10px' }} variant="subtitle1" gutterBottom>
                                        {categoryKey.replace(/^food_/, '').replace(/_/g, ' ')}
                                    </Typography>

                                    {/* Render Food Items */}
                                    {groupedItems[categoryKey]
                                        .sort((a, b) => (a.name?.toLowerCase() ?? "").localeCompare(b.name?.toLowerCase() ?? ""))
                                        .map((item) => renderSupplyItem(item))}
                                </Box>
                            ))}

                    </TabPanel>

                </TabContext>
            </div>


            {/* Confirmation Dialog */}
            <Dialog
                open={isDeleteDialogOpen}
                onClose={closeDeleteDialog}
            >
                <DialogTitle>Delete Supply Item</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the supply item "{supplyItemToDelete?.name}"? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDeleteSupplies(supplyItemToDelete.id)} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <InfoSupplies
                isOpen={isSuppliesInfoDialogOpen}
                onClose={() => setSuppliesInfoDialogOpen(false)}
            />
        </div>
    )
}

export default SuppliesList;
