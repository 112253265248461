import dayjs from "dayjs";
import usePA_GlobalStore from "./ZustandGlobalStore";


export function WeatherUsingVisualCrossing() {

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    console.log("WeatherDetails : See if need to pull weather report");

    /*
        first we are looking at event dates to see if we are within the 14 day window of the event beginning
        and if so then we need locations to send with weather request
        so we'll look at any lodgings with locations and create an array of locations
     */

    // Check if event has dates
    const eventDates = PA_GlobalObject.Planning?.time_table?.dates || [];

    if (eventDates.length > 0) {
        const eventBeginDate = dayjs(eventDates[0].date); // Ensure proper date parsing
        const eventEndDate = dayjs(eventDates[eventDates.length - 1].date); // Ensure proper date parsing
        const daysUntilBeginDate = eventBeginDate.diff(dayjs(), 'day');

        console.log(`Event begins on: ${eventBeginDate.format("YYYY-MM-DD")} (in ${daysUntilBeginDate} days)`);

        if (daysUntilBeginDate <= 14) {
            console.log("Within the 14-day window. Fetching lodging locations...");

            // Extract lodging locations that have valid latitude/longitude
            const lodgingLocations = PA_GlobalObject.Planning.lodging
                .filter(lodging => lodging.location?.latitude!==0 && lodging.location?.longitude!==0);

            if (lodgingLocations.length === 0) {
                console.warn("No valid lodging locations found for weather report.");
                return;
            }

            console.log(`Found ${lodgingLocations.length} valid lodging locations.`);

            // Construct weather request data
            const _search_data_event_locations = lodgingLocations.map(lodge => {
                const lodgeBeginDate = dayjs(lodge.time_table.dates[0].date).format('YYYY-MM-DD');
                const lodgeEndDate = dayjs(lodge.time_table.dates[lodge.time_table.dates.length - 1].date).format('YYYY-MM-DD');

                return {
                    city: lodge.location.city,
                    state: lodge.location.state,
                    location_type: lodge.location.latitude && lodge.location.longitude ? "latlng" : "address",
                    latitude: lodge.location.latitude,
                    longitude: lodge.location.longitude,
                    location: lodge.location.address ? `${lodge.location.address} ${lodge.location.city} ${lodge.location.state} ${lodge.location.zip}` : "",
                    unit_group: lodge.location.country === "US" ? "us" : "metric",
                    begin_date: lodgeBeginDate,
                    end_date: lodgeEndDate,
                };
            });

            console.log("Weather request locations:", JSON.stringify(_search_data_event_locations, null, 2));

            // Construct API URLs
            const visualCrossingBase = "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/";
            const timelineService = "timeline/";

            // Determine if we are in the event time window
            const inEvent = eventBeginDate.diff(dayjs(), "days") <= 0 && dayjs().diff(eventEndDate, "days") < 1;

            // Function to delay API calls
            const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

            // Function to fetch weather data sequentially (one at a time)
            const fetchWeatherData = async () => {
                const weatherData = [];

                for (const location of _search_data_event_locations) {
                    const searchLocation = location.location_type === "address"
                        ? `${location.location}/`
                        : `${location.latitude},${location.longitude}/`;

                    const searchBeginDate = `${location.begin_date}/`;
                    const searchEndDate = location.end_date;
                    const includeData = inEvent ? "" : "&include=days";

                    const searchUrl = `${visualCrossingBase}${timelineService}${searchLocation}${searchBeginDate}${searchEndDate}?key=HQTW7H7BS2KSSU4Z3LTWMETCV&contentType=json&unitGroup=${location.unit_group}${includeData}`;

                    console.log(`Fetching weather data from: ${searchUrl.replaceAll(" ", "%20")}`);

                    try {
//                        const response = await fetch(searchUrl.replaceAll(" ", "%20"), { method: 'GET' });
                        const response = {'ok': true};


                        if (!response.ok) {
                            throw new Error(`HTTP Error ${response.status}`);
                        }

                        const data = await response.json();
                        console.log("Weather API Response:", JSON.stringify(data, null, 2));
                        weatherData.push({ ...location, report: data });

                    } catch (error) {
                        console.error(`Error fetching weather for ${location.city}:`, error);
                        weatherData.push({ ...location, report: null, error: true });
                    }

                    // **Add a delay to avoid "Too Many Requests" error**
                    await delay(2000); // Wait 2 seconds before the next request
                }

                return weatherData;
            };

            // Fetch weather data sequentially and update state
            fetchWeatherData().then(weatherData => {
                console.log("Final Weather Data:", weatherData);

                return weatherData;
            });



        } else {
            console.info("Outside the 14-day window. No weather report needed yet.");
        }

    } else {
        console.warn("Event does not have any dates set. Weather report cannot be pulled.");
    }

}

export default WeatherUsingVisualCrossing;
