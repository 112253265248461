import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Avatar,
    Button,
    Card, CardActions, CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle, FormControl,
    InputLabel, Menu, MenuItem,
    OutlinedInput, Select, TextField,
    Tooltip
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import {capitalizeFirstLetter, formatName, stringAvatar, stringToColor} from "../Helpers/TextStyling";
import dayjs from "dayjs";
import {PAContext} from "../../Services/PAContext";
import Papa from "papaparse";
import * as XLSX from 'xlsx';
import {DataGrid} from "@mui/x-data-grid";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import country_codes from "../../sampleData/PhonePrefixes";
import usePA_GlobalStore from "../../Services/ZustandGlobalStore";
import {Stack} from "@mui/system";
import {useGoogleAutocompleteForAddress} from "../Helpers/GoogleAutocomplete";



function MasterAttendees(
    {
        dialogRefAttendees,
        isAttendeesDialogOpen,
        closeAttendeesDialog,
        isEventDialogOpen,
        isUploadModeOn
    }){

    const { dataArray, updateAttendee, removeFirstDialogActions } = useContext(PAContext);
    const { Account, Attendees, SampleAttendees, DialogPipeline } = dataArray;

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated
        ? Attendees
        : SampleAttendees);
    useEffect(() => {
        setAttendeesReferenceArray(
            dataArray.authenticated
                ? Attendees
                : SampleAttendees
        );
    }, [dataArray.authenticated, Attendees, SampleAttendees]);

    const [sortedAttendees, setSortedAttendees] = useState(Attendees);
    const [sortOrderAttendees, setSortOrderAttendees] = useState('asc');

    useEffect(() => {
        const sortedAttendees = [...Attendees].sort((a, b) => {
            const lnameA = (a.lname || '').toLowerCase();
            const lnameB = (b.lname || '').toLowerCase();

            if (lnameA < lnameB) return -1;
            if (lnameA > lnameB) return 1;
            return 0;
        });
        setSortedAttendees(sortedAttendees);
    }, [Attendees]);

    useEffect(() => {
        if (DialogPipeline.length === 0) return; // Prevent unnecessary runs

        DialogPipeline.forEach(_incoming => {
            console.log('_incoming:', JSON.stringify(_incoming, null, '\t'));

            if (_incoming.result === "ok") {
                if (_incoming.action === "updateMasterContactsAttendeeFieldsResults") {
                    //find the Attendee in the sortedAttendees and apply the updates

                    setSortedAttendees((prevAttendees) =>
                        prevAttendees.map((att) => {
                            if (att.id === _incoming.attendee_id) {
                                const updatedAttendee = {
                                    ...att,
                                    image: _incoming.image,
                                    avatar: _incoming.avatar,
                                    fname: _incoming.fname,
                                    lname: _incoming.lname,
                                    nickname: _incoming.nickname,
                                    email: _incoming.email,
                                    dob: _incoming.dob,
                                    address: _incoming.address,
                                    city: _incoming.city,
                                    state: _incoming.state,
                                    zip: _incoming.zip,
                                    country: _incoming.country,
                                    phone_prefix: _incoming.phone_prefix,
                                    phone: _incoming.phone,
                                    language: _incoming.language,
                                    preferred_airport: _incoming.preferred_airport,
                                };

                                // Also update PAContext Attendees
                                updateAttendee({ ...updatedAttendee });

                                return updatedAttendee;
                            }
                            return att;
                        })
                    );

                    //close the Edit side
                    setActiveSection(null);

                }
            } else {
                if (_incoming.action === "updateMasterContactsAttendeeFieldsResults") {
                    console.error("Error:", _incoming.reason);
                }
            }

            // ✅ Ensure we remove processed actions
            removeFirstDialogActions();
        });

    }, [DialogPipeline]);

    const [searchTerm, setSearchTerm] = useState(''); // Unified search term
    const handleSearchChange = (event) => {
        setIsUploadMode(false);
        console.log("Search term: " + event.target.value);
        setSearchTerm(event.target.value); // Update search term
    };
    useEffect(() => {
        console.log("Search term changed");

        const normalizedSearchTerm = searchTerm.toLowerCase();

        const filteredAttendees = Attendees.filter((att) => {
            return att.fname.toLowerCase().startsWith(normalizedSearchTerm) ||
                att.lname.toLowerCase().startsWith(normalizedSearchTerm);
        });

        setSortedAttendees(filteredAttendees);

    }, [searchTerm]);


    const handleAttendeeSort = () => {
        setIsUploadMode(false);

        const sortedAttendees = [...Attendees].sort((a, b) => {
            const lnameA = a.lname.toLowerCase();
            const lnameB = b.lname.toLowerCase();
            const fnameA = a.fname.toLowerCase();
            const fnameB = b.fname.toLowerCase();

            if (sortOrderAttendees === 'asc') {
                if (lnameA < lnameB) return -1;
                if (lnameA > lnameB) return 1;
                // If last names are equal, compare first names
                if (fnameA < fnameB) return -1;
                if (fnameA > fnameB) return 1;
                return 0;
            } else {
                if (lnameA > lnameB) return -1;
                if (lnameA < lnameB) return 1;
                // If last names are equal, compare first names
                if (fnameA > fnameB) return -1;
                if (fnameA < fnameB) return 1;
                return 0;
            }
        });

        setSortedAttendees(sortedAttendees);
        setSortOrderAttendees((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };
    const handleAttendeeReset = () => {
        setIsUploadMode(false);
        const sortedAttendees = [...Attendees].sort((a, b) => {
            const lnameA = a.lname.toLowerCase();
            const lnameB = b.lname.toLowerCase();

            if (lnameA < lnameB) return -1;
            if (lnameA > lnameB) return 1;
            return 0;
        });
        setSortedAttendees(sortedAttendees);
    }


    const [isUploadMode, setIsUploadMode] = useState(isUploadModeOn);
    const fileInputRef = useRef(null);
    const [originalFileData, setOriginalFileData] = useState([]);
    const [fileDataWithIds, setFileDataWithIds] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedEntries, setSelectedEntries] = useState([]); // To store the selected entries from file
    const [duplicateEmails, setDuplicateEmails] = useState([]);

    const handleUploadClick = () => {
        if (!isUploadMode){
            //they are toggling ON; clear everything
            handleAttendeeFileReset();
        }

        setIsUploadMode(!isUploadMode);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setSelectedFile(file);

        const reader = new FileReader();
        const fileType = file.name.split('.').pop().toLowerCase();

        if (fileType === 'csv') {
            // Handle CSV Parsing
            reader.onload = (e) => {
                const text = e.target.result;
                const parsedData = Papa.parse(text, { header: true });

                /*
                    if there isn't a column labeled as id then we need to male one and
                    populate it with just numbered data
                 */
                const dataWithIds = parsedData.data
                    .filter(row => Object.values(row).some(value => value !== '' && value !== null && value !== undefined)) // Filter out empty rows
                    .map((row, index) => {
                        const idValue = row.id || row.ID || row.Id || index; // Use existing id if it exists, otherwise use index
                        const displayId = index + 1;
                        return {
                            id: idValue,  // Ensure the `id` field is always present
                            displayId,
                            ...row  // Spread the rest of the row's data
                        };
                    });

                setFileDataWithIds(dataWithIds);

                setOriginalFileData(parsedData.data);  // Store the original data
                setColumns(Object.keys(parsedData.data[0]).map((key) => ({ field: key, headerName: key, width: 150 })));
            };
            reader.readAsText(file);
        } else if (fileType === 'xls' || fileType === 'xlsx') {
            // Handle XLS/X Parsing
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

                // First row is header
                const headers = sheet[0];
                const rows = sheet.slice(1).map((row) => {
                    const rowData = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index];
                    });
                    return rowData;
                });

                const dataWithIds = rows
                    .filter(row => Object.values(row).some(value => value !== '' && value !== null && value !== undefined)) // Filter out empty rows
                    .map((row, index) => {
                        const idValue = row.id || row.ID || row.Id || index; // Use existing id if it exists, otherwise use index
                        const displayId = index + 1;
                        return {
                            id: idValue,  // Ensure the `id` field is always present
                            displayId,
                            ...row  // Spread the rest of the row's data
                        };
                    });

                setFileDataWithIds(dataWithIds);
                setOriginalFileData(rows);  // Store the original data
                setColumns(headers.map((header) => ({ field: header, headerName: header, width: 150 })));

            };
            reader.readAsArrayBuffer(file);
        } else {
            alert('Unsupported file format. Please upload a CSV or Excel file.');
        }
    };
    const highlightDuplicates = (duplicates) => {
        const updatedRows = fileDataWithIds.map((row) => {
            // Dynamically find the email field (case-insensitive check for 'email')
            const emailKey = Object.keys(row).find(key => key.toLowerCase().includes('email'));
            return {
                ...row,
                isDuplicate: duplicates.includes(row[emailKey]?.toLowerCase()) // Mark duplicates
            };
        });

        console.log("updatedRows: "+JSON.stringify(updatedRows));

        // Sort: move duplicates to the top
        const sortedData = updatedRows.sort((a, b) => {
            if (a.isDuplicate && !b.isDuplicate) return -1;
            if (!a.isDuplicate && b.isDuplicate) return 1;
            return 0;
        });

        // Update the grid data with sorted and marked rows (this updates dataWithIds)
        setFileDataWithIds(sortedData);  // Update the state for DataGrid to re-render
    };
    const handleButtonSubmit = () => {
        console.log("selectedEntries : ", selectedEntries);

        // Extract emails from the selectedEntries (case-insensitive lookup for any variation of 'email')
        const selectedEmails = selectedEntries.map((row) => {
            // Find a key that contains 'email' (case-insensitive)
            const emailKey = Object.keys(row).find(key => key.toLowerCase().includes('email'));
            // Return the email value if found, otherwise return null
            return row[emailKey]?.toLowerCase() || null;
        }).filter(Boolean);  // Filter out any null values

        // Extract emails from the Attendees array
        const attendeeEmails = Attendees.map((attendee) => attendee.email?.toLowerCase());

        // Find duplicates between selected emails and Attendees' emails
        const duplicates = selectedEmails.filter((email) => attendeeEmails.includes(email));
        console.log("duplicates : ", duplicates);

        // Show the alert if duplicates are found and highlight them
        if (duplicates.length > 0) {
            setDuplicateEmails(duplicates);  // Display duplicates as an alert or some other UI
            highlightDuplicates(duplicates); // Highlight duplicates in the DataGrid
        } else {
            setDuplicateEmails([]);  // Clear duplicates
            // Proceed with the upload logic if no duplicates
            console.log('No duplicates found. Proceeding with submission...');
        }
    };
    const handleAttendeeFileReset = () => {

        // Reset the file input to remove any selected file
        setSelectedFile(null);

        // Clear file data states to reset the uploaded data
        setFileDataWithIds([]); // If using dataWithIds as a processed version
        setColumns([]);

        // Clear duplicate alerts or state flags related to duplicates
        setDuplicateEmails([]);
        setSelectedEntries([]);

        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Clear the displayed file name
        }

    }


    // Dynamically calculate the card width based on the dialog width
    const [dialogWidth, setDialogWidth] = useState(0);
    // Get the width of the Dialog once it's rendered
    useEffect(() => {
        if (isAttendeesDialogOpen && dialogRefAttendees.current) {
            const width = dialogRefAttendees.current.offsetWidth;
            console.log("&&&&&&&& setting dialogWidth : "+width);
            setDialogWidth(width);
        }
    }, [isEventDialogOpen]);
    const calculateCardWidth = () => {
        const cardsPerRow = 3;  // You can adjust the number of cards per row here
        const spacing = 16;  // Space between cards (as defined by Grid spacing)
        const totalSpacing = (cardsPerRow - 1) * spacing;
        const calculatedWidth = (dialogWidth - totalSpacing) / cardsPerRow;

        // Ensure the card width does not exceed 250px
        return Math.min(calculatedWidth, 250);
    };



    const [anchorAttendeeElements, setAnchorAttendeeElements] = useState(sortedAttendees.map(() => null));
    const handleOpenAttendeeMenu = (event, index) => {
        const updatedAnchors = [...anchorAttendeeElements];
        updatedAnchors[index] = event.currentTarget;
        setAnchorAttendeeElements(updatedAnchors);
    };

    // Function to handle closing the menu for a specific attendee.
    const handleCloseAttendeeMenu = (index) => {
        const updatedAnchors = [...anchorAttendeeElements];
        updatedAnchors[index] = null;
        setAnchorAttendeeElements(updatedAnchors);
    };


    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [attendeeToDeleteId, setAttendeeToDeleteId] = useState("");
    const [attendeeToDeleteName, setAttendeeToDeleteName] = useState("");

    function handleDialogDeleteOpen(index, attendee_id) {
        console.log("handleDialogDeleteOpen : "+attendee_id);

        handleCloseAttendeeMenu(index);

        setAttendeeToDeleteId(attendee_id);

        const foundAttendee = Attendees.find((_att) => _att.id === attendee_id);
        setAttendeeToDeleteName(foundAttendee.fname + ' ' + foundAttendee.lname);

        setOpenDialogDelete(true);

    }
    function handleDialogDeleteClose() {
        setOpenDialogDelete(false);
    }
    function handleDialogDeleteOK(){
        setOpenDialogDelete(false);

    }

    // Function to handle file input click on avatar
    const [ attendeeAvatar, setAttendeeAvatar] = useState({});
    const handleAvatarClick = (id) => {
        document.getElementById(`file-input-${id}`).click();
    };

    // Function to handle file change and set the avatar
    const handleImageChange = (event, id) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64data = reader.result;

            setAttendeeAvatar(prev => (
                {
                    preview: base64data, // This is base64 string of image
                    name: file.name,
                    type: file.type,
                    size: file.size,
                }
            ));

        };

        reader.readAsDataURL(file);
    };




    const [activeSection, setActiveSection] = useState(null); // Keeps track of active section
    const [selectedAttendeeId, setSelectedAttendeeId] = useState(null);

    const [addressFieldsResidential, setAddressFieldsResidential] = useState({
        google_place_id: '',
        latitude: 0,
        longitude: 0,
        formatted_address: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    });
    const { inputRefResidential, attachAutocompleteResidential, removePacContainerResidential } = useGoogleAutocompleteForAddress((parsedAddress) => {
        setAddressFieldsResidential({
            google_place_id: parsedAddress.google_place_id,
            latitude: parsedAddress.latitude,
            longitude: parsedAddress.longitude,
            formatted_address: parsedAddress.formatted_address,
            address: parsedAddress.address,
            city: parsedAddress.city,
            state: parsedAddress.state,
            zip: parsedAddress.zip,
            country: parsedAddress.country,
        });
    });

    const handleAddressComponentChange = (e) => {
        const { name, value } = e.target;
        setAddressFieldsResidential((prev) => ({ ...prev, [name]: value }));
    };

    const handleEditAttendee = (attendeeId, index) => {

        const _selected_attendee = Attendees.find(attendee => attendee.id === attendeeId);

        if (_selected_attendee){
            setGroupedAttendees([]); // Clear grouped attendees
            setSelectedAttendeeId(attendeeId); // Set the selected attendee's ID

            setAttendeeAvatar(_selected_attendee.avatar);

            setFname(_selected_attendee.fname);
            setLname(_selected_attendee.lname);
            setNickname(_selected_attendee.nickname);

            setAddressFieldsResidential((prev) => ({ ...prev, ['address']: _selected_attendee.address }));
            setAddressFieldsResidential((prev) => ({ ...prev, ['city']: _selected_attendee.city }));
            setAddressFieldsResidential((prev) => ({ ...prev, ['state']: _selected_attendee.state }));
            setAddressFieldsResidential((prev) => ({ ...prev, ['zip']: _selected_attendee.zip }));
            setAddressFieldsResidential((prev) => ({ ...prev, ['country']: _selected_attendee.country }));

            setEmail(_selected_attendee.email);
            setPhonePrefix(_selected_attendee.phone_prefix);
            setPhone(_selected_attendee.phone);
            setDOB(_selected_attendee.dob);
            setLanguage(_selected_attendee.language);
            setPreferredAirport(_selected_attendee.preferred_airport);

            setActiveSection('edit'); // Set active section to 'edit'
            handleCloseAttendeeMenu(index); // Close the menu
        }

    };
    const handleSendEmail = (attendeeId, index) => {
        setGroupedAttendees([]); // Clear grouped attendees
        setSelectedAttendeeId(attendeeId); // Set the selected attendee's ID
        setActiveSection('email'); // Set active section to 'email'
        handleCloseAttendeeMenu(index); // Close the menu
    };
    const handleAddToEvent = (attendeeId, index) => {
        setGroupedAttendees([]); // Clear grouped attendees
        setSelectedAttendeeId(attendeeId); // Set the selected attendee's ID
        setActiveSection('add'); // Set active section to 'add'
        handleCloseAttendeeMenu(index); // Close the menu
    };
    const handleSendCoupon = (attendeeId, index) => {
        setGroupedAttendees([]); // Clear grouped attendees
        setSelectedAttendeeId(attendeeId); // Set the selected attendee's ID
        setActiveSection('coupon'); // Set active section to 'coupon'
        handleCloseAttendeeMenu(index); // Close the menu
    };
    function clearMenuSelections() {
        setGroupedAttendees([]);
        setSelectedAttendeeId(null);
        setActiveSection(null);
    }

    const [groupedAttendees, setGroupedAttendees] = useState([]); // State to hold grouped attendees
    useEffect(() => {
        if (groupedAttendees.length > 0) {
            setActiveSection('grouped');
        } else if (activeSection === 'grouped') {
            setActiveSection(null); // Clear active section if groupedAttendees is empty
        }
    }, [groupedAttendees]);
    // Toggle attendee in and out of the group array
    const handleGroupToggle = (attendeeId) => {
        setGroupedAttendees(prevGrouped =>
            prevGrouped.includes(attendeeId)
                ? prevGrouped.filter(id => id !== attendeeId) // Remove if already in group
                : [...prevGrouped, attendeeId] // Add if not in group
        );

        // Set activeSection to 'grouped' if there are any grouped attendees, else clear it
        setActiveSection(groupedAttendees.length > 0 ? 'grouped' : null);
        setSelectedAttendeeId(null);
    };
    function handleAddContactsToPlanning() {
        set_PA_GlobalObject((prev) => {
            // Ensure `Planning.attendees` exists
            const existingAttendees = prev.Planning?.attendees || [];

            // Find full attendee objects using groupedAttendees (which are just IDs)
            const newAttendees = attendeesReferenceArray.filter(attendee => groupedAttendees.includes(attendee.id));

            /*
                for each Attendee that we're adding they should have the role of 'guest'
                except if its the user that is logged in
                so go through the list and update the role
             */
            const updatedNewAttendees = newAttendees.map(attendee => ({
                ...attendee,
                role: attendee.id === Account.id ? 'admin' : 'guest'
            }));

            // Merge existing attendees while avoiding duplicates (based on attendee.id)
            const uniqueAttendees = [
                ...existingAttendees,
                ...updatedNewAttendees.filter(
                    newAttendee => !existingAttendees.some(existing => existing.id === newAttendee.id)
                ),
            ];

            return {
                Planning: {
                    ...prev.Planning, // Retain all existing fields in Planning
                    attendees: uniqueAttendees,
                },
            };
        });

        //clear out the selected attendees
        setGroupedAttendees([]);
        //close the Dialog
        closeAttendeesDialog();
    }


    //for editing Attendee info
    const [image,setImage] = useState("");
    const [fname,setFname] = useState("");
    const [lname,setLname] = useState("");
    const [nickname,setNickname] = useState("");
    const [email,setEmail] = useState("");
    const [phonePrefix,setPhonePrefix] = useState("");
    const [phone,setPhone] = useState("");
    const [dob,setDOB] = useState("");
    const [language,setLanguage] = useState("");
    const [preferredAirport,setPreferredAirport] = useState("");

    function handleFnameChange(event){
        setFname(event.target.value);
    }
    function handleLnameChange(event){
        setLname(event.target.value);
    }
    function handleNicknameChange(event){
        setNickname(event.target.value);

    }
    function handleEmailChange(event){
        setEmail(event.target.value);
    }
    function handlePhonePrefixChange(event){
        console.log("setting phone prefix to : "+event.target.value);
        setPhonePrefix(event.target.value);
    }
    function handlePhoneChange(event){
        console.log("setting phone prefix to : "+event.target.value);

        if (phonePrefix === "+1"){
            //format for area code
            const input = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
            let formattedNumber = input;

            if (input.length >= 3) {
                formattedNumber = `(${input.slice(0, 3)})`;

                if (input.length > 3) {
                    formattedNumber += ` ${input.slice(3, 6)}`;

                    if (input.length > 6) {
                        formattedNumber += `-${input.slice(6, 10)}`;
                    }
                }
            }
            setPhone(formattedNumber);
        } else {
            setPhone(event.target.value);
        }

    }
    function handleDOBChange(event){
        setDOB(event.target.value);
    }
    function handleLanguageChange(event){
        setLanguage(event.target.value);
    }
    function handlePreferredAirportChange(event){
        setPreferredAirport(event.target.value);
    }

    function handleSaveAttendeeEdit(){

        //send to server
        const _sendString = {};
        _sendString.action = "updateMasterContactsAttendeeFields";
        _sendString.event_id = "";
        _sendString.attendee_id = selectedAttendeeId;
        _sendString.image = image;
        _sendString.avatar = attendeeAvatar;
        _sendString.fname = fname;
        _sendString.lname = lname;
        _sendString.nickname = nickname;

        _sendString.address = addressFieldsResidential['address'];
        _sendString.city = addressFieldsResidential['city'];
        _sendString.state = addressFieldsResidential['state'];
        _sendString.zip = addressFieldsResidential['zip'];
        _sendString.country = addressFieldsResidential['country'];

        _sendString.email = email;
        _sendString.dob = dob;

        _sendString.phone_prefix = phonePrefix;
        _sendString.phone = phone;

        _sendString.language = language;
        _sendString.preferred_airport = preferredAirport;


        console.log("_sendString : ", _sendString);

        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
    }


    return (

        <Dialog
            ref={dialogRefAttendees}
            open={isAttendeesDialogOpen}
            onClose={closeAttendeesDialog}
            fullWidth
            maxWidth="md"
            PaperProps={{
                sx: {
                    minHeight: '600px',
                    '@media (max-height:600px)': {
                        minHeight: '100%', // Let it shrink if the screen is smaller than 600px
                    },
                },
            }}
        >
            <DialogTitle>{sortedAttendees.length} Contact{sortedAttendees.length===1?'':'s'}</DialogTitle>

            {/* Combined Search Input Box */}
            <TextField
                sx={{
                    position: 'absolute',
                    right: 250, // Adjust as needed
                    top: 8,
                    width: '250px' // Adjust width to accommodate both names
                }}
                value={searchTerm}
                onChange={handleSearchChange} // Unified search change handler
                label="First or Last Name"
                type="search"
                variant="standard"
                size="small"
            />
            <Tooltip title="Sort">
                <IconButton
                    aria-label="Sort"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 200,
                        top: 8,
                        color: theme.palette.grey[700],
                        cursor: 'pointer',
                        fontSize: '18px',
                    })}
                    onClick={handleAttendeeSort}
                >
                    <i
                        className="icon-sort-alpha-asc"
                        style={{
                            color: 'green',
                            fontSize: '18px',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title="Bulk Upload">
                <IconButton
                    aria-label="Upload"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 150,
                        top: 8,
                        color: theme.palette.grey[700],
                        cursor: 'pointer',
                        fontSize: '18px',
                    })}
                    onClick={handleUploadClick}
                >
                    <i
                        className="icon-upload"
                        style={{
                            color: 'green',
                            fontSize: '18px',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title="Add New Contact">
                <IconButton
                    aria-label="AddNew"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 100,
                        top: 8,
                        color: theme.palette.grey[700],
                        cursor: 'pointer',
                        fontSize: '18px',
                    })}
                    onClick={closeAttendeesDialog}
                >
                    <i
                        className="icon-user-plus"
                        style={{
                            color: 'red',
                            fontSize: '18px',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title="Reset">
                <IconButton
                    aria-label="Reset"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 50,
                        top: 8,
                        color: theme.palette.grey[700],
                        cursor: 'pointer',
                        fontSize: '18px',
                    })}
                    onClick={isUploadMode ? handleAttendeeFileReset : handleAttendeeReset}
                >
                    <i
                        className="icon-cw"
                        style={{
                            color: 'green',
                            fontSize: '18px',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title="Close">
                <IconButton
                    aria-label="Close"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 0,
                        top: 8,
                        color: theme.palette.grey[700],
                        cursor: 'pointer',
                        fontSize: '18px',
                    })}
                    onClick={closeAttendeesDialog}
                >
                    <i
                        className="icon-cross"
                        style={{
                            color: 'green',
                            fontSize: '18px',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    />
                </IconButton>
            </Tooltip>

            <DialogContent
                sx={{
                    display: 'flex',
                    gap: 2,
                    position: 'relative'
                }}
            >
                {isUploadMode ? (
                    <div>
                        <Typography variant="subtitle1">Select a file to upload:</Typography>
                        {/* Row with OutlinedInput, duplicateEmails, and Button */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2, marginBottom: 2 }}>
                            {/* File Upload Input */}
                            <OutlinedInput
                                type="file"
                                inputRef={fileInputRef}
                                inputProps={{ accept: '.csv, .xls, .xlsx' }} // Accept CSV and Excel files
                                onChange={handleFileUpload}
                                sx={{ flexGrow: 1 }} // This will make the input box expand to fill the available space
                            />


                            {/* Submit Button */}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleButtonSubmit} // Button action when submitting
                                sx={{ whiteSpace: 'nowrap' }} // Ensure the button stays the same size
                            >
                                Submit
                            </Button>
                        </Box>

                        {/* Data Grid for uploaded data */}
                        {originalFileData.length > 0 && fileDataWithIds.length > 0 && (
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={fileDataWithIds}
                                    columns={
                                        fileDataWithIds[0]
                                            ? Object.keys(fileDataWithIds[0])
                                                .filter((header) => header !== 'id' && header !== 'isDuplicate')  // Exclude 'id' from being shown
                                                .map((header) => ({
                                                    field: header, // Display `displayId` but keep other columns intact
                                                    headerName: header === 'displayId' ? 'ID' : header, // Rename 'displayId' to 'ID'
                                                }))
                                            : [] // Provide an empty array if `fileDataWithIds[0]` is undefined
                                    }
                                    pageSize={5}
                                    getRowId={(row) => row.id}
                                    checkboxSelection // This enables checkboxes
                                    onRowSelectionModelChange={(newSelection) => {
                                        const selectedRows = newSelection.map(id => fileDataWithIds.find(row => row.id === id));
                                        setSelectedEntries(selectedRows);
                                    }}
                                    getRowClassName={(params) => (params.row.isDuplicate ? 'duplicate-row' : '')}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <Box
                        sx={{
                            flex: 1,
                            marginRight: groupedAttendees.length > 0 || activeSection !== null ? '300px' : '0',
                            overflowY: 'auto',
                        }}
                    >
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                padding: '15px 0 15px 2px',
                            }}
                        >
                            {
                                sortedAttendees.map((_attendee, index) => (
                                    <Grid
                                        item
                                        key={_attendee.id}
                                        sx={{
                                            width: calculateCardWidth(),
                                        }}
                                    >
                                        <Card
                                            sx={{
                                                width: calculateCardWidth(),
                                                minWidth: '250px',
                                                height: '150px',  // Fixed height for all cards
                                                display: 'flex',
                                                flexDirection: 'column',  // Arrange image and title vertically
                                                justifyContent: 'space-between',
                                                overflow: 'visible',
                                                backgroundColor: groupedAttendees.includes(_attendee.id) || selectedAttendeeId === _attendee.id ? 'rgba(198, 226, 255, 0.8)' : 'white' // Peach if selected
                                            }}
                                        >
                                            <CardHeader
                                                sx={{
                                                    padding: '5px 8px 0 0',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    position: 'relative',
                                                    '& .MuiCardHeader-avatar': { // Targets the avatar container in CardHeader
                                                        marginRight: 0,           // Removes the right margin
                                                    }
                                                }}
                                                avatar={
                                                    !_attendee.avatar || !_attendee.avatar.preview ?  (
                                                        <>
                                                            <Avatar
                                                                {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    boxShadow: '' +
                                                                        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                                        '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                                        '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                                    border: `1px solid ${stringToColor(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}`,
                                                                    borderRadius: '10px',
                                                                    textAlign: 'center',
                                                                    fontWeight: '700',
                                                                    backgroundColor: '#FFF',
                                                                    color: 'black', // Text color (adjust as needed)
                                                                    fontSize: '15px',
                                                                    width: 30, // Adjust width
                                                                    height: 30, // Adjust height
                                                                }}
                                                            />
                                                        </>

                                                    ) : (
                                                        <>
                                                            <Avatar
                                                                src={_attendee.avatar.preview} // Display the selected image
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                                    border: `1px solid ${stringToColor(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}`,
                                                                    borderRadius: '10px',
                                                                    textAlign: 'center',
                                                                    fontWeight: '700',
                                                                    backgroundColor: '#FFF',
                                                                    color: 'black',
                                                                    fontSize: '15px',
                                                                    width: 30,
                                                                    height: 30,
                                                                }}
                                                            />
                                                        </>
                                                    )

                                                }
                                                action={
                                                    <>
                                                        <Typography
                                                            variant="caption"
                                                            sx={{
                                                                cursor: 'pointer',
                                                                position: 'absolute',
                                                                top: '-12px',
                                                                left: '10px',
                                                                fontSize: '11px',
                                                                color: '#FFFFFF',
                                                                backgroundColor: '#f09637',
                                                                border: '1px solid #428ce2',
                                                                borderRadius: '5px',
                                                                zIndex: 2
                                                            }}
                                                        >
                                                            Status
                                                        </Typography>

                                                        <IconButton
                                                            aria-label="more"
                                                            aria-controls={`menu-${index}`}
                                                            aria-haspopup="true"
                                                            onClick={(event) => handleOpenAttendeeMenu(event, index)}
                                                            sx={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 0
                                                            }}
                                                        >
                                                            <i className="icon-menu2" style={{fontSize:'16px'}} />
                                                        </IconButton>
                                                        <Menu
                                                            sx={{
                                                                width: 320,
                                                                maxWidth: '100%'
                                                            }}
                                                            anchorEl={anchorAttendeeElements[index]}
                                                            open={Boolean(anchorAttendeeElements[index])}
                                                            onClose={() => handleCloseAttendeeMenu(index)}
                                                        >
                                                            <MenuItem
                                                                sx={{
                                                                    backgroundColor: 'rgba(198, 226, 255, 0.8)'
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                >
                                                                    {capitalizeFirstLetter(_attendee.fname)} {capitalizeFirstLetter(_attendee.lname)}
                                                                </Typography>
                                                            </MenuItem>
                                                            <Divider />
                                                            <MenuItem
                                                                onClick={() => handleEditAttendee(_attendee.id, index)}
                                                            >
                                                                <ListItemIcon>
                                                                    <i className="icon-edit2" style={{fontSize:'16px'}}/>
                                                                </ListItemIcon>
                                                                <Typography
                                                                    variant="body2"
                                                                >
                                                                    Edit
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => handleSendEmail(_attendee.id, index)}
                                                            >
                                                                <ListItemIcon>
                                                                    <i className="icon-envelope" style={{fontSize:'16px'}}/>
                                                                </ListItemIcon>
                                                                <Typography
                                                                    variant="body2"
                                                                >
                                                                    Send an Email
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => handleAddToEvent(_attendee.id, index)}
                                                            >
                                                                <ListItemIcon>
                                                                    <i className="icon-calendar" style={{fontSize:'16px'}}/>
                                                                </ListItemIcon>
                                                                <Typography
                                                                    variant="body2"
                                                                >
                                                                    Add to an Event
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => handleSendCoupon(_attendee.id, index)}
                                                            >
                                                                <ListItemIcon>
                                                                    <i className="icon-ticket" style={{fontSize:'16px'}}/>
                                                                </ListItemIcon>
                                                                <Typography
                                                                    variant="body2"
                                                                >
                                                                    Send a Coupon
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => handleDialogDeleteOpen(index, _attendee.id)}
                                                            >
                                                                <ListItemIcon>
                                                                    <i className="icon-trash" style={{fontSize:'16px', color:'#F00'}}/>
                                                                </ListItemIcon>
                                                                <Typography
                                                                    variant="body2"
                                                                >
                                                                    Delete
                                                                </Typography>
                                                            </MenuItem>
                                                        </Menu>
                                                    </>
                                                }
                                            />
                                            <CardContent
                                                sx={{
                                                    paddingTop: 0,
                                                    textAlign: 'center',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => handleGroupToggle(_attendee.id)} // Toggle group on name click
                                            >
                                                <Typography variant="subtitle2">{capitalizeFirstLetter(_attendee.fname)} {capitalizeFirstLetter(_attendee.lname)}</Typography>
                                                <Typography variant="caption">{_attendee.email}</Typography>
                                            </CardContent>
                                            <CardActions
                                                disableSpacing
                                                style={{ display: 'flex', justifyContent: 'space-between'}}
                                            >
                                                <Typography variant="caption">{dayjs(_attendee.created).format('D MMM YYYY')}</Typography>
                                                <Typography variant="caption">{_attendee.events?.length} Event{_attendee.events?.length===1?'':'s'}</Typography>
                                            </CardActions>

                                        </Card>

                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                )}

                {/* Display sections based on activeSection */}
                {activeSection === 'edit' && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: '320px',
                            height: '100%',
                            borderLeft: '1px solid #ddd',
                            backgroundColor: 'rgba(235,245,255,0.8)',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {/* Fixed Header */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                p: 1,
                                backgroundColor: 'rgba(198, 226, 255, 0.8)',
                                position: 'sticky',
                                top: '-20px',
                                zIndex: 10,
                            }}
                        >
                            <Typography variant="subtitle2" sx={{paddingLeft: '10px'}}>
                                Edit {capitalizeFirstLetter(Attendees.find(attendee => attendee.id === selectedAttendeeId)?.fname)}
                            </Typography>

                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <IconButton
                                    onClick={handleSaveAttendeeEdit}
                                    aria-label="Update"
                                    sx={{ color: 'green' }}
                                >
                                    <i className="icon-floppy-disk" style={{ fontSize: '14px' }} />
                                </IconButton>

                                <IconButton
                                    onClick={() => setActiveSection(null)}
                                    aria-label="Close"
                                    sx={{ color: 'red' }}
                                >
                                    <i className="icon-cross" style={{ fontSize: '14px' }} />
                                </IconButton>
                            </Box>
                        </Box>

                        {/* Scrollable content */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                overflowY: 'auto',
                                p: 2,
                                pb: 4, // padding-bottom so there's space at the end
                            }}
                        >
                            <Stack spacing={2}>
                                {/* Avatar Upload Section */}
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <input
                                        type="file"
                                        id={`file-input-${selectedAttendeeId}`}
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleImageChange(e, selectedAttendeeId)}
                                    />
                                    <Avatar
                                        src={
                                            attendeeAvatar?.preview ||
                                            sortedAttendees.find(a => a.id === selectedAttendeeId)?.avatar?.preview ||
                                            ''
                                        }
                                        alt="Attendee Avatar"
                                        sx={{ width: 64, height: 64, cursor: 'pointer' }}
                                        onClick={() => document.getElementById(`file-input-${selectedAttendeeId}`).click()}
                                    />
                                    <Typography variant="body2" color="text.secondary">
                                        Click avatar to upload or replace
                                    </Typography>
                                </Box>
                                <TextField fullWidth id="fname" label="First Name" required value={fname} onChange={handleFnameChange} />
                                <TextField fullWidth id="lname" label="Last Name" required value={lname} onChange={handleLnameChange} />
                                <TextField fullWidth id="nickname" label="Nickname" value={nickname} onChange={handleNicknameChange} />
                                <TextField fullWidth id="address" name="address" label="Address"
                                           value={addressFieldsResidential.address}
                                           onChange={handleAddressComponentChange}
                                           inputRef={inputRefResidential}
                                           onFocus={attachAutocompleteResidential}
                                           onBlur={removePacContainerResidential}
                                />
                                <TextField fullWidth id="city" name="city" label="City" value={addressFieldsResidential.city} onChange={handleAddressComponentChange} />
                                <TextField fullWidth id="state" name="state" label="State" value={addressFieldsResidential.state} onChange={handleAddressComponentChange} />
                                <TextField fullWidth id="zip" name="zip" label="Zip" value={addressFieldsResidential.zip} onChange={handleAddressComponentChange} />
                                <TextField fullWidth id="country" name="country" label="Country" value={addressFieldsResidential.country} onChange={handleAddressComponentChange} />
                                <TextField fullWidth id="email_address" label="Email" type="email" value={email} onChange={handleEmailChange} helperText="Required if they are going to be logging in" />                                <FormControl fullWidth>
                                    <InputLabel id="prefix-label">Phone Prefix</InputLabel>
                                    <Select
                                        labelId="prefix-label"
                                        id="phone-prefix-select"
                                        value={phonePrefix}
                                        onChange={handlePhonePrefixChange}
                                        label="Phone Prefix"
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {country_codes.map((item, index) => (
                                            <MenuItem key={index} value={item.dialCode}>
                                                {item.dialCode} : {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField fullWidth id="phone" label="Phone" value={phone} onChange={handlePhoneChange} />
                                <TextField fullWidth id="dob" label="Date of Birth" value={dob} onChange={handleDOBChange} />
                                <TextField fullWidth id="language" label="Preferred Language" value={language} onChange={handleLanguageChange} />
                                <TextField fullWidth id="preferredAirport" label="Preferred Airport" value={preferredAirport} onChange={handlePreferredAirportChange} />
                            </Stack>
                        </Box>
                    </Box>
                )}

                {activeSection === 'email' && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: '320px',
                            height: '100%', // Make it fill the dialog height
                            borderLeft: '1px solid #ddd',
                            overflowY: 'auto', // Independent vertical scroll
                            backgroundColor: 'rgba(235,245,255,0.8)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: 1,
                                backgroundColor: 'rgba(198, 226, 255, 0.8)',
                            }}
                        >
                            <Typography variant="subtitle2" sx={{paddingLeft: '10px'}}>
                                Send {capitalizeFirstLetter(Attendees.find(attendee => attendee.id === selectedAttendeeId)?.fname)} an Email
                            </Typography>

                            <IconButton
                                onClick={() => setActiveSection(null)}
                                aria-label="Close"
                                sx={{ color: 'red' }}
                            >
                                <i className="icon-cross" style={{ fontSize: '14px' }} /> {/* Replace with actual icon class or use Material-UI icon */}
                            </IconButton>
                        </Box>
                    </Box>
                )}

                {activeSection === 'add' && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: '320px',
                            height: '100%', // Make it fill the dialog height
                            borderLeft: '1px solid #ddd',
                            overflowY: 'auto', // Independent vertical scroll
                            backgroundColor: 'rgba(235,245,255,0.8)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: 1,
                                backgroundColor: 'rgba(198, 226, 255, 0.8)',
                            }}
                        >
                            <Typography variant="subtitle2" sx={{paddingLeft: '10px'}}>
                                Add {capitalizeFirstLetter(Attendees.find(attendee => attendee.id === selectedAttendeeId)?.fname)} to an Event
                            </Typography>

                            <IconButton
                                onClick={() => setActiveSection(null)}
                                aria-label="Close"
                                sx={{ color: 'red' }}
                            >
                                <i className="icon-cross" style={{ fontSize: '14px' }} /> {/* Replace with actual icon class or use Material-UI icon */}
                            </IconButton>
                        </Box>
                    </Box>
                )}

                {activeSection === 'coupon' && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: '320px',
                            height: '100%', // Make it fill the dialog height
                            borderLeft: '1px solid #ddd',
                            overflowY: 'auto', // Independent vertical scroll
                            backgroundColor: 'rgba(235,245,255,0.8)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: 1,
                                backgroundColor: 'rgba(198, 226, 255, 0.8)'
                            }}
                        >
                            <Typography variant="subtitle2" sx={{paddingLeft: '10px'}}>
                                Send {capitalizeFirstLetter(Attendees.find(attendee => attendee.id === selectedAttendeeId)?.fname)} a Coupon
                            </Typography>

                            <IconButton
                                onClick={() => setActiveSection(null)}
                                aria-label="Close"
                                sx={{ color: 'red' }}
                            >
                                <i className="icon-cross" style={{ fontSize: '14px' }} /> {/* Replace with actual icon class or use Material-UI icon */}
                            </IconButton>
                        </Box>
                    </Box>
                )}

                {/* Grouped Attendees Column */}
                {activeSection === 'grouped' && groupedAttendees.length > 0 && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: '320px',
                            height: '100%', // Make it fill the dialog height
                            borderLeft: '1px solid #ddd',
                            overflowY: 'auto', // Independent vertical scroll
                            backgroundColor: 'rgba(235,245,255,0.8)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: 1,
                                backgroundColor: 'rgba(198, 226, 255, 0.8)'
                            }}
                        >
                            <Typography variant="subtitle2" sx={{paddingLeft: '10px'}}>
                                {groupedAttendees.length} Selected
                            </Typography>

                            <Tooltip title={`Add ${groupedAttendees.length} to Event`}>
                                <IconButton
                                    aria-label="AddToEvent"
                                    sx={(theme) => ({
                                        color: theme.palette.grey[700],
                                        cursor: 'pointer',
                                        fontSize: '18px',
                                    })}
                                    onClick={handleAddContactsToPlanning}
                                >
                                    <i
                                        className="icon-user-plus"
                                        style={{
                                            color: 'green',
                                            fontSize: '18px',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <IconButton
                                onClick={() => clearMenuSelections()} // Clear both grouped attendees and active section
                                aria-label="Clear selected"
                                sx={{ color: 'green' }}
                            >
                                <i className="icon-cw" style={{ fontSize: '14px' }} /> {/* Replace with actual icon class or use Material-UI icon */}
                            </IconButton>
                        </Box>


                        {Attendees
                            .filter(attendee => groupedAttendees.includes(attendee.id))
                            .sort((a, b) => {
                                const lastNameComparison = a.lname.localeCompare(b.lname);
                                return lastNameComparison !== 0
                                    ? lastNameComparison // Sort by last name if they're different
                                    : a.fname.localeCompare(b.fname); // Otherwise, sort by first name
                            })
                            .map(attendee => (
                                <Box
                                    key={attendee.id}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '10px',
                                        borderBottom: '1px solid #ddd',
                                    }}
                                >
                                    {/* Attendee Name */}
                                    <Typography variant="subtitle2">
                                        {capitalizeFirstLetter(attendee.fname)} {capitalizeFirstLetter(attendee.lname)}
                                    </Typography>

                                    {/* Remove Attendee (X Icon) */}
                                    <IconButton
                                        aria-label="Remove Attendee"
                                        onClick={() => setGroupedAttendees(prev => prev.filter(id => id !== attendee.id))}
                                        sx={{ color: 'red' }}
                                    >
                                        <i className="icon-cross" style={{ fontSize: '16px' }} /> {/* Replace with MUI Close Icon if needed */}
                                    </IconButton>
                                </Box>
                            ))}
                    </Box>
                )}


            </DialogContent>
        </Dialog>
    );
}

export default MasterAttendees;
