import React, {useEffect, useState} from 'react';

import dayjs from "dayjs";
import {Chip} from "@mui/material";

import Typography from "@mui/material/Typography";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import './weather.css';
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";

function WeatherDetails() {

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const [weatherData, setWeatherData] = useState(PA_GlobalObject.Planning.weather || []);
    const [pullWeatherData, setPullWeatherData] = useState(true);
    const [weatherDataUnit, setWeatherDataUnit] = useState('dates');


    // ✅ Function to fetch weather data when needed
    useEffect(() => {
        if (pullWeatherData && weatherData.length === 0) {
            fetchWeatherData();
        }
    }, [pullWeatherData, weatherData.length]);


    // ✅ Regular function to fetch weather data
    async function fetchWeatherData() {
        console.log("Checking if weather report needs to be pulled...");

        // Check if event has dates
        const eventDates = PA_GlobalObject.Planning?.time_table?.dates || [];
        if (eventDates.length === 0) {
            console.warn("Event does not have any dates set. Weather report cannot be pulled.");
            return;
        }

        const eventBeginDate = dayjs(eventDates[0].date);
        const eventEndDate = dayjs(eventDates[eventDates.length - 1].date);
        const daysUntilBeginDate = eventBeginDate.diff(dayjs(), 'day');

        console.log(`Event begins on: ${eventBeginDate.format("YYYY-MM-DD")} (in ${daysUntilBeginDate} days)`);

        if (daysUntilBeginDate > 14) {
            console.info("Outside the 14-day window. No weather report needed yet.");
            return;
        }

        console.log("Within the 14-day window. Fetching lodging locations...");

        // Extract valid lodging locations
        const lodgingLocations = PA_GlobalObject.Planning.lodging
            .filter(lodging => lodging.location?.latitude !== 0 && lodging.location?.longitude !== 0);

        if (lodgingLocations.length === 0) {
            console.warn("No valid lodging locations found for weather report.");
            return;
        }

        console.log(`Found ${lodgingLocations.length} valid lodging locations.`);

        // Construct weather request data
        const _search_data_event_locations = lodgingLocations.map(lodge => ({
            city: lodge.location.city,
            state: lodge.location.state,
            location_type: lodge.location.latitude && lodge.location.longitude ? "latlng" : "address",
            latitude: lodge.location.latitude,
            longitude: lodge.location.longitude,
            location: lodge.location.address ? `${lodge.location.address} ${lodge.location.city} ${lodge.location.state} ${lodge.location.zip}` : "",
            unit_group: lodge.location.country === "US" ? "us" : "metric",
            begin_date: dayjs(lodge.time_table.dates[0].date).format('YYYY-MM-DD'),
            end_date: dayjs(lodge.time_table.dates[lodge.time_table.dates.length - 1].date).format('YYYY-MM-DD'),
        }));

        console.log("Weather request locations:", JSON.stringify(_search_data_event_locations, null, 2));

        // Construct API URLs
        const visualCrossingBase = "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/";
        const timelineService = "timeline/";

        const inEvent = eventBeginDate.diff(dayjs(), "days") <= 0 && dayjs().diff(eventEndDate, "days") < 1;

        // Function to delay API calls (prevents rate-limiting)
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        // Function to fetch weather data sequentially
        const fetchWeatherDataSequentially = async () => {
            const weatherData = [];

            for (const location of _search_data_event_locations) {
                const searchLocation = location.location_type === "address"
                    ? `${location.location}/`
                    : `${location.latitude},${location.longitude}/`;

                const searchBeginDate = `${location.begin_date}/`;
                const searchEndDate = location.end_date;
                const includeData = inEvent ? "" : "&include=days";

                const searchUrl = `${visualCrossingBase}${timelineService}${searchLocation}${searchBeginDate}${searchEndDate}?key=HQTW7H7BS2KSSU4Z3LTWMETCV&contentType=json&unitGroup=${location.unit_group}${includeData}`;

                console.log(`Fetching weather data from: ${searchUrl.replaceAll(" ", "%20")}`);

                try {
                    const response = await fetch(searchUrl.replaceAll(" ", "%20"), { method: 'GET' });

                    if (!response.ok) {
                        throw new Error(`HTTP Error ${response.status}`);
                    }

                    const data = await response.json();
                    console.log("Weather API Response:", JSON.stringify(data, null, 2));

                    weatherData.push({ ...location, report: data });

                } catch (error) {
                    console.error(`Error fetching weather for ${location.city}:`, error);
                    weatherData.push({ ...location, report: null, error: true });
                }

                // **Add a delay to avoid "Too Many Requests" error**
                await delay(2000); // Wait 2 seconds before the next request
            }

            return weatherData;
        };

        // Fetch weather data and update state
        const finalWeatherData = await fetchWeatherDataSequentially();

        setWeatherData(finalWeatherData);

        set_PA_GlobalObject((prev) => ({
            ...prev,
            Planning: {
                ...prev.Planning,
                weather: finalWeatherData,
            },
        }));

        // Prevent repeated API calls
        setPullWeatherData(false);
    }




    return (

        <div className="weather-list-container">

            {/* show the header */}
            <div
                className="weather-list-header"
            >
                <div className="weather-list-header-text">
                    <h5>Daily Weather</h5>
                </div>
            </div>

            {/* show the list of dates/days for Event */}
            <div className="weather-list-timetable-list-container">
                {
                    weatherDataUnit === 'dates' ? (
                        PA_GlobalObject.Planning['time_table'] && PA_GlobalObject.Planning['time_table'].dates.map((eventDateObj, index) => {
                            const eventDate = eventDateObj.date;
                            const formattedDay = dayjs(eventDate, 'YYYY-MM-DD').format('ddd'); // e.g., "Wed"
                            const formattedDate = dayjs(eventDate, 'YYYY-MM-DD').format('M/D'); // e.g., "10/13"

                            return (
                                <Chip
                                    key={index}
                                    label={
                                        <div style={{textAlign: 'center'}}>
                                            <Typography variant="body2">{formattedDay}</Typography>
                                            <Typography variant="caption">{formattedDate}</Typography>
                                        </div>
                                    }
                                    sx={unselectedChipStyle} // Style for the Chips
                                    style={{margin: '0.2rem', height: '40px'}}
                                />
                            );
                        })
                    ) : (
                        <div>No weather data available for days</div> // Add a fallback for 'days' or another unit.
                    )
                }
            </div>

            <div className="weather-list-items-container">
                {
                    weatherData ? (

                        <div>
                            {/* show the weather for the relevant areas for the selected day */}
                            {
                                weatherData.map((location, index) => (

                                    <div key={index}>
                                        {/* Display the day's weather data here */}
                                        <p>Location: {location.city} {location.state}</p>

                                        {
                                            location['report'] ? (
                                                location['report'].days.map((day, day_index) => (
                                                    <div key={day_index}>
                                                        <p>Date: {dayjs(day.datetime, 'YYYY-MM-DD').format('dddd MMM D')}</p>
                                                        <p>Icon: {day.icon}</p>
                                                        <p>Conditions: {day.conditions}</p>
                                                        <p>Description: {day.description}</p>
                                                    </div>

                                                ))
                                            ) : (
                                                <></>
                                            )

                                        }

                                    </div>
                                ))}
                        </div>
                    ) : (
                        <div>No Weather Data</div>
                    )
                }
            </div>

        </div>
    )
}

export default WeatherDetails;
