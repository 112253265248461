import TimeTable from "./TimeTable";
import PAAttendee from "./Attendee";


class PAEvent {
    constructor(eventData = {}) {
        // Default values for all fields
        const _temp = PAAttendee.generateRandomString(20);

        const defaults = {
            id: _temp,
            temp_id: _temp,
            title: "",
            description: "",
            eventIntro: "",

            coverImage: {},
            file_name: "",
            cover_image_thumbnail: "",
            cover_image_full_data: "",
            host: [],
            hostNotes: "",

            time_table: new TimeTable(),

            locations: [],
            attendees: [],
            activities: [],
            lodging: [],
            meals: [],
            ideas: [],
            flights: [],
            car_rentals: [],
            cruises: [],
            tours: [],
            supplies: [],
            to_do: [],
            documents: [],
            receipts: [],
            messages: [],
            latest_message_timestamp: 0,
            photos: [],
            menu_items: [],
            weather: [],

            suggestions: {
                outdoor: [],
                indoor: [],
                walking: [],
                unique: [],
                hidden: []
            },

            budget_total: 0,
            budget_currency: "USD",
            budget_actual: 0,

            business_info_and_data: {},
            business_authorizations: {},

            version: 2.0,
            need_to_save: false,

            is_template: false,
            template_id: null,
            template_timestamp: null,
            template_to_event: [],

            plan_id : "60ae1bd0e3e8cf67c8ee324d",
            plan_name : "",
            plan_description : "",
            plan_max_attendees : 0,
            plan_price : 0.00,
            plan_promo_amount : 0.00,
            plan_promo_code : "",
            plan_promo_unit : "percent",   //this is either 'dollars' or 'percent'
            plan_promo_rate : 100,
            plan_total : 0.00,

            card_entry_number : "",
            card_entry_exp_month : 0,
            card_entry_exp_year : 0,
            card_entry_cvc : 0,

            payment_total : 0.00,

            sms_selected : false,

            payment_processor : "",    //google, stripe, apple
            client_secret : "",
            payment_token : {},
            wallet_event : {},
            stripe_payment_intent : {},

            closed: 0,
            last_mod: 0,
            status: 0
        };

        // Merge the provided eventData, keeping default values for any missing fields
        Object.assign(this, defaults, eventData);
    }

}


export default PAEvent;
