import React, {useEffect, useState} from "react";

import {Card, CardContent, CardMedia, Dialog, DialogContent, DialogTitle, Tooltip} from "@mui/material";
import Grid from '@mui/material/Grid2';

import IconButton from "@mui/material/IconButton";
import {getAllTemplatesFromDatabase} from "../../Services/BrowserDatabaseFunctions";
import PAEvent from "../../MasterEventObjects/PAEvent";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";


function MasterTemplates({dialogRefTemplates, isTemplatesDialogOpen, closeTemplatesDialog, handleTemplateMenuClick}){

    const [allTemplates, setAllTemplates] = useState([]);
    const [sortedTemplates, setSortedTemplates] = useState([]);

    // Function to fetch templates from the database
    const fetchTemplates = () => {
        console.log("Fetching latest Templates...");

        getAllTemplatesFromDatabase().then((templates) => {
            console.log("templates:", templates);

            if (templates && Array.isArray(templates)) {
                // Convert each template into a PAEvent object
                const convertedTemplates = templates.map(template => new PAEvent(template));

                // Update state with all templates
                setAllTemplates(convertedTemplates);

                // Sort templates by title (alphabetically)
                const sorted = [...convertedTemplates].sort((a, b) =>
                    (a.title || '').localeCompare(b.title || '')
                );

                setSortedTemplates(sorted);
            }
        }).catch(error => {
            console.error("Error retrieving templates:", error);
        });
    };

    // Fetch templates only when the dialog opens
    useEffect(() => {
        if (isTemplatesDialogOpen) {
            fetchTemplates();
        }
    }, [isTemplatesDialogOpen]);

    const [dialogWidth, setDialogWidth] = useState(0);
    useEffect(() => {
        if (isTemplatesDialogOpen && dialogRefTemplates.current) {
            const width = dialogRefTemplates.current.offsetWidth;
            console.log("&&&&&&&& setting dialogWidth : "+width);
            setDialogWidth(width);
        }
    }, [isTemplatesDialogOpen]);
    const calculateCardWidth = () => {
        const cardsPerRow = 3;  // You can adjust the number of cards per row here
        const spacing = 16;  // Space between cards (as defined by Grid spacing)
        const totalSpacing = (cardsPerRow - 1) * spacing;
        const calculatedWidth = (dialogWidth - totalSpacing) / cardsPerRow;

        // Handle cases where calculatedWidth is negative
        if (calculatedWidth <= 0) {
            return 250; // Minimum fallback width
        }

        // Ensure the card width does not exceed 250px
        return Math.min(calculatedWidth, 250);
    };
    const formatEventTime = (timeTable) => {
        // Helper function to format the time (handle begin and end time)
        const formatTimeRange = (beginTime, endTime) => {
            const formattedBeginTime = dayjs(`2024-01-01 ${beginTime}`).format('hA');
            const formattedEndTime = endTime ? dayjs(`2024-01-01 ${endTime}`).format('hA') : '';
            return formattedEndTime ? `${formattedBeginTime} - ${formattedEndTime}` : formattedBeginTime;
        };

        // Function to format the date range
        const getDateRange = () => {
            const { dates } = timeTable;

            if (dates && dates.length > 0) {
                const beginDate = dayjs(dates[0].date);
                const endDate = dayjs(dates[dates.length - 1].date);

                if (beginDate.isSame(endDate, 'day')) {
                    // Same day with begin and end time
                    const beginTime = dates[0]['begin_time'] || '12:00 AM';
                    const endTime = dates[0]['end_time'] || '11:59 PM';
                    return `${beginDate.format('D MMM YYYY')} ${formatTimeRange(beginTime, endTime)}`;
                } else if (beginDate.isSame(endDate, 'year')) {
                    // Same year, different days
                    return `${beginDate.format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
                } else {
                    // Different years
                    return `${beginDate.format('D MMM YYYY')} - ${endDate.format('D MMM YYYY')}`;
                }
            }
            return null;
        };

        // Function to format the days (if no dates exist)
        const getDaysRange = () => {
            const { days } = timeTable;

            if (days && days.length > 0) {
                const firstDay = days[0]['day 1'];
                const lastDay = days[days.length - 1][`day ${days.length}`];
                const dayCount = days.length;

                if (dayCount === 1 || firstDay === lastDay) {
                    return `1 Day ${firstDay}`; // Singular "Day"
                }

                return `${dayCount} Days ${firstDay} - ${lastDay}`; // Plural "Days"
            }
            return null;
        };

        // Return the event's date range or days as a formatted string
        const dateRange = getDateRange();
        const daysRange = getDaysRange();

        // Return either the date range or days range
        return dateRange || daysRange || '';
    };


    return (

        <Dialog
            ref={dialogRefTemplates}
            open={isTemplatesDialogOpen}
            onClose={closeTemplatesDialog}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>{sortedTemplates.length} Template{sortedTemplates.length===1?'':'s'}</DialogTitle>

            <Tooltip title="Close">
                <IconButton
                    aria-label="Close"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 0,
                        top: 8,
                        color: theme.palette.grey[700],
                        cursor: 'pointer',
                        fontSize: '18px',
                    })}
                    onClick={closeTemplatesDialog}
                >
                    <i
                        className="icon-cross"
                        style={{
                            color: 'green',
                            fontSize: '18px',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    />
                </IconButton>
            </Tooltip>

            <DialogContent
                sx={{
                    display: 'flex',
                    gap: 2,
                    position: 'relative'
                }}
            >
                <Grid container spacing={2}>
                    {
                        sortedTemplates.map((template, index) => (
                            <Grid
                                item
                                key={index}
                                sx={{
                                    width: calculateCardWidth(),
                                }}
                            >
                                <Card
                                    onClick={() => handleTemplateMenuClick(template.template_id)}
                                    sx={{
                                        cursor: 'pointer',
                                        width: calculateCardWidth(),
                                        height: '220px',  // Fixed height for all cards
                                        display: 'flex',
                                        flexDirection: 'column',  // Arrange image and title vertically
                                        justifyContent: 'space-between',
                                    }}
                                >

                                    {
                                        (template.cover_image_full_data !== '' || template.cover_image_thumbnail !== '') ? (
                                            <CardMedia
                                                sx={{
                                                    width: calculateCardWidth(), // Set the desired width
                                                    height: '150px', // Set the desired height
                                                    objectFit: 'cover', // Ensures the image fills the box while maintaining aspect ratio
                                                }}
                                                component="img"
                                                image={template.cover_image_full_data || template.cover_image_thumbnail}
                                                alt={template.title}
                                            />
                                        ) : (
                                            <CardMedia
                                                sx={{
                                                    width: calculateCardWidth(), // Set the desired width
                                                    height: '150px', // Set the desired height
                                                    objectFit: 'cover', // Ensures the image fills the box while maintaining aspect ratio
                                                }}
                                                component="img"
                                                image={"https://plannedadventure.com/assets/images/user_bg1.png"}
                                                alt={template.title}
                                            />
                                        )
                                    }

                                    <CardContent sx={{paddingTop:0, paddingBottom:'15px !important'}}>
                                        <Typography variant="subtitle2" display="block">{template.title}</Typography>
                                        {/* add the dates or days */}

                                        <Typography variant="caption" display="block">{formatEventTime(template.time_table)}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default MasterTemplates

