import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import {Card, CardContent, CardHeader, Checkbox, Menu, MenuItem, MenuList} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";



function TodoCard(props){

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Attendees, SampleAttendees } = dataArray;

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated
        ? Attendees
        : SampleAttendees);
    useEffect(() => {
        setAttendeesReferenceArray(
            dataArray.authenticated
                ? Attendees
                : SampleAttendees
        );
    }, [dataArray.authenticated, Attendees, SampleAttendees]);

    const [eventTodo, setEventTodo] = useState(props.to_do);
    useEffect(()=> {
        console.log("props.to_do has been updated --- : ");
        setEventTodo(props.to_do);
    }, [props.to_do]);

    const handleCheckboxChange = (event) => {
        // Update the eventTodo status based on the checkbox
        const updatedTodo = { ...eventTodo, status: event.target.checked ? 0 : 1 };
        setEventTodo(updatedTodo);

    };

    const getPriorityIconColor = (priority) => {
        switch (priority) {
            case "Low":
                return "#0F0"; // Replace with your desired color
            case "Medium":
                return "#b8ab10"; // Replace with your desired color #b8ab10
            case "High":
                return "#f09637"; // Replace with your desired color
            case "Urgent":
                return "#F00"; // Replace with your desired color
            default:
                return "#151269"; // Default color if priority is not recognized
        }
    };

    const priorityIconColor = eventTodo ? getPriorityIconColor(eventTodo.priority) : 'gray';

    const [anchorTodoListItemMenu, setAnchorTodoListItemMenu] = useState(null);
    const handleTodoListItemMenuOpenMenu = (event) => {
        setAnchorTodoListItemMenu(event.currentTarget);
    };
    const handleTodoListItemMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorTodoListItemMenu(event.currentTarget);

    };
    const handleTodoListItemMenuClose = () => {
        setAnchorTodoListItemMenu(null);
    };

    const handleFooterItemClick = (_item) => {
        if (_item === 'Delete'){

            //close the menu
            handleTodoListItemMenuClose();

            //prompt for confirmation
            props.onDeleteTodo();
        }
    };



    return (

        <Card
            style={{
                width:'100%',
                marginBottom:'20px',
                border: `2px solid ${priorityIconColor}`,
            }}
        >
            <CardHeader
                title={eventTodo ? eventTodo['title'] : <></>}
                action={
                    <IconButton
                        aria-label="settings"
                        onClick={handleTodoListItemMenuOpenMenu}
                    >
                        <i
                            className="icon-menu2"
                            style={{fontSize: '16px'}}
                        />
                    </IconButton>
                }
                sx={{paddingBottom:'0'}}
                titleTypographyProps={{ fontSize: '18px' }} // Set the font size here
            />
            <Menu
                sx={{ width: 320, maxWidth: '100%' }}
                anchorEl={anchorTodoListItemMenu}
                id="activity-item-menu"
                open={Boolean(anchorTodoListItemMenu)}
                onClose={handleTodoListItemMenuClose}

                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuList
                    dense
                    sx={{ width: 320, maxWidth: '100%' }}
                >
                    <MenuItem
                        data-id="delete"
                        onClick={() => handleFooterItemClick('Delete')}
                    >
                        <ListItemIcon sx={{color:'#F00'}}>
                            <i className="icon-trash" title="Delete"/>
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>

            <CardContent>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {/* Date (Left-Aligned) */}
                    <Typography
                        variant="body1"
                        style={{
                            marginLeft: 8,
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '5px'
                        }}
                    >
                        {dayjs(eventTodo['schedule_date']).format('ddd, MMM D')}
                    </Typography>

                    {/* Time (Right-Aligned) */}
                    <Typography
                        variant="caption"
                        style={{
                            marginLeft: 8,
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '5px'
                        }}
                    >
                        {eventTodo['schedule_all_day'] ? (
                            'No Specific Time'
                        ) : (
                            eventTodo['schedule_begin_time_selected'] && eventTodo['schedule_end_time_selected'] ? (
                                `${eventTodo['schedule_begin_time']} - ${eventTodo['schedule_end_time']}`
                            ) : eventTodo['schedule_begin_time_selected'] ? (
                                eventTodo['schedule_begin_time']
                            ) : eventTodo['schedule_end_time_selected'] ? (
                                eventTodo['schedule_end_time']
                            ) : (
                                'No Time Selected'
                            )
                        )}
                    </Typography>
                </div>

                {/* Notes */}
                <Typography variant="caption" sx={{margin:'15px 10px'}}>
                    {eventTodo ? eventTodo['notes'] : <></>}
                </Typography>

                {/* Checkbox for completion status */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Typography variant="body2" style={{ marginRight: 8 }}>Done</Typography>
                    <Checkbox
                        checked={eventTodo.status === 0}
                        onChange={handleCheckboxChange}
                        inputProps={{ 'aria-label': 'Done' }}
                    />
                </div>

                {/* Reminders */}
                {(eventTodo["reminder_days_before_array"].length > 0 || eventTodo["reminder_day_of_time"]) && (
                    <>
                        <Typography variant="subtitle2" sx={{ marginTop: 2, fontWeight: "bold" }}>
                            Reminders
                        </Typography>

                        <Timeline sx={{ padding: 0, marginTop: 1 }}>
                            {/* Days Before Reminders */}
                            {eventTodo["reminder_days_before_array"].length > 0 && (
                                Array.from({ length: Math.max(...eventTodo["reminder_days_before_array"]) }, (_, i) => i + 1) // Generate all days before (1, 2, 3, ..., max selected day)
                                    .reverse() // Start with the farthest reminder
                                    .map((day, index) => {
                                        const hasTime = eventTodo["reminder_days_before_array"].includes(day);
                                        const time = hasTime ? eventTodo["reminder_days_before_time"] : null;

                                        // Calculate the corresponding reminder date
                                        const reminderDate = dayjs(eventTodo["schedule_date"]).subtract(day, "day").format("ddd, MMM D");

                                        return (
                                            <TimelineItem key={`before-${day}`}>
                                                {/* Time on the Left */}
                                                <TimelineOppositeContent sx={{ flex: 0.5, textAlign: "right", pr: 2 }}>
                                                    <Typography variant="caption" sx={{ fontWeight: "bold", color: "gray" }}>
                                                        {time ? time : "None"}
                                                    </Typography>
                                                </TimelineOppositeContent>

                                                {/* Timeline Dot & Connector */}
                                                <TimelineSeparator>
                                                    <TimelineDot color={hasTime ? "primary" : "grey"} />
                                                    {index !== eventTodo["reminder_days_before_array"].length && <TimelineConnector />}
                                                    {index === eventTodo["reminder_days_before_array"].length && eventTodo["reminder_day_of_time"] && <TimelineConnector />}
                                                </TimelineSeparator>

                                                {/* Label and Date on the Right */}
                                                <TimelineContent>
                                                    <Typography variant="body2">
                                                        {`${day} Day${day > 1 ? 's' : ''} before`}
                                                    </Typography>
                                                    <Typography variant="caption" color="text.secondary">
                                                        {reminderDate}
                                                    </Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        );
                                    })
                            )}

                            {/* Day of Reminder */}
                            {eventTodo["reminder_day_of_time"] && (
                                <TimelineItem>
                                    {/* Time on the Left */}
                                    <TimelineOppositeContent sx={{ flex: 0.5, textAlign: "right", pr: 2 }}>
                                        <Typography variant="caption" sx={{ fontWeight: "bold", color: "gray" }}>
                                            {eventTodo["reminder_day_of_time"]}
                                        </Typography>
                                    </TimelineOppositeContent>

                                    {/* Timeline Dot */}
                                    <TimelineSeparator>
                                        <TimelineDot color="secondary" />
                                    </TimelineSeparator>

                                    {/* Label and Date on the Right */}
                                    <TimelineContent>
                                        <Typography variant="body2">Day of</Typography>
                                        <Typography variant="caption" color="text.secondary">
                                            {dayjs(eventTodo["schedule_date"]).format("ddd, MMM D")}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            )}
                        </Timeline>
                    </>
                )}

                {eventTodo['assigned_to'] !== eventTodo['created_by'] && (
                    <Typography variant="caption" style={{ marginLeft: 16 }}>
                        Assigned By: {capitalizeFirstLetter(attendeesReferenceArray.find((_att) => _att.id === eventTodo['created_by'])?.nickname || '')}
                    </Typography>
                )}

            </CardContent>
        </Card>
    )
}

export default TodoCard;