
import dayjs from "dayjs";
import Location from "./Location";

class Receipt {
    constructor(){

        this.id = dayjs().valueOf();

        this.attendee_id = "";
        this.file_name = "";
        this.file_type = "";
        this.file_size = 0;
        this.full_data = "";            //this is the full size image data
        this.full_data_loaded = false;
        this.thumbnail_data = "";
        this.thumbnail_loaded = false;
        this.section = "General";       //this is the category
        this.section_item = 0;          //this is the id of the category item
        this.location = new Location();
        this.amount = 0;
        this.currency = "USD";
        this.private = false;
        this.notes = "";
        this.last_modified = 0;
        this.status = 0;

    }
}

export default Receipt;
