
class PALocation{

    constructor(){
        this.google_place_id = "";
        this.latitude = 0;
        this.longitude = 0;

        this.name = "";
        this.formatted_address = "";
        this.address = "";
        this.city = "";
        this.state = "";
        this.zip = "";
        this.country = "";
        this.phone_prefix = "";
        this.phone = "";

        //for establishments
        this.types = [];
        this.hours = [];
        this.website = "";
        this.photos = [];
        this.price_level = 0;
        this.rating = 0.0;
        this.total_ratings = 0;
    }

}

export default PALocation;
