import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';

import {
    TextField,
    Checkbox,
    FormControlLabel,
    Box,
    Switch,
    Autocomplete,
    Chip,
    CardHeader,
    CardContent,
    Card,
    FormControl,
    ListItem,
    ListItemAvatar,
    Avatar,
    Tab,
    InputBase, ButtonGroup, Button, InputLabel, Select, MenuItem, CardMedia, CardActions
} from '@mui/material';

import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import Activity from "../../../MasterEventObjects/Activity";
import PALocation from "../../../MasterEventObjects/Location";
import {PAContext} from "../../../Services/PAContext";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import './activities.css';

import {addChipStyle} from "../../ButtonStyle/Add";
import {useNavigate} from "react-router-dom";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {formatName, stringAvatar, stringToColor} from "../../Helpers/TextStyling";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import ActivityAddLocationTab from "./ActivityAddLocationTab";
import Typography from "@mui/material/Typography";
import TimeTable from "../../../MasterEventObjects/TimeTable";
import {useGoogleAutocompleteForAddress, useGoogleAutocompleteForEstablishment} from "../../Helpers/GoogleAutocomplete";
import Rating from "@mui/material/Rating";
import {daysOfWeekMap} from "../../Helpers/DatesAndTimes";
import IconButton from "@mui/material/IconButton";
import {useDropzone} from "react-dropzone";


const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]; // Weekday names



function ActivitiesAddNew(props) {

    const navigate = useNavigate();

    const inputRefNewActivityEstablishment = useRef(null);
    const inputRefNewActivityAddress = useRef(null);

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Account, Attendees, SampleAttendees } = dataArray; // Fetch event attendees

    const [eventHasDates, setEventHasDates] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0);
    const [eventHasDays, setEventHasDays] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0);

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated && !PA_GlobalObject.Planning.is_template
        ? Attendees
        : SampleAttendees);
    useEffect(() => {
        setAttendeesReferenceArray(
            dataArray.authenticated
                ? Attendees
                : SampleAttendees
        );
    }, [dataArray.authenticated, Attendees, SampleAttendees]);


    // State management for the new Activity
    const [activity, setActivity] = useState(new Activity());
    const [isScheduled, setIsScheduled] = useState(true); // Toggle for all-day activity
    const [allDay, setAllDay] = useState(true); // Toggle for all-day activity

    const planningDates = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates || [];
    const [selectedActivityBeginDate, setSelectedActivityBeginDate] = useState(
        planningDates.length > 0 && planningDates[0].date ? dayjs(planningDates[0].date) : null
    );
    const [selectedActivityEndDate, setSelectedActivityEndDate] = useState(
        planningDates.length > 0 && planningDates[0].date ? dayjs(planningDates[0].date) : null
    );
    const [selectedActivityBeginDay, setSelectedActivityBeginDay] = useState(() => {
        const firstDay = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days?.[0];
        return firstDay ? { day_index: Object.keys(firstDay)[0], day: Object.values(firstDay)[0] } : null;
    });
    const [selectedActivityEndDay, setSelectedActivityEndDay] = useState(() => {
        const firstDay = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days?.[0];
        return firstDay ? { day_index: Object.keys(firstDay)[0], day: Object.values(firstDay)[0] } : null;
    });
    const [activityDaysCount, setActivityDaysCount] = useState(1);
    const [activityStartingOnDay, setActivityStartingOnDay] = useState('');


    const planningTimeTable = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table;

    const rawBeginTime =
        planningTimeTable?.dates?.[0]?.begin_time ||
        planningTimeTable?.days?.[0]?.begin_time ||
        null;

    const rawEndTime =
        planningTimeTable?.dates?.[planningTimeTable.dates.length - 1]?.end_time ||
        planningTimeTable?.days?.[planningTimeTable.days.length - 1]?.end_time ||
        null;

    const [selectedActivityBeginTime, setSelectedActivityBeginTime] = useState(
        rawBeginTime ? dayjs(rawBeginTime, 'hh:mm A') : null
    );

    const [selectedActivityEndTime, setSelectedActivityEndTime] = useState(
        rawEndTime ? dayjs(rawEndTime, 'hh:mm A') : null
    );

    useEffect(() => {
        createTimeTableForActivity();
    }, [selectedActivityBeginDate, selectedActivityEndDate,
        selectedActivityBeginDay, selectedActivityEndDay,
        activityDaysCount,
        selectedActivityBeginTime, selectedActivityEndTime]);

    const [tabValueSchedule, setTabValueSchedule] = useState(eventHasDates || !eventHasDays ? "dates" : "days");
    const handleTabScheduleChange  = (event, newValue) => {
        console.log("handleTabScheduleChange ");
        setTabValueSchedule(newValue);

    };

    // set Begin Date
    const handleBeginDateChange = (date) => {
        setSelectedActivityBeginDate(date);

        setSelectedActivityEndDate((prevEndDate) =>
            prevEndDate === null || dayjs(date).isAfter(dayjs(prevEndDate))
                ? date // If end date is missing or before new begin date, update it
                : prevEndDate // Otherwise, keep the existing end date
        );
    };

    // set End Date
    const handleEndDateChange = (date) => {
        setSelectedActivityEndDate(date);
    };

    const handleActivityBeginDayChange = (dayKey, dayName) => {
        console.log("handleActivityBeginDayChange", dayKey, ":", dayName);
        // Set the selected activity day in state
        setSelectedActivityBeginDay({
            day_index : dayKey,
            day: dayName
        });
        setActivityStartingOnDay(dayName);
    };
    const handleActivityDaysCountChange = (event) => {
        const newDaysCount = parseInt(event.target.value, 10);
        setActivityDaysCount(newDaysCount);

        // Validate the input and ensure it's a number
        if (isNaN(newDaysCount) || newDaysCount <= 0) {
            return; // Don't update if the value is invalid or zero
        }

        console.log("activityStartingOnDay : "+activityStartingOnDay);

        // We know the starting day (e.g., "Wednesday", "Friday") from state: activityStartingOnDay
        const startDayIndex = daysOfWeekMap[activityStartingOnDay] ?? NaN; // Fallback to NaN if the day is invalid

        console.log("startDayIndex : "+startDayIndex);

        // Array to store the new selectedDays
        const newSelectedDays = [];

        // Generate the selected days based on the count and starting day
        for (let i = 0; i < newDaysCount; i++) {
            const dayIndex = (startDayIndex + i) % 7; // Wrap around if the index goes beyond 6 (Saturday)
            const dayName = dayjs().day(dayIndex).format('dddd'); // Get the full day name (e.g., "Wednesday")

            newSelectedDays.push({
                key: `day ${i + 1}`, // "Day 1", "Day 2", etc.
                value: dayName,       // Corresponding day name
            });
        }

        // Update the selectedDays array in state
        setSelectedDays(newSelectedDays);
    };
    function handleActivityStartingOnChange(event) {
        const newStartingDay = event.target.value; // Get the new starting day (e.g., "Wednesday")
        console.log("handleActivityStartingOnChange : "+newStartingDay);

        // We already have the eventDaysCount from the state (how many days the event lasts)
        const newSelectedDays = [];

        // Get the index of the new starting day
        const startDayIndex = daysOfWeekMap[newStartingDay] ?? NaN; // Fallback to NaN if the day is invalid

        // Loop through the number of days (activityDaysCount) and calculate the days
        for (let i = 0; i < activityDaysCount; i++) {
            const dayIndex = (startDayIndex + i) % 7; // Wrap around the days of the week (if needed)
            const dayName = dayjs().day(dayIndex).format('dddd'); // Get the day name for each day

            // Push the new day to the array
            newSelectedDays.push({
                key: `day ${i + 1}`, // "Day 1", "Day 2", etc.
                value: dayName,       // The actual day name (e.g., "Wednesday")
            });
        }

        // Update the state with the new selectedDays array and the new starting day
        setSelectedDays(newSelectedDays);
        setActivityStartingOnDay(newStartingDay);
    }


    // Time handling for begin and end times
    const handleBeginTimeChange = (time) => {
        setSelectedActivityBeginTime(time);
    };
    const handleEndTimeChange = (time) => {
        setSelectedActivityEndTime(time);
    };


    // Toggle between showing time_table and not
    const handleIsScheduledToggle = (event) => {
        setIsScheduled(event.target.checked);
        if (!event.target.checked){
            //clear out all of the time_table info
            setSelectedActivityBeginDate(null);
            setSelectedActivityEndDate(null);
            setSelectedActivityBeginDay({});
            setSelectedActivityEndDay({});
        }
    };

    // Toggle between all-day and specific time activity
    const handleAllDayToggle = (event) => {
        setAllDay(event.target.checked);
        if (event.target.checked){
            setSelectedActivityEndTime(null);
            setSelectedActivityBeginTime(null);
        }
    };

    const [selectedDays, setSelectedDays] = useState([]);
    const renderDaysLayout = () => {
        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        // Helper function to get the index of the day of the week (0-6, where 0 = Sunday)
        const getDayOfWeekIndex = (dayName) => daysOfWeek.indexOf(dayName.slice(0, 3));

        // Organize selectedDays into a grid of full weeks (always 7 days per row)
        const weeks = [];
        let currentWeek = Array(7).fill(null); // Start with an empty week (7 slots)

        selectedDays.forEach((day) => {
            const dayIndex = getDayOfWeekIndex(day.value); // Get the index (0-6)

            // Place the day into the correct slot in the current week
            currentWeek[dayIndex] = day;

            // If the last day (Saturday) is reached, store the full week and reset
            if (dayIndex === 6) {
                weeks.push([...currentWeek]); // Store a copy of the array
                currentWeek = Array(7).fill(null); // Reset for next week
            }
        });

        // If any days remain, pad the remaining slots and store the last week
        if (currentWeek.some((day) => day !== null)) {
            weeks.push([...currentWeek]);
        }

        return (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '8px', paddingBottom: '20px' }}>
                {weeks.map((week, weekIndex) => (
                    <Box
                        key={weekIndex}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center', // Centers the week layout
                            gap: '6px', // Small spacing between Chips
                            width: '100%',
                        }}
                    >
                        {week.map((day, dayIndex) => (
                            <Box
                                key={dayIndex}
                                sx={{
                                    flex: '1 1 calc(100% / 7 - 6px)',  // Ensures even width for each slot WITH SPACING
                                    maxWidth: '50px', // Prevents oversized Chips
                                    aspectRatio: '1', // Keeps it square
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {day ? (
                                    <Chip
                                        label={
                                            <Typography variant="body2" sx={{ textAlign: 'center' }}>
                                                {day.value.slice(0, 2)}
                                            </Typography>
                                        }
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgba(0, 123, 255, 0.2)',
                                            borderRadius: '10px', // Rounded corners
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    />
                                ) : (
                                    <Chip
                                        label={
                                            <Typography variant="body2" sx={{ textAlign: 'center', color:'#FFF' }}>
                                                {daysOfWeek[0].slice(0, 2)}
                                            </Typography>
                                        }
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            borderRadius: '10px', // Rounded corners
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    />
                                )}
                            </Box>
                        ))}
                    </Box>
                ))}
            </Box>
        );
    };
    const renderActivityDaysLayoutForBegin = () => {

        // Get all planned days from Planning.time_table.days
        const planningDays = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days || [];

        if (planningDays.length === 0) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        gap: '4px', // Small spacing between squares
                        paddingBottom: '20px',
                    }}
                >
                    {daysOfWeek.map((day, index) => (
                        <Box
                            key={index}
                            sx={{
                                flex: 1,
                                aspectRatio: '1 / 1', // Ensures square
                                maxWidth: 'calc((100% - 6 * 4px) / 7)', // Account for 6 gaps (4px each) between 7 items
                            }}
                        >
                            <Chip
                                label={
                                    <Typography variant="body2" textAlign="center">
                                        {day.slice(0, 2)}
                                    </Typography>
                                }
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 123, 255, 0.2)',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                clickable
                                onClick={() => handleActivityBeginDayChange(`day ${index + 1}`, day)}
                            />
                        </Box>
                    ))}
                </Box>
            );
        }

        // Create an array to store all possible day objects (even for multi-week events)
        const calendarDays = [];

        planningDays.forEach((dayObj, index) => {
            const dayKey = Object.keys(dayObj)[0]; // e.g., 'day 1'
            const dayName = Object.values(dayObj)[0]; // e.g., 'Wednesday'
            const dayOfWeekIndex = daysOfWeek.indexOf(dayName); // Index for Su-Sa layout (0-6)

            // Add the day to the correct slot (considering multiple weeks)
            calendarDays.push({
                key: dayKey,
                name: dayName,
                index: index + 1, // Index representing day in Planning (1-based index)
                isSelected: dayKey === selectedActivityBeginDay.day_index // Set the selected status based on the selectedActivityDay
            });
        });

        console.log("calendarDays : ", calendarDays);

        // Organize into weeks (rows of 7 days)
        const weeks = [];
        let currentWeek = Array(7).fill(null);

        calendarDays.forEach((dayObj) => {
            const dayOfWeekIndex = daysOfWeek.indexOf(dayObj.name);
            currentWeek[dayOfWeekIndex] = dayObj;

            // When the week is complete (Saturday), push the current week to the weeks array
            if (dayOfWeekIndex === 6) {
                weeks.push(currentWeek);
                currentWeek = Array(7).fill(null);
            }
        });

        console.log("currentWeek : ", currentWeek);

        // Push the final incomplete week if it exists
        if (currentWeek.some(day => day !== null)) {
            weeks.push(currentWeek);
        }
        console.log("weeks : ", weeks);

        return (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '8px', paddingBottom: '20px' }}>
                {/* Iterate over weeks */}
                {weeks.map((week, weekIndex) => (
                    <Box
                        key={weekIndex}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center', // Centers the week layout
                            gap: '6px', // Small spacing between Chips
                            width: '100%',
                        }}
                    >
                        {/* Iterate over each day in the week */}
                        {
                            week.map((day, dayIndex) => (
                                <Box
                                    key={dayIndex}
                                    sx={{
                                        flex: '1 1 calc(100% / 7 - 6px)',  // Ensures even width for each slot WITH SPACING
                                        maxWidth: '50px', // Prevents oversized Chips
                                        aspectRatio: '1', // Keeps it square
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {day ? (
                                        <Chip
                                            label={
                                                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                                                    {day.name.slice(0, 2)}
                                                </Typography>
                                            }
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(0, 123, 255, 0.2)',
                                                borderRadius: '10px', // Rounded corners
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            clickable
                                            onClick={() => handleActivityBeginDayChange(day.key, day.name)}
                                        />
                                    ) : (
                                        <Chip
                                            label={
                                                <Typography variant="body2" sx={{ textAlign: 'center', color:'#FFF' }}>
                                                    {daysOfWeek[0].slice(0, 2)}
                                                </Typography>
                                            }
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgb(251, 251, 251)',
                                                borderRadius: '10px', // Rounded corners
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            clickable
                                            onClick={() => handleActivityBeginDayChange(day.key, day.name)}
                                        />
                                    )}
                                </Box>
                            ))
                        }
                    </Box>
                ))}
            </Box>
        );
    };

    //build the activity time_table based on the begin_date, end_date, begin_time, and end_time
    function createTimeTableForActivity() {
        console.log("createTimeTableForActivity : ", usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table);

        console.log("selectedActivityBeginDate:", selectedActivityBeginDate);
        console.log("selectedActivityEndDate:", selectedActivityEndDate);
        console.log("selectedActivityBeginDay:", selectedActivityBeginDay);
        console.log("selectedActivityEndDay:", selectedActivityEndDay);
        console.log("selectedActivityBeginTime:", selectedActivityBeginTime);
        console.log("selectedActivityEndTime:", selectedActivityEndTime);

        // Retrieve the existing time_table
        const existingTimeTable = activity?.time_table || new TimeTable();
        console.log("---- EXISTING time_table"+ JSON.stringify(existingTimeTable, null, '\t'));

        // Parse begin and end dates if available
        const start = selectedActivityBeginDate ? dayjs(selectedActivityBeginDate) : null;
        const end = selectedActivityEndDate ? dayjs(selectedActivityEndDate) : start; // Default to start if no end date

        // Calculate total days if dates exist
        let totalDateDays = start && end ? end.diff(start, "days") + 1 : 0;
        console.log("Total DateDays Calculated:", totalDateDays);

        /*
            first look at dates to see if exist
         */
        const dateEntries = [];
        const dayEntries = [];
        const newSelectedDays = [];

        if (totalDateDays > 0){
            //make an entry for each date with the first having any begin_time and the last having the end_time
            let currentDate = dayjs(selectedActivityBeginDate);

            setActivityDaysCount(totalDateDays);
            setActivityStartingOnDay(currentDate.format("dddd"));

            for (let i = 0; i < totalDateDays; i++) {
                dateEntries.push({
                    date: currentDate.format("YYYY-MM-DD"),
                    dayOfWeek: currentDate.format("dddd"),
                    'all-day': allDay,
                    'begin_time': i === 0 ? selectedActivityBeginTime ? selectedActivityBeginTime.format('h:mm A') : null : null, // Only first entry gets the begin_time
                    'end_time': i === totalDateDays - 1 ? selectedActivityEndTime ? selectedActivityEndTime.format('h:mm A') : null : null, // Only last entry gets the end_time
                    notes: "",
                });
                dayEntries.push({
                    [`day ${i + 1}`]: currentDate.format("dddd"),
                    'all-day': allDay,
                    'begin_time': i === 0 ? selectedActivityBeginTime ? selectedActivityBeginTime.format('h:mm A') : null : null, // Only first entry gets the begin_time
                    'end_time': i === totalDateDays - 1 ? selectedActivityEndTime ? selectedActivityEndTime.format('h:mm A') : null : null, // Only last entry gets the end_time
                    notes: "",
                });

                // Push the new day to the array
                newSelectedDays.push({
                    key: `day ${i + 1}`,
                    value: currentDate.format("dddd"),
                });

                currentDate = currentDate.add(1, 'day'); // Move to the next date
            }

        } else {
            //make a single entry with just any times
            dateEntries.push({
                date: selectedActivityBeginDate ? dayjs(selectedActivityBeginDate).format("YYYY-MM-DD") : null,
                dayOfWeek: selectedActivityBeginDate ? dayjs(selectedActivityBeginDate).format("dddd") : '',
                'all-day': allDay,
                'begin_time':  selectedActivityBeginTime ? selectedActivityBeginTime.format('h:mm A') : null,
                'end_time': selectedActivityEndTime ? selectedActivityEndTime.format('h:mm A') : null,
                notes: "",
            });
        }

        // If there are no dates but days
        if (totalDateDays === 0 && activityStartingOnDay) {
            const _day_name = activityStartingOnDay;         // Actual day name, e.g., "Tuesday"
            const _day_of_week_index = daysOfWeek.indexOf(_day_name); // Index in daysOfWeek array

            for (let i = 0; i < activityDaysCount; i++) {
                const currentDayIndex = (_day_of_week_index + i) % 7;
                const currentDayName = daysOfWeek[currentDayIndex];

                dayEntries.push({
                    [`day ${i + 1}`]: currentDayName,
                    'all-day': allDay,
                    'begin_time': i === 0 ? (selectedActivityBeginTime ? selectedActivityBeginTime.format('h:mm A') : null) : null,
                    'end_time': i === activityDaysCount - 1 ? (selectedActivityEndTime ? selectedActivityEndTime.format('h:mm A') : null) : null,
                    notes: "",
                });
                newSelectedDays.push({
                    key: `day ${i + 1}`,
                    value: currentDayName,
                });
            }
        }
        console.log("---- UPDATED dateEntries"+ JSON.stringify(dateEntries, null, '\t'));
        console.log("---- UPDATED dayEntries"+ JSON.stringify(dayEntries, null, '\t'));

        setSelectedDays(newSelectedDays);

        // **🚀 Update the activity with the new `time_table`**
        setActivity(prevState => ({
            ...prevState,
            time_table: {
                ...prevState.time_table, // ✅ Keep all existing properties
                dates: dateEntries,
                days: dayEntries
            }
        }));
    }


    const [selectedAttendees, setSelectedAttendees] = useState([]); // Attendees selection
    useEffect(() => {
        const attendees = usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees;
        console.log("attendees : ",attendees);
        if (attendees && attendees.length > 0) {
            const filtered = attendees
                .filter((att) => att.role !== 'planner')
                .map((att) => ({
                    id: att.id,
                    cost: 0,
                    is_business_expense: false,
                    business_expense_amount_authorized: 0,
                    business_expense_amount_authorized_by: "",
                    business_expense_amount_authorized_timestamp: 0,
                    business_expense_amount: 0,
                    business_expense_paid: 0,
                    business_expense_reference_id: "",
                }));

            setActivity(prevState => ({
                ...prevState,
                attendees: filtered
            }));

            setSelectedAttendees(() => {
                return filtered
                    .map((_att) => attendeesReferenceArray.find((att) => att.id === _att.id)) // Ensure return value
                    .filter(Boolean); // Remove any `undefined` values in case no match is found
            });
        }
    }, []);
    const handleParticipantsToggle = (_attendee_id) => () => {
        console.log("_attendee_id : "+_attendee_id);

        //see if this Attendee needs to be added or removed
        const index = activity["attendees"].findIndex(item => item.id === _attendee_id);
        console.log("index : "+index);

        let updatedList = [...activity["attendees"]];
        console.log("updatedList : ",updatedList);
        if (index !== -1) {
            // Remove attendee from activity list
            console.log("Remove attendee from activity list");
            updatedList.splice(index, 1);

        } else {
            console.log("Add attendee to activity list");
            // Find the attendee in the reference array
            const newAttendee = attendeesReferenceArray.find(
                (attendee) => String(attendee.id) === String(_attendee_id));
//            const newAttendee = attendeesReferenceArray.find(att => att.id === _attendee_id);

            console.log("Found attendee to add");

            if (!newAttendee) {
                console.error(`Attendee with ID ${_attendee_id} not found.`);
                return;
            }

            console.log("Add to global context if not already present");
            // Add to global context if not already present
            const globalAttendees = usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees;
            if (!globalAttendees.some(att => att.id === _attendee_id)) {
                set_PA_GlobalObject(prev => ({
                    Planning: {
                        ...prev.Planning,
                        attendees: [...prev.Planning.attendees, newAttendee],
                    },
                }));
            }

            // Add the attendee to the activity attendee list
            const attendeeWithCost = {
                id: _attendee_id,
                cost: 0,
                is_business_expense: false,
                business_expense_amount_authorized: 0,
                business_expense_amount_authorized_by: "",
                business_expense_amount_authorized_timestamp: 0,
                business_expense_amount: 0,
                business_expense_paid: 0,
                business_expense_reference_id: "",
            };
            updatedList = [...activity.attendees, attendeeWithCost];
        }

        console.log("the completed updatedList : ",updatedList);

        setActivity(prevState => ({
            ...prevState,
            attendees: updatedList
        }));

        console.log("now update the selectedAttendees : ", selectedAttendees);
        setSelectedAttendees(() => {
            return updatedList
                .map((_att) => attendeesReferenceArray.find((att) => att.id === _att.id)) // Ensure return value
                .filter(Boolean); // Remove any `undefined` values in case no match is found
        });
    };


    const handleTabLocationChange  = (event, newValue) => {
        console.log("handleTabLocationChange ");
        setTabValueLocation(newValue);
    };

    // State for location
    const [tabValueLocation, setTabValueLocation] = useState("locations");
    const [mapSearchType, setMapSearchType] = useState("address");
    const [mapSearchText, setMapSearchText] = useState("");

    const handleSearchTypeChange = (type) => {
        setMapSearchType(type);
        setMapSearchText(''); // Clear the input when switching search types
    };
    const handleMapSearchInput = (event) => {
        const searchText = event.target.value;
        setMapSearchText(searchText);
    };

    const [activityLocation, setActivityLocation] = useState(new PALocation());
    useEffect(() => {
        console.log("an update to the Activity Location : ", activityLocation);
        setActivity((prev) => ({
            ...prev,
            location: activityLocation
        }));
    }, [activityLocation]);

    // Handle field changes with one reusable function
    const handleLocationFieldChange = (field) => (e) => {
        const value = e.target.value;
        setActivityLocation((prev) => ({
            ...prev,
            [field]: value
        }));
    };

    const [addressFieldsResidential, setAddressFieldsResidential] = useState({
        google_place_id: '',
        latitude: 0,
        longitude: 0,
        formatted_address: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    });
    const { inputRefResidential, attachAutocompleteResidential, removePacContainerResidential } = useGoogleAutocompleteForAddress((parsedAddress) => {
        setAddressFieldsResidential({
            google_place_id: parsedAddress.google_place_id,
            latitude: parsedAddress.latitude,
            longitude: parsedAddress.longitude,
            formatted_address: parsedAddress.formatted_address,
            address: parsedAddress.address,
            city: parsedAddress.city,
            state: parsedAddress.state,
            zip: parsedAddress.zip,
            country: parsedAddress.country,
        });
    });

    const [addressFieldsEstablishment, setAddressFieldsEstablishment] = useState({
        google_place_id: '',
        latitude: 0,
        longitude: 0,

        name: '',
        formatted_address: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        phone_prefix: '',
        phone: '',

        types: [],
        hours: [],
        website: "",
        photos: [],
        price_level: 0,
        rating: 0.0,
        total_ratings: 0,
    });
    const { inputRefEstablishment, attachAutocompleteEstablishment, removePacContainerEstablishment } = useGoogleAutocompleteForEstablishment((parsedAddress) => {
        setAddressFieldsEstablishment({
            google_place_id: parsedAddress.google_place_id,
            latitude: parsedAddress.latitude,
            longitude: parsedAddress.longitude,

            name: parsedAddress.name,
            formatted_address: parsedAddress.formatted_address,
            address: parsedAddress.address,
            city: parsedAddress.city,
            state: parsedAddress.state,
            zip: parsedAddress.zip,
            country: parsedAddress.country,
            phone_prefix: parsedAddress.phone_prefix,
            phone: parsedAddress.phone,

            types: parsedAddress.types,
            hours: parsedAddress.hours,
            website: parsedAddress.website,
            photos: parsedAddress.photos,
            price_level: parsedAddress.price_level,
            rating: parsedAddress.rating,
            total_ratings: parsedAddress.total_ratings
        });
    });

    useEffect(() => {

        const newLocation = new PALocation();
        Object.assign(newLocation, addressFieldsResidential); // Copy matching fields from addressFields
        setActivityLocation(newLocation);

        //clear the input box
        setMapSearchText('');

    }, [addressFieldsResidential]);
    useEffect(() => {

        const newLocation = new PALocation();
        Object.assign(newLocation, addressFieldsEstablishment); // Copy matching fields from addressFields
        setActivityLocation(newLocation);

        //clear the input box
        setMapSearchText('');

    }, [addressFieldsEstablishment]);


    const [imagesToAdd, setImagesToAdd] = useState([]);
    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file, index) => {
            // Ensure file type is valid
            if (!file.type || file.type === "0") {
                console.error(`Skipped "${file.name}" due to an invalid MIME type.`);
                return;
            }

            const validTypes = ["image/jpeg", "image/png", "image/gif", "application/pdf"];
            if (!validTypes.includes(file.type)) {
                console.error(`Skipped "${file.name}" due to an invalid file type: ${file.type}`);
                return;
            }

            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {

                const new_photo = {
                    id: dayjs().valueOf() + index,
                    event_id: PA_GlobalObject.Planning.id,
                    attendee_id: Account.id,
                    file_name: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    height:0,
                    width:0,
                    thumbnail_data:"",
                    full_data: reader.result,
                    caption: "",
                    private: false,
                    sharable: false,
                    share_with: [],
                    link_with_sections: [],
                    photo_album: false,
                    full_size_uploaded: false,
                    thumbnail_finished: false,
                    last_modified: 0,
                    status: 0
                }

                console.log("new_photo : ", new_photo);

                setImagesToAdd(prevImages => [...prevImages, new_photo]);
            }
            reader.readAsDataURL(file)
        })
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        maxFiles: 30,
        accept: {
            "image/jpeg": [],
            "image/png": [],
            "image/gif": [],
        },
    });

    function handleCaptionChange(value, index) {
        setImagesToAdd(prev => {
            const updated = [...prev];
            updated[index].caption = value;
            return updated;
        });
    }


    // Function to toggle the public property of an image data object
    function togglePublic(index) {
        setImagesToAdd(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages[index].private = !updatedImages[index].private;
            return updatedImages;
        });
    }

    // Function to toggle the sharable property of an image data object
    function toggleSharable(index) {
        setImagesToAdd(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages[index].sharable = !updatedImages[index].sharable;

            // Ensure email list is initialized when sharable is enabled
            if (updatedImages[index].sharable && !updatedImages[index].share_with) {
                updatedImages[index].share_with = [];
            }

            return updatedImages;
        });
    }
    function handleEmailInputChange(event, index) {
        if (event.key === "Enter") {
            event.preventDefault();
            const newEmail = event.target.value.trim();

            if (newEmail && /\S+@\S+\.\S+/.test(newEmail)) { // Simple email validation
                setImagesToAdd(prevImages => {
                    const updatedImages = [...prevImages];
                    updatedImages[index].share_with.push(newEmail);
                    return updatedImages;
                });

                event.target.value = ""; // Clear input after adding
            }
        }
    }
    function removeEmail(imageIndex, emailIndex) {
        setImagesToAdd(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages[imageIndex].share_with.splice(emailIndex, 1);
            return updatedImages;
        });
    }
    function removeImage(index) {
        setImagesToAdd(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
    }



    function onSubmit(){

        console.log("adding the activity : "+ JSON.stringify(activity, null  ,'\t'));

        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                activities: [...(prev.Planning.activities || []), activity], // Append new Activity
            },
        }));

        handlePromptChangeClose();

    }

    function handlePromptChangeClose() {
        navigate('/Planner/Activities');
    }




    return (

        <div className="activities-list-container">

            {/* show the header */}
            <div
                className="activities-list-header"
            >
                <div className="activities-list-header-text">
                    <h5>New Activity</h5>
                </div>
                <div className="activities-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Cancel"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />
                    <Chip
                        sx={addChipStyle}
                        label='Add'
                        size="small"
                        onClick={onSubmit}
                    />
                </div>
            </div>

            <div className="activities-list-add-item-container">

                <Box sx={{ padding: 2}}>
                    {/* Title Section */}
                    <TextField
                        label="Activity Title"
                        value={activity.title}
                        onChange={(e) => setActivity({...activity, title: e.target.value})}
                        fullWidth
                        margin="normal"
                    />

                    {/* Date and Time Section */}

                    <Card
                        sx={{
                            margin:'15px 0 0 0'
                        }}
                    >
                        <CardHeader
                            title="Schedule"
                            subheader="Activities can be as short as a single day with a specific time frame,
                            such as a Picnic in the Park on Friday from 2pm to 4pm. Alternatively, they can span multiple days,
                            like a Camping Trip that begins on Friday at 8am and concludes on Saturday at 6pm."
                            sx={{
                                "& .MuiCardHeader-title": {
                                    fontSize: "16px",  // Set font size for the title
                                },
                                "& .MuiCardHeader-subheader": {
                                    fontSize: "12px",  // Set font size for the subheader
                                    color: "text.secondary",  // Optional: Adjust color for better readability
                                },
                            }}
                        />
                        <CardContent sx={{ paddingTop: '0' }}>

                            <FormControlLabel
                                control={<Switch checked={isScheduled} onChange={handleIsScheduledToggle}/>}
                                label="Schedule"
                            />
                            {
                                isScheduled && (
                                    <TabContext sx={{ position:'relative'}} value={tabValueSchedule}>
                                        <TabList
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: 'divider',
                                                backgroundColor: 'rgba(251, 251, 251, 0.8)',
                                                backdropFilter: 'blur(6px)',
                                            }}
                                            centered
                                            variant="fullWidth"
                                            onChange={handleTabScheduleChange}
                                            aria-label="Schedule"
                                        >
                                            <Tab
                                                label="Dates"
                                                value="dates"
                                            />
                                            <Tab
                                                label="Days"
                                                value="days"
                                            />
                                        </TabList>
                                        <TabPanel value="dates" sx={{padding: '24px 0'}}>
                                            <FormControl fullWidth margin="dense">
                                                <DatePicker
                                                    label="Begin Date"
                                                    value={selectedActivityBeginDate}
                                                    onChange={handleBeginDateChange}
                                                    renderInput={(params) => <TextField {...params} fullWidth margin="normal"/>}
                                                />
                                            </FormControl>

                                            <FormControl fullWidth margin="dense">
                                                <DatePicker
                                                    label="End Date"
                                                    value={selectedActivityEndDate}
                                                    onChange={handleEndDateChange}
                                                    renderInput={(params) => <TextField {...params} fullWidth margin="normal"/>}
                                                />
                                            </FormControl>
                                        </TabPanel>
                                        <TabPanel value="days" sx={{padding: '24px 0'}}>
                                            {
                                                renderDaysLayout()
                                            }

                                            <Box sx={{ marginBottom: '20px' }}>
                                                <TextField
                                                    fullWidth
                                                    id="event_date_days"
                                                    label="Number of Days"
                                                    value={activityDaysCount}
                                                    onChange={handleActivityDaysCountChange}
                                                    type="number"
                                                />
                                            </Box>
                                            <Box sx={{ marginBottom: '20px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="event-days-select-label">Starting On Day</InputLabel>
                                                    <Select
                                                        labelId="event-days-select-label"
                                                        id="event_days_select"
                                                        value={activityStartingOnDay}
                                                        label="Starting On Day"
                                                        onChange={handleActivityStartingOnChange}
                                                    >
                                                        <MenuItem value="">Not Selected</MenuItem>
                                                        <MenuItem value="Sunday">Sunday</MenuItem>
                                                        <MenuItem value="Monday">Monday</MenuItem>
                                                        <MenuItem value="Tuesday">Tuesday</MenuItem>
                                                        <MenuItem value="Wednesday">Wednesday</MenuItem>
                                                        <MenuItem value="Thursday">Thursday</MenuItem>
                                                        <MenuItem value="Friday">Friday</MenuItem>
                                                        <MenuItem value="Saturday">Saturday</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </TabPanel>
                                    </TabContext>
                                )
                            }



                            <FormControlLabel
                                control={<Switch checked={allDay} onChange={handleAllDayToggle}/>}
                                label="All Day Activity"
                            />

                            {!allDay && (
                                <>
                                    <FormControl fullWidth margin="dense">
                                        <TimePicker
                                            label="Begin Time"
                                            value={dayjs(selectedActivityBeginTime, 'hh:mm A')}
                                            onChange={handleBeginTimeChange}
                                            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth margin="dense">
                                        <TimePicker
                                            label="End Time"
                                            value={dayjs(selectedActivityEndTime, 'hh:mm A')}
                                            onChange={handleEndTimeChange}
                                            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                        />
                                    </FormControl>
                                </>
                            )}
                        </CardContent>
                    </Card>


                    {/* Attendee Section */}

                    <Card
                        sx={{
                            margin:'15px 0 0 0'
                        }}
                    >
                        <CardHeader
                            title="Participants"
                            subheader="Select which Attendees will be participating."
                            sx={{
                                "& .MuiCardHeader-title": {
                                    fontSize: "16px",  // Set font size for the title
                                },
                                "& .MuiCardHeader-subheader": {
                                    fontSize: "14px",  // Set font size for the subheader
                                    color: "text.secondary",  // Optional: Adjust color for better readability
                                },
                            }}
                        />
                        <CardContent>

                            <List dense sx={{ width: '100%' }}>
                                {selectedAttendees
                                    .sort((a, b) =>
                                        `${formatName(a.lname).toLowerCase()}, ${formatName(a.fname).toLowerCase()}`
                                            .localeCompare(
                                                `${formatName(b.lname).toLowerCase()}, ${formatName(b.fname).toLowerCase()}`
                                            )
                                    )
                                    .map((_attendee, index) => (
                                        <div key={index}>
                                            <ListItem
                                                secondaryAction={
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={handleParticipantsToggle(_attendee.id)}
                                                        checked={selectedAttendees.some(_att => _att.id === _attendee.id)}
                                                        inputProps={{ 'aria-labelledby': index }}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}
                                                            sx={{
                                                                boxShadow: '' +
                                                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                                    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                                border: `1px solid ${stringToColor(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}`,
                                                                borderRadius: '10px',
                                                                textAlign: 'center',
                                                                fontWeight: '700',
                                                                backgroundColor: '#FFF',
                                                                color: 'black',
                                                                fontSize: '15px',
                                                                width: '30px',
                                                                height: '30px',
                                                            }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        id={index}
                                                        primary={`${formatName(_attendee.lname)}, ${formatName(_attendee.fname)}`}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    ))
                                }

                                {/* Optional autocomplete for adding attendees */}
                                <Box mt={6} mb={1}>
                                    <Divider />
                                </Box>

                                {/* Autocomplete for adding attendees */}
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    options={[
                                        // Sort and group "Event Attendees" who are not already in the activity and are NOT planners
                                        ...usePA_GlobalStore
                                            .getState()
                                            .PA_GlobalObject.Planning.attendees
                                            .filter(
                                                (eventAttendee) =>
                                                    !eventAttendee.planner && // Exclude planners
                                                    !selectedAttendees.some((selected) => selected.id === eventAttendee.id)
                                            )
                                            .sort((a, b) =>
                                                `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(
                                                    `${formatName(b.lname)}, ${formatName(b.fname)}`
                                                )
                                            )
                                            .map((attendee) => ({ ...attendee, group: "Event Attendees" })),

                                        // Sort and group "Other Contacts" who are NOT in the event or activity and are NOT planners
                                        ...attendeesReferenceArray
                                            .filter(
                                                (referenceAttendee) =>
                                                    !referenceAttendee.planner && // Exclude planners
                                                    !usePA_GlobalStore
                                                        .getState()
                                                        .PA_GlobalObject.Planning.attendees.some(
                                                            (eventAttendee) => eventAttendee.id === referenceAttendee.id
                                                        ) &&
                                                    !selectedAttendees.some(
                                                        (selected) => selected.id === referenceAttendee.id
                                                    )
                                            )
                                            .sort((a, b) =>
                                                `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(
                                                    `${formatName(b.lname)}, ${formatName(b.fname)}`
                                                )
                                            )
                                            .map((attendee) => ({ ...attendee, group: "Other Contacts" })),
                                    ]}
                                    groupBy={(option) => option.group} // Group by "Event Attendees" or "Other Contacts"
                                    getOptionLabel={(option) => `${formatName(option.lname)}, ${formatName(option.fname)}`}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <div
                                                style={{
                                                    padding: "4px 10px",
                                                    fontWeight: "bold",
                                                    backgroundColor: "#f0f0f0",
                                                }}
                                            >
                                                {params.group}
                                            </div>
                                            <ul style={{ padding: 0, margin: 0 }}>{params.children}</ul>
                                        </li>
                                    )}
                                    renderOption={(props, option) => (
                                        <ListItem {...props} key={option.id}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    {...stringAvatar(`${formatName(option.fname)}, ${formatName(option.lname)}`)}
                                                    sx={{
                                                        boxShadow:
                                                            "0px 3px 1px -2px rgba(0, 0, 0, 0.2), " +
                                                            "0px 2px 2px 0px rgba(0, 0, 0, 0.14), " +
                                                            "0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                                                        border: `1px solid ${stringToColor(`${formatName(option.fname)}, ${formatName(option.lname)}`)}`,
                                                        borderRadius: "10px",
                                                        textAlign: "center",
                                                        fontWeight: "700",
                                                        backgroundColor: "#FFF",
                                                        color: "black",
                                                        fontSize: "15px",
                                                        width: "30px",
                                                        height: "30px",
                                                    }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={`${formatName(option.lname)}, ${formatName(option.fname)}`} />
                                        </ListItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Select Attendees"
                                            placeholder="Search Attendees"
                                        />
                                    )}
                                    onChange={(event, newValue) => {
                                        newValue.forEach((newAttendee) => {
                                            handleParticipantsToggle(newAttendee.id)();
                                        });
                                    }}
                                />

                            </List>
                        </CardContent>

                    </Card>


                    {/*  Location Section  */}
                    <Card
                        sx={{
                            margin:'15px 0 0 0'
                        }}
                    >
                        <CardHeader
                            title="Location"
                            subheader="Where the Activity is taking place."
                            sx={{
                                "& .MuiCardHeader-title": {
                                    fontSize: "16px",  // Set font size for the title
                                },
                                "& .MuiCardHeader-subheader": {
                                    fontSize: "14px",  // Set font size for the subheader
                                    color: "text.secondary",  // Optional: Adjust color for better readability
                                },
                            }}
                        />

                        <CardContent>

                            {
                                activityLocation ? (
                                    <Box sx={{ marginBottom: '10px' }}>
                                        {/* Title + Address */}
                                        <Typography sx={{ fontSize: '14px' }}>{activityLocation.name}</Typography>
                                        <Typography sx={{ fontSize: '12px' }}>{activityLocation.address}</Typography>
                                        <Typography sx={{ fontSize: '12px' }}>
                                            {activityLocation.city} {activityLocation.state} {activityLocation.zip}
                                        </Typography>
                                        <Typography sx={{ fontSize: '12px' }}>{activityLocation.country}</Typography>

                                        {/* Phone */}
                                        {activityLocation.phone_prefix && (
                                            <Typography sx={{ fontSize: '12px' }}>
                                                {activityLocation.phone_prefix} {activityLocation.phone}
                                            </Typography>
                                        )}

                                        {/* Website + Hours row */}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                fontSize: '12px',
                                                mt: 1,
                                            }}
                                        >
                                            {activityLocation.website && (
                                                <span
                                                    className="planning-map-area-place-info-website-holder"
                                                    onClick={() => window.open(activityLocation.website, '_blank')}
                                                    style={{ cursor: 'pointer', color: '#1976d2', textDecoration: 'underline' }}
                                                >
                                                  Website
                                                </span>
                                            )}
                                            {Array.isArray(activityLocation.hours) && activityLocation.hours.length > 0 && (
                                                <span
                                                    onClick={() => {
                                                        alert(
                                                            activityLocation.hours
                                                                .map((line) =>
                                                                    line
                                                                        .replace(/12:00 AM/g, 'midnight')
                                                                        .replace(/12:00 PM/g, 'noon')
                                                                )
                                                                .join('\n')
                                                        );
                                                    }}
                                                    style={{ cursor: 'pointer', color: '#1976d2', textDecoration: 'underline' }}
                                                >
                                                  View Hours
                                                </span>
                                            )}
                                        </Box>

                                        {/* Rating Level + Total Ratings row */}
                                        {(activityLocation.price_level > 0 || activityLocation.total_ratings > 0) && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    fontSize: '12px',
                                                    mt: 1,
                                                }}
                                            >
                                                {activityLocation.rating > 0 && (
                                                    <Rating
                                                        name="read-only"
                                                        value={activityLocation.rating}
                                                        precision={0.1}
                                                        readOnly
                                                        size="small"
                                                    />
                                                )}
                                                {activityLocation.total_ratings > 0 && (
                                                    <Typography sx={{ fontSize: '12px' }}>
                                                        {activityLocation.total_ratings} review
                                                        {activityLocation.total_ratings === 1 ? '' : 's'}
                                                    </Typography>
                                                )}
                                            </Box>
                                        )}

                                        {/* Price level */}
                                        {activityLocation.price_level > 0 && (
                                            <Typography sx={{ fontSize: '12px' }}>
                                                {'$'.repeat(activityLocation.price_level)}
                                            </Typography>
                                        )}
                                    </Box>
                                ) : null

                            }

                            <TabContext sx={{ position:'relative'}} value={tabValueLocation}>
                                <TabList
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                        backgroundColor: 'rgba(251, 251, 251, 0.8)',
                                        backdropFilter: 'blur(6px)',
                                    }}
                                    centered
                                    variant="fullWidth"
                                    onChange={handleTabLocationChange}
                                    aria-label="Activity Location"
                                >
                                    <Tab
                                        label="Search"
                                        value="search"
                                    />
                                    <Tab
                                        label="Locations"
                                        value="locations"
                                    />
                                    <Tab
                                        label="Address"
                                        value="address"
                                    />
                                </TabList>
                                <TabPanel value="search" sx={{padding: '24px 0'}}>

                                    {/* Right-Aligned Button Group */}
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                                        <ButtonGroup variant="text" aria-label="outlined button group">
                                            <Button
                                                style={{
                                                    fontSize: '11px',
                                                    backgroundColor: mapSearchType === 'establishment' ? 'rgba(198, 226, 255, 0.8)' : 'initial'
                                                }}
                                                onClick={() => handleSearchTypeChange('establishment')}
                                            >
                                                Establishment
                                            </Button>
                                            <Button
                                                style={{
                                                    fontSize: '11px',
                                                    backgroundColor: mapSearchType === 'address' ? 'rgba(198, 226, 255, 0.8)' : 'initial'
                                                }}
                                                onClick={() => handleSearchTypeChange('address')}
                                            >
                                                Residence
                                            </Button>
                                        </ButtonGroup>
                                    </Box>

                                    {/* Input Fields with Borders */}
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            zIndex: 9999, // ensures the dropdown appears above other elements
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        {mapSearchType === 'establishment' && (
                                            <InputBase
                                                sx={{
                                                    display: 'block',
                                                    flex: 1,
                                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    borderRadius: '4px',
                                                    padding: '6px 12px',
                                                    backgroundColor: 'white'
                                                }}
                                                placeholder='Establishment Name'
                                                inputProps={{ 'aria-label': 'search establishments' }}
                                                value={mapSearchText}
                                                onChange={handleMapSearchInput}
                                                inputRef={inputRefEstablishment}
                                                onFocus={attachAutocompleteEstablishment}
                                                onBlur={removePacContainerEstablishment}
                                            />
                                        )}

                                        {mapSearchType === 'address' && (
                                            <InputBase
                                                sx={{
                                                    display: 'block',
                                                    flex: 1,
                                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    borderRadius: '4px',
                                                    padding: '6px 12px',
                                                    backgroundColor: 'white'
                                                }}
                                                placeholder='Residential Address'
                                                inputProps={{ 'aria-label': 'search residential addresses' }}
                                                value={mapSearchText}
                                                onChange={handleMapSearchInput}
                                                inputRef={inputRefResidential}
                                                onFocus={attachAutocompleteResidential}
                                                onBlur={removePacContainerResidential}
                                            />
                                        )}

                                    </Box>
                                </TabPanel>

                                <TabPanel value="locations" sx={{padding: '24px 0'}}>
                                    <ActivityAddLocationTab
                                        activityLocation={activityLocation}
                                        setActivityLocation={setActivityLocation}
                                    />
                                </TabPanel>

                                <TabPanel value="address" sx={{ padding: '24px 0' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>

                                        {/* Location Name */}
                                        <TextField
                                            label="Name"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={activityLocation.name}
                                            onChange={handleLocationFieldChange("name")}
                                        />

                                        {/* Address */}
                                        <TextField
                                            label="Address"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={activityLocation.address}
                                            onChange={handleLocationFieldChange("address")}
                                        />

                                        {/* City */}
                                        <TextField
                                            label="City"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={activityLocation.city}
                                            onChange={handleLocationFieldChange("city")}
                                        />

                                        {/* State */}
                                        <TextField
                                            label="State"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={activityLocation.state}
                                            onChange={handleLocationFieldChange("state")}
                                        />

                                        {/* Zip Code */}
                                        <TextField
                                            label="Zip Code"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={activityLocation.zip}
                                            onChange={handleLocationFieldChange("zip")}
                                        />

                                        {/* Country */}
                                        <TextField
                                            label="Country"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={activityLocation.country}
                                            onChange={handleLocationFieldChange("country")}
                                        />

                                    </Box>
                                </TabPanel>

                            </TabContext>

                        </CardContent>
                    </Card>

                    {/*  Photos Section  */}
                    <Card
                        sx={{
                            margin:'15px 0 0 0'
                        }}
                        >
                        <CardHeader
                            title="Photos"
                            subheader={
                                dataArray.authenticated ? (
                                    <Typography variant="caption">Add photos related to this Activity</Typography>
                                ) : null
                            }
                            action={
                                !dataArray.authenticated ? (
                                    <IconButton
                                        aria-label="delete"

                                        size="small"
                                        sx={{ color: '#d32f2f' }}
                                    >
                                        <i className="icon-lock2" style={{ fontSize: '16px' }} />
                                    </IconButton>
                                ) : null
                            }
                            sx={{
                                "& .MuiCardHeader-title": {
                                    fontSize: "16px",  // Set font size for the title
                                },
                                "& .MuiCardHeader-subheader": {
                                    fontSize: "14px",  // Set font size for the subheader
                                    color: "text.secondary",  // Optional: Adjust color for better readability
                                },
                            }}
                        />
                        <CardContent>
                            {
                                dataArray.authenticated ? (
                                    <>
                                        {/* Drag and Drop Box */}
                                        <Box
                                            {...getRootProps()}
                                            sx={{
                                                border: "2px dashed gray",
                                                textAlign: "center",
                                                cursor: "pointer",
                                                borderRadius: "8px",
                                                margin: "20px",
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <Typography variant="caption">
                                                {isDragActive
                                                    ? "Drop the files here..."
                                                    : "Drag & drop photos here, or click to select"}
                                            </Typography>
                                        </Box>

                                        {/* Uploaded Image Cards */}
                                        {imagesToAdd &&
                                            imagesToAdd.map((imageData, index) => (
                                                <Card key={index} sx={{ marginBottom: "20px" }}>
                                                    <CardMedia
                                                        component="img"
                                                        src={imageData.full_data}
                                                        alt={`Image ${index + 1}`}
                                                    />
                                                    <CardContent>
                                                        {/* Caption Input */}
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            label="Add a caption"
                                                            variant="outlined"
                                                            value={imageData.caption || ""}
                                                            onChange={(e) => handleCaptionChange(e.target.value, index)}
                                                            sx={{ mb: 2 }}
                                                        />

                                                        {/* Share with Attendees */}
                                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2, mb: 2 }}>
                                                            <Typography variant="subtitle2" color="text.secondary">
                                                                Share with Attendees
                                                            </Typography>
                                                            <ButtonGroup variant="text" aria-label="attendee-share-toggle">
                                                                <Button
                                                                    style={{
                                                                        fontSize: "11px",
                                                                        backgroundColor: !imageData.private ? "rgba(198, 226, 255, 0.8)" : "initial",
                                                                    }}
                                                                    onClick={() => togglePublic(index)}
                                                                >
                                                                    YES
                                                                </Button>
                                                                <Button
                                                                    style={{
                                                                        fontSize: "11px",
                                                                        backgroundColor: imageData.private ? "rgba(198, 226, 255, 0.8)" : "initial",
                                                                    }}
                                                                    onClick={() => togglePublic(index)}
                                                                >
                                                                    NO
                                                                </Button>
                                                            </ButtonGroup>
                                                        </Box>

                                                        <Typography sx={{ fontSize: "13px" }} color="text.secondary">
                                                            Share with Event attendees or just keep the photo to yourself.
                                                        </Typography>

                                                        <Divider sx={{ marginTop: "10px" }} />

                                                        {/* Share Publicly */}
                                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2, mb: 2 }}>
                                                            <Typography variant="subtitle2" color="text.secondary">
                                                                Share with Internet
                                                            </Typography>
                                                            <ButtonGroup variant="text" aria-label="public-share-toggle">
                                                                <Button
                                                                    style={{
                                                                        fontSize: "11px",
                                                                        backgroundColor: imageData.sharable ? "rgba(198, 226, 255, 0.8)" : "initial",
                                                                    }}
                                                                    onClick={() => toggleSharable(index)}
                                                                >
                                                                    Share
                                                                </Button>
                                                                <Button
                                                                    style={{
                                                                        fontSize: "11px",
                                                                        backgroundColor: !imageData.sharable ? "rgba(198, 226, 255, 0.8)" : "initial",
                                                                    }}
                                                                    onClick={() => toggleSharable(index)}
                                                                >
                                                                    Don't Share
                                                                </Button>
                                                            </ButtonGroup>
                                                        </Box>

                                                        <Typography sx={{ fontSize: "13px" }} color="text.secondary">
                                                            A link to this image will be made available for you to share with individuals you choose.
                                                            This link will expire 30 days after the Event ends.
                                                        </Typography>

                                                        {/* Email Inputs */}
                                                        {imageData.sharable && (
                                                            <Box sx={{ mt: 2 }}>
                                                                <Typography sx={{ fontSize: "12px" }} color="text.secondary">
                                                                    Enter email addresses of the people you want to share this image with:
                                                                </Typography>

                                                                <input
                                                                    type="email"
                                                                    placeholder="Enter email and press Enter"
                                                                    onKeyPress={(event) => handleEmailInputChange(event, index)}
                                                                    style={{
                                                                        padding: "8px",
                                                                        marginTop: "8px",
                                                                        border: "1px solid #ccc",
                                                                        borderRadius: "4px",
                                                                    }}
                                                                />

                                                                {imageData.share_with?.length > 0 && (
                                                                    <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap", gap: 1 }}>
                                                                        {imageData.share_with.map((email, emailIndex) => (
                                                                            <Chip
                                                                                key={emailIndex}
                                                                                label={email}
                                                                                onDelete={() => removeEmail(index, emailIndex)}
                                                                                sx={{ backgroundColor: "#cdeefd" }}
                                                                            />
                                                                        ))}
                                                                    </Box>
                                                                )}

                                                                <Typography sx={{ fontSize: "12px", mt: "10px" }} color="text.secondary">
                                                                    The link will be created after saving the photo.
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </CardContent>

                                                    {/* Delete Button */}
                                                    <CardActions sx={{ justifyContent: "flex-end" }}>
                                                        <IconButton
                                                            title="Remove"
                                                            sx={{ color: "#f00", cursor: "pointer" }}
                                                            onClick={() => removeImage(index)}
                                                        >
                                                            <i className="icon-trash" />
                                                        </IconButton>
                                                    </CardActions>
                                                </Card>
                                            ))}
                                    </>
                                ) : (
                                    <Typography sx={{ mt: 2, fontSize: "14px" }} color="text.secondary" align="center">
                                        You must be logged in to add photos.
                                    </Typography>
                                )
                            }
                        </CardContent>

                    </Card>

                    {/* Notes Section */}
                    <Card
                        sx={{
                            margin: '15px 0 0 0'
                        }}
                    >
                        <CardHeader
                            title="Notes"
                            sx={{
                                "& .MuiCardHeader-title": {
                                    fontSize: "16px",  // Set font size for the title
                                },
                            }}
                        />
                        <CardContent>
                            <TextField
                                label="Notes"
                                value={activity.notes}
                                onChange={(e) => setActivity({...activity, notes: e.target.value})}
                                multiline
                                rows={4}
                                fullWidth
                                margin="normal"
                            />
                        </CardContent>
                    </Card>


                </Box>
            </div>
        </div>
    )
}


export default ActivitiesAddNew;
