import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";

import {PAContext} from "../../../Services/PAContext";
import {
    Card, CardActions,
    CardContent,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import Grid from '@mui/material/Grid2';

import DOMPurify from 'dompurify';

import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {updateChipStyle} from "../../ButtonStyle/Update";

import {useDropzone} from 'react-dropzone';

import './details.css';
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";





function DetailsView(props){

    const location = useLocation();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray, setShowSuccessSnackbar  } = useContext(PAContext);
    const { Events, Categories, Account } = dataArray;

    const [detailsTabSelected, setDetailsTabSelected] = useState("Name");
    const [eventName, setEventName] = useState("");

    function handlePromptDetailsClose() {
        props.setShowEventItemConfig(false);
    }

    useEffect(() => {
        if (location.state?.selectedTab !== undefined) {
            props.setShowEventItemConfig(true);
            setDetailsTabSelected(location.state.selectedTab); // Set the active tab from state
        }
    }, [location.state]);


    function handleFooterItemClick(_item){
        detailsTabSelected === _item ? setDetailsTabSelected("") : setDetailsTabSelected(_item)
    }

    function handleEventNameChange(event){
        setEventName(event.target.value);
    }

    function handleEventNameUpdate(){

        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                title: eventName, // Update only the title
            },
        }));

        //propagate the change
//        props.setEventName(eventName);

        //show that it was successful
//        setShowSuccessSnackbar(true);

    }


    const [ needToSaveImage , setNeedToSaveImage ] = useState(false);
    const [ coverImageName, setCoverImageName ] = useState('');
    const [ coverImageType, setCoverImageType ] = useState('');
    const [ coverImageSize, setCoverImageSize ] = useState('');
    const [ coverImageHeight, setCoverImageHeight ] = useState('');
    const [ coverImageWidth, setCoverImageWidth ] = useState('');
    const [ coverImageData, setCoverImageData ] = useState(
        usePA_GlobalStore.getState().PA_GlobalObject.Planning.cover_image_thumbnail !== "" ?
            usePA_GlobalStore.getState().PA_GlobalObject.Planning.cover_image_thumbnail
            :
            usePA_GlobalStore.getState().PA_GlobalObject.Planning.cover_image_full_data);

    useEffect(() => {
        console.log("coverImageData : ", coverImageData);
        console.log("PA_GlobalObject.Planning.cover_image_thumbnail : ", PA_GlobalObject.Planning.cover_image_thumbnail);
        console.log("PA_GlobalObject.Planning.cover_image_full_data : ", PA_GlobalObject.Planning.cover_image_full_data);

        setCoverImageData(PA_GlobalObject.Planning.cover_image_thumbnail || PA_GlobalObject.Planning.cover_image_full_data);
    }, [PA_GlobalObject.Planning.cover_image_thumbnail, PA_GlobalObject.Planning.cover_image_full_data]);

    const [ files, setFiles ] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/jpeg": [],
            "image/png": [],
            "image/gif": [],
        },
        onDrop: acceptedFiles => {
            setNeedToSaveImage(true);
            console.log("acceptedFiles : "+JSON.stringify(acceptedFiles));

            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    useEffect(() => {

        for (const _file of files){
            console.log("_file : "+_file);

            const reader = new FileReader();
            reader.onload = function (event){
                console.log("name : " + _file.name);
                console.log("size : " + _file.size);
                console.log("type : " + _file.type);
                console.log("height : " + _file.height);
                console.log("width : " + _file.width);

                console.log("event.target.result : "+ event.target.result);

                setCoverImageName(_file.name);
                setCoverImageSize(_file.size);
                setCoverImageType(_file.type);
                setCoverImageHeight(_file.height);
                setCoverImageWidth(_file.width);
                setCoverImageData(event.target.result);
            }
            reader.readAsDataURL(_file);
        }

    }, [files]);

    useEffect(() => {
        console.log("needToSaveImage : "+needToSaveImage);
    }, [needToSaveImage]);

    useEffect(() => {
        console.log("Planning.coverImage : "+usePA_GlobalStore.getState().PA_GlobalObject.Planning.coverImage);
        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.coverImage){
            const _coverImage = usePA_GlobalStore.getState().PA_GlobalObject.Planning.coverImage;
            setCoverImageName(_coverImage.name);
            setCoverImageSize(_coverImage.size);
            setCoverImageType(_coverImage.type);
            setCoverImageHeight(_coverImage.height);
            setCoverImageWidth(_coverImage.width);
        }
        setEventName(usePA_GlobalStore.getState().PA_GlobalObject.Planning.title);
    }, [usePA_GlobalStore.getState().PA_GlobalObject.Planning]);

    function handleEventCoverImageSave(){

        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                coverImage: {
                    name:coverImageName,
                    size:coverImageSize,
                    type:coverImageType,
                    height:coverImageHeight,
                    width:coverImageWidth
                },
                file_name : coverImageName,
                cover_image_full_data : coverImageData,
                cover_image_thumbnail : ""
            },
        }));

        //turn off the save button
        setNeedToSaveImage(false);
    }

    /*
        for the Event Introduction/description
     */
    const [userInput, setUserInput] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.eventIntro);
    const [sanitizedContent, setSanitizedContent] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.eventIntro);

    const handleInputChange = (event) => {
        setUserInput(event.target.value);
    };

    const renderContent = () => {
        const formattedInput = userInput.replace(/\n/g, '<br />');
        const sanitized = DOMPurify.sanitize(formattedInput);
        setSanitizedContent(sanitized);

        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                eventIntro: sanitized,
            },
        }));

    };


    useEffect(()=> {
        console.log("Categories Change!! : "+Categories);
    }, [Categories]);
    const _attendee_categories = [];
    const _event_categories = [
        "Birthdays",
        "Holidays",
        "Reunions",
        "Anniversaries",
        "Vacations",
        "Weddings",
        "Weekends",
        "Sporting Events",
        "Family Getaways"
    ];

    useEffect(() => {
        console.log("In the Events for getting Categories");
        console.log("Events.length : "+Events.length);

        const _previously_selected_categories = [];

        if (Events.length > 0){

            for (const _event of Events){
                let _entries_exist = false;
                for (const _menu_item of _event.menu_items){
                    console.log("Event menu items : ");
                    if (_menu_item.id === Account.id){

                        //found an entry for this attendee
                        for (const _cat of _menu_item["categories"]){
                            _attendee_categories.push(_cat);


                            if (_event.id === usePA_GlobalStore.getState().PA_GlobalObject.Planning.id){
                                //this is a category they selected for this Event
                                _previously_selected_categories.push(_cat);
                            }
                        }

                        _entries_exist = true;
                    }

                }
            }

            console.log("Adding attendee categories : "+_attendee_categories);

            setEventCategories([ ...new Set([ ..._event_categories , ..._attendee_categories]) ].sort());
            setSelectedEventCategories(_previously_selected_categories);
        }
    }, [Events]);

    const initialCategories = [...new Set([..._event_categories, ..._attendee_categories])].sort();
    const [ enableUpdateButton , setEnableUpdateButton ] = useState(false);
    const [ needToUpdate , setNeedToUpdate ] = useState(false);
    const [ eventCategories, setEventCategories ] = useState(initialCategories);
    const [ selectedEventCategories , setSelectedEventCategories ] = useState([]);
    const [ newCategory, setNewCategory ] = useState("");
    function handleEventCategoryNewCategory(e){
        setNewCategory(e.target.value);
    }
    function handleEventCategoryAddNewCategory(){

        //add the category to the event categories list
        setEventCategories([...eventCategories, newCategory]);

        //add the category to the selected list
        setSelectedEventCategories([...selectedEventCategories, newCategory]);

        //clear the input box
        setNewCategory('');

    }
    function handleEventCategoryToggleSelectedCategory(_category){
        //either add or remove the category from the selectedEventCategories list
        console.log("toggle "+_category);

        setSelectedEventCategories(prevCategories => {
            if (prevCategories.includes(_category)) {
                // Category exists, so remove it
                return prevCategories.filter(cat => cat !== _category);
            } else {
                // Category doesn't exist, so add it
                return [...prevCategories, _category];
            }
        });

        setNeedToUpdate(true);

    }

    useEffect(() => {
        console.log("selectedEventCategories : "+JSON.stringify(selectedEventCategories));
        showCategoryUpdateButton();
    }, [selectedEventCategories, needToUpdate]);
    function showCategoryUpdateButton(){
        //to be eligible to update
        // 1. must be logged in
        // 2. must be added to the event
        // 3. categories must have changed

        console.log("Logged in ? : "+(Account.id !== undefined));
        console.log("in event ? : "+(usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees && usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.some((attendee) => attendee.id === Account.id)));
        console.log("Need to update ? : "+needToUpdate);

        let _loggedIn = (Account.id !== undefined);
        let _inEvent = (usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees && usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.some((attendee) => attendee.id === Account.id));

        console.log("(_loggedIn&&_inEvent&&needToUpdate) ? : "+(_loggedIn&&_inEvent&&needToUpdate));

        setEnableUpdateButton((_loggedIn&&_inEvent&&needToUpdate));

    }
    function handleEventCategoriesUpdate(){
        //this user needs to be logged in

        console.log("Account.id : "+Account.id);
        console.log("Planning.attendees.includes(Account.id) : "+usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.some((attendee) => attendee.id === Account.id));

        if (Account.id !== undefined && usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees && usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.some((attendee) => attendee.id === Account.id)){
            console.log("Ok to update categories!!");

            const existingItemIndex = usePA_GlobalStore.getState().PA_GlobalObject.Planning['menu_items'].findIndex((item) => item.id === Account.id);
            if (existingItemIndex !== -1) {
                //there is already an entry for this attendee, so update it with the new list of categories

                const currentMenuItems = [...usePA_GlobalStore.getState().PA_GlobalObject.Planning['menu_items']];
                console.log("existing menu_items "+JSON.stringify(currentMenuItems));

                currentMenuItems[existingItemIndex] = {
                    ...currentMenuItems[existingItemIndex],
                    categories: [...selectedEventCategories], // Replace with the selected categories
                };

                console.log("updating menu_items to "+JSON.stringify(currentMenuItems));

                // Update the Planning object
                set_PA_GlobalObject((prev) => ({
                    Planning: {
                        ...prev.Planning, // Retain all existing fields in Planning
                        menu_items: currentMenuItems,
                    },
                }));

            } else {
                //need to add the entry for the attendee

                const newMenuItem = {
                    id: Account.id,
                    categories: [...selectedEventCategories], // Set the selected categories
                };

                console.log("updating attendee categories to "+newMenuItem);

                // Update the Planning object
                set_PA_GlobalObject((prev) => ({
                    Planning: {
                        ...prev.Planning, // Retain all existing fields in Planning
                        menu_items: [...prev.Planning.menu_items, newMenuItem], // Update the menu_items array
                    },
                }));

            }


            //show that it was successful
            setShowSuccessSnackbar(true);

            //turn off the update button
            setNeedToUpdate(false);

        } else {
            //show the error message

        }

    }



    const [ status, setStatus] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.status || 0);
    function handleEventStatusUpdate(e){
        setStatus(e.target.value);

        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                status: e.target.value,
            },
        }));

        //show that it was successful
        setShowSuccessSnackbar(true);
    }



    return (

        <div className="details-list-container" >
            {/* show the header */}
            <div
                className="details-list-header"
            >
                <div className="details-list-header-text">
                    <h5>Event Details</h5>
                </div>

                <div className="details-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptDetailsClose}
                    />
                </div>
            </div>

            <div className="details-list-items-container">
                <Card
                    className=""
                >

                    <CardActions>
                        <div className="details-list-item-footer-container">

                            {/* add the Event Name */}
                            <div
                                style={{backgroundColor: detailsTabSelected === "Name" ? '#EDEDED' : 'initial'}}
                                className="details-list-item-footer-name-container pointer"
                                onClick={()=>handleFooterItemClick('Name')}
                            >
                                <i className="icon-handwriting" style={{color: '#f09637'}} title="Name"/>
                            </div>

                            {/* add the Event Cover Image */}
                            <div
                                style={{backgroundColor: detailsTabSelected === "Cover" ? '#EDEDED' : 'initial'}}
                                className="details-list-item-footer-cover-container pointer"
                                onClick={()=>handleFooterItemClick('Cover')}
                            >
                                <i className="icon-portrait" style={{color: '#f09637'}} title="Cover Image"/>
                            </div>

                            {/* add the Event Intro / description */}
                            <div
                                style={{backgroundColor: detailsTabSelected === "Intro" ? '#EDEDED' : 'initial'}}
                                className="details-list-item-footer-cover-container pointer"
                                onClick={()=>handleFooterItemClick('Intro')}
                            >
                                <i className="icon-file-user" style={{color: '#f09637'}} title="Introduction/description"/>
                            </div>

                            {/* add the Event Categories */}
                            <div
                                style={{backgroundColor: detailsTabSelected === "Categories" ? '#EDEDED' : 'initial'}}
                                className="details-list-item-footer-categories-container pointer"
                                onClick={()=>handleFooterItemClick('Categories')}
                            >
                                <Badge
                                    badgeContent={selectedEventCategories.length}
                                    color="badge"
                                >
                                    <i className="icon-heart-shiny" style={{color: '#f09637'}} title="Categories"/>
                                </Badge>
                            </div>

                            {/* add the Event Status */}
                            <div
                                style={{backgroundColor: detailsTabSelected === "Status" ? '#EDEDED' : 'initial'}}
                                className="details-list-item-footer-status-container pointer"
                                onClick={()=>handleFooterItemClick('Status')}
                            >
                                <i className="icon-sync-gear" style={{color: '#f09637'}} title="Status"/>
                            </div>
                        </div>
                    </CardActions>

                    <CardContent
                        sx={{
                            overflowY: 'auto',
                            maxHeight: 'calc(100vh - 366px)',
                        }}
                    >
                        {
                            detailsTabSelected === "Name" ? (
                                <Grid container spacing={2} direction="column" sx={{marginTop:'0'}}>

                                    <Grid item style={{ flex: '1' }}>
                                        <TextField
                                            fullWidth
                                            id="eventName"
                                            label="Event Name"
                                            value={eventName}
                                            onChange={handleEventNameChange}
                                        />

                                        {/* add the update button */}
                                        <div className="details-list-item-update-name-chip-holder" >
                                            <Chip
                                                sx={updateChipStyle}
                                                className="details-list-item-update-name-chip"
                                                label="Update"
                                                size="small"
                                                onClick={handleEventNameUpdate}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            ) : (
                                <></>
                            )
                        }

                        {
                            detailsTabSelected === "Cover" ? (

                                <Grid container spacing={2} direction="column" sx={{marginTop:'0'}}>
                                    <Grid item style={{ flex: '1' }}>
                                        <div {...getRootProps()} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                            <input {...getInputProps()} />
                                            <Chip
                                                sx={updateChipStyle}
                                                style={{cursor:'pointer', margin:'0 0 10px 0'}}
                                                className="details-list-item-update-name-chip"
                                                label="Select Image"
                                                size="small"
                                            />
                                        </div>
                                        <div>
                                            <img
                                                src={coverImageData}
                                                style={{ maxWidth: '298px' }}
                                                alt=""
                                            />
                                        </div>

                                        {/* add the save button */}
                                        {
                                            needToSaveImage ? (
                                                <div className="details-list-item-update-name-chip-holder" >
                                                    <Chip
                                                        sx={updateChipStyle}
                                                        className="details-list-item-update-name-chip"
                                                        label="Save"
                                                        size="small"
                                                        onClick={handleEventCoverImageSave}
                                                    />
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </Grid>
                                </Grid>

                            ) : (
                                <></>
                            )

                        }

                        {
                            detailsTabSelected === "Intro" ? (
                                <Grid container spacing={2} direction="column" sx={{marginTop:'0'}}>
                                    <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                                        <TextField
                                            label="Enter text or HTML"
                                            multiline
                                            rows={10}
                                            variant="outlined"
                                            fullWidth
                                            value={userInput}
                                            onChange={handleInputChange}
                                        />
                                        {/* add the update button */}
                                        <div className="details-list-item-update-intro-chip-holder" >
                                            <Chip
                                                sx={updateChipStyle}
                                                className="details-list-item-update-intro-chip"
                                                label="Update"
                                                size="small"
                                                onClick={renderContent}
                                            />
                                        </div>

                                    </Box>
                                    <Box sx={{ mt: 2, border: '1px solid #ccc', padding: 2 }}>
                                        <Typography variant="h6">Rendered Content:</Typography>
                                        <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                                    </Box>
                                </Grid>
                            ) : (
                                <></>
                            )
                        }

                        {
                            detailsTabSelected === "Categories" ? (

                                <Grid container spacing={2} direction="column" sx={{marginTop:'0'}}>
                                    <Grid item style={{ flex: '1' }}>

                                        {
                                            eventCategories.map((_category, _index) => (
                                                <Chip
                                                    key={_index}
                                                    label={_category}
                                                    style={{ cursor: 'pointer', backgroundColor: selectedEventCategories.includes(_category) ? 'rgba(198, 226, 255, 0.8)' : 'rgba(0, 0, 0, 0.04)' }}
                                                    onClick={() => handleEventCategoryToggleSelectedCategory(_category)}
                                                    clickable
                                                />
                                            ))
                                        }

                                        <Card
                                            className="details-list-item"
                                        >
                                            <CardContent>
                                                <TextField
                                                    fullWidth
                                                    id="new_category_name"
                                                    label="New Category"
                                                    value={newCategory}
                                                    onChange={handleEventCategoryNewCategory}
                                                />

                                                {/* add button */}
                                                <div className="details-list-item-update-name-chip-holder" >
                                                    <Chip
                                                        sx={updateChipStyle}
                                                        label="Add"
                                                        size="small"
                                                        onClick={handleEventCategoryAddNewCategory}
                                                    />
                                                </div>
                                            </CardContent>
                                        </Card>


                                        <Grid item style={{ flex: '1' }}>
                                            {/* update button */}
                                            <div className="details-list-item-update-name-chip-holder" >
                                                <Chip
                                                    sx={updateChipStyle}
                                                    label="Update"
                                                    size="small"
                                                    onClick={handleEventCategoriesUpdate}
                                                    clickable
                                                    disabled={!enableUpdateButton}
                                                />
                                                {
                                                    !enableUpdateButton ? (
                                                        <div style={{padding: '20px'}} >
                                                            {/* need to add some attendees first */}
                                                            <i className="icon-warning" style={{fontSize:'11px', color:'red', marginRight:'10px'}}/>
                                                            <Typography variant="caption">Before assigning categories : </Typography>
                                                            <div><Typography variant="caption">1. You must login/create an account</Typography></div>
                                                            <div><Typography variant="caption">2. You must be added as an Attendee</Typography></div>
                                                            <div><Typography variant="caption">3. Select or Create categories</Typography></div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            ) : (
                                <></>
                            )

                        }

                        {
                            detailsTabSelected === "Status" ? (
                                <Grid container spacing={2} direction="column" sx={{marginTop:'0'}}>
                                    <Grid item style={{ flex: '1' }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={status}
                                                label="Status"
                                                onChange={handleEventStatusUpdate}
                                            >
                                                <MenuItem value={10}>Planning</MenuItem>
                                                <MenuItem value={0}>Open</MenuItem>
                                                <MenuItem value={2}>Rescheduled</MenuItem>
                                                <MenuItem value={1}>Closed</MenuItem>
                                                <MenuItem value={3}>Cancelled</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            ) : (
                                <></>
                            )

                        }
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default DetailsView;
