import React, {useContext, useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    ButtonGroup,
    Checkbox,
    Chip,
    ListItem,
    Tab
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { suppliesSuggestionList, foodSuggestionList} from '../../../sampleData/SupplyItemSuggestions';
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {addChipStyle} from "../../ButtonStyle/Add";
import {addWaitChipStyle} from "../../ButtonStyle/AddWait";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {PAContext} from "../../../Services/PAContext";




function SuppliesAddSuggestions(props){

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Account, Attendees, SampleAttendees } = dataArray;

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated ? Attendees : SampleAttendees);
    useEffect(() => {
        setAttendeesReferenceArray(dataArray.authenticated ? Attendees : SampleAttendees);
    }, [dataArray.authenticated, Attendees, SampleAttendees]);

    function handleSuppliesAddOK() {
        console.log("handleSuppliesAddOK : ", suppliesChecked);
        console.log("handleSuppliesAddOK : ", foodChecked);

        /*
            for each of the selected items
            set the assigned_to to the Account.id
         */
        const newSupplyItems = suppliesChecked.map((supplyItem) => ({
            ...supplyItem,
            assigned_to: Account.id === undefined ? PA_GlobalObject.Planning.attendees[0].id : Account.id, // Each To-Do has a single assigned attendee
        }));
        const newFoodItems = foodChecked.map((foodItem) => ({
            ...foodItem,
            assigned_to: Account.id === undefined ? PA_GlobalObject.Planning.attendees[0].id : Account.id,
        }));

        console.log("newSupplyItems : ",newSupplyItems);
        console.log("newFoodItems : ",newFoodItems);

        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                supplies: [
                    ...prev.Planning.supplies, // Keep the existing supplies
                    ...newSupplyItems, // Add newly selected supplies
                    ...newFoodItems // Add newly selected food items
                ]
            },
        }));
        handlePromptAddNewClose();
    }

    function handlePromptAddNewClose() {
        props.setShowEventItemExtendedConfig(false);
        navigate('/Planner/Supplies')
    }

    const [tabValue, setTabValue] = useState("supplies");
    const handleTabValueChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [suppliesChecked, setSuppliesChecked] = useState([]);
    const [foodChecked, setFoodChecked] = useState([]);

    const suppliessubcategories = Object.keys(suppliesSuggestionList).sort();

    // Iterate over the sorted subcategories
    const supplyItems = suppliessubcategories.map((subcategory) => {
        // Sort the items within each subcategory alphabetically
        const sortedItems = suppliesSuggestionList[subcategory].sort((a, b) => a.localeCompare(b));

        console.log("------- supplyItems");

        return (
            <div style={{position:'relative'}} >
                <div style={{position:'absolute', top:'0', right:'0', zIndex:'1'}} >
                    {
                        suppliesChecked.filter(
                            (checkedItem) => checkedItem.sub_category === subcategory
                        ).length === 0 ? (
                            <></>
                        ) : (
                            <Badge
                                badgeContent={
                                    suppliesChecked.filter(
                                        (checkedItem) => checkedItem.sub_category === subcategory
                                    ).length
                                }
                                color="success"
                            />
                        )
                    }
                </div>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h4>{subcategory}</h4>
                    </AccordionSummary>

                    <AccordionDetails>
                        <List dense>
                            {sortedItems.map((item_name, index) => (
                                <div key={`${subcategory}-${item_name}`}> {/* Use a unique key */}
                                    <ListItem
                                        secondaryAction={
                                            <Checkbox
                                                edge="end"
                                                onChange={() => handleSupplyItemToggle(subcategory, item_name)}
                                                checked={suppliesChecked.some((checkedItem) => checkedItem.sub_category === subcategory && checkedItem.name === item_name)}
                                                inputProps={{ 'aria-labelledby': `${subcategory}-${item_name}` }}
                                            />
                                        }
                                    >
                                        <ListItemText primary={item_name} />
                                    </ListItem>

                                    {/* IF this item is selected - add in the options */}
                                    {
                                        suppliesChecked.some((checkedItem) => checkedItem.sub_category === subcategory && checkedItem.name === item_name) ? (

                                            <div style={{marginBottom: '10px'}}>
                                                <div style={{display:'flex'}}>
                                                    {/* this is for the Privacy  */}
                                                    <span style={{flex: '1', textAlign: 'center'}}>
                                    <ButtonGroup
                                        size="small"
                                        variant="text"
                                        aria-label="supply items"
                                    >
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: suppliesChecked.some(
                                                    (checkedItem) =>
                                                        checkedItem.sub_category === subcategory &&
                                                        checkedItem.name === item_name &&
                                                        checkedItem.private
                                                )
                                                    ? 'initial'
                                                    : 'rgba(198, 226, 255, 0.8)'
                                            }}
                                            onClick={()=>handleSupplyItemPrivacy(subcategory, item_name, false)}
                                        >
                                            Public
                                        </Button>
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: suppliesChecked.some(
                                                    (checkedItem) =>
                                                        checkedItem.sub_category === subcategory &&
                                                        checkedItem.name === item_name &&
                                                        checkedItem.private
                                                )
                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                    : 'initial'
                                            }}
                                            onClick={()=>handleSupplyItemPrivacy(subcategory, item_name, true)}
                                        >
                                            Private
                                        </Button>
                                    </ButtonGroup>
                                    </span>

                                                    {/* this is for the Essential  */}
                                                    <span style={{flex: '1', textAlign: 'center'}}>
                                    <ButtonGroup
                                        size="small"
                                        variant="text"
                                        aria-label="supply items"
                                    >
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: suppliesChecked.some(
                                                    (checkedItem) =>
                                                        checkedItem.sub_category === subcategory &&
                                                        checkedItem.name === item_name &&
                                                        checkedItem.essential
                                                )
                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                    : 'initial'
                                            }}
                                            onClick={()=>handleSupplyItemEssential(subcategory, item_name, true)}
                                        >
                                            Essential
                                        </Button>
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: suppliesChecked.some(
                                                    (checkedItem) =>
                                                        checkedItem.sub_category === subcategory &&
                                                        checkedItem.name === item_name &&
                                                        checkedItem.essential
                                                )
                                                    ? 'initial'
                                                    : 'rgba(198, 226, 255, 0.8)'
                                            }}
                                            onClick={()=>handleSupplyItemEssential(subcategory, item_name, false)}
                                        >
                                            Not
                                        </Button>
                                    </ButtonGroup>
                                    </span>


                                                </div>

                                                {/* see if to assign it to others */}
                                                <div style={{fontSize:'11px', textAlign:'center', padding:'10px 5px'}}>
                                                    <span>Assign to others?</span><i className="icon-user-plus" />
                                                </div>


                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }

                                    <Divider light />
                                </div>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </div>

        );
    });

    function handleSupplyItemToggle(sub_category, item_name) {
        //get the details about the supply item
        console.log("Supply Items selected : "+JSON.stringify(suppliesChecked));

        //see if the item has already been selected
        const selected_supply_item = suppliesChecked.find((checkedItem) => checkedItem.sub_category === sub_category && checkedItem.name === item_name);

        const newChecked = [...suppliesChecked];

        console.log("checked supply item : "+JSON.stringify(selected_supply_item));

        if (selected_supply_item === undefined) {

            //add the supply item to the array

            const _new_supply_item = {};
            _new_supply_item.id = dayjs().valueOf();
            _new_supply_item.name = item_name;
            _new_supply_item.category = "supplies";
            _new_supply_item.sub_category = sub_category;
            _new_supply_item.private = false;

            _new_supply_item.assigned_by = props.selectedAttendeeForSupplies;
            _new_supply_item.assigned_to = [props.selectedAttendeeForSupplies];

            _new_supply_item.essential = false;
            _new_supply_item.essential_id = 0;
            _new_supply_item.notes = "";

            _new_supply_item.status = 1;

            console.log("adding new supply item : "+JSON.stringify(_new_supply_item));

            newChecked.push(_new_supply_item);
            setSuppliesChecked(newChecked);

        } else {

            console.log("remove from array ");
            //remove this item from the array
            setSuppliesChecked(newChecked.filter((checkedItem) => !(checkedItem.sub_category === sub_category && checkedItem.name === item_name)));

        }

    }

    function handleSupplyItemPrivacy(sub_category, item_name, _private){
        console.log("handleSupplyItemPrivacy");

        const updatedSuppliesChecked = [...suppliesChecked];

        const selected_supply_item = updatedSuppliesChecked.find((checkedItem) => checkedItem.sub_category === sub_category && checkedItem.name === item_name);

        if (selected_supply_item) {
            selected_supply_item.private = _private;
        }

        console.log("updatedSuppliesChecked : "+JSON.stringify(updatedSuppliesChecked));
        setSuppliesChecked(updatedSuppliesChecked);

    }

    function handleSupplyItemEssential(sub_category, item_name, _essential){
        console.log("handleSupplyItemEssential");

        const updatedSuppliesChecked = [...suppliesChecked];

        const selected_supply_item = updatedSuppliesChecked.find((checkedItem) => checkedItem.sub_category === sub_category && checkedItem.name === item_name);

        if (selected_supply_item) {
            selected_supply_item.essential = _essential;
        }

        console.log("updatedSuppliesChecked : "+JSON.stringify(updatedSuppliesChecked));
        setSuppliesChecked(updatedSuppliesChecked);

    }



    const foodsubcategories = Object.keys(foodSuggestionList).sort();

    // Iterate over the sorted subcategories
    const foodItems = foodsubcategories.map((subcategory) => {
        // Sort the items within each subcategory alphabetically
        const sortedItems = foodSuggestionList[subcategory].sort((a, b) => a.localeCompare(b));

        console.log("------- foodItems");

        return (

            <div style={{position:'relative'}} >
                <div style={{position:'absolute', top:'0', right:'0', zIndex:'1'}} >
                    {
                        foodChecked.filter(
                            (checkedItem) => checkedItem.sub_category === subcategory
                        ).length === 0 ? (
                            <></>
                        ) : (
                            <Badge
                                badgeContent={
                                    foodChecked.filter(
                                        (checkedItem) => checkedItem.sub_category === subcategory
                                    ).length
                                }
                                color="success"
                            />
                        )
                    }
                </div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h4>{subcategory}</h4>
                    </AccordionSummary>

                    <AccordionDetails>
                        <List dense>
                            {sortedItems.map((item_name) => (

                                <div key={`${subcategory}-${item_name}`}> {/* Use a unique key */}
                                    <ListItem
                                        secondaryAction={
                                            <Checkbox
                                                edge="end"
                                                onChange={() => handleFoodItemToggle(subcategory, item_name)}
                                                checked={foodChecked.some((checkedItem) => checkedItem.sub_category === subcategory && checkedItem.name === item_name)}
                                                inputProps={{ 'aria-labelledby': `${subcategory}-${item_name}` }}
                                            />
                                        }
                                    >
                                        <ListItemText primary={item_name} />
                                    </ListItem>

                                    {/* IF this item is selected - add in the options */}
                                    {foodChecked.some((checkedItem) => checkedItem.sub_category === subcategory && checkedItem.name === item_name) ? (
                                        <div style={{display:'flex', marginBottom: '10px'}}>
                                <span style={{flex: '1', textAlign: 'center'}}>
                                    <ButtonGroup variant="text" aria-label="outlined button group">
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: foodChecked.some(
                                                    (checkedItem) =>
                                                        checkedItem.sub_category === subcategory &&
                                                        checkedItem.name === item_name &&
                                                        checkedItem.private
                                                )
                                                    ? 'initial'
                                                    : 'rgba(198, 226, 255, 0.8)'
                                            }}
                                            onClick={()=>handleFoodItemPrivacy(subcategory, item_name, false)}
                                        >
                                            Public
                                        </Button>
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: foodChecked.some(
                                                    (checkedItem) =>
                                                        checkedItem.sub_category === subcategory &&
                                                        checkedItem.name === item_name &&
                                                        checkedItem.private
                                                )
                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                    : 'initial'
                                            }}
                                            onClick={()=>handleFoodItemPrivacy(subcategory, item_name, true)}
                                        >
                                            Private
                                        </Button>
                                    </ButtonGroup>
                                </span>

                                <span style={{flex: '1', textAlign: 'center'}}>
                                    <ButtonGroup
                                        size="small"
                                        variant="text"
                                        aria-label="food items"
                                    >
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: foodChecked.some(
                                                    (checkedItem) =>
                                                        checkedItem.sub_category === subcategory &&
                                                        checkedItem.name === item_name &&
                                                        checkedItem.essential
                                                )
                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                    : 'initial'
                                            }}
                                            onClick={()=>handleFoodItemEssential(subcategory, item_name, true)}
                                        >
                                            Essential
                                        </Button>
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: foodChecked.some(
                                                    (checkedItem) =>
                                                        checkedItem.sub_category === subcategory &&
                                                        checkedItem.name === item_name &&
                                                        checkedItem.essential
                                                )
                                                    ? 'initial'
                                                    : 'rgba(198, 226, 255, 0.8)'
                                            }}
                                            onClick={()=>handleFoodItemEssential(subcategory, item_name, false)}
                                        >
                                            Not
                                        </Button>
                                    </ButtonGroup>
                                    </span>

                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    <Divider light />
                                </div>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    });

    function handleFoodItemToggle(sub_category, item_name) {
        //get the details about the food item
        console.log("Food Items selected : "+JSON.stringify(foodChecked));

        //see if the item has already been selected
        const selected_food_item = foodChecked.find((checkedItem) => checkedItem.sub_category === sub_category && checkedItem.name === item_name);

        const newChecked = [...foodChecked];

        console.log("checked food item : "+JSON.stringify(selected_food_item));

        if (selected_food_item === undefined) {

            //add the supply item to the array

            const _new_food_item = {};
            _new_food_item.id = dayjs().format('x');
            _new_food_item.name = item_name;
            _new_food_item.category = "food";
            _new_food_item.sub_category = sub_category;
            _new_food_item.private = false;

            _new_food_item.assigned_by = props.selectedAttendeeForSupplies;
            _new_food_item.assigned_to = [props.selectedAttendeeForSupplies];

            _new_food_item.essential = false;
            _new_food_item.essential_id = 0;
            _new_food_item.notes = "";

            _new_food_item.status = 1;

            console.log("adding new food item : "+JSON.stringify(_new_food_item));

            newChecked.push(_new_food_item);
            setFoodChecked(newChecked);

        } else {

            console.log("remove from array ");
            //remove this item from the array
            setFoodChecked(newChecked.filter((checkedItem) => !(checkedItem.sub_category === sub_category && checkedItem.name === item_name)));

        }

    }

    function handleFoodItemPrivacy(sub_category, item_name, _private){
        console.log("handleFoodItemPrivacy");

        const updatedFoodChecked = [...foodChecked];

        const selected_food_item = updatedFoodChecked.find((checkedItem) => checkedItem.sub_category === sub_category && checkedItem.name === item_name);

        if (selected_food_item) {
            selected_food_item.private = _private;
        }

        console.log("updatedFoodChecked : "+JSON.stringify(updatedFoodChecked));
        setFoodChecked(updatedFoodChecked);

    }

    function handleFoodItemEssential(sub_category, item_name, _essential){
        console.log("handleFoodItemEssential");

        const updatedFoodChecked = [...foodChecked];

        const selected_food_item = updatedFoodChecked.find((checkedItem) => checkedItem.sub_category === sub_category && checkedItem.name === item_name);

        if (selected_food_item) {
            selected_food_item.essential = _essential;
        }

        console.log("updatedFoodChecked : "+JSON.stringify(updatedFoodChecked));
        setFoodChecked(updatedFoodChecked);

    }



    return (
        <div className="supplies-list-container" >
            {/* show the header */}
            <div
                className="supplies-list-header"
            >
                <div className="supplies-list-header-text">
                    <h5>Supply Suggestions</h5>
                </div>
                <div className="supplies-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Cancel"
                        size="small"
                        color="error"
                        onClick={handlePromptAddNewClose}
                    />
                    <Chip
                        sx={(suppliesChecked.length + foodChecked.length)===0 ? addWaitChipStyle : addChipStyle}
                        label={(suppliesChecked.length + foodChecked.length)===0?'Add': 'Add '+(suppliesChecked.length + foodChecked.length)}
                        size="small"
                        onClick={(suppliesChecked.length + foodChecked.length)===0 ? undefined : handleSuppliesAddOK}
                    />
                </div>
            </div>


            {/* show all the fields */}
            <div className="supplies-list-add-suggestion-item-container">
                <Grid container spacing={2} direction="column">

                    <Grid item >
                        <TabContext sx={{ position:'relative'}} value={tabValue}>
                            <Box
                                sx={{
                                    width: '350px',
                                    position: 'fixed',
                                    marginTop: '-5px',
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                    backgroundColor: 'rgba(251, 251, 251, 0.8)',
                                    backdropFilter: 'blur(6px)',
                                    zIndex:'2'
                                }}
                            >
                                <TabList
                                    centered
                                    variant="fullWidth"
                                    onChange={handleTabValueChange}
                                    aria-label="Supply Suggestions List"
                                >
                                    <Tab
                                        label={`Supplies ${suppliesChecked.length>0?suppliesChecked.length:''}`}
                                        value="supplies"
                                    />
                                    <Tab
                                        label={`Food ${foodChecked.length>0?foodChecked.length:''}`}
                                        value="food"
                                    />
                                </TabList>
                            </Box>
                            <TabPanel value="supplies" sx={{ padding:'50px 10px 10px 10px'}}>
                                {
                                    supplyItems
                                }
                            </TabPanel>
                            <TabPanel value="food" sx={{ padding:'50px 10px 10px 10px'}}>
                                {
                                    foodItems
                                }
                            </TabPanel>
                        </TabContext>
                    </Grid>

                </Grid>
            </div>
        </div>
    )
}

export default SuppliesAddSuggestions;
