import React from 'react';
import {Dialog, DialogContent, DialogTitle, Divider, List, ListItem, ListItemText, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

function InfoActivities({ isOpen, onClose }){


    return (
        <Dialog open={isOpen} onClose={onClose}>
            {/* Dialog Title */}
            <DialogTitle
                sx={{
                    color: '#151269',
                    textAlign: 'center',
                    backgroundColor: 'rgba(198, 226, 255, 0.8)',
                    paddingRight: '30px'
                }}
            >
                Activities
            </DialogTitle>

            <DialogTitle
                sx={{
                    fontSize: '18px',
                    textAlign: 'center',
                    color: '#151269',
                    backgroundColor: 'rgba(198, 226, 255, 0.8)',
                    padding: 0
                }}
            >
                The fun part — this is what your Event’s all about 🎈
            </DialogTitle>

            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: '#151269',
                })}
            >
                <i className="icon-cross" style={{fontSize: '18px', padding: '5px'}}/>
            </IconButton>

            <DialogContent>

                <List>
                    {/* Creating a new Activity */}
                    <ListItem>
                        <ListItemText
                            primary={
                                <span>
                                    <i className="icon-plus-circle" title="Adding a new Event" style={{ fontSize: "18px", marginRight: "8px", color: "#1976d2" }} />
                                    Add an Activity
                                </span>
                            }
                            secondary={
                                <Typography variant="body2" sx={{color:'rgba(0, 0, 0, 0.6)'}}>
                                    Spotted it on the map? Just tap to add it in. Want to include something like 'Set up the chairs' or 'Drive to the venue'?
                                    You can add that manually too.
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider />

                    {/* Adding/Removing Activity Attendees */}
                    <ListItem>
                        <ListItemText
                            primary={
                                <span>
                                    <i className="icon-group" title="Attendees" style={{ fontSize: "18px", marginRight: "8px", color: "#1976d2" }} />
                                    Who's Participating
                                </span>
                            }
                            secondary={
                                <span>
                                    <Typography variant="body2">
                                        Pick the folks joining in — whether it’s karaoke night, game time, or just hauling the cooler.
                                    </Typography>

                                    <Box
                                        sx={{
                                            backgroundColor: "#e3f2fd",
                                            color: "#0d47a1",
                                            padding: "6px 12px",
                                            borderRadius: "8px",
                                            marginTop: "8px",
                                            fontStyle: "italic",
                                            fontSize: "0.85rem",
                                        }}
                                    >
                                        💡 PRO Tip: All attendees are added by default — you can remove anyone who’s not joining.
                                    </Box>
                                </span>
                            }
                        />
                    </ListItem>
                    <Divider />

                    {/* Activity Costs */}
                    <ListItem>
                        <ListItemText
                            primary={
                                <span>
                                    <i className="icon-wallet" title="Activity Costs" style={{ fontSize: "18px", marginRight: "8px", color: "#1976d2" }} />
                                    Activity Cost
                                </span>
                            }
                            secondary={
                                <span>
                                    <Typography variant="body2">
                                        Add the cost if it matters. We’ll split it evenly by default, but you can customize it.
                                    </Typography>

                                    <Box
                                        sx={{
                                            backgroundColor: "#e3f2fd",
                                            color: "#0d47a1",
                                            padding: "6px 12px",
                                            borderRadius: "8px",
                                            marginTop: "8px",
                                            fontStyle: "italic",
                                            fontSize: "0.85rem",
                                        }}
                                    >
                                        💡 PRO Tip: Splits are flexible — tweak them to match who’s pitching in.
                                    </Box>
                                </span>
                            }
                        />
                    </ListItem>
                    <Divider />

                    {/* Updating Activity Location */}
                    <ListItem>
                        <ListItemText
                            primary={
                                <span>
                                    <i className="icon-map-marker" title="Activity Location" style={{ fontSize: "18px", marginRight: "8px", color: "#1976d2" }} />
                                    Where Is It?
                                </span>
                            }
                            secondary={
                                <Typography variant="body2" sx={{color:'rgba(0, 0, 0, 0.6)'}}>
                                    Pick a spot from your event locations, tap on the map, or type it in manually — whatever works best.
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider />

                    {/* Updating Activity Details */}
                    <ListItem>
                        <ListItemText
                            primary={
                                <span>
                                    <i className="icon-settings-icons" title="Details" style={{ fontSize: "18px", marginRight: "8px", color: "#1976d2" }} />
                                    Details & Notes
                                </span>
                            }
                            secondary={
                                <Typography variant="body2" sx={{color:'rgba(0, 0, 0, 0.6)'}}>
                                    Include anything helpful: what to bring, reminders, meeting points, or anything to make it smoother.
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>

            </DialogContent>
        </Dialog>
    )
}

export default InfoActivities;