import React, {useCallback, useContext, useState} from 'react';

import './photos.css';

import {Button, ButtonGroup, Card, CardActions, CardContent, CardMedia, Chip, Divider, TextField} from "@mui/material";
import {addChipStyle} from "../../ButtonStyle/Add";
import {useNavigate} from "react-router-dom";
import {useDropzone} from 'react-dropzone'
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import Box from "@mui/material/Box";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import dayjs from "dayjs";
import {PAContext} from "../../../Services/PAContext";
import {addEventPhoto} from "../../../Services/BrowserDatabaseFunctions";



function PhotosAddNew(){

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Account } = dataArray;

    const [imagesToAdd, setImagesToAdd] = useState([]);
    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file, index) => {
            // Ensure file type is valid
            if (!file.type || file.type === "0") {
                console.error(`Skipped "${file.name}" due to an invalid MIME type.`);
                return;
            }

            const validTypes = ["image/jpeg", "image/png", "image/gif", "application/pdf"];
            if (!validTypes.includes(file.type)) {
                console.error(`Skipped "${file.name}" due to an invalid file type: ${file.type}`);
                return;
            }

            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {

                const new_photo = {
                    id: dayjs().valueOf() + index,
                    event_id: PA_GlobalObject.Planning.id,
                    attendee_id: Account.id,
                    file_name: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    height:0,
                    width:0,
                    thumbnail_data:"",
                    full_data: reader.result,
                    caption: "",
                    private: false,
                    sharable: false,
                    share_with: [],
                    link_with_sections: [],
                    photo_album: false,
                    full_size_uploaded: false,
                    thumbnail_finished: false,
                    last_modified: 0,
                    status: 0
                }

                console.log("new_photo : ", new_photo);

                setImagesToAdd(prevImages => [...prevImages, new_photo]);
            }
            reader.readAsDataURL(file)
        })
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        maxFiles: 30,
        accept: {
            "image/jpeg": [],
            "image/png": [],
            "image/gif": [],
        },
    });

    function handleCaptionChange(value, index) {
        setImagesToAdd(prev => {
            const updated = [...prev];
            updated[index].caption = value;
            return updated;
        });
    }


    // Function to toggle the public property of an image data object
    function togglePublic(index) {
        setImagesToAdd(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages[index].private = !updatedImages[index].private;
            return updatedImages;
        });
    }

    // Function to toggle the sharable property of an image data object
    function toggleSharable(index) {
        setImagesToAdd(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages[index].sharable = !updatedImages[index].sharable;

            // Ensure email list is initialized when sharable is enabled
            if (updatedImages[index].sharable && !updatedImages[index].share_with) {
                updatedImages[index].share_with = [];
            }

            return updatedImages;
        });
    }
    function handleEmailInputChange(event, index) {
        if (event.key === "Enter") {
            event.preventDefault();
            const newEmail = event.target.value.trim();

            if (newEmail && /\S+@\S+\.\S+/.test(newEmail)) { // Simple email validation
                setImagesToAdd(prevImages => {
                    const updatedImages = [...prevImages];
                    updatedImages[index].share_with.push(newEmail);
                    return updatedImages;
                });

                event.target.value = ""; // Clear input after adding
            }
        }
    }
    function removeEmail(imageIndex, emailIndex) {
        setImagesToAdd(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages[imageIndex].share_with.splice(emailIndex, 1);
            return updatedImages;
        });
    }

    // Function to toggle the photo_album property of an image data object
    function togglePhotoAlbum(index) {
        setImagesToAdd(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages[index].photo_album = !updatedImages[index].photo_album;
            return updatedImages;
        });
    }

    function removeImage(index) {
        setImagesToAdd(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
    }

    function handlePromptAddNewClose() {
        navigate('/Planner/Photos');
    }

    async function handlePhotosAddOK() {
        console.log("handlePhotosAddOK");

        // this will add or overwrite if exists
        for (const photo of imagesToAdd) {
            try {
                await addEventPhoto(photo);
                console.log(`Photo saved locally: ${photo.file_name}`);
            } catch (err) {
                console.error("Failed to store photo in browser DB:", err);
            }
        }


        //Prevent duplicate additions by file_name
        set_PA_GlobalObject((prev) => {
            const existingPhotos = prev.Planning.photos || [];

            // Filter out any image that has a file_name already in existingPhotos
            const newPhotos = imagesToAdd.filter((newPhoto) =>
                !existingPhotos.some(existing => existing.file_name === newPhoto.file_name)
            );

            return {
                Planning: {
                    ...prev.Planning,
                    photos: [...existingPhotos, ...newPhotos],
                },
            };
        });

        navigate('/Planner/Photos');
    }

    return (

        <div className="photos-list-container" >
            {/* show the header */}
            <div
                className="photos-list-header"
            >
                <div className="photos-list-header-text">
                    <h5>New Photos</h5>
                </div>
                <div className="photos-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Cancel"
                        size="small"
                        onClick={handlePromptAddNewClose}
                    />
                    {imagesToAdd.length === 0 ? (
                        <></>
                    ) : (
                        <IconButton onClick={handlePhotosAddOK}>
                            <Chip
                                sx={addChipStyle}
                                label={`Add ${imagesToAdd.length}`}
                                size="small"
                            />
                        </IconButton>
                    )}
                </div>
            </div>


            {/* the items for adding photos */}
            <div className="photos-add-new-item-container">

                <Box
                    {...getRootProps()}
                    sx={{
                        border: "2px dashed gray",
                        textAlign: "center",
                        cursor: "pointer",
                        borderRadius: "8px",
                        margin: '20px'
                    }}
                >
                    <input {...getInputProps()} />
                    {isDragActive
                        ? <Typography variant="caption">Drop the files here...</Typography>
                        : <Typography variant="caption">Drag & drop photos here, or click to select</Typography>}
                </Box>

                {
                    imagesToAdd && imagesToAdd.map((imageData, index) => (

                        <Card key={index} sx={{ marginBottom: '20px' }}>
                            <CardMedia
                                component="img"
                                src={imageData.full_data}
                                alt={`Image ${index + 1}`}
                            />
                            <CardContent>
                                {/* add the input area to add a caption */}
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Add a caption"
                                    variant="outlined"
                                    value={imageData.caption || ""}
                                    onChange={(e) => handleCaptionChange(e.target.value, index)}
                                    sx={{ mb: 2 }}
                                />

                                {/* Public/Private Area */}
                                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2, mb: 2 }}>
                                    {/* Left: "Share with Attendees" Text */}
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Share with Attendees
                                    </Typography>

                                    {/* Right: ButtonGroup */}
                                    <ButtonGroup variant="text" aria-label="outlined button group">
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: imagesToAdd.find((imageItem) => imageItem.name === imageData.name).private
                                                    ? 'initial'
                                                    : 'rgba(198, 226, 255, 0.8)'
                                            }}
                                            onClick={() => togglePublic(index)}
                                        >
                                            YES
                                        </Button>
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: imagesToAdd.find((imageItem) => imageItem.name === imageData.name).private
                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                    : 'initial'
                                            }}
                                            onClick={() => togglePublic(index)}
                                        >
                                            NO
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                                <Typography sx={{fontSize:'13px'}} color="text.secondary">
                                    Share with Event attendees or just keep the photo to yourself.
                                </Typography>

                                <Divider sx={{marginTop: '10px'}}/>

                                {/* Publicly Sharable Area */}
                                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2, mb: 2 }}>
                                    {/* Left: "Share with Internet" Text */}
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Share with Internet
                                    </Typography>

                                    {/* Right: ButtonGroup */}
                                    <ButtonGroup variant="text" aria-label="outlined button group">
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: imagesToAdd.find((imageItem) => imageItem.name === imageData.name).sharable
                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                    : 'initial'
                                            }}
                                            onClick={() => toggleSharable(index)}
                                        >
                                            Share
                                        </Button>
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: imagesToAdd.find((imageItem) => imageItem.name === imageData.name).sharable
                                                    ? 'initial'
                                                    : 'rgba(198, 226, 255, 0.8)'
                                            }}
                                            onClick={() => toggleSharable(index)}
                                        >
                                            Don't Share
                                        </Button>
                                    </ButtonGroup>
                                </Box>

                                {/* Additional Sharable Description */}
                                <Typography sx={{fontSize:'13px'}} color="text.secondary">
                                    A link to this image will be made available for you to share with individuals you choose.
                                    This link will expire 30 days after the Event ends.
                                </Typography>
                                {/* Show Email Input Only If Sharable */}
                                {imageData.sharable && (
                                    <Box sx={{ mt: 2 }}>
                                        <Typography sx={{fontSize:'12px'}} color="text.secondary">
                                            Enter email addresses of the people you want to share this image with:
                                        </Typography>

                                        {/* Email Input Field */}
                                        <input
                                            type="email"
                                            placeholder="Enter email and press Enter"
                                            onKeyPress={(event) => handleEmailInputChange(event, index)}
                                            style={{
                                                padding: "8px",
                                                marginTop: "8px",
                                                border: "1px solid #ccc",
                                                borderRadius: "4px"
                                            }}
                                        />

                                        {/* Display Added Emails as Chips */}
                                        {imageData.share_with && imageData.share_with.length > 0 && (
                                            <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap", gap: 1 }}>
                                                {imageData.share_with.map((email, emailIndex) => (
                                                    <Chip
                                                        key={emailIndex}
                                                        label={email}
                                                        onDelete={() => removeEmail(index, emailIndex)}
                                                        sx={{ backgroundColor: "#cdeefd" }}
                                                    />
                                                ))}
                                            </Box>
                                        )}

                                        <Typography sx={{fontSize:'12px', mt: '10px'}} color="text.secondary">
                                            The link will be created after saving the photo.
                                        </Typography>
                                    </Box>
                                )}

                                <Divider sx={{marginTop: '10px'}}/>

                                {/* Public/Private Area */}
                                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2, mb: 2 }}>
                                    {/* Left: "Include in Photo Album" Text */}
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Include in Photo Album
                                    </Typography>

                                    {/* Right: ButtonGroup */}
                                    <ButtonGroup variant="text" aria-label="outlined button group">
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: imagesToAdd.find((imageItem) => imageItem.name === imageData.name).photo_album
                                                    ? 'initial'
                                                    : 'rgba(198, 226, 255, 0.8)'
                                            }}
                                            onClick={() => togglePhotoAlbum(index)}
                                        >
                                            YES
                                        </Button>
                                        <Button
                                            style={{
                                                fontSize: '11px',
                                                backgroundColor: imagesToAdd.find((imageItem) => imageItem.name === imageData.name).private
                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                    : 'initial'
                                            }}
                                            onClick={() => togglePhotoAlbum(index)}
                                        >
                                            NO
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                                <Typography sx={{fontSize:'13px'}} color="text.secondary">
                                    Make your Event cover image more memorable
                                </Typography>


                            </CardContent>
                            <CardActions
                                style={{justifyContent:'flex-end'}}
                            >
                                {/* add the delete icon */}
                                <IconButton
                                    title="Remove"
                                    sx={{
                                        color: '#f00',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => removeImage(index)}
                                >
                                    {
                                        <i className="icon-trash" />
                                    }
                                </IconButton>
                            </CardActions>
                        </Card>

                    ))
                }

            </div>
        </div>

    )

}

export default PhotosAddNew;
