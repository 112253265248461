import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../Services/PAContext";

import dayjs from "dayjs";
import {CardMedia, Checkbox, Chip, InputBase, ListItem, Menu, MenuItem, MenuList, Paper} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import {cancelChipStyle} from "../../components/ButtonStyle/Cancel";
import Rating from '@mui/material/Rating';
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import _ from "lodash";
import './planningPageStyle.css';
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Idea from "../../MasterEventObjects/Idea";
import Meal from "../../MasterEventObjects/Meal";
import PALocation from "../../MasterEventObjects/Location";
import Lodging from "../../MasterEventObjects/Lodging";
import {updateChipStyle} from "../../components/ButtonStyle/Update";
import Activity from "../../MasterEventObjects/Activity";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {selectedChipStyle} from "../../components/ButtonStyle/Selected";
import {unselectedChipStyle} from "../../components/ButtonStyle/Unselected";
import usePA_GlobalStore from "../../Services/ZustandGlobalStore";
import {createTimeTableForLodging} from "../../components/Helpers/EventTimeTableConversion";


let openInfoWindow = null;
let _global_map_service = null;


function GoogleMapMobile(props) {

    const navigate = useNavigate();

    const inputRefEstablishment = useRef(null);
    const inputRefAddress = useRef(null);


    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorLayersEl, setAnchorLayersEl] = useState(null);

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { ConciergeMapActions, SuggestionsLodging } = dataArray;

    const [zoomInOnLocation, setZoomInOnLocation] = useState(props.zoomInOnLocation);
    const [searchingForLocation, setSearchingForLocation] = useState(props.searchingForLocation);
    const [suggestionForLocation, setSuggestionForLocation] = useState("");

    const [eventActivityMarkers, setEventActivityMarkers] = useState([]);
    const [viewEventActivityMarkers, setViewEventActivityMarkers] = useState(true);

    const [eventLodgingMarkers, setEventLodgingMarkers] = useState([]);
    const [viewEventLodgingMarkers, setViewEventLodgingMarkers] = useState(true);

    const [eventIdeaMarkers, setEventIdeaMarkers] = useState([]);
    const [viewEventIdeaMarkers, setViewEventIdeaMarkers] = useState(true);

    const [eventMealMarkers, setEventMealMarkers] = useState([]);
    const [viewEventMealMarkers, setViewEventMealMarkers] = useState(true);

    const [eventFlightMarkers, setEventFlightMarkers] = useState([]);
    const [viewEventFlightMarkers, setViewEventFlightMarkers] = useState(true);

    const [suggestionLodgingMarkers, setSuggestionLodgingMarkers] = useState([]);
    const [viewSuggestionLodgingMarkers, setViewSuggestionLodgingMarkers] = useState(props.viewSuggestionLodgingMarkers);
    const [viewSuggestionLodgingMarkersFilter, setViewSuggestionLodgingMarkersFilter] = useState(props.viewSuggestionLodgingMarkersFilter);

    const [mapSearchType, setMapSearchType] = useState("establishment");
    const [mapSearchText, setMapSearchText] = useState("");
    const [mapSearchTextResultCount, setMapSearchTextResultCount] = useState(0);

    const [viewPlaceInfoPlace, setViewPlaceInfoPlace] = useState(null);
    const [viewPlaceInfoWindow, setViewPlaceInfoWindow] = useState(false);

    const [mapMarkerLayers, setMapMarkerLayers] = useState([]);
    const [mapMarkerLayersCount, setMapMarkerLayersCount] = useState(0);


    const _default_map_options = {
        mapId: "6c79697cd5555167",
        center: {
            lat: 35.5951,
            lng: -82.5515,
        },
        zoom: 10,
        mapTypeControl: false,
        fullscreenControl: false
    }


    useEffect(() => {
        console.log("props._global_map_ref has been set! "+props._global_map_ref);
        console.log("props._global_map_ref.current : "+props._global_map_ref?.current);

        if (props._global_map_ref) {

            if (!window.google || !window.google.maps) {
                console.error("Google Maps API is not available.");
                return;
            }

            let _planningExists = false;
            if (usePA_GlobalStore.getState().PA_GlobalObject.Planning && usePA_GlobalStore.getState().PA_GlobalObject.Planning.locations?.length > 0){
                _planningExists = true;
                props._global_map_ref.current = new window.google.maps.Map(
                    document.getElementById("global_google_map_holder"),
                    {
                        mapId: "6c79697cd5555167",
                        center: {
                            lat: usePA_GlobalStore.getState().PA_GlobalObject.Planning.locations[0].latitude,
                            lng: usePA_GlobalStore.getState().PA_GlobalObject.Planning.locations[0].longitude,
                        },
                        zoom: 10,
                        mapTypeControl: false,
                        fullscreenControl: false
                    }
                );
            } else {
                props._global_map_ref.current = new window.google.maps.Map(
                    document.getElementById("global_google_map_holder"),
                    _default_map_options
                );

            }

            // Check if _global_map_ref.current is a valid Google Map instance
            if (props._global_map_ref.current && typeof props._global_map_ref.current.getCenter === 'function') {
                console.log("This is a Google Map instance.");
                console.log("Map Center: ", props._global_map_ref.current.getCenter());
                console.log("Map Zoom: ", props._global_map_ref.current.getZoom());
            } else {
                console.error("props._global_map_ref.current is not a valid Google Map instance.");
            }

            if (props._global_map_ref?.current) {

                window.google.maps.event.addListenerOnce(props._global_map_ref.current, "idle", function() {
                    // Map has finished loading and is now idle
                    // Perform any desired actions here
                    console.log("-------------------- props._global_map_ref.current loaded");

                    console.log("##### props._global_map_ref.current : "+props._global_map_ref.current);

                    if (props._global_map_ref?.current) {
                        props._global_map_ref.current.addListener('click', (event) => {
                            console.log("-------------------- props._global_map_ref clickeddddddd");
                        });

                        const ClickEventHandlerGlobalMap = function (map, origin) {
                            this.origin = origin;
                            this.map = map;
                            this.placesService = new window.google.maps.places.PlacesService(map);
                            this.infowindow = new window.google.maps.InfoWindow();

                            // Listen for clicks on the map.
                            this.map.addListener('click', this.handleClickGlobalMap.bind(this));
                        };

                        ClickEventHandlerGlobalMap.prototype.handleClickGlobalMap = function (place) {
                            console.log("handleClickGlobalMap");
                            console.log('You clicked on: ' + JSON.stringify(place));
                            console.log('You clicked on: ' + place.latLng);

                            // If the place has a placeId, use it.
                            if (place.placeId) {
                                console.log('You clicked on place:' + place.placeId);

                                // Calling e.stop() on the place prplaces the default info window from
                                // showing.
                                // If you call stop here when there is no placeId you will prplace some
                                // other map click place handlers from receiving the place.
                                place.stop();
                                this.getPlaceInformationGlobalMap(place.placeId);

                            } else {
                                //get place details using coordinates
                                console.log('You clicked on residence');
                                this.getResidenceInformationGlobalMap(place.latLng);
                            }
                        };

                        ClickEventHandlerGlobalMap.prototype.getPlaceInformationGlobalMap = function (_google_business_id) {

                            const me = this;

                            this.placesService.getDetails({placeId: _google_business_id}, function (place, status) {

                                if (status === 'OK') {

                                    setViewPlaceInfoPlace(place);

                                    console.log("the international phone number : " + place.international_phone_number);

                                    console.log("the website url : " + place.website);

                                    let _address_line = "";
                                    let _address_city = "";
                                    let _address_state = "";
                                    let _address_zip = "";
                                    let _address_country = "";


                                    let _route_exists = false;
                                    let _country_code = "";
                                    for (const component of place.address_components) {
                                        if (component.types.includes("route")) {
                                            _route_exists = true;
                                        } else if (component.types.includes("country")) {
                                            _country_code = component.short_name;
                                        }
                                    }

                                    place.address_components.forEach((component) => {
                                        if (component.types.includes("street_number")) {
                                            _address_line = component.long_name;
                                        } else if (component.types.includes("route")) {
                                            _address_line += " " + component.long_name;
                                        } else if (!_route_exists && component.types.includes("administrative_area_level_2")) {
                                            _address_line += " " + component.long_name;
                                        } else if (component.types.includes("locality") || component.types.includes("postal_town")) {
                                            _address_city = component.long_name;
                                        } else if (component.types.includes("administrative_area_level_1") && _country_code === "US") {
                                            _address_state = component.short_name;
                                        } else if (component.types.includes("postal_code")) {
                                            _address_zip = component.long_name;
                                        } else if (component.types.includes("country")) {
                                            if (_country_code !== "US") {
                                                _address_state = component.long_name;
                                            }
                                            _address_country = component.short_name;
                                        }
                                    });

                                    if (_address_line === "") {
                                        //try getting it from the formatted address
                                        try {
                                            const _f_address_pieces = place.formatted_address.split(',');
                                            if (_f_address_pieces[0] !== "") {
                                                _address_line = _f_address_pieces[0].trim();
                                            }
                                        } catch (e) {
                                        }
                                    }


                                    console.log("the _address_line : " + _address_line);
                                    console.log("the _address_city : " + _address_city);
                                    console.log("the _address_state : " + _address_state);
                                    console.log("the _address_zip: " + _address_zip);
                                    console.log("the _address_country: " + _address_country);

                                    setViewPlaceInfoWindow(true);

                                } else {
                                    //no results for this PlaceId to use


                                }
                            });
                        };

                        ClickEventHandlerGlobalMap.prototype.getResidenceInformationGlobalMap = function (_latLng) {

                            //get the entire details about the location
                            const me = this;


                            const geocoder = new window.google.maps.Geocoder();
                            geocoder.geocode(
                                {
                                    'latLng': _latLng
                                },

                                function (place, status) {

                                    console.log("status 1: " + status)
                                    //            console.log("The returned place : " + JSON.stringify(place, null, '\t'));

                                    if (status === 'OK' && place.length > 0) {

                                        console.log("The place[0] : " + JSON.stringify(place[0], null, '\t'));

                                        setViewPlaceInfoPlace(place[0]);

                                        //the coordinates
                                        const _geometry = place[0].geometry;
                                        console.log("_geometry : " + JSON.stringify(_geometry, null, '\t'));
                                        console.log("latitude : " + _geometry.location.lat());
                                        console.log("longitude : " + _geometry.location.lng());

                                        //just look at the first address

                                        let _address_line = "";
                                        let _address_city = "";
                                        let _address_state = "";
                                        let _address_zip = "";
                                        let _address_country = "";

                                        console.log("_address_line: " + _address_line);
                                        console.log("_address_city: " + _address_city);
                                        console.log("_address_state: " + _address_state);
                                        console.log("_address_zip: " + _address_zip);

                                        let _route_exists = false;
                                        let _country_code = "";
                                        for (const component of place[0].address_components) {
                                            if (component.types.includes("route")) {
                                                _route_exists = true;
                                            } else if (component.types.includes("country")) {
                                                _country_code = component.short_name;
                                            }
                                        }

                                        place[0].address_components.forEach((component) => {
                                            if (component.types.includes("street_number")) {
                                                _address_line = component.long_name;
                                            } else if (component.types.includes("route")) {
                                                _address_line += " " + component.long_name;
                                            } else if (!_route_exists && component.types.includes("administrative_area_level_2")) {
                                                _address_line += " " + component.long_name;
                                            } else if (component.types.includes("locality") || component.types.includes("postal_town")) {
                                                _address_city = component.long_name;
                                            } else if (component.types.includes("administrative_area_level_1") && _country_code === "US") {
                                                _address_state = component.short_name;
                                            } else if (component.types.includes("postal_code")) {
                                                _address_zip = component.long_name;
                                            } else if (component.types.includes("country")) {
                                                if (_country_code !== "US") {
                                                    _address_state = component.long_name;
                                                }
                                                _address_country = component.short_name;
                                            }
                                        });

                                        if (_address_line === "") {
                                            //try getting it from the formatted address
                                            try {
                                                const _f_address_pieces = place[0].formatted_address.split(',');
                                                if (_f_address_pieces[0] !== "") {
                                                    _address_line = _f_address_pieces[0].trim();
                                                }
                                            } catch (e) {
                                            }
                                        }


                                        //now show the content window
//                            me.infowindow.close();
//                            me.infowindow.setPosition(_geometry.location);

                                        //add the info window
//                            me.infowindow.setContent(
//                                `<div class="planning-google-map-marker-info-window-content">
//                                    <h3>${place[0].name === undefined ? '' : place[0].name}</h3>
//                                    <h3>${place[0].formatted_address}</h3>
//                                    </div>`
//                            );
//                            me.infowindow.open(me.map);
//                            openInfoWindow = me.infowindow;

                                        setViewPlaceInfoWindow(true);

                                    }
                                }
                            );


                        };

                        ClickEventHandlerGlobalMap.prototype.showCustomInfoWindow = function (
                            latLng,
                            offsetX,
                            offsetY,
                            content
                        ) {
                            console.log("showCustomInfoWindow");
                            // Calculate the position to display the InfoWindow.
                            const projection = this.map.getProjection();
                            const point = projection.fromLatLngToPoint(latLng);
                            const offsetPoint = new window.google.maps.Point(point.x + offsetX, point.y + offsetY);
                            const offsetLatLng = projection.fromPointToLatLng(offsetPoint);

                            // Create and open the custom InfoWindow.
                            this.infowindow.setContent(content);
                            this.infowindow.setPosition(offsetLatLng);
                            this.infowindow.open(this.map);

                            // Optionally, you can close the InfoWindow on map click or other events.
                            this.map.addListener('click', () => {
                                console.log("clicked!!");
                                this.infowindow.close();
                            });
                        };

                        new ClickEventHandlerGlobalMap(props._global_map_ref.current, usePA_GlobalStore.getState().PA_GlobalObject.Planning.locations?.length === 0 ? _default_map_options : {
                            mapId: "6c79697cd5555167",
                            center: {
                                lat: usePA_GlobalStore.getState().PA_GlobalObject.Planning.locations[0].latitude,
                                lng: usePA_GlobalStore.getState().PA_GlobalObject.Planning.locations[0].longitude,
                            },
                            zoom: 10
                        });

                        _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);

                        const autocomplete = new window.google.maps.places.Autocomplete(inputRefAddress.current);
                        autocomplete.addListener('place_changed', () => {

                            //a single place was selected from the autocomplete list

                            const place = autocomplete.getPlace();
                            setViewPlaceInfoPlace(place);
                            console.log(JSON.stringify(place, null, '\t'));
                            console.log(place.formatted_address);

                            const _geometry = place.geometry;

                            let _address_line = "";
                            let _address_city = "";
                            let _address_state = "";
                            let _address_zip = "";
                            let _address_country = "";

                            let _route_exists = false;
                            let _country_code = "";
                            for (const component of place.address_components) {
                                if (component.types.includes("route")) {
                                    _route_exists = true;
                                } else if (component.types.includes("country")) {
                                    _country_code = component.short_name;
                                }
                            }

                            place.address_components.forEach((component) => {
                                if (component.types.includes("street_number")) {
                                    _address_line = component.long_name;
                                } else if (component.types.includes("route")) {
                                    _address_line += " " + component.long_name;
                                } else if (!_route_exists && component.types.includes("administrative_area_level_2")) {
                                    _address_line += " " + component.long_name;
                                } else if (component.types.includes("locality") || component.types.includes("postal_town")) {
                                    _address_city = component.long_name;
                                } else if (component.types.includes("administrative_area_level_1") && _country_code === "US") {
                                    _address_state = component.short_name;
                                } else if (component.types.includes("postal_code")) {
                                    _address_zip = component.long_name;
                                } else if (component.types.includes("country")) {
                                    if (_country_code !== "US") {
                                        _address_state = component.long_name;
                                    }
                                    _address_country = component.short_name;
                                }
                            });

                            if (_address_line === "") {
                                //try getting it from the formatted address
                                try {
                                    const _f_address_pieces = place.formatted_address.split(',');
                                    if (_f_address_pieces[0] !== "") {
                                        _address_line = _f_address_pieces[0].trim();
                                    }
                                } catch (e) {
                                }
                            }

                            console.log("the _address_line : " + _address_line);
                            console.log("the _address_city : " + _address_city);
                            console.log("the _address_state : " + _address_state);
                            console.log("the _address_zip: " + _address_zip);
                            console.log("the _address_country: " + _address_country);

                            //put a marker at the address
                            //add the marker to the layers
                            const markerContentDiv = document.createElement('div');
                            markerContentDiv.innerHTML = `
                                  <div class="planning-google-map-marker-layer-item">
                                    <i class="planning-google-map-marker-layer-item-icon icon-home3" />
                                    <span class="planning-google-map-marker-layer-item-name">
                                       ${place.name}
                                    </span>
                                    
                                  </div>
                                `;

                            const _markerData = {};
                            _markerData.title = place.name;
                            _markerData.google_place_id = place.place_id;

                            const _marker = new window.google.maps.marker.AdvancedMarkerView({
                                map: props._global_map_ref.current,
                                position: _geometry.location,
                                content: markerContentDiv,
                                title: place.name,
                            });
                            _marker.addListener("click", () => {
                                console.log("!!!! clicked on " + _marker.title);
                                props._global_map_ref.current.setZoom(15);
                                props._global_map_ref.current.setCenter(_marker.position);
                                setViewPlaceInfoWindow(true);
                            });
                            _markerData.marker = _marker;

                            addMarkerToLayer("Residences", _markerData);

                            props._global_map_ref.current.setCenter(_geometry.location);

                            //show the infowindowview so they can immediately add it
                            setViewPlaceInfoWindow(true);

                        });
                    }
                });

            } else {
                console.error("props._global_map_ref.current is null");
            }

        }

    }, [props._global_map_ref]);

    useEffect(() => {
        console.log("props.searchingForLocation : {"+props.searchingForLocation+"}");

        if (Object.keys(props.searchingForLocation)?.length > 0){
            //do the search
            const request = {
                query: props.searchingForLocation,
            };

            console.log("props.searchingForLocation request : "+JSON.stringify(request));

            const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
            _global_map_service.textSearch(request, (results, status) => {

                console.log("textSearch status : "+status);

                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    // Handle the search results (e.g. display them on a map or list)

                    console.log("number of results : "+results.length);

                    for (const _item of results) {

                        console.log("The returned place : " + JSON.stringify(_item, null, '\t'));
                        const _geometry = _item.geometry;

                        const icon_for_event_activity = document.createElement("div");
                        icon_for_event_activity.innerHTML = '<i class="icon-list-heart google-maps-advanced-marker-glyph-icon"></i>';

                        const pinViewCustomIdea = new window.google.maps.marker.PinView({
                            background: "#f09637",
                            borderColor: "#428ce2",
                            glyphColor: "#FFFFFF",
                            glyph: icon_for_event_activity,
                        });

                        new window.google.maps.marker.AdvancedMarkerView({
                            map: props._global_map_ref.current,
                            position: _geometry.location,
                            content: pinViewCustomIdea.element,
                            title: _item.name,
                        });

                        props._global_map_ref.current.setCenter(_geometry.location);

                    }

//                adjustGlobalMapMarkersBoundsZoom();

                } else {
                    console.log("no results for ")
                }
            });

        }


    }, [props.searchingForLocation]);

    useEffect(() => {
        console.log("Move to area : "+JSON.stringify(props.zoomInOnLocation, null, '\t'));

        console.log("latitude : "+props.zoomInOnLocation['latitude']);
        console.log("longitude : "+props.zoomInOnLocation['longitude']);

        if (Object.keys(props.zoomInOnLocation).length !== 0){
            try {

                props._global_map_ref.current.setCenter(
                    new window.google.maps.LatLng(props.zoomInOnLocation['latitude'], props.zoomInOnLocation['longitude'])
                );
                setZoomInOnLocation(new window.google.maps.LatLng(props.zoomInOnLocation['latitude'], props.zoomInOnLocation['longitude']));

                //add a Marker at the location

//                new window.google.maps.marker.AdvancedMarkerView({
//                    map: props._global_map_ref.current,
//                    position: new window.google.maps.LatLng(props.zoomInOnLocation['latitude'], props.zoomInOnLocation['longitude']),
//                });

            } catch (e){
                console.log("Move to error : "+e);
            }
        }

    }, [props.zoomInOnLocation]);

    useEffect(() => {
        console.log("Another ConciergeMapActions was triggered : "+ConciergeMapActions.length);

        for (let i = 0; i < ConciergeMapActions.length; i++) {
            const mapAction = ConciergeMapActions[i];

            console.log(JSON.stringify(mapAction, null, '\t'));

            if (mapAction.action === "planningConciergeMapSuggestionChangeResults"){

                if (mapAction.suggestion === "Outdoor Activities"){
                    for (const _item of mapAction.response_message_array){
                        console.log("the Outdoor Idea item : "+_item.business_name);
                        //put a pin on the map and number it

                        const request = {
                            query: _item.business_name +" located in "+props.searchingForLocation,
                            location: props._global_map_ref.current.getCenter(),
                            radius: 1500,
                            types: _item.activity
                        };

                        console.log("search request : "+JSON.stringify(request));

                        const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                        _global_map_service.textSearch(request, (results, status) => {

                            console.log("textSearch status : "+status);

                            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                // Handle the search results (e.g. display them on a map or list)

                                const icon_for_event_activity = document.createElement("div");
                                icon_for_event_activity.innerHTML = '<i class="icon-list-heart google-maps-advanced-marker-glyph-icon"></i>';

                                const pinViewCustomIdea = new window.google.maps.marker.PinView({
                                    background: "#f09637",
                                    borderColor: "#428ce2",
                                    glyphColor: "#FFFFFF",
                                    glyph: icon_for_event_activity,
                                    scale: 1.5
                                });

                                for (const _item of results) {

                                    console.log("The returned place : " + JSON.stringify(_item, null, '\t'));
                                    const _geometry = _item.geometry;

                                    new window.google.maps.marker.AdvancedMarkerView({
                                        map: props._global_map_ref.current,
                                        position: _geometry.location,
                                        content: pinViewCustomIdea.element,
                                        title: _item.name,
                                    });

                                }

                                //rezoom the map
//                                adjustGlobalMapMarkersBoundsZoom();

                            } else {
                                console.log("no results for ")
                            }
                        });
                    }
                }

            }

            //remove the one item we just processed
            ConciergeMapActions.splice(i, 1);

        }

    }, [ConciergeMapActions]);


    const MEAL_RADIUS = 0.00005; // ~5.5 meters
    const ACTIVITY_RADIUS = 0.00006; // ~6.6 meters
    const LODGING_RADIUS = 0.00007; // ~7.7 meters
    const IDEA_RADIUS = 0.00008; // ~8.8 meters

    const CATEGORY_RADIUSES = {
        MEAL: 0.00005,      // ~5.5 meters
        ACTIVITY: 0.00006,  // ~6.6 meters
        LODGING: 0.00007,   // ~7.7 meters
        IDEA: 0.00008,      // ~8.8 meters
    };

    const globalMarkerMap = new Map();

    function getCircularOffsetPosition(basePosition, index, totalMarkers, radius) {
        const angle = (2 * Math.PI * index) / totalMarkers; // Angle for each marker
        const latOffset = radius * Math.cos(angle); // X-coordinate offset
        const lngOffset = radius * Math.sin(angle); // Y-coordinate offset

        return {
            lat: basePosition.lat + latOffset,
            lng: basePosition.lng + lngOffset,
        };
    }

    useEffect(() => {
        console.log("@@@@@@@@@@@@@@@@@ Should be clearing all map markers now bc a new event was loaded");
        setMapMarkerLayers((prevLayers) => {
            const updatedLayers = [...prevLayers];

            //go through each layer and delete all the Markers
            updatedLayers.forEach((_layer) => {
                _layer.markers.forEach((marker) => {
                    if (marker.marker) {
                        marker.marker.setMap(null);
                    }
                })
            });

            console.log("----------------------------------- just cleared all the markers");
            return [];
        });

    }, [PA_GlobalObject.Planning.id]);

    useEffect(() => {
        console.log("PA_GlobalObject.Planning.lodging triggered");

        const currentLodging = usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging;

        if (currentLodging && currentLodging.length > 0) {

            const _shade_of_black = getRandomShadeOfBlack();

            currentLodging.map((lodgingItem, index) => {

                // Create a new marker for the lodging item
                const markerContent = document.createElement("div");
                markerContent.className = "search-results planning-google-map-marker-search-results-item";
                markerContent.style.backgroundColor = _shade_of_black;
                markerContent.style.color = "white";
                markerContent.innerHTML = `<i class="planning-google-map-marker-layer-item-icon icon-bed" />`;

                //if there is a lodging location
                if (lodgingItem.location.latitude && lodgingItem.location.longitude){

                    const basePosition = {
                        lat: lodgingItem.location.latitude,
                        lng: lodgingItem.location.longitude,
                    };

                    const adjustedPosition = getCircularOffsetPosition(basePosition, index, currentLodging.length, LODGING_RADIUS);

                    const newMarker = new window.google.maps.marker.AdvancedMarkerView({
                        map: props._global_map_ref.current,
                        position: adjustedPosition,
                        content: markerContent,
                        title: lodgingItem.name,
                    });

                    newMarker.addListener("click", () => {
                        console.log("!!!! clicked on " + newMarker.title);
                        props._global_map_ref.current.setZoom(15);
                        props._global_map_ref.current.setCenter(newMarker.position);

                        const _global_map_service = new window.google.maps.places.PlacesService(
                            props._global_map_ref.current
                        );
                        _global_map_service.getDetails(
                            { placeId: lodgingItem.location.google_place_id },
                            function (place, status) {
                                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                    setViewPlaceInfoPlace(place);
                                    setViewPlaceInfoWindow(true);
                                }
                            }
                        );
                    });

                    const newMarkerData = {
                        _id: lodgingItem.id,
                        temp_id: lodgingItem.temp_id,
                        title: lodgingItem.name,
                        google_place_id: lodgingItem.location.google_place_id,
                        marker: newMarker,
                    };

                    addMarkerToLayer("Lodging", newMarkerData);

                }

            });

        }
    }, [PA_GlobalObject.Planning.lodging]);

    useEffect(() => {
        // Create markers for each Activity when the component mounts
        console.log("PA_GlobalObject.Planning.activities triggered : ");

        const currentActivities = usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities;

        if (currentActivities && currentActivities.length > 0) {

            const _shade_of_black = getRandomShadeOfBlack();

            currentActivities.map((_item, index) => {

                /*
                    if the activity doesn't have a location then nothing to do
                 */
                const _activity_location = _item['location'];

                if (_activity_location.latitude!== 0 && _activity_location.longitude!== 0 ){

                    const markerContent = document.createElement("div");
                    markerContent.className = "search-results planning-google-map-marker-search-results-item";
                    markerContent.style.backgroundColor = _shade_of_black
                    markerContent.style.color = "white";
                    markerContent.innerHTML = `<i class="planning-google-map-marker-layer-item-icon icon-list-heart" />`;

                    const basePosition = {
                        lat: _item['location'].latitude,
                        lng: _item['location'].longitude,
                    };

                    const adjustedPosition = getCircularOffsetPosition(basePosition, index, currentActivities.length, ACTIVITY_RADIUS);

                    const _marker =  new window.google.maps.marker.AdvancedMarkerView({
                        map: props._global_map_ref.current,
                        position: adjustedPosition,
                        content: markerContent,
                        title: _item['title'],
                    });

                    _marker.addListener("click", () => {
                        console.log("!!!! clicked on Activity: "+JSON.stringify(_item, null, '\t'));
                        props._global_map_ref.current.setZoom(15);
                        props._global_map_ref.current.setCenter(_marker.position);

                        //get info about the google place and then show in the info
                        const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                        _global_map_service.getDetails({placeId : _item['location'].google_place_id}, function(place, status) {
                            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                console.log("The returned place : " + JSON.stringify(place, null, '\t'));

                                if (status === 'OK') {
                                    setViewPlaceInfoPlace(place);
                                    setViewPlaceInfoWindow(true);
                                }
                            }
                        });

                        setViewPlaceInfoWindow(true);
                    });

                    const _markerData = {
                        _id:_item.id,
                        temp_id:_item.temp_id,
                        title:_item['title'],
                        google_place_id:_item['location'].google_place_id,
                        marker:_marker,
                    };

                    addMarkerToLayer("Activit", _markerData);

                } else {
                    console.log("location not available for this Activity : "+_item['title']);
                }

            });

        }

    }, [PA_GlobalObject.Planning.activities]);

    useEffect(() => {
        // Create markers for each Meal when the component mounts
        console.log("PA_GlobalObject.Planning.meals triggered : ");

        const currentMeals = usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals;

        if (currentMeals && currentMeals.length > 0) {

            const _shade_of_black = getRandomShadeOfBlack();

            currentMeals.map((_item, index) => {

                const _meal_location = _item['location'];

                if (_meal_location.latitude!== 0 && _meal_location.longitude!== 0 ){

                    const markerContent = document.createElement("div");
                    markerContent.className = "search-results planning-google-map-marker-search-results-item";
                    markerContent.style.backgroundColor = _shade_of_black;
                    markerContent.style.color = "white";
                    markerContent.innerHTML = `<i class="planning-google-map-marker-layer-item-icon icon-fork-knife2" />`;

                    const basePosition = {
                        lat: _item['location'].latitude,
                        lng: _item['location'].longitude,
                    };

                    const adjustedPosition = getCircularOffsetPosition(basePosition, index, currentMeals.length, MEAL_RADIUS);


                    const _marker =  new window.google.maps.marker.AdvancedMarkerView({
                        map: props._global_map_ref.current,
                        position: adjustedPosition,
                        content: markerContent,
                        title: _item['location'].name,
                    });

                    _marker.addListener("click", () => {
                        console.log("!!!! clicked on "+_marker.title);
                        props._global_map_ref.current.setZoom(15);
                        props._global_map_ref.current.setCenter(_marker.position);

                        //get info about the google place and then show in the info
                        const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                        _global_map_service.getDetails({placeId : _item['location'].google_place_id}, function(place, status) {
                            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                console.log("The returned place : ", place);

                                if (status === 'OK') {
                                    setViewPlaceInfoPlace(place);
                                    setViewPlaceInfoWindow(true);
                                }
                            }
                        });


                        setViewPlaceInfoWindow(true);
                    });

                    const newMarkerData = {
                        _id: _item.id,
                        temp_id: _item.temp_id,
                        title: _item['location'].name,
                        google_place_id: _item['location'].google_place_id,
                        marker: _marker,
                    };

                    addMarkerToLayer("Meal", newMarkerData);

                } else {
                    console.log("location not available for this Meal : "+_item['name']);
                }

            });

        }

    }, [PA_GlobalObject.Planning.meals]);

    useEffect(() => {
        // Create markers for each Idea when the component mounts

        const currentIdeas = usePA_GlobalStore.getState().PA_GlobalObject.Planning.ideas;

        if (currentIdeas && currentIdeas.length > 0) {

            const _shade_of_black = getRandomShadeOfBlack();

            currentIdeas.map((_item, index) => {

                const _idea_location = _item['location'];

                if (_idea_location.latitude!== 0 && _idea_location.longitude!== 0 ){

                    const _markerData = {};
                    _markerData.title = _item['title'];
                    _markerData.google_place_id = _item['location'].google_place_id;


                    const markerContent = document.createElement("div");
                    markerContent.className = "search-results planning-google-map-marker-search-results-item";
                    markerContent.style.backgroundColor = _shade_of_black
                    markerContent.style.color = "white";
                    markerContent.innerHTML = `<i class="planning-google-map-marker-layer-item-icon icon-list-heart" />`;

                    const basePosition = {
                        lat: _item['location'].latitude,
                        lng: _item['location'].longitude,
                    };

                    const adjustedPosition = getCircularOffsetPosition(basePosition, index, currentIdeas.length, IDEA_RADIUS);

                    const _marker =  new window.google.maps.marker.AdvancedMarkerView({
                        map: props._global_map_ref.current,
                        position: adjustedPosition,
                        content: markerContent,
                        title: _item['title'],
                    });

                    _marker.addListener("click", () => {
                        console.log("!!!! clicked on Idea: "+JSON.stringify(_item, null, '\t'));
                        props._global_map_ref.current.setZoom(15);
                        props._global_map_ref.current.setCenter(_marker.position);

                        //get info about the google place and then show in the info
                        const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                        _global_map_service.getDetails({placeId : _item['location'].google_place_id}, function(place, status) {
                            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                console.log("The returned place : " + JSON.stringify(place, null, '\t'));

                                if (status === 'OK') {
                                    setViewPlaceInfoPlace(place);
                                    setViewPlaceInfoWindow(true);
                                }
                            }
                        });

                        setViewPlaceInfoWindow(true);
                    });

                    _markerData.marker = _marker;

                    addMarkerToLayer("Idea", _markerData);

                } else {
                    console.log("location not available for this Idea : "+_item['title']);
                }

            });

        }

    }, [PA_GlobalObject.Planning.ideas]);

    useEffect(() => {
        // Create markers for each lodging when the component mounts

        suggestionLodgingMarkers.forEach((marker) => {
            marker.map = null; // Hide the marker on the map
        });

        // Clear the markers from the array
        setSuggestionLodgingMarkers([]);


        const markers = SuggestionsLodging.map((_item) => {

            const _item_check_in_date = dayjs(_item.check_in_date);
            const _item_check_out_date = dayjs(_item.check_out_date);
            const _item_num_nights = _item_check_out_date.diff(
                _item_check_in_date,
                'days'
            );

            const _item_price = _item.cheapest_rate_total_amount;
            const _item_per_night_rate = _item_price / _item_num_nights;

            const priceTag = document.createElement("div");
            try {
                const _star_rating = _item['accommodation']['ratings'][0].value;

                //set the background color of the marker
                switch (_star_rating) {
                    case 1:
                        priceTag.className = "price-tag planning-google-map-marker-pricing-one-star";
                        break;
                    case 2:
                        priceTag.className = "price-tag planning-google-map-marker-pricing-two-star";
                        break;
                    case 3:
                        priceTag.className = "price-tag planning-google-map-marker-pricing-three-star";
                        break;
                    case 4:
                        priceTag.className = "price-tag planning-google-map-marker-pricing-four-star";
                        break;
                    case 5:
                        priceTag.className = "price-tag planning-google-map-marker-pricing-five-star";
                        break;
                    default:
                        priceTag.className = "price-tag planning-google-map-marker-pricing-one-star";
                }

                priceTag.textContent = _item_per_night_rate.toFixed(0) + " "+ _item.cheapest_rate_currency;

            } catch (e){
                //no rating so just default green
                priceTag.className = "price-tag planning-google-map-marker-pricing-one-star";
                priceTag.textContent = _item_per_night_rate.toFixed(0) + " "+ _item.cheapest_rate_currency;
            }

            const _marker =  new window.google.maps.marker.AdvancedMarkerView({
                map: null,
                position: {
                    lat:
                    _item['accommodation']['location']['geographic_coordinates']
                        .latitude,
                    lng:
                    _item['accommodation']['location']['geographic_coordinates']
                        .longitude,
                },
                content: priceTag,
                title: _item['accommodation'].name,
            });

            const infoWindow = new window.google.maps.InfoWindow({
                content: `<div class="planning-google-map-marker-info-window-content">
                              <h3>${_item['accommodation'].name}</h3>
                              <p>Price per night: $${_item_per_night_rate.toFixed(0)}</p>
                          </div>`
            });

            _marker.addListener("click", ({ domEvent, latLng }) => {
                console.log("!!!! clicked on "+_item['id']);
                const { target } = domEvent;
                console.log("lodging name : "+_item['id']);
                props.setLodgingSuggestionNameFromMap(_item['id']);
                if (!props.showEventItemConfig){
                    //if it's not showing then show it
                    props.setShowEventItemConfig(true);
                    navigate('/Planner/Lodging/Suggestions');
                }

                /*
                if (openInfoWindow != null) {
                    openInfoWindow.close();
                }
                infoWindow.open(_marker.map, _marker);
                openInfoWindow = infoWindow;
                 */
            });

            return _marker;
        });

        // Store the markers in the state variable
        setSuggestionLodgingMarkers(markers);

    }, [SuggestionsLodging]);

    useEffect(() => {
        // Update marker visibility based on filter criteria

        if (props.viewSuggestionLodgingMarkers) {
            suggestionLodgingMarkers.forEach((marker, index) => {

                const _item = SuggestionsLodging[index];

                const _item_check_in_date = dayjs(_item.check_in_date);
                const _item_check_out_date = dayjs(_item.check_out_date);
                const _item_num_nights = _item_check_out_date.diff(
                    _item_check_in_date,
                    'days'
                );

                const _item_price = _item.cheapest_rate_total_amount;
                const _item_per_night_rate = _item_price / _item_num_nights;

                // Check if the lodging meets the filter criteria
                const meetsFilterCriteria =
                    _item_per_night_rate >= props.viewSuggestionLodgingMarkersFilter[0] &&
                    _item_per_night_rate <= props.viewSuggestionLodgingMarkersFilter[1];

                // Set the marker visibility
                if (meetsFilterCriteria) {
                    marker.map = props._global_map_ref.current; // Show the marker
                } else {
                    marker.map = null; // Hide the marker
                }
            });
        } else {
            //turn them all off
            suggestionLodgingMarkers.forEach((marker, index) => {
                marker.map = null; // Hide the marker
            });
        }
    }, [props.viewSuggestionLodgingMarkers, props.viewSuggestionLodgingMarkersFilter]);

    useEffect(() => {
        //update the tour cities layer


    }, [props.tourSuggestionsTourCities]);

    useEffect(() => {
        console.log("the mapMarkerLayers : ", mapMarkerLayers);
        setMapMarkerLayersCount(mapMarkerLayers.length);
    }, [mapMarkerLayers]);


    const handleSearchTypeChange = (type) => {
        setMapSearchType(type);
        setMapSearchText(''); // Clear the input when switching search types
    };
    const handleMapSearchInput = (event) => {
        const searchText = event.target.value;
        setMapSearchText(searchText);
    };

    function handleMapSearchOK() {
        // Handle searching based on searchType ('address' or 'business')
        if (mapSearchType === 'establishment') {
            // Perform a Google search for businesses using searchText

            if (mapSearchText.length > 0){
                //see what AI has to say about search
                //see if there's a location to go along with the query

                const _sendString = {};
                _sendString.action = "planningConciergeServiceMapQuery";
                _sendString.query = mapSearchText;
                _sendString.language = "en";

                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");


                const request = {
                    query: mapSearchText + (props.searchingForLocation !== "" ? " located in "+props.searchingForLocation : ''),
                    location: props._global_map_ref.current.getCenter(),
                    radius: 5000,
                };

                console.log("search request : "+JSON.stringify(request));

                const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                _global_map_service.textSearch(request, (results, status) => {

                    console.log("textSearch status : "+status);

                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        // Handle the search results (e.g. display them on a map or list)

                        console.log("number of results : "+results.length);
                        setMapSearchTextResultCount(results.length);

                        const _shade_of_black = getRandomShadeOfBlack();
                        for (let _index = 0; _index < results.length; _index++) {

                            console.log("The returned place : " + JSON.stringify(results[_index], null, '\t'));
                            const _geometry = results[_index].geometry;

                            const markerContent = document.createElement("div");
                            markerContent.className = "search-results planning-google-map-marker-search-results-item";
                            markerContent.textContent = (+_index + 1).toString();
                            markerContent.style.backgroundColor = _shade_of_black
                            markerContent.style.color = "white";

                            const _item_marker = new window.google.maps.marker.AdvancedMarkerView({
                                map: props._global_map_ref.current,
                                position: _geometry.location,
                                content: markerContent,
                                title: results[_index].name,
                            });
                            _item_marker.addListener('click', ()=>{
                                console.log("!!!! clicked on "+_item_marker.title);

                                setViewPlaceInfoPlace(results[_index]);
                                setViewPlaceInfoWindow(true);
                            });

                            //add this to the layer with the search terms as the category
                            const _markerData = {};
                            _markerData.title = results[_index].name;
                            _markerData.google_place_id = results[_index].place_id;
                            _markerData.marker = _item_marker;

                            addMarkerToLayer(mapSearchText, _markerData);

                            props._global_map_ref.current.setCenter(_geometry.location);
                            props._global_map_ref.current.setZoom(11);

                            if (results.length !== 0 && _index === 0){

                                const _sendLocation = {
                                    'google_place_id': results[_index].place_id,
                                    'latitude': _geometry.location.lat(),
                                    'longitude': _geometry.location.lng()
                                };
                                const currentDate = dayjs();
                                const twoWeeksLater = currentDate.add(14, 'day');
                                const fiveDaysAfter = twoWeeksLater.add(5, 'day');

                                // Get event time_table
                                const eventTimeTable = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table;

                                // Determine begin and end dates from event time_table
                                const eventBeginDate = eventTimeTable?.dates?.[0]?.date
                                    ? dayjs(eventTimeTable.dates[0].date, "YYYY-MM-DD")
                                    : dayjs().add(14, 'day'); // Default to two weeks later if missing

                                const eventEndDate = eventTimeTable?.dates?.slice(-1)?.[0]?.date
                                    ? dayjs(eventTimeTable.dates.slice(-1)[0].date, "YYYY-MM-DD")
                                    : eventBeginDate.add(5, 'day'); // Default to 5 days after begin_date if missing

                                // Extract attendees from the Planning object
                                const attendees = usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees || [];

                                // Generate the guests array based on attendees' ages
                                const guestsArray = attendees
                                    .filter(attendee => !attendee.planner && attendee.status !== 4) // Exclude planners & status=4 attendees
                                    .map(attendee => {
                                        const isChild = attendee.age && attendee.age < 18;
                                        return isChild
                                            ? { type: 'child', age: attendee.age } // Include age only for children
                                            : { type: 'adult' }; // Exclude age for adults
                                    });

                                // If no valid attendees, default to 2 adults
                                if (guestsArray.length === 0) {
                                    guestsArray.push({ type: 'adult' }, { type: 'adult' });
                                }

                                const _sendLodgingString = {};
                                _sendLodgingString.action = "planningConciergeSuggestionServiceLodging";
                                _sendLodgingString.location = _sendLocation;
                                _sendLodgingString.guests = guestsArray;
                                _sendLodgingString.rooms = 1;
                                _sendLodgingString.begin_date = eventBeginDate.format("YYYY-MM-DD"); // Format for consistency
                                _sendLodgingString.end_date = eventEndDate.format("YYYY-MM-DD");

                                console.log("!!!! Sending out the lodging request : " + JSON.stringify(_sendLodgingString));
//                                dataArray.Websocket.send(JSON.stringify(_sendLodgingString) + "|^");


                                const _sendToursString = {};
                                _sendToursString.action = "planningConciergeSuggestionServiceTours";

                                _sendToursString.location = _sendLocation;
                                _sendToursString.city = _sendLocation;
                                _sendToursString.state = _sendLocation;
                                _sendToursString.country = _sendLocation;
                                _sendToursString.begin_date = usePA_GlobalStore.getState().PA_GlobalObject.Planning.begin_date && usePA_GlobalStore.getState().PA_GlobalObject.Planning.begin_date !== "" ? usePA_GlobalStore.getState().PA_GlobalObject.Planning.begin_date : twoWeeksLater;
                                _sendToursString.end_date = usePA_GlobalStore.getState().PA_GlobalObject.Planning.end_date && usePA_GlobalStore.getState().PA_GlobalObject.Planning.end_date !== "" ? usePA_GlobalStore.getState().PA_GlobalObject.Planning.end_date : fiveDaysAfter;

                                console.log("!!!! Sending out the Tours request");
//                                dataArray.Websocket.send(JSON.stringify(_sendToursString) + "|^");

                                const _sendUniqueString = {};
                                _sendUniqueString.action = "planningConciergeSuggestionServiceUnique";

                                _sendUniqueString.location = _sendLocation;
                                _sendUniqueString.begin_date = usePA_GlobalStore.getState().PA_GlobalObject.Planning.begin_date && usePA_GlobalStore.getState().PA_GlobalObject.Planning.begin_date !== "" ? usePA_GlobalStore.getState().PA_GlobalObject.Planning.begin_date : twoWeeksLater;
                                _sendUniqueString.end_date = usePA_GlobalStore.getState().PA_GlobalObject.Planning.end_date && usePA_GlobalStore.getState().PA_GlobalObject.Planning.end_date !== "" ? usePA_GlobalStore.getState().PA_GlobalObject.Planning.end_date : fiveDaysAfter;

                                console.log("!!!! Sending out the unique request");
//                                dataArray.Websocket.send(JSON.stringify(_sendUniqueString) + "|^");

                            }

                        }


                    } else {
                        console.log("no results for ");
                        setMapSearchTextResultCount(0);
                    }
                });

                //clear the search box
                setMapSearchText("");
            }

        } else {
            console.log("not an establishment");
        }
    }

    function adjustGlobalMapMarkersBoundsZoom(){
        console.log("adjustGlobalMapMarkersBoundsZoom");

        const bounds = new window.google.maps.LatLngBounds();

        //add a little more padding
        const extendAmount = 0.1;
        bounds.extend(new window.google.maps.LatLng(
            bounds.getNorthEast().lat() + extendAmount,
            bounds.getNorthEast().lng() + extendAmount
        ));
        bounds.extend(new window.google.maps.LatLng(
            bounds.getSouthWest().lat() - extendAmount,
            bounds.getSouthWest().lng() - extendAmount
        ));

        // Fit the map to the bounds of the markers
        props._global_map_ref.current.fitBounds(bounds);
    }

    const handleButtonOpenLayersMenuClick = (event) => {
        setAnchorLayersEl(event.currentTarget);
    }
    const handleButtonLayersMenuClose = () => {
        setAnchorLayersEl(null);
    };

    const toggleLayerVisibility = (layerName) => {
        console.log("toggleLayerVisibility : "+layerName);

        setMapMarkerLayers((prevLayers) => {
            const updatedLayers = [...prevLayers];

            const layerIndex = updatedLayers.findIndex((layer) => layer.name === layerName);
            console.log("layerIndex : "+layerIndex);
            if (layerIndex !== -1) {
                // Toggle the visibility of the layer
                updatedLayers[layerIndex].visible = !updatedLayers[layerIndex].visible;

                // Toggle the map for all markers in the layer
                updatedLayers[layerIndex].markers.forEach((markerData) => {

                    console.log("markerData : ", markerData);

                    if (markerData.marker) {
                        markerData.marker.setMap(
                            updatedLayers[layerIndex].visible ? props._global_map_ref.current : null
                        );
                    }
                });

                console.log("updatedLayers : ", updatedLayers);
            }

            return updatedLayers;
        });
    };

    function layerMarker(layerName){
        console.log("layerMarker : "+layerName);

        let _icon = "icon-bell"
        switch (layerName){
            case "Activities":
                _icon="icon-list-heart";
                break;
            case "Ideas":
                _icon="icon-lamp-bright";
                break;
            case "Meals":
                _icon="icon-fork-knife2";
                break;
            case "Lodging":
                _icon="icon-bed";
                break;
            case "Shopping":
                _icon="icon-bag";
                break;
            case "Planning":
                _icon="icon-sophisticated";
                break;
            case "Residences":
                _icon="icon-home3";
                break;
            case "Tours":
                _icon="icon-lamp-bright";
                break;
            default:


        }


        const markerContentDiv = document.createElement('div');
        markerContentDiv.innerHTML = `
          <div class="planning-google-map-marker-layer-item">
            <i class="planning-google-map-marker-layer-item-icon ${_icon}" />
            <span class="planning-google-map-marker-layer-item-name">
               ${viewPlaceInfoPlace.name}
            </span>
          </div>
        `;

        const _geometry = viewPlaceInfoPlace.geometry;

        const _markerData = {};
        _markerData.title = viewPlaceInfoPlace.name;
        _markerData.google_place_id = viewPlaceInfoPlace.place_id;

        console.log("props._global_map_ref.current : "+props._global_map_ref.current);

        const _marker = new window.google.maps.marker.AdvancedMarkerView({
            map: props._global_map_ref.current,
            position: _geometry.location,
            content: markerContentDiv,
            title: viewPlaceInfoPlace.name,
        });
        _marker.addListener("click", () => {
            console.log("!!!! clicked on "+_marker.title);
            props._global_map_ref.current.setZoom(15);
            props._global_map_ref.current.setCenter(_marker.position);
            setViewPlaceInfoWindow(true);
        });
        _markerData.marker = _marker;

        console.log("returning markerData : "+_markerData);
        return _markerData;

    }

    const addMarkerToLayer = (layerName, markerData) => {
        console.log("addMarkerToLayer : "+layerName , markerData);

        setMapMarkerLayers((prevLayers) => {
            const updatedLayers = [...prevLayers];

            // Check if the layer already exists
            const existingLayerIndex = updatedLayers.findIndex((layer) => layer.name === layerName);

            console.log("existingLayerIndex : ", existingLayerIndex);

            // Collect all existing markers across layers for the same `google_place_id`
            const allMarkers = updatedLayers.flatMap((layer) => layer.markers);
            const markersAtSameLocation = allMarkers.filter(
                (marker) => marker.google_place_id === markerData.google_place_id
            );

            // Calculate the offset position for the new marker
            const basePosition = {
                lat: markerData.marker.position.lat,
                lng: markerData.marker.position.lng,
            };
            const offsetPosition = getCircularOffsetPosition(
                basePosition,
                markersAtSameLocation.length, // Current marker index
                markersAtSameLocation.length + 1, // Total markers after adding this one
                CATEGORY_RADIUSES[layerName.toUpperCase()] || 0.00005 // Default radius
            );

            // Update the marker's position
            markerData.marker.position = offsetPosition;

            if (existingLayerIndex !== -1) {
                console.log("Layer already exists");
                // Layer already exists
                const existingLayer = updatedLayers[existingLayerIndex];

                console.log("existingLayer : ", existingLayer);

                // Count existing markers for the same google_place_id
                const existingMarkerCount = existingLayer.markers.filter(
                    (marker) => marker.google_place_id === markerData.google_place_id
                ).length;

                console.log("existingMarkerCount : ", existingMarkerCount);

                if (existingMarkerCount === 0) {
                    console.log("adding the marker to the existing layer and then ");
                    // Add the marker if we haven't exceeded the matching item count
                    existingLayer.markers.push(markerData);
                    updatedLayers[existingLayerIndex] = existingLayer;
                }
            } else {
                console.log("Layer doesn't exist");
                // Layer doesn't exist, create a new layer with the marker
                const newLayer = {
                    name: layerName,
                    type: "markers",
                    visible: true,
                    markers: [markerData],
                };

                updatedLayers.push(newLayer);
            }

            console.log("the resulting updatedLayers : ", updatedLayers);

            return updatedLayers;
        });

        // ✅ ALSO UPDATE THE GLOBAL STORE (Planning[layerName])
        usePA_GlobalStore.setState((prevState) => {
            const updatedPlanning = { ...prevState.PA_GlobalObject.Planning };

            // Ensure the layer exists in Planning, if not create it
            if (!updatedPlanning[layerName.toLowerCase()]) {
                updatedPlanning[layerName.toLowerCase()] = [];
            }

            // Check if this marker already exists
            const markerExists = updatedPlanning[layerName.toLowerCase()].some(
                (item) => item.location.google_place_id === markerData.google_place_id
            );

            if (!markerExists) {
                console.log("marker doesn't exist so need to add it ");
                updatedPlanning[layerName.toLowerCase()].push({
                    location: markerData.marker.position,
                    google_place_id: markerData.google_place_id,
                    name: markerData.title || "Unknown",
                    type: layerName,
                });
            }

            return {
                ...prevState,
                PA_GlobalObject: {
                    ...prevState.PA_GlobalObject,
                    Planning: updatedPlanning,
                },
            };
        });

    };

    const handleButtonOpenMenuClick = (event) => {
        console.log("handleButtonOpenMenuClick");
        if (anchorEl){
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleButtonCloseMenuClick = () => {
        console.log("handleButtonCloseMenuClick");
        setAnchorEl(null); // Close the menu
    };
    const handleButtonCloseClick = () => {
        console.log("handleButtonCloseClick");
        setAnchorEl(null); // Close the menu
        setViewPlaceInfoWindow(false);
    };

    const handleMenuItemClick = (option) => {
        // Handle the click on a menu item (e.g., perform an action)
        console.log(`Selected option: ${option}`);
        setAnchorEl(null); // Close the menu

        let _action = "";
        let _set_feature = "";
        switch (option) {
            case "Activities":
                _action = '/Planner/Activities';
                _set_feature = "Activities";
//                addMarkerToLayer('Activities', layerMarker('Activities'));
                break;
            case "Ideas":
                _set_feature = "Ideas";
                addMarkerToLayer('Ideas', layerMarker('Ideas'));
                break;
            case "Meals":
                _action = '/Planner/Meals';
                _set_feature = "Meals";
//                addMarkerToLayer('Meals', layerMarker('Meals'));
                break;
            case "Lodging":
                _action = '/Planner/Lodging';
                _set_feature = "Lodging";
//                addMarkerToLayer('Lodging', layerMarker('Lodging'));
                break;
            case "Shopping":
                _set_feature = "Shopping";
                addMarkerToLayer('Shopping', layerMarker('Shopping'));
                break;
            case "Tours":
                _set_feature = "Tours";
                addMarkerToLayer('Tours', layerMarker('Tours'));
                break;
            case "Planning":
                //add to the ConciergePlanning array
                const existingItems = props.conciergePlanningItemsFromMap;
                const newArray = [...existingItems, _.cloneDeep(viewPlaceInfoPlace)];
                props.setConciergePlanningItemsFromMap(newArray);

                //now add a marker to the Marker Layer
                addMarkerToLayer('Planning', layerMarker("Planning"));

                break;
            default:

        }

        if (option === "Lodging"){
            //get details about the Place (viewPlaceInfoPlace)

            //create the new Lodging
            const _new_lodging = new Lodging();

            _new_lodging.name = viewPlaceInfoPlace["name"];
            _new_lodging.phone = viewPlaceInfoPlace["formatted_phone_number"];

            //add any of the Event attendees to the lodging
            const attendees = usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees;

            if (attendees.length > 0) {
                _new_lodging.attendees = attendees
                    .filter((att) => att.role !== 'planner') // Exclude attendees with planner: true
                    .map((att) => ({
                        id: att.id,
                        cost: 0,
                        is_business_expense: false,
                        business_expense_amount_authorized: 0,
                        business_expense_amount_authorized_by: "",
                        business_expense_amount_authorized_timestamp: 0,
                        business_expense_amount: 0,
                        business_expense_paid: 0,
                        business_expense_reference_id: "",
                    }));
            }


            //for this we look to see if the Event has dates assigned and if so we
            //create a matching time_table for the lodging
            const eventTimeTable = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table;
            if (eventTimeTable?.dates?.length > 0) {
                _new_lodging.time_table = createTimeTableForLodging(
                    eventTimeTable.dates[0].date,
                    eventTimeTable.dates[eventTimeTable.dates.length - 1].date,
                    eventTimeTable);
            }


            //set the Place object from Google
            _new_lodging.Place = viewPlaceInfoPlace;

            //add the lodging location
            const _place_location = viewPlaceInfoPlace["geometry"];

            const _lodging_location = new PALocation();
            _lodging_location.google_place_id = viewPlaceInfoPlace["place_id"];
            _lodging_location.latitude = _place_location.location.lat();
            _lodging_location.longitude = _place_location.location.lng();


            let _address_line = "";
            let _address_city = "";
            let _address_state = "";
            let _address_zip = "";
            let _address_country = "";

            let _route_exists = false;
            let _country_code = "";
            if (Array.isArray(viewPlaceInfoPlace.address_components)) {

                for (const component of viewPlaceInfoPlace.address_components) {
                    if (component.types.includes("route")) {
                        _route_exists = true;
                    } else if (component.types.includes("country")) {
                        _country_code = component.short_name;
                    }
                }

                viewPlaceInfoPlace.address_components.forEach((component) => {
                    if (component.types.includes("street_number")){
                        _address_line = component.long_name;
                    } else if (component.types.includes("route")){
                        _address_line += " "+component.long_name;
                    } else if (!_route_exists && component.types.includes("administrative_area_level_2")){
                        _address_line += " "+component.long_name;
                    } else if (component.types.includes("locality") || component.types.includes("postal_town")){
                        _address_city = component.long_name;
                    } else if (component.types.includes("administrative_area_level_1") && _country_code === "US"){
                        _address_state = component.short_name;
                    } else if (component.types.includes("postal_code")){
                        _address_zip = component.long_name;
                    } else if (component.types.includes("country")){
                        if (_country_code !== "US"){
                            _address_state = component.long_name;
                        }
                        _address_country = component.short_name;
                    }
                });

            } else {
                console.log("address_components is not iterable");
                /*
                    so lets see if we can break apart the formatted_address
                 */

                const components = viewPlaceInfoPlace.formatted_address.split(',').map(component => component.trim());

                // Initialize variables
                let address = '';
                let city = '';
                let state = '';
                let zip = '';
                let country = '';

                // Assuming the country is always the last component
                if (components.length > 0) {
                    _address_country = components.pop();
                }

                // Process remaining components
                if (components.length > 0) {
                    _address_line = components.shift();
                }

                if (components.length > 0) {
                    _address_city = components.shift();
                }

                if (components.length > 0) {
                    _address_state = components.shift();
                }

                // If the state contains a zip code, split them
                const stateZipMatch = _address_state.match(/^(.+?)\s*(\d{5})?$/);
                if (stateZipMatch) {
                    _address_state = stateZipMatch[1];
                    _address_zip = stateZipMatch[2] || '';
                }


            }


            if (_address_line === ""){
                //try getting it from the formatted address
                try {
                    const _f_address_pieces = viewPlaceInfoPlace.formatted_address.split(',');
                    if (_f_address_pieces[0] !== ""){
                        _address_line = _f_address_pieces[0].trim();
                    }
                } catch (e){}
            }

            console.log("the _address_line : "+_address_line);
            console.log("the _address_city : "+_address_city);
            console.log("the _address_state : "+_address_state);
            console.log("the _address_zip: "+_address_zip);
            console.log("the _address_country: "+_address_country);


            _lodging_location.name =  viewPlaceInfoPlace["name"];
            _lodging_location.formatted_address = viewPlaceInfoPlace["formatted_address"];
            _lodging_location.address = _address_line;
            _lodging_location.city = _address_city;
            _lodging_location.state = _address_state;
            _lodging_location.zip = _address_zip;
            _lodging_location.country = _address_country;

            _new_lodging.location = _lodging_location;

            const _photos_array = [];
            if (viewPlaceInfoPlace.photos && Array.isArray(viewPlaceInfoPlace.photos)) {
                for (const _photo of viewPlaceInfoPlace.photos) {
                    _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                }
            }
            _new_lodging.photos = _photos_array;

            set_PA_GlobalObject((prev) => ({
                Planning: {
                    ...prev.Planning, // Retain all existing fields in Planning
                    lodging: [...prev.Planning.lodging, _new_lodging],
                },
            }));

        } else if (option === "Activities"){
            setAnchorEl(null); // Close the menu

            //get details about the Place (viewPlaceInfoPlace)
            console.log("the Activities viewPlaceInfoPlace : ", viewPlaceInfoPlace);

            //create the new Activity
            const _new_activity = new Activity();
            _new_activity.title = viewPlaceInfoPlace["name"]
            _new_activity.phone = viewPlaceInfoPlace["formatted_phone_number"]

            //add any of the Event attendees to the activity
            const attendees = usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees;

            if (attendees.length > 0) {
                _new_activity.attendees = attendees
                    .filter((att) => att.role !== 'planner') // Exclude attendees with planner: true
                    .map((att) => ({
                        id: att.id,
                        cost: 0,
                        is_business_expense: false,
                        business_expense_amount_authorized: 0,
                        business_expense_amount_authorized_by: "",
                        business_expense_amount_authorized_timestamp: 0,
                        business_expense_amount: 0,
                        business_expense_paid: 0,
                        business_expense_reference_id: "",
                    }));
            }

            //set the Place object from Google
            _new_activity.Place = viewPlaceInfoPlace;

            //add the activity location
            const _place_location = viewPlaceInfoPlace["geometry"];

            const _activity_location = new PALocation();
            _activity_location.google_place_id = viewPlaceInfoPlace["place_id"];
            _activity_location.latitude = _place_location.location.lat();
            _activity_location.longitude = _place_location.location.lng();


            let _address_line = "";
            let _address_city = "";
            let _address_state = "";
            let _address_zip = "";
            let _address_country = "";

            let _route_exists = false;
            let _country_code = "";
            if (Array.isArray(viewPlaceInfoPlace.address_components)) {

                for (const component of viewPlaceInfoPlace.address_components){
                    if (component.types.includes("route")) {
                        _route_exists = true;
                    } else if (component.types.includes("country")){
                        _country_code = component.short_name;
                    }
                }

                viewPlaceInfoPlace.address_components.forEach((component) => {
                    if (component.types.includes("street_number")){
                        _address_line = component.long_name;
                    } else if (component.types.includes("route")){
                        _address_line += " "+component.long_name;
                    } else if (!_route_exists && component.types.includes("administrative_area_level_2")){
                        _address_line += " "+component.long_name;
                    } else if (component.types.includes("locality") || component.types.includes("postal_town")){
                        _address_city = component.long_name;
                    } else if (component.types.includes("administrative_area_level_1") && _country_code === "US"){
                        _address_state = component.short_name;
                    } else if (component.types.includes("postal_code")){
                        _address_zip = component.long_name;
                    } else if (component.types.includes("country")){
                        if (_country_code !== "US"){
                            _address_state = component.long_name;
                        }
                        _address_country = component.short_name;
                    }
                });
            } else {
                console.log("address_components is not iterable");
                /*
                    so lets see if we can break apart the formatted_address
                 */

                const components = viewPlaceInfoPlace.formatted_address.split(',').map(component => component.trim());

                // Initialize variables
                let address = '';
                let city = '';
                let state = '';
                let zip = '';
                let country = '';

                // Assuming the country is always the last component
                if (components.length > 0) {
                    _address_country = components.pop();
                }

                // Process remaining components
                if (components.length > 0) {
                    _address_line = components.shift();
                }

                if (components.length > 0) {
                    _address_city = components.shift();
                }

                if (components.length > 0) {
                    _address_state = components.shift();
                }

                // If the state contains a zip code, split them
                const stateZipMatch = _address_state.match(/^(.+?)\s*(\d{5})?$/);
                if (stateZipMatch) {
                    _address_state = stateZipMatch[1];
                    _address_zip = stateZipMatch[2] || '';
                }


            }

            if (_address_line === ""){
                //try getting it from the formatted address
                try {
                    const _f_address_pieces = viewPlaceInfoPlace.formatted_address.split(',');
                    if (_f_address_pieces[0] !== ""){
                        _address_line = _f_address_pieces[0].trim();
                    }
                } catch (e){}
            }

            console.log("the _address_line : "+_address_line);
            console.log("the _address_city : "+_address_city);
            console.log("the _address_state : "+_address_state);
            console.log("the _address_zip: "+_address_zip);
            console.log("the _address_country: "+_address_country);


            _activity_location.name =  viewPlaceInfoPlace["name"];
            _activity_location.formatted_address = viewPlaceInfoPlace["formatted_address"];
            _activity_location.address = _address_line;
            _activity_location.city = _address_city;
            _activity_location.state = _address_state;
            _activity_location.zip = _address_zip;
            _activity_location.country = _address_country;

            _new_activity.location = _activity_location;

            const _photos_array = [];
            if (viewPlaceInfoPlace.photos && Array.isArray(viewPlaceInfoPlace.photos)) {
                for (const _photo of viewPlaceInfoPlace.photos) {
                    _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                }
            }
            _new_activity.photos = _photos_array;

            set_PA_GlobalObject((prev) => ({
                Planning: {
                    ...prev.Planning, // Retain all existing fields in Planning
                    activities: [...prev.Planning.activities, _new_activity],
                },
            }));

        } else if (option === "Meals"){
            setAnchorEl(null); // Close the menu

            //get details about the Place (viewPlaceInfoPlace)
//            console.log("the Meal viewPlaceInfoPlace : "+JSON.stringify(viewPlaceInfoPlace, null, '\t'));

            //create the new Meal
            const _new_meal = new Meal();

            _new_meal.phone = viewPlaceInfoPlace["formatted_phone_number"]

            //add any of the Event attendees to the meal
            const attendees = usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees;

            if (attendees.length > 0) {
                _new_meal.attendees = attendees
                    .filter((att) => att.role !== 'planner') // Exclude attendees with planner: true
                    .map((att) => ({
                        id: att.id,
                        cost: 0,
                        is_business_expense: false,
                        business_expense_amount_authorized: 0,
                        business_expense_amount_authorized_by: "",
                        business_expense_amount_authorized_timestamp: 0,
                        business_expense_amount: 0,
                        business_expense_paid: 0,
                        business_expense_reference_id: "",
                    }));
            }

            //set the Place object from Google
            _new_meal.Place = viewPlaceInfoPlace;

            //add the meal location
            const _place_location = viewPlaceInfoPlace["geometry"];

            const _meal_location = new PALocation();
            _meal_location.google_place_id = viewPlaceInfoPlace["place_id"];
            _meal_location.latitude = _place_location.location.lat();
            _meal_location.longitude = _place_location.location.lng();


            let _address_line = "";
            let _address_city = "";
            let _address_state = "";
            let _address_zip = "";
            let _address_country = "";

            let _route_exists = false;
            let _country_code = "";
            if (Array.isArray(viewPlaceInfoPlace?.address_components)) {
                for (const component of viewPlaceInfoPlace.address_components) {
                    if (component.types.includes("route")) {
                        _route_exists = true;
                    } else if (component.types.includes("country")) {
                        _country_code = component.short_name;
                    }
                }
            } else {
                console.error('address_components is not iterable or not an array');
            }

            if (Array.isArray(viewPlaceInfoPlace?.address_components)) {
                viewPlaceInfoPlace.address_components.forEach((component) => {
                    if (component.types.includes("street_number")){
                        _address_line = component.long_name;
                    } else if (component.types.includes("route")){
                        _address_line += " "+component.long_name;
                    } else if (!_route_exists && component.types.includes("administrative_area_level_2")){
                        _address_line += " "+component.long_name;
                    } else if (component.types.includes("locality") || component.types.includes("postal_town")){
                        _address_city = component.long_name;
                    } else if (component.types.includes("administrative_area_level_1") && _country_code === "US"){
                        _address_state = component.short_name;
                    } else if (component.types.includes("postal_code")){
                        _address_zip = component.long_name;
                    } else if (component.types.includes("country")){
                        if (_country_code !== "US"){
                            _address_state = component.long_name;
                        }
                        _address_country = component.short_name;
                    }
                });
            }

            if (_address_line === ""){
                //try getting it from the formatted address
                try {
                    const _f_address_pieces = viewPlaceInfoPlace.formatted_address.split(',');
                    if (_f_address_pieces[0] !== ""){
                        _address_line = _f_address_pieces[0].trim();
                    }
                } catch (e){}
            }

            console.log("the _address_line : "+_address_line);
            console.log("the _address_city : "+_address_city);
            console.log("the _address_state : "+_address_state);
            console.log("the _address_zip: "+_address_zip);
            console.log("the _address_country: "+_address_country);


            _meal_location.name =  viewPlaceInfoPlace["name"];
            _meal_location.formatted_address = viewPlaceInfoPlace["formatted_address"];
            _meal_location.address = _address_line;
            _meal_location.city = _address_city;
            _meal_location.state = _address_state;
            _meal_location.zip = _address_zip;
            _meal_location.country = _address_country;

            _new_meal.location = _meal_location;

            _new_meal.title = viewPlaceInfoPlace["name"];

            const _photos_array = [];
            if (viewPlaceInfoPlace.photos && Array.isArray(viewPlaceInfoPlace.photos)) {
                for (const _photo of viewPlaceInfoPlace.photos) {
                    _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                }
            }
            _new_meal.photos = _photos_array;

            set_PA_GlobalObject((prev) => ({
                Planning: {
                    ...prev.Planning, // Retain all existing fields in Planning
                    meals: [...prev.Planning.meals, _new_meal],
                },
            }));

        } else if (option === "Ideas"){
            setAnchorEl(null); // Close the menu

            //get details about the Place (viewPlaceInfoPlace)
            console.log("the Idea viewPlaceInfoPlace : "+JSON.stringify(viewPlaceInfoPlace, null, '\t'));

            //create the new Idea
            const _new_idea = new Idea();
            _new_idea.title = viewPlaceInfoPlace["name"]
            _new_idea.phone = viewPlaceInfoPlace["formatted_phone_number"]

            //set the Place object from Google
            _new_idea.Place = viewPlaceInfoPlace;

            //add the activity location
            const _place_location = viewPlaceInfoPlace["geometry"];

            const _idea_location = new PALocation();
            _idea_location.google_place_id = viewPlaceInfoPlace["place_id"];
            _idea_location.latitude = _place_location.location.lat();
            _idea_location.longitude = _place_location.location.lng();


            let _address_line = "";
            let _address_city = "";
            let _address_state = "";
            let _address_zip = "";
            let _address_country = "";

            let _route_exists = false;
            let _country_code = "";
            for (const component of viewPlaceInfoPlace.address_components){
                if (component.types.includes("route")) {
                    _route_exists = true;
                } else if (component.types.includes("country")){
                    _country_code = component.short_name;
                }
            }

            viewPlaceInfoPlace.address_components.forEach((component) => {
                if (component.types.includes("street_number")){
                    _address_line = component.long_name;
                } else if (component.types.includes("route")){
                    _address_line += " "+component.long_name;
                } else if (!_route_exists && component.types.includes("administrative_area_level_2")){
                    _address_line += " "+component.long_name;
                } else if (component.types.includes("locality") || component.types.includes("postal_town")){
                    _address_city = component.long_name;
                } else if (component.types.includes("administrative_area_level_1") && _country_code === "US"){
                    _address_state = component.short_name;
                } else if (component.types.includes("postal_code")){
                    _address_zip = component.long_name;
                } else if (component.types.includes("country")){
                    if (_country_code !== "US"){
                        _address_state = component.long_name;
                    }
                    _address_country = component.short_name;
                }
            });

            if (_address_line === ""){
                //try getting it from the formatted address
                try {
                    const _f_address_pieces = viewPlaceInfoPlace.formatted_address.split(',');
                    if (_f_address_pieces[0] !== ""){
                        _address_line = _f_address_pieces[0].trim();
                    }
                } catch (e){}
            }

            console.log("the _address_line : "+_address_line);
            console.log("the _address_city : "+_address_city);
            console.log("the _address_state : "+_address_state);
            console.log("the _address_zip: "+_address_zip);
            console.log("the _address_country: "+_address_country);


            _idea_location.name =  viewPlaceInfoPlace["name"];
            _idea_location.formatted_address = viewPlaceInfoPlace["formatted_address"];
            _idea_location.address = _address_line;
            _idea_location.city = _address_city;
            _idea_location.state = _address_state;
            _idea_location.zip = _address_zip;
            _idea_location.country = _address_country;

            _new_idea.location = _idea_location;

            const _photos_array = [];
            if (viewPlaceInfoPlace.photos && Array.isArray(viewPlaceInfoPlace.photos)) {
                for (const _photo of viewPlaceInfoPlace.photos) {
                    _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                }
            }
            _new_idea.photos = _photos_array;

            set_PA_GlobalObject((prev) => ({
                Planning: {
                    ...prev.Planning, // Retain all existing fields in Planning
                    ideas: [...prev.Planning.ideas, _new_idea],
                },
            }));

        }

        props.setCurrentFeature(_set_feature);

        navigate(_action);
        props.setShowEventItemConfig(true);


    };

    function getRandomShadeOfBlack() {
        console.log("getRandomShadeOfBlack");
        // Generate a random value for saturation and lightness
        const randomSaturation = Math.floor(Math.random() * 20) + 80; // Random value between 80 and 100
        const randomLightness = Math.floor(Math.random() * 20) + 10; // Random value between 10 and 30

        // Convert HSL values to a CSS color string
        return `hsl(0, ${randomSaturation}%, ${randomLightness}%)`;
    }

    const [column1Width, setColumn1Width] = useState(0);
    const column1Ref = useRef(null);
    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                // Identify the column being observed and update the corresponding state
                if (entry.target === column1Ref.current) {
                    setColumn1Width(entry.contentRect.width);
                }
            }
        });

        if (column1Ref.current) observer.observe(column1Ref.current);

        return () => {
            if (column1Ref.current) observer.unobserve(column1Ref.current);
        };

    }, []);



    return (

        <div style={{ position:'relative'}}>

            {/* add the layers icon */}
            <div className="planning-map-mobile-area-layers-icon-holder">
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
                >
                    <Badge
                        badgeContent={mapMarkerLayersCount}
                        color="badge"
                    >
                        <IconButton
                            type="button"
                            sx={{
                                p: '10px',
                            }}
                            aria-label="map layers for Markers"
                            onClick={handleButtonOpenLayersMenuClick}
                        >
                            <i className="icon-layers" title="Marker Layers"/>
                        </IconButton>
                    </Badge>
                    <Menu
                        id="split-button-menu"
                        anchorEl={anchorLayersEl}
                        open={Boolean(anchorLayersEl)}
                        onClose={handleButtonLayersMenuClose}

                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

                    >
                        <MenuList dense
                                  sx={{cursor:'default'}}
                        >
                            {
                                Object.keys(mapMarkerLayers).length > 0 ? (
                                    mapMarkerLayers.map((layer, index) => (
                                        <div key={index}>
                                            <ListItem
                                                key={index}
                                                sx={{
                                                    cursor: 'default',
                                                    padding: '0 8px',
                                                    alignItems: 'center',
                                                    backgroundColor: 'rgba(198, 226, 255, 0.8)',
                                                    display: 'flex' // ✅ Ensures everything inside is flex-aligned
                                                }}
                                                disableRipple
                                                onClick={null} // Prevents the entire ListItem from being clickable
                                            >
                                                {/* Icon: Number of Markers */}
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 'auto', // ✅ Removes extra left padding
                                                        fontSize: '14px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center', // ✅ Ensures text stays centered
                                                        marginRight: '8px' // ✅ Adds spacing between number & label
                                                    }}
                                                >
                                                    <Typography variant="body2">{layer['markers'].length}</Typography> {/* ✅ Ensures correct text alignment */}
                                                </ListItemIcon>

                                                {/* Layer Name */}
                                                <ListItemText
                                                    sx={{
                                                        fontSize: '14px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginRight: '8px' // ✅ Keeps spacing even
                                                    }}
                                                >
                                                    {
                                                        layer.name==='Activit'?
                                                            layer['markers'].length===1?'Activity':'Activities'
                                                            :
                                                            layer['markers'].length===1 ? layer.name:layer.name+'s'
                                                    }
                                                </ListItemText>

                                                {/* Visibility Checkbox */}
                                                <Checkbox
                                                    edge="end"
                                                    checked={layer.visible}
                                                    sx={{ display: 'flex', alignItems: 'center' }} // ✅ Centers Checkbox
                                                    inputProps={{ 'aria-labelledby': "Toggle Layer" }}
                                                    onChange={() => toggleLayerVisibility(layer.name)}
                                                    onClick={(e) => e.stopPropagation()} // Prevent ListItem from handling the click
                                                />
                                            </ListItem>
                                            {/* Show individual markers for this layer (if applicable) */}
                                            <MenuList dense>
                                                {layer['markers'].map((markerData, markerMarkerIndex) => {
                                                    const uniqueMarkerKey = `${index}-${markerMarkerIndex}`; // Unique key to prevent conflicts
                                                    return (
                                                        <ListItem
                                                            key={uniqueMarkerKey}
                                                            sx={{ cursor: 'default', padding:'0 8px', alignItems: 'center', display: 'flex' }}
                                                            disableRipple
                                                            onClick={null} // Prevents ListItem from handling clicks
                                                        >
                                                            {/* Marker Title */}
                                                            <ListItemText>
                                                                <Typography variant="caption" noWrap>
                                                                    {markerData.title}
                                                                </Typography>
                                                            </ListItemText>
                                                        </ListItem>
                                                    );
                                                })}
                                            </MenuList>

                                        </div>
                                    ))
                                ):(
                                    <MenuItem>
                                        <ListItemText>No Layers</ListItemText>
                                    </MenuItem>
                                )

                            }
                        </MenuList>
                    </Menu>
                </Paper>
            </div>

            {/* add the map */}
            <div
                ref={column1Ref}
                className="planning-map-mobile-area-google-map-holder"
                id="global_google_map_holder"
                style={{ width: '100%', height: 'calc(100vh - 265px)' }}
            />

            {/* add the place info panel */}
            <div
                className="planning-map-mobile-area-place-info-container"
                style={{display: viewPlaceInfoWindow ? 'block' : 'none'}}
            >
                <Paper
                    sx={{ p: '10px', display: 'flex', alignItems: 'center' }}
                >
                    {
                        viewPlaceInfoPlace && viewPlaceInfoWindow ? (
                            <div className="planning-map-mobile-area-place-info-holder" >
                                {/* add the header */}
                                <div className="planning-map-area-place-info-name"><strong>{viewPlaceInfoPlace.name}</strong></div>
                                {/* add the description */}

                                {/* add the Chip to add to a particular area */}
                                <div className="planning-map-area-place-info-button-holder">
                                    <Chip
                                        sx={cancelChipStyle}
                                        label="Close"
                                        size="small"
                                        onClick={handleButtonCloseClick}
                                    />
                                    <Chip
                                        sx={updateChipStyle}
                                        label="Add"
                                        size="small"
                                        onClick={handleButtonOpenMenuClick}
                                    />
                                    <Menu
                                        id="split-button-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleButtonCloseMenuClick}

                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

                                    >
                                        <MenuList dense >
                                            <MenuItem onClick={() => handleMenuItemClick('Activities')}>Add to Activities</MenuItem>
                                            <MenuItem onClick={() => handleMenuItemClick('Ideas')}>Add to Ideas</MenuItem>
                                            <MenuItem onClick={() => handleMenuItemClick('Lodging')}>Add to Lodging</MenuItem>
                                            <MenuItem onClick={() => handleMenuItemClick('Meals')}>Add to Meals</MenuItem>
                                            <MenuItem onClick={() => handleMenuItemClick('Shopping')}>Add to Shopping</MenuItem>
                                            <MenuItem onClick={() => handleMenuItemClick('Planning')}>Add to Concierge Planning</MenuItem>
                                        </MenuList>

                                    </Menu>
                                </div>

                                {/* add the address */}
                                <div className="planning-map-area-place-info-address">{viewPlaceInfoPlace.formatted_address}</div>

                                {/* add any images */}
                                {
                                    viewPlaceInfoPlace.photos && viewPlaceInfoPlace.photos.length > 0 ? (
                                        <div style={{ width: '100%', display: 'flex', overflowX: 'scroll' }}>
                                            {
                                                viewPlaceInfoPlace['photos'].map((_photo, index) => (
                                                    <CardMedia
                                                        key={index}
                                                        component="img"
                                                        sx={{marginRight: '10px', marginBottom: '5px', borderRadius: '5px', maxHeight:'150px'}}
                                                        alt={`Attribution ${index + 1}`}
                                                        image={_photo.getUrl({maxWidth: 150, maxHeight: 150})} // Use the attribution as the image source
                                                    />
                                                ))
                                            }
                                        </div>
                                    ):(
                                        <></>
                                    )
                                }

                                {/* add the reviews */}
                                <div className="planning-map-area-place-info-rating-holder">
                                    {
                                        viewPlaceInfoPlace.rating ? (
                                            <Rating
                                                sx={{flex: '1'}}
                                                name="read-only"
                                                value={viewPlaceInfoPlace.rating}
                                                precision={0.1}
                                                readOnly />
                                        ) : null
                                    }
                                    {
                                        viewPlaceInfoPlace.user_ratings_total ? (
                                            <Typography
                                                sx={{
                                                    fontSize:'12px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                                component="legend"
                                            >
                                                {viewPlaceInfoPlace.user_ratings_total} review{viewPlaceInfoPlace.user_ratings_total===1?'':'s'}
                                            </Typography>
                                        ) : null
                                    }
                                </div>
                                {/* add the price level */}

                                {/* add the types chips */}
                                {
                                    viewPlaceInfoPlace.types && viewPlaceInfoPlace.types.length > 0 ? (
                                        <div className="planning-map-area-place-info-type-holder">
                                            {viewPlaceInfoPlace.types.map((item, index) => (
                                                item !== 'point_of_interest' && item !== 'establishment' ? (
                                                    <Chip
                                                        key={index}
                                                        label={item}
                                                        size='small'
                                                    />
                                                ) : (
                                                    <div key={index}></div>
                                                )

                                            ))
                                            }
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }
                                {/* add the hours */}


                                {/* add the website */}
                                {
                                    viewPlaceInfoPlace.website && viewPlaceInfoPlace.website !== "" ? (
                                        <span
                                            className="planning-map-area-place-info-website-holder"
                                            onClick={() => {window.open(viewPlaceInfoPlace.website, "_blank")}}
                                        >
                                            Website
                                        </span>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                        ) : (
                            <></>
                        )

                    }
                </Paper>
            </div>

        </div>
    )

}

export default GoogleMapMobile;



