import React, {useEffect, useState} from 'react';

import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Chip,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Menu,
    MenuItem,
    MenuList,
    Paper
} from "@mui/material";
import Grid from '@mui/material/Grid2';

import ListItemText from "@mui/material/ListItemText";
import {useNavigate} from "react-router-dom";

import lodgingHeaderImage from "../../../graphics/th-513411954.jpg";
import Typography from "@mui/material/Typography";
import './activities.css';
import ActivitiesCard from "./ActivitiesCard";
import dayjs from "dayjs";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import Box from "@mui/material/Box";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";
import InfoActivities from "../../Dialogs/InfoActivities";



function ActivitiesList(props) {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const [eventActivities, setEventActivities] = useState([]);
    const [filteredActivities, setFilteredActivities] = useState([]);

    const [viewType, setViewType] = useState(
        usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0 ?
            'dates'
            : usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0 ?
                'days' : 'dates'); // Either 'dates' or 'days'
    const [eventHasDates, setEventHasDates] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0);
    const [eventHasDays, setEventHasDays] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0);
    const [selectedDate, setSelectedDate] = useState('all'); // For selected date
    const [selectedDay, setSelectedDay] = useState('all'); // For selected day

    // Toggle visibility of dates or days chips
    const handleToggleViewType = () => {
        console.log("handleToggleViewType : " + viewType);

        const planning = usePA_GlobalStore.getState().PA_GlobalObject.Planning;
        const eventDays = planning.time_table?.days || [];
        const eventDates = planning.time_table?.dates || [];

        if (viewType === 'dates') {
            console.log("Changing Chips to days...");

            if (selectedDate === 'all') {
                setSelectedDay('all'); // Show all meals if "all" was selected
            } else {
                // Find the corresponding day for the selected date
                const correspondingDay = eventDates.find(dateEntry => dateEntry.date === selectedDate);
                if (correspondingDay) {
                    const dayIndex = eventDates.indexOf(correspondingDay);
                    const correspondingDayKey = Object.keys(eventDays[dayIndex] || {})[0] || 'all';
                    setSelectedDay(correspondingDayKey);
                } else {
                    setSelectedDay('all'); // Fallback
                }
            }

            setViewType('days');

        } else if (viewType === 'days') {
            console.log("Changing Chips to dates...");

            if (selectedDay === 'all') {
                setSelectedDate('all'); // Show all meals if "all" was selected
            } else {
                // Find the corresponding date for the selected day
                const correspondingDayEntry = eventDays.find(dayEntry => Object.keys(dayEntry)[0] === selectedDay);
                if (correspondingDayEntry) {
                    const dayIndex = eventDays.indexOf(correspondingDayEntry);
                    const correspondingDate = eventDates[dayIndex]?.date || 'all';
                    setSelectedDate(correspondingDate);
                } else {
                    setSelectedDate('all'); // Fallback
                }
            }

            setViewType('dates');
        }
    };

    const [anchorActivitiesListMenu, setAnchorActivitiesListMenu] = useState(null);
    const handleActivitiesListMenuOpenMenu = (event) => {
        setAnchorActivitiesListMenu(event.currentTarget);
    };
    const handleActivitiesListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorActivitiesListMenu(event.currentTarget);

        if (dataId === "new-entry"){
            navigate('/Planner/Activities/Add');
        }
    };
    const handleActivitiesListMenuClose = () => {
        setAnchorActivitiesListMenu(null);
    };
    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }

    useEffect(() => {
        console.log("Planning.activities : ", usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities);

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities !== eventActivities) {
            setEventActivities([...usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities]);
            setEventHasDates(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0);
            setEventHasDays(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0);

        }

    }, [PA_GlobalObject.Planning.activities]);
    useEffect(() => {
        console.log("eventActivities triggered again: ", eventActivities);

        if ((viewType === 'dates' && selectedDate === 'all') || (viewType === 'days' && selectedDay === 'all')){
            handleActivityChipAllClick();
        }

    }, [eventActivities]);

    function handleActivityChipAllClick(){
        console.log("handleActivityChipAllClick");

        let filtered = [...eventActivities];

        // Sort meals by the begin time (if available)
        filtered = filtered.sort((a, b) => {
            // Extract begin_time for both meals, defaulting to '00:00' if not found
            const timeA = a.time_table.dates?.[0]?.begin_time || a.time_table.days?.[0]?.begin_time || '00:00';
            const timeB = b.time_table.dates?.[0]?.begin_time || b.time_table.days?.[0]?.begin_time || '00:00';

            // Extract the date or day (if date is present, use it, otherwise use a placeholder date with the day's index)
            const dateA = a.time_table.dates?.[0]?.date
                || (Array.isArray(a.time_table.days) && a.time_table.days[0]
                    ? dayjs().day(Object.keys(a.time_table.days[0])[0].replace('day ', '')).format('YYYY-MM-DD')
                    : null);
            const dateB = b.time_table.dates?.[0]?.date
                || (Array.isArray(b.time_table.days) && b.time_table.days[0]
                    ? dayjs().day(Object.keys(b.time_table.days[0])[0].replace('day ', '')).format('YYYY-MM-DD')
                    : null);
//            const dateA = a.time_table.dates?.[0]?.date || dayjs().day(Object.keys(a.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');
//            const dateB = b.time_table.dates?.[0]?.date || dayjs().day(Object.keys(b.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');

            // Combine date and time for comparison
            const dateTimeA = dayjs(`${dateA} ${timeA}`, 'YYYY-MM-DD HH:mm A');
            const dateTimeB = dayjs(`${dateB} ${timeB}`, 'YYYY-MM-DD HH:mm A');

            // Compare the combined date and time
            return dateTimeA.diff(dateTimeB);
        });

        setFilteredActivities(filtered);
    }
    function handleActivityChipDateClick(_activity_date){
        console.log("handleActivityChipDateClick : "+_activity_date);

        if (viewType !== "dates"){
            setViewType("dates");
        }
        setSelectedDate(_activity_date);

        let filtered = [];
        filtered = eventActivities.filter(activity =>
            activity.time_table?.dates?.length > 0 && activity.time_table.dates[0].date === _activity_date
        );
        // Sort meals by the begin time (if available)
        filtered = filtered.sort((a, b) => {
            // Extract begin_time for both meals, defaulting to '00:00' if not found
            const timeA = a.time_table.dates?.[0]?.begin_time || '00:00';
            const timeB = b.time_table.dates?.[0]?.begin_time || '00:00';

            // Extract the date or day (if date is present, use it, otherwise use a placeholder date with the day's index)
            const dateA = a.time_table.dates?.[0]?.date;
            const dateB = b.time_table.dates?.[0]?.date;

            // Combine date and time for comparison
            const dateTimeA = dayjs(`${dateA} ${timeA}`, 'YYYY-MM-DD HH:mm A');
            const dateTimeB = dayjs(`${dateB} ${timeB}`, 'YYYY-MM-DD HH:mm A');

            // Compare the combined date and time
            return dateTimeA.diff(dateTimeB);
        });

        console.log("filtered : ", filtered);

        setFilteredActivities(filtered);
    }
    function handleActivityChipDayClick(_activity_day){
        console.log("handleActivityChipDayClick : "+_activity_day);

        if (viewType !== "days"){
            setViewType("days");
        }
        setSelectedDay(_activity_day);

        let filtered = [];
        filtered = eventActivities.filter(activity =>
            activity.time_table?.days?.length > 0 && Object.keys(activity.time_table.days) === _activity_day
        );
        // Sort meals by the begin time (if available)
        filtered = filtered.sort((a, b) => {
            // Extract begin_time for both meals, defaulting to '00:00' if not found
            const timeA = a.time_table.days?.[0]?.begin_time || '00:00';
            const timeB = b.time_table.days?.[0]?.begin_time || '00:00';

            // Extract the date or day (if date is present, use it, otherwise use a placeholder date with the day's index)
            const dateA = dayjs().day(Object.keys(a.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');
            const dateB = dayjs().day(Object.keys(b.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');

            // Combine date and time for comparison
            const dateTimeA = dayjs(`${dateA} ${timeA}`, 'YYYY-MM-DD HH:mm A');
            const dateTimeB = dayjs(`${dateB} ${timeB}`, 'YYYY-MM-DD HH:mm A');

            // Compare the combined date and time
            return dateTimeA.diff(dateTimeB);
        });
        console.log("filtered : "+JSON.stringify(filtered, null, '\t'));

        setFilteredActivities(filtered);
    }

    const updateActivityDetails = (updatedActivity) => {
        // Find the index of the updated Activity in eventActivities
        const activityIndex = eventActivities.findIndex(
            (activity) => activity.id === updatedActivity.id || activity.temp_id === updatedActivity.temp_id
        );

        console.log("trying to update Activity index : "+activityIndex);

        if (activityIndex !== -1) {
            // If the activity is found, update it in the eventActivities array
            const updatedEventActivitiesArray = [...eventActivities];
            updatedEventActivitiesArray[activityIndex] = updatedActivity;

            //this updates the meal at the Global level
            set_PA_GlobalObject((prev) => ({
                Planning: {
                    ...prev.Planning, // Retain all existing fields in Planning
                    activities: updatedEventActivitiesArray, // Update only the title
                },
            }));

            console.log("updatedEventActivities");
        }

    };

    const [activityToDelete, setActivityToDelete] = useState(null);  // Holds the activity to delete for confirmation dialog
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);  // Controls the confirmation dialog visibility
    const handleDeleteActivity = (activityId) => {
        // Remove the activity with the given ID
        const updatedActivities = eventActivities.filter(activity => activity.id !== activityId);

        // You can call a function to persist this change or update parent state
        setEventActivities(eventActivities);


        //this updates the activities array at the Global level
        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                activities: updatedActivities, // Update only the title
            },
        }));

        closeDeleteDialog();

    };
    const openDeleteDialog = (meal) => {
        setActivityToDelete(meal);  // Set the meal to be deleted
        setDeleteDialogOpen(true);  // Open the confirmation dialog
    };
    const closeDeleteDialog = () => {
        setActivityToDelete(null);  // Reset the meal to be deleted
        setDeleteDialogOpen(false);  // Close the confirmation dialog
    };

    const [isActivityInfoDialogOpen, setActivityInfoDialogOpen] = useState(false);


    return (

        <div className="activities-list-container" >

            {/* show the header */}
            <div
                className="activities-list-header"
            >
                <div className="activities-list-header-text">
                    <h5>Activities</h5>
                </div>

                <div className="activities-list-header-info-icon-holder">
                    <i
                        className="icon-info-circle"
                        style={{fontSize: '20px', padding: '5px'}}
                        onClick={() => setActivityInfoDialogOpen(!isActivityInfoDialogOpen)}
                    />
                </div>

                <div className="activities-list-header-controls">
                    {/* Toggle for Dates/Days */}
                    <Box sx={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
                        {/* Icon Button for Dates */}
                        <IconButton
                            type="button"
                            sx={{
                                padding: '3px',
                                backgroundColor: viewType === 'dates' ? '#FFF' : '',
                                boxShadow: '' +
                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                border: '1px solid #0096ff',
                                borderRadius: '10px',
                                textAlign: 'center',
                                color: 'black', // Text color (adjust as needed)
                                '&:hover': {
                                    background: '#cbe0f7', // Reverse the gradient on hover
                                },
                            }}
                            aria-label="toggle to dates"
                            onClick={handleToggleViewType}
                        >
                            <Typography variant="caption">Dates</Typography>
                        </IconButton>

                        {/* Divider between buttons */}
                        <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>

                        {/* Icon Button for Days */}
                        <IconButton
                            type="button"
                            sx={{
                                padding: '3px',
                                backgroundColor: viewType === 'days' ? '#FFF' : '',
                                boxShadow: '' +
                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                border: '1px solid #0096ff',
                                borderRadius: '10px',
                                textAlign: 'center',
                                color: 'black', // Text color (adjust as needed)
                                '&:hover': {
                                    background: '#cbe0f7', // Reverse the gradient on hover
                                },
                            }}
                            aria-label="toggle to days"
                            onClick={handleToggleViewType}
                        >
                            <Typography variant="caption">Days</Typography>
                        </IconButton>
                    </Box>
                    {/* Menu Chip */}
                    <Chip
                        sx={menuChipStyle}
                        label={<i className="icon-menu2" style={{fontSize: '16px'}}/>}
                        size="small"
                        onClick={handleActivitiesListMenuOpenMenu}
                    />
                    <Menu
                        sx={{width: 320, maxWidth: '100%'}}
                        anchorEl={anchorActivitiesListMenu}
                        id="library-menu"
                        open={Boolean(anchorActivitiesListMenu)}
                        onClose={handleActivitiesListMenuClose}
                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuList dense sx={{width: 320, maxWidth: '100%'}}>
                            <MenuItem data-id="new-entry" onClick={handleActivitiesListMenuClick}>
                                <ListItemText>New Entry</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>


            {/* show the Event dates/days */}
            <div className="activities-list-timetable-list-container">
                {/* "All" Chip to show all activities */}
                {
                    eventHasDates || eventHasDays ? (
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'inline-block',
                                marginTop: '5px',
                                marginRight: '10px',
                            }}
                        >
                            <Chip
                                label="All"
                                sx={viewType === 'all' ? selectedChipStyle : unselectedChipStyle}
                                style={{ margin: '0.2rem', height: '40px' }}
                                onClick={handleActivityChipAllClick}

                            />
                        </Box>
                    ) : (
                        <></>
                    )
                }


                {
                    (
                        viewType === 'all' || viewType === 'dates') && eventHasDates ? (
                        usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table'].dates.map((eventDateObj) => {
                            const eventDate = eventDateObj.date;
                            const formattedDay = dayjs(eventDate, 'YYYY-MM-DD').format('ddd');
                            const formattedDate = dayjs(eventDate, 'YYYY-MM-DD').format('M/D');

                            // Filter activities for this specific date
                            const activitiesForDate = eventActivities.filter(activity =>
                                activity.time_table?.dates?.length > 0 && activity.time_table.dates[0].date === eventDate
                            );
                            const activityCount = activitiesForDate.length;

                            return (
                                <Box
                                    key={`activity-${eventDate}`}
                                    sx={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        marginTop: '5px',
                                        marginRight: '10px',
                                    }}
                                >
                                    <Chip
                                        label={
                                            <div style={{textAlign: 'center'}}>
                                                <Typography variant="body2">{formattedDay}</Typography>
                                                <Typography variant="caption">{formattedDate}</Typography>
                                            </div>
                                        }
                                        sx={viewType === 'dates' && selectedDate === eventDate ? selectedChipStyle : unselectedChipStyle}
                                        style={{margin: '0.2rem', height: '40px'}}
                                        onClick={() => handleActivityChipDateClick(eventDate)}
                                    />
                                    {/* Badge or warning icon */}
                                    {activityCount > 0 ? (
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: '-5px',
                                                right: '-5px',
                                                backgroundColor: 'white',
                                                color: 'green',
                                                borderRadius: '35%',
                                                width: '15px',
                                                height: '15px',
                                                fontSize: '12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 1,
                                                border: '1px solid',
                                                fontWeight: '700',
                                            }}
                                        >
                                {activityCount}
                            </span>
                                    ) : (
                                        <i
                                            className="icon-warning"
                                            style={{
                                                position: 'absolute',
                                                top: '-5px',
                                                right: '-5px',
                                                fontSize: '10px',
                                                color: 'red',
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                padding: '3px',
                                                zIndex: 1,
                                            }}
                                        />
                                    )}
                                </Box>
                            );
                        })
                    ) : (viewType === 'all' || viewType === 'days') && eventHasDays ? (
                        usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table'].days.map((dayObj) => {
                            const dayKey = Object.keys(dayObj).find(k => k.startsWith('day '));
                            const dayName = dayObj[dayKey];

                            console.log("counting activities for : "+dayKey);

                            // Filter activities for this specific day
                            const activitiesForDay = eventActivities.filter(activity => {
                                return activity.time_table?.days?.some(dayObj => {
                                    return Object.entries(dayObj).some(([key, value]) => {
                                        return key === dayKey && value === dayName;
                                    });
                                });
                            });

                            const activityCount = activitiesForDay.length;

                            console.log("activityCount : ", activityCount);

                            return (
                                <Box
                                    key={`activity-${dayKey}`}
                                    sx={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        marginTop: '5px',
                                        marginRight: '10px',
                                    }}
                                >
                                    <Chip
                                        label={
                                            <div style={{textAlign: 'center'}}>
                                                <Typography variant="body2">{dayName.slice(0, 3)}</Typography>
                                                <Typography
                                                    variant="caption">{capitalizeFirstLetter(dayKey)}</Typography>
                                            </div>
                                        }
                                        sx={viewType === 'days' && selectedDay === dayKey ? selectedChipStyle : unselectedChipStyle}
                                        style={{margin: '0.2rem', height: '40px'}}
                                        onClick={() => handleActivityChipDayClick(dayKey)}
                                    />
                                    {/* Badge or warning icon */}
                                    {activityCount > 0 ? (
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: '-5px',
                                                right: '-5px',
                                                backgroundColor: 'white',
                                                color: 'green',
                                                borderRadius: '35%',
                                                width: '15px',
                                                height: '15px',
                                                fontSize: '12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 1,
                                                border: '1px solid',
                                                fontWeight: '700',
                                            }}
                                        >
                                    {activityCount}
                                </span>
                                    ) : (
                                        <i
                                            className="icon-warning"
                                            style={{
                                                position: 'absolute',
                                                top: '-5px',
                                                right: '-5px',
                                                fontSize: '10px',
                                                color: 'red',
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                padding: '3px',
                                                zIndex: 1,
                                            }}
                                        />
                                    )}
                                </Box>
                            );
                        })
                    ) : (
                        <></>
                    )
                }
            </div>



            {/* show the list of activities */}
            <div
                className="activities-list-items-container"
                style={
                    !eventHasDates && !eventHasDays
                        ? {height: 'calc(100vh - 300px)'}
                        : {height: 'calc(100vh - 375px)'}
                }
            >

                {
                    filteredActivities.length > 0 ? (
                        filteredActivities
                            .map((activityDetails, index) => (

                                <Grid
                                    item
                                    key={`${activityDetails.id || activityDetails.temp_id}-${selectedDate || selectedDay}-${index}`} // Ensure key is unique to the current meal and date/day selection
                                    sx={{
                                        display: 'block',
                                        marginBottom: '10px'
                                    }}
                                >

                                    <ActivitiesCard
                                        activity={activityDetails}
                                        onDeleteActivity={() => openDeleteDialog(activityDetails)}
                                        _global_map_ref={props._global_map_ref}
                                    />

                                </Grid>

                            ))
                    ) : (
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{borderRadius: '5px', overflow: 'hidden'}}>
                                <Card>

                                    <CardMedia
                                        sx={{height: 200}}
                                        image={lodgingHeaderImage}
                                        title="Let's build your adventure!"
                                    />

                                    <CardContent sx={{padding: '24px'}}>
                                        {/* Section Title */}
                                        <Typography variant="h5" gutterBottom sx={{fontWeight: 600, color: '#1976d2'}}>
                                            🎉 No Activities Yet!
                                        </Typography>

                                        {/* Subhead */}
                                        <Typography variant="body1" sx={{color: 'text.secondary', mb: 3}}>
                                            Your event is just a few clicks away from being unforgettable.
                                        </Typography>

                                        {/* Section: Options */}
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>

                                            {/* From the Map */}
                                            <Box sx={{display: 'flex', alignItems: 'flex-start', gap: 2}}>
                                                <i className="icon-map-marker"
                                                   style={{fontSize: '24px', color: '#1976d2'}}/>
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{fontWeight: 500}}>
                                                        📍 Find it on the Map
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Search nearby spots — restaurants, museums, parks — and drop
                                                        them right into your itinerary.
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Manual Entry */}
                                            <Box sx={{display: 'flex', alignItems: 'flex-start', gap: 2}}>
                                                <i className="icon-edit" style={{fontSize: '24px', color: '#388e3c'}}/>
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{fontWeight: 500}}>
                                                        ✍️ Add It Manually
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Got something unique in mind? Just type in the details. Boom —
                                                        added.
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* AI from Receipt */}
                                            <Box sx={{display: 'flex', alignItems: 'flex-start', gap: 2}}>
                                                <i className="icon-receipt"
                                                   style={{fontSize: '24px', color: '#f57c00'}}/>
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{fontWeight: 500}}>
                                                        🤖 Upload a Receipt
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Drag in a PDF and let our AI Concierge extract the info. You
                                                        review before saving.
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Camping or Custom Location */}
                                            <Box sx={{display: 'flex', alignItems: 'flex-start', gap: 2}}>
                                                <i className="icon-compass2"
                                                   style={{fontSize: '24px', color: '#6a1b9a'}}/>
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{fontWeight: 500}}>
                                                        🏕️ Out in Nature?
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Drop a pin right where you are and call it home. Great for
                                                        camping, hiking, or hideaways.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>

                                        {/* Call to Action */}
                                        <Box sx={{mt: 4, textAlign: 'center'}}>
                                            <Typography variant="body2" sx={{fontStyle: 'italic', color: '#616161'}}>
                                                Every great event starts with one awesome Activity.
                                            </Typography>
                                            <Typography
                                                variant="button"
                                                sx={{
                                                    marginTop: '12px',
                                                    display: 'inline-block',
                                                    color: '#fff',
                                                    backgroundColor: '#1976d2',
                                                    padding: '8px 16px',
                                                    borderRadius: '8px',
                                                    cursor: 'pointer',
                                                    mt: 2,
                                                }}
                                                onClick={() => {
                                                    // Trigger your add activity action here
                                                }}
                                            >
                                                ➕ Add Your First Activity
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Paper>
                        </Grid>
                    )

                }
            </div>

            {/* Confirmation Dialog */}
            <Dialog
                open={isDeleteDialogOpen}
                onClose={closeDeleteDialog}
            >
            <DialogTitle>Delete Activity</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the activity "{activityToDelete?.title}"? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDeleteActivity(activityToDelete.id)} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            
            <InfoActivities
                isOpen={isActivityInfoDialogOpen}
                onClose={() => setActivityInfoDialogOpen(false)}
            />

        </div>
    )

}

export default ActivitiesList;
