import { useRef } from 'react';

export function useGoogleAutocompleteForAddress(onPlaceParsed) {
    const inputRefResidential = useRef(null);
    const autocompleteRefResidential = useRef(null);
    const hasInitialized = useRef(false);
    const pacContainerRef = useRef(null); // Ref for .pac-container

    const attachAutocompleteResidential = () => {
        console.log("attachAutocompleteResidential");
        if (!window.google || !window.google.maps || !inputRefResidential.current || hasInitialized.current) return;

        autocompleteRefResidential.current = new window.google.maps.places.Autocomplete(inputRefResidential.current, {
            types: ['address'],
        });

        autocompleteRefResidential.current.addListener('place_changed', () => {
            console.log("place_changed residential");
            const place = autocompleteRefResidential.current.getPlace();
            const _geometry = place.geometry;

            let _address_name = "";
            let _address_line = "";
            let _address_city = "";
            let _address_state = "";
            let _address_zip = "";
            let _address_country = "";

            let _route_exists = false;
            let _country_code = "";
            for (const component of place.address_components) {
                if (component.types.includes("route")) {
                    _route_exists = true;
                } else if (component.types.includes("country")) {
                    _country_code = component.short_name;
                }
            }

            place.address_components.forEach((component) => {
                if (component.types.includes("street_number")) {
                    _address_line = component.long_name;
                } else if (component.types.includes("route")) {
                    _address_line += " " + component.long_name;
                } else if (!_route_exists && component.types.includes("administrative_area_level_2")) {
                    _address_line += " " + component.long_name;
                } else if (component.types.includes("locality") || component.types.includes("postal_town")) {
                    _address_city = component.long_name;
                } else if (component.types.includes("administrative_area_level_1") && _country_code === "US") {
                    _address_state = component.short_name;
                } else if (component.types.includes("postal_code")) {
                    _address_zip = component.long_name;
                } else if (component.types.includes("country")) {
                    if (_country_code !== "US") {
                        _address_state = component.long_name;
                    }
                    _address_country = component.short_name;
                }
            });

            if (_address_line === "") {
                try {
                    const _f_address_pieces = place.formatted_address.split(',');
                    if (_f_address_pieces[0] !== "") {
                        _address_line = _f_address_pieces[0].trim();
                    }
                } catch (e) {}
            }

            onPlaceParsed(
                {
                    name: _address_name || '',
                    formatted_address: place.formatted_address || '',
                    address: _address_line || '',
                    city: _address_city || '',
                    state: _address_state || '',
                    zip: _address_zip || '',
                    country: _address_country || '',
                    latitude: _geometry?.location?.lat() || null,
                    longitude: _geometry?.location?.lng() || null,
                    google_place_id: place.place_id || '',
                },
                place
            );

            removePacContainerResidential();

        });

        // Capture the .pac-container element.
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.addedNodes) {
                    Array.from(mutation.addedNodes).forEach((node) => {
                        if (node.classList && node.classList.contains('pac-container')) {
                            pacContainerRef.current = node;
                        }
                    });
                }
            });
        });

        observer.observe(document.body, { childList: true, subtree: true });

        hasInitialized.current = true;

        // Cleanup the observer when the component unmounts.
        return () => {
            observer.disconnect();
            if(pacContainerRef.current){
                removePacContainerResidential();
            }
        };

    };

    const removePacContainerResidential = () => {
        if (pacContainerRef.current) {
            pacContainerRef.current.remove();
            pacContainerRef.current = null;
            hasInitialized.current = false;
        }
    };

    return {
        inputRefResidential,
        attachAutocompleteResidential,
        removePacContainerResidential,
    };
}

export function useGoogleAutocompleteForEstablishment(onPlaceParsed) {
    const inputRefEstablishment = useRef(null);
    const autocompleteRefEstablishment = useRef(null);
    const hasInitialized = useRef(false);
    const pacContainerRef = useRef(null); // Ref for .pac-container

    const attachAutocompleteEstablishment = () => {
        console.log("attachAutocompleteEstablishment");
        if (!window.google || !window.google.maps || !inputRefEstablishment.current || hasInitialized.current) return;

        autocompleteRefEstablishment.current = new window.google.maps.places.Autocomplete(inputRefEstablishment.current, {
            types: ['establishment'],
        });

        autocompleteRefEstablishment.current.addListener('place_changed', () => {
            console.log("place_changed establishment");
            const place = autocompleteRefEstablishment.current.getPlace();
            const _geometry = place.geometry;

            let _address_line = "";
            let _address_city = "";
            let _address_state = "";
            let _address_zip = "";
            let _address_country = "";

            let _route_exists = false;
            let _country_code = "";
            for (const component of place.address_components) {
                if (component.types.includes("route")) {
                    _route_exists = true;
                } else if (component.types.includes("country")) {
                    _country_code = component.short_name;
                }
            }

            place.address_components.forEach((component) => {
                if (component.types.includes("street_number")) {
                    _address_line = component.long_name;
                } else if (component.types.includes("route")) {
                    _address_line += " " + component.long_name;
                } else if (!_route_exists && component.types.includes("administrative_area_level_2")) {
                    _address_line += " " + component.long_name;
                } else if (component.types.includes("locality") || component.types.includes("postal_town")) {
                    _address_city = component.long_name;
                } else if (component.types.includes("administrative_area_level_1") && _country_code === "US") {
                    _address_state = component.short_name;
                } else if (component.types.includes("postal_code")) {
                    _address_zip = component.long_name;
                } else if (component.types.includes("country")) {
                    if (_country_code !== "US") {
                        _address_state = component.long_name;
                    }
                    _address_country = component.short_name;
                }
            });

            if (_address_line === "") {
                //try getting it from the formatted address
                try {
                    const _f_address_pieces = place.formatted_address.split(',');
                    if (_f_address_pieces[0] !== "") {
                        _address_line = _f_address_pieces[0].trim();
                    }
                } catch (e) {
                }
            }

            console.log("the _address_line : " + _address_line);
            console.log("the _address_city : " + _address_city);
            console.log("the _address_state : " + _address_state);
            console.log("the _address_zip: " + _address_zip);
            console.log("the _address_country: " + _address_country);

            const internationalPhone = place.international_phone_number || "";

            let phone_prefix = "";
            let phone = "";

            if (internationalPhone.startsWith("+")) {
                // Split on the first space only
                const firstSpaceIndex = internationalPhone.indexOf(" ");
                if (firstSpaceIndex !== -1) {
                    phone_prefix = internationalPhone.substring(0, firstSpaceIndex); // +1
                    phone = internationalPhone.substring(firstSpaceIndex + 1);       // 704-541-0106
                } else {
                    // fallback: no space, maybe just prefix
                    phone_prefix = internationalPhone;
                    phone = "";
                }
            }

            onPlaceParsed(
                {
                    google_place_id: place.place_id,
                    latitude: _geometry["location"].lat(),
                    longitude: _geometry["location"].lng(),

                    name: place.name,
                    formatted_address: place.formatted_address,
                    address: _address_line,
                    city: _address_city,
                    state: _address_state,
                    zip: _address_zip,
                    country: _address_country,
                    phone_prefix: phone_prefix,
                    phone: phone,

                    types: place.types,
                    hours: place['current_opening_hours'].weekday_text,
                    website: place.website,
                    photos: place.photos,
                    price_level: place.price_level,
                    rating: place.rating,
                    total_ratings: place.user_ratings_total
                },
                place);

            removePacContainerEstablishment();
        });

        // Capture the .pac-container element.
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.addedNodes) {
                    Array.from(mutation.addedNodes).forEach((node) => {
                        if (node.classList && node.classList.contains('pac-container')) {
                            pacContainerRef.current = node;
                        }
                    });
                }
            });
        });

        observer.observe(document.body, { childList: true, subtree: true });

        hasInitialized.current = true;

        // Cleanup the observer when the component unmounts.
        return () => {
            observer.disconnect();
            if(pacContainerRef.current){
                removePacContainerEstablishment();
            }
        };

    };

    const removePacContainerEstablishment = () => {
        if (pacContainerRef.current) {
            pacContainerRef.current.remove();
            pacContainerRef.current = null;
            hasInitialized.current = false;
        }
    };

    return {
        inputRefEstablishment,
        attachAutocompleteEstablishment,
        removePacContainerEstablishment,
    };
}