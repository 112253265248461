import React, { useState, useEffect, useContext } from "react";
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Switch,
    ListItemText,
    List,
    ListItem,
    Typography,
    Box,
    Chip,
    Card,
    CardContent,
    CardHeader, Tab, Divider, Button
} from "@mui/material";
import {DateCalendar, TimePicker} from "@mui/x-date-pickers";
import { PAContext } from "../../../Services/PAContext";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {addChipStyle} from "../../ButtonStyle/Add";
import {useNavigate} from "react-router-dom";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore"; // Context for attendees, push notifications
import dayjs from "dayjs";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import PAAttendee from "../../../MasterEventObjects/Attendee";

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]; // Weekday names
const getPriorityIconColor = (priority) => {
    switch (priority) {
        case "Low":
            return "#0F0"; // Replace with your desired color
        case "Medium":
            return "#b8ab10"; // Replace with your desired color #b8ab10
        case "High":
            return "#f09637"; // Replace with your desired color
        case "Urgent":
            return "#F00"; // Replace with your desired color
        default:
            return "#151269"; // Default color if priority is not recognized
    }
};

function TodoAddNew() {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Account, Attendees, SampleAttendees } = dataArray; // Fetch event attendees

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated
        ? Attendees
        : SampleAttendees);
    useEffect(() => {
        setAttendeesReferenceArray(
            dataArray.authenticated
                ? Attendees
                : SampleAttendees
        );
    }, [dataArray.authenticated, Attendees, SampleAttendees]);

    const [tabValueSchedule, setTabValueSchedule] = useState("dates");
    const [tabValueReminders, setTabValueReminders] = useState("daysBefore");


    const [title, setTitle] = useState("");
    const [notes, setNotes] = useState("");
    const [category, setCategory] = useState("General");
    const [assignedTo, setAssignedTo] = useState([Account.id ? Account.id : PA_GlobalObject.Planning.attendees[0].id ? PA_GlobalObject.Planning.attendees[0].id : null]);
    const [priority, setPriority] = useState("Low");
    const [scheduleDate, setScheduleDate] = useState(() => {
        const eventDates = PA_GlobalObject.Planning?.time_table?.dates;
        return eventDates?.length > 0 ? eventDates[0].date : '';
    });
    const [scheduleDay, setScheduleDay] = useState(() => {
        const eventDays = PA_GlobalObject.Planning?.time_table?.days;

        // Extract the first day's key and get its value
        if (eventDays?.length > 0) {
            const firstDayKey = Object.keys(eventDays[0])[0]; // Get the first key (e.g., "day 1")
            return eventDays[0][firstDayKey]; // Return the corresponding value (e.g., "Thursday")
        }

        return ''; // Default empty string if no days exist
    });
    const [scheduleAllDay, setScheduleAllDay] = useState(true);
    const [scheduleBeginTimeSelected, setScheduleBeginTimeSelected] = useState(false);
    const [scheduleEndTimeSelected, setScheduleEndTimeSelected] = useState(false);
    const [scheduleBeginTime, setScheduleBeginTime] = useState("");
    const [scheduleEndTime, setScheduleEndTime] = useState("");
    const [reminderDaysBeforeTimeSelected, setReminderDaysBeforeTimeSelected] = useState(false);
    const [reminderDaysBeforeArray, setReminderDaysBeforeArray] = useState([]);
    const [reminderDaysBeforeTime, setReminderDaysBeforeTime] = useState("");
    const [reminderDayOfTimeSelected, setReminderDayOfTimeSelected] = useState(false);
    const [reminderDayOfTime, setReminderDayOfTime] = useState("");
    const [isPrivate, setIsPrivate] = useState(false);
    const [status, setStatus] = useState(1);

    function handleTitleChange(event){
        setTitle(event.target.value);
    }
    function handleNotesChange(event){
        setNotes(event.target.value);
    }
    function handleCategoryChange(event){
        setCategory(event.target.value);
    }
    function handleAssignedToChange(event, id) {
        setAssignedTo((prevAssignedTo) => {
            const assignedList = Array.isArray(prevAssignedTo) ? prevAssignedTo : [];
            return assignedList.includes(id)
                ? assignedList.filter(item => item !== id) // Remove if already selected
                : [...assignedList, id]; // Add if not selected
        });
    }

    function handlePriorityChange(event) {
        setPriority(event.target.value);
    }
    function handleScheduleDateChange(schedule_date) {
        setScheduleDate(schedule_date.format('YYYY-MM-DD'));
    }
    function handleScheduleDayChange(day) {
        setScheduleDay(day);
    }
    function handleScheduleAllDayChange(event) {
        setScheduleAllDay(event.target.checked);
        if (event.target.checked) {
            // If toggled ON, reset time pickers
            setScheduleBeginTimeSelected(false);
            setScheduleBeginTime("");
            setScheduleEndTimeSelected(false);
            setScheduleEndTime("");
        }
    }
    function handleScheduleBeginTimeChange(time) {
        setScheduleBeginTime(time.format('h:mm A'));
        setScheduleBeginTimeSelected(true);
    }
    function handleScheduleEndTimeChange(time) {
        setScheduleEndTime(time.format('h:mm A'));
        setScheduleEndTimeSelected(true);
    }
    function handleReminderDaysBeforeArrayChange(event, day) {
        setReminderDaysBeforeArray((prevArray) => {
            const updatedArray = Array.isArray(prevArray) ? [...prevArray] : [];
            const newArray = updatedArray.includes(day)
                ? updatedArray.filter((d) => d !== day) // Remove if already selected
                : [...updatedArray, day]; // Add if not selected

            // If the array becomes empty, reset the reminder time
            if (newArray.length === 0) {
                setReminderDaysBeforeTime("");
            }

            return newArray;
        });
    }
    function handleReminderDaysBeforeTimeChange(time) {
        setReminderDaysBeforeTime(time.format('h:mm A'));
    }
    function handleReminderDayOfTimeChange(time) {
        setReminderDayOfTime(time.format('h:mm A'));
        setReminderDayOfTimeSelected(true);
    }
    function handleResetReminderDayOfTime() {
        setReminderDayOfTime(""); // Clear the time
        setReminderDayOfTimeSelected(false); // Unselect the reminder
    }

    function handleIsPrivateChange(event) {
        setIsPrivate(event.target.checked);
    }
    function handleStatusChange(event) {
        setStatus(Number(event.target.value)); // Convert to number in case it's stored as a string
    }



    const [doesWantPush, setDoesWantPush] = useState(false);

    // Check if the user wants push notifications
    useEffect(() => {
        const user = Attendees.find((att) => att.id === dataArray.Account.id);
        if (user?.push_notifications) setDoesWantPush(true);
    }, [Attendees]);

    const categories = [
        "General", "Activities", "Attendees", "Meals", "Flights", "Lodging",
        "Supplies", "Receipts", "Photos", "Notifications"
    ];
    const priorities = ["Urgent", "High", "Medium", "Low"];


    const handleToggleReminderDayBefore = (day) => {

    };

    const handleTabValueScheduleChange = (event, newValue) => {
        console.log("handleTabValueScheduleChange");
        setTabValueSchedule(newValue);
    };

    // Render custom days Chips layout
    const renderDaysLayout = () => {
        // Get all planned days from Planning.time_table.days
        const planningDays = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days || [];

        if (planningDays.length === 0) {
            return (
                <Box sx={{ display: 'flex', gap: '3px' }}>
                    {daysOfWeek.map((day, index) => (
                        <Chip
                            key={index}
                            label={
                                <div style={{ textAlign: 'center' }}>
                                    <Typography variant="body2">{day.slice(0, 2)}</Typography> {/* Two-letter day */}
                                </div>
                            }
                            sx={{
                                width: '40px',
                                height: '40px',
                                backgroundColor: scheduleDay === day
                                    ? 'rgba(0, 123, 255, 0.6)' // Highlight selected day
                                    : 'rgba(0, 123, 255, 0.2)',
                            }}
                            clickable
                            onClick={() => handleScheduleDayChange(day)}
                        />
                    ))}
                </Box>
            );
        }

        // Create an array to store all possible day objects (even for multi-week events)
        const calendarDays = [];

        planningDays.forEach((dayObj, index) => {
            const dayKey = Object.keys(dayObj)[0]; // e.g., 'day 1'
            const dayName = Object.values(dayObj)[0]; // e.g., 'Wednesday'
            const dayOfWeekIndex = daysOfWeek.indexOf(dayName.slice(0, 2)); // Index for Su-Sa layout (0-6)

            // Add the day to the correct slot (considering multiple weeks)
            calendarDays.push({
                key: dayKey,
                name: dayName,
                index: index + 1, // Index representing day in Planning (1-based index)
                isSelected: dayName === scheduleDay // Set the selected status based on the scheduleDay
            });
        });

        // Organize into weeks (rows of 7 days)
        const weeks = [];
        let currentWeek = Array(7).fill(null);

        calendarDays.forEach((dayObj) => {
            const dayOfWeekIndex = daysOfWeek.indexOf(dayObj.name);
            currentWeek[dayOfWeekIndex] = dayObj;

            // When the week is complete (Saturday), push the current week to the weeks array
            if (dayOfWeekIndex === 6) {
                weeks.push(currentWeek);
                currentWeek = Array(7).fill(null);
            }
        });

        // Push the final incomplete week if it exists
        if (currentWeek.some(day => day !== null)) {
            weeks.push(currentWeek);
        }


        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '20px' }}>
                {/* Iterate over weeks */}
                {weeks.map((week, weekIndex) => (
                    <Box key={weekIndex} sx={{ display: 'flex', gap: '3px' }}>
                        {/* Iterate over each day in the week */}
                        {week.map((day, dayIndex) => {
                            if (day) {
                                return (
                                    <Chip
                                        key={dayIndex}
                                        label={
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography variant="body2">{day.name.slice(0, 2)}</Typography> {/* Two-letter day */}
                                            </div>
                                        }
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            backgroundColor: day.isSelected
                                                ? 'rgba(0, 123, 255, 0.6)' // Highlight selected day
                                                : 'rgba(0, 123, 255, 0.2)',
                                        }}
                                        clickable
                                        onClick={() => handleScheduleDayChange(day.name)} // Pass full day object on click
                                    />
                                );
                            } else {
                                return <Box key={dayIndex} sx={{ width: '40px', height: '40px' }} />;
                            }
                        })}
                    </Box>
                ))}
            </Box>
        );
    };


    const handleTabValueRemindersChange = (event, newValue) => {
        console.log("handleTabValueRemindersChange");
        setTabValueReminders(newValue);
    };




    function handleTodoAddOK() {
        console.log("handleTodoAddOK");
        if (title.trim() === '') return alert("Title is required.");

        // Generate separate To-Do objects for each assigned attendee
        const newTodos = assignedTo.map((attendeeId) => ({
            id: PAAttendee.generateRandomString(10), // Ensure each To-Do gets a unique ID
            created_by: Account.id ? Account.id : PA_GlobalObject.Planning.attendees[0].id,
            title: title,
            notes: notes,
            category: category,
            assigned_to: attendeeId,
            priority: priority,
            schedule_date: scheduleDate,
            schedule_day: scheduleDay,
            schedule_all_day: scheduleAllDay,
            schedule_begin_time_selected: scheduleBeginTimeSelected,
            schedule_end_time_selected: scheduleEndTimeSelected,
            schedule_begin_time: scheduleBeginTime,
            schedule_end_time: scheduleEndTime,
            reminder_days_before_time_selected: reminderDaysBeforeTimeSelected,
            reminder_days_before_array: reminderDaysBeforeArray,
            reminder_days_before_time: reminderDaysBeforeTime,
            reminder_day_of_time_selected: reminderDayOfTimeSelected,
            reminder_day_of_time: reminderDayOfTime,
            is_private: isPrivate,
            status: status,
        }));

        console.log("newTodos",newTodos);
        // Update the global state, adding the new To-Dos to the existing list
        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                to_do: [...(prev.Planning.to_do || []), ...newTodos], // Append new To-Dos
            },
        }));

        // Navigate back to the To-Do list
        navigate('/Planner/Todo');
    }

    function handlePromptAddNewClose() {
        navigate('/Planner/Todo');
    }

    const toggleSelection = (selectedList, id) => {
        return selectedList.includes(id)
            ? selectedList.filter(item => item !== id) // Remove if already selected
            : [...selectedList, id]; // Add if not selected
    };

    return (

        <div className="todo-list-container" >
            {/* show the header */}
            <div
                className="todo-list-header"
            >
                <div className="todo-list-header-text">
                    <h5>New To-Do</h5>
                </div>
                <div className="todo-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Cancel"
                        size="small"
                        color="error"
                        onClick={handlePromptAddNewClose}
                    />
                    <Chip
                        sx={addChipStyle}
                        label="Add"
                        size="small"
                        onClick={handleTodoAddOK}
                    />
                </div>
            </div>

            {/* show all the fields */}
            <div className="todo-add-new-item-container">

                <Box sx={{ padding: 2}}>
                    {/* Title */}
                    <TextField
                        size="small"
                        label="Title"
                        fullWidth
                        required
                        value={title}
                        onChange={handleTitleChange}
                        margin="dense"
                    />

                    {/* Notes */}
                    <TextField
                        size="small"
                        label="Notes"
                        fullWidth
                        multiline
                        rows={3}
                        value={notes}
                        onChange={handleNotesChange}
                        margin="dense"
                    />

                    {/* Category */}
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Category</InputLabel>
                        <Select
                            size="small"
                            label="Category"
                            value={category}
                            onChange={handleCategoryChange}
                        >
                            {categories.map((cat) => (
                                <MenuItem key={cat} value={cat}>{cat}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Assign To */}
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Assign To</InputLabel>
                        <Select
                            size="small"
                            multiple
                            label="Assign To"
                            value={assignedTo}
                            onChange={handleAssignedToChange}
                            renderValue={(selected) =>
                                selected
                                    .map(id => attendeesReferenceArray.find(att => att.id === id)) // Convert IDs to attendee objects
                                    .filter(Boolean) // Remove undefined values
                                    .sort((a, b) => a.fname.localeCompare(b.fname)) // Sort by first name
                                    .map(att => att.nickname || `${att.fname} ${att.lname}`) // Display name
                                    .join(", ")
                            }
                        >
                            {(() => {
                                // Get attendees already in the event
                                const eventAttendees = usePA_GlobalStore
                                    .getState()
                                    .PA_GlobalObject.Planning.attendees
                                    .map(att => att.id); // Extract IDs

                                // Separate attendees into event attendees & other attendees
                                const assignedEventAttendees = attendeesReferenceArray
                                    .filter(att => eventAttendees.includes(att.id)) // Attendees already in the event
                                    .sort((a, b) => (a.fname?.toLowerCase() ?? "").localeCompare(b.fname?.toLowerCase() ?? ""))

                                const otherAttendees = attendeesReferenceArray
                                    .filter(att => !eventAttendees.includes(att.id)) // Not in event
                                    .sort((a, b) => (a.fname?.toLowerCase() ?? "").localeCompare(b.fname?.toLowerCase() ?? ""))

                                // Return an array instead of a Fragment
                                const menuItems = [];

                                // Event Attendees
                                assignedEventAttendees.forEach(att => {
                                    menuItems.push(
                                        <MenuItem key={att.id} value={att.id} disableRipple>
                                            <Checkbox
                                                checked={assignedTo.includes(att.id)}
                                                onChange={(e) => handleAssignedToChange(e, att.id)}
                                            />
                                            <ListItemText primary={att.nickname || `${att.fname} ${att.lname}`} />
                                        </MenuItem>
                                    );
                                });

                                // Add Divider if both lists have attendees
                                if (assignedEventAttendees.length > 0 && otherAttendees.length > 0) {
                                    menuItems.push(
                                        <MenuItem key="divider" disabled>
                                            <Divider sx={{ width: "100%" }} />
                                        </MenuItem>
                                    );
                                }

                                // Other Attendees
                                otherAttendees.forEach(att => {
                                    menuItems.push(
                                        <MenuItem key={att.id} value={att.id} disableRipple>
                                            <Checkbox
                                                checked={assignedTo.includes(att.id)}
                                                onChange={(e) => handleAssignedToChange(e, att.id)}
                                            />
                                            <ListItemText primary={att.nickname || `${att.fname} ${att.lname}`} />
                                        </MenuItem>
                                    );
                                });

                                return menuItems; // Return array instead of Fragment
                            })()}
                        </Select>
                    </FormControl>

                    {/* Priority */}
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Priority</InputLabel>
                        <Select
                            size="small"
                            label="Priority"
                            value={priority}
                            onChange={handlePriorityChange}
                            renderValue={(selected) => (
                                <Box display="flex" alignItems="center">
                                    <Box
                                        sx={{
                                            width: 10,
                                            height: 10,
                                            borderRadius: "50%",
                                            backgroundColor: getPriorityIconColor(selected),
                                            marginRight: 1,
                                        }}
                                    />
                                    {selected}
                                </Box>
                            )}
                        >
                            {priorities.map((prio) => (
                                <MenuItem key={prio} value={prio}>
                                    <Box display="flex" alignItems="center" width="100%">
                                        {prio}
                                        <Box
                                            sx={{
                                                width: 10,
                                                height: 10,
                                                borderRadius: "50%",
                                                backgroundColor: getPriorityIconColor(prio),
                                                marginLeft: "auto", // Pushes to the right side
                                            }}
                                        />
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Schedule */}
                    <Card
                        sx={{
                            margin:'15px 0 0 0'
                        }}
                    >
                        <CardHeader
                            title="Schedule"
                            subheader="You can schedule To-Do items before, during, or after the Event dates."
                            sx={{
                                "& .MuiCardHeader-title": {
                                    fontSize: "16px",  // Set font size for the title
                                },
                                "& .MuiCardHeader-subheader": {
                                    fontSize: "14px",  // Set font size for the subheader
                                    color: "text.secondary",  // Optional: Adjust color for better readability
                                },
                            }}
                        />

                        <CardContent sx={{ padding: '0' }}>

                            <TabContext sx={{ position: 'relative' }} value={tabValueSchedule}>
                                <TabList
                                    centered
                                    variant="fullWidth"
                                    onChange={handleTabValueScheduleChange}
                                    aria-label="Event Dates / Days"
                                >
                                    <Tab
                                        label="Dates"
                                        value="dates"
                                    />
                                    <Tab
                                        label="Days"
                                        value="days"
                                    />
                                </TabList>

                                {/* Dates Tab Panel */}
                                <TabPanel value="dates" sx={{ padding: '10px 10px 10px 10px' }}>
                                    {/* Schedule Date & Time */}
                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <DateCalendar
                                            value={scheduleDate && dayjs(scheduleDate).isValid() ? dayjs(scheduleDate) : dayjs(PA_GlobalObject.Planning['time_table'].dates[0]?.date, 'YYYY-MM-DD').isValid() ? dayjs(PA_GlobalObject.Planning['time_table'].dates[0]?.date, 'YYYY-MM-DD') : dayjs()}
                                            onChange={handleScheduleDateChange}
                                            disablePast={false}
                                            renderLoading={() => <Typography>Loading Calendar...</Typography>}
                                        />
                                    </Box>
                                </TabPanel>

                                {/* Days Tab Panel */}
                                <TabPanel value="days" sx={{ padding: '10px 0 10px 0' }}>
                                    <Box className="dates-times-selection-times-text" sx={{ marginBottom: '20px' }}>
                                        {renderDaysLayout()}
                                    </Box>
                                </TabPanel>
                            </TabContext>

                            <Box sx={{ padding: '0 10px' }}>

                                {/* Toggle for All Day */}
                                <Box
                                    sx={{
                                        padding: scheduleAllDay ? '0' : '0 0 15px 0'
                                    }}
                                >
                                    <Switch
                                        checked={scheduleAllDay}
                                        onChange={handleScheduleAllDayChange}
                                        color="primary"
                                    />
                                    <Typography sx={{ fontSize: "14px" }} variant="caption">
                                        No Specific Time
                                    </Typography>
                                </Box>

                                {/* Show Time Pickers only when All Day is OFF */}
                                {!scheduleAllDay && (
                                    <>
                                        <Typography sx={{ fontSize: "14px" }} variant="caption">
                                            Set a time / begin time
                                        </Typography>

                                        <TimePicker
                                            sx={{
                                                margin: "10px 0",
                                                width: "100%",
                                                "& .MuiInputBase-root": {
                                                    fontSize: "14px",
                                                    padding: "0 8px",
                                                    height: "35px",
                                                },
                                                "& .MuiInputLabel-root": {
                                                    fontSize: "14px",
                                                },
                                                "& .MuiOutlinedInput-root": {
                                                    height: "35px",
                                                    fontSize: "14px",
                                                },
                                            }}
                                            label="Begin Time"
                                            value={scheduleBeginTime ? dayjs(scheduleBeginTime, "HH:mm") : null}
                                            onChange={handleScheduleBeginTimeChange}
                                            renderInput={(params) => <TextField fullWidth margin="dense" {...params} />}
                                        />

                                        <Typography sx={{ fontSize: "14px" }} variant="caption">
                                            Set an end time if there is one
                                        </Typography>

                                        <TimePicker
                                            sx={{
                                                margin: "10px 0",
                                                width: "100%",
                                                "& .MuiInputBase-root": {
                                                    fontSize: "14px",
                                                    padding: "0 8px",
                                                    height: "35px",
                                                },
                                                "& .MuiInputLabel-root": {
                                                    fontSize: "14px",
                                                },
                                                "& .MuiOutlinedInput-root": {
                                                    height: "35px",
                                                    fontSize: "14px",
                                                },
                                            }}
                                            label="End Time"
                                            value={scheduleEndTime ? dayjs(scheduleEndTime, "HH:mm") : null}
                                            onChange={handleScheduleEndTimeChange}
                                            renderInput={(params) => <TextField fullWidth margin="dense" {...params} />}
                                        />
                                    </>
                                )}

                            </Box>

                        </CardContent>
                    </Card>

                    {/* Reminders */}
                    <Card
                        sx={{
                            margin:'15px 0 0 0'
                        }}
                    >
                        <CardHeader
                            title="Reminders"
                            subheader="You can set reminders for up to 5 days before the scheduled day.
                            Reminders come as emails."
                            sx={{
                                "& .MuiCardHeader-title": {
                                    fontSize: "16px",  // Set font size for the title
                                },
                                "& .MuiCardHeader-subheader": {
                                    fontSize: "14px",  // Set font size for the subheader
                                    color: "text.secondary",  // Optional: Adjust color for better readability
                                },
                            }}
                        />
                        <CardContent sx={{ paddingTop: '0' }}>


                            <TabContext sx={{ position: 'relative' }} value={tabValueReminders}>
                                <TabList
                                    centered
                                    variant="fullWidth"
                                    onChange={handleTabValueRemindersChange}
                                    aria-label="Days Before / Day Of"
                                >
                                    <Tab
                                        label="Days Before"
                                        value="daysBefore"
                                    />
                                    <Tab
                                        label="Day Of"
                                        value="dayOf"
                                    />
                                </TabList>
                                {/* Days Before Tab Panel */}
                                <TabPanel value="daysBefore" sx={{ padding: '10px 10px 10px 10px' }}>
                                    <List>
                                        {[1, 2, 3, 4, 5].map((day) => (
                                            <ListItem key={day} sx={{padding:'0'}}>
                                                <Checkbox
                                                    checked={reminderDaysBeforeArray.includes(day)}
                                                    onChange={(event) => handleReminderDaysBeforeArrayChange(event, day)}
                                                />
                                                <ListItemText primary={`${day} Day${day > 1 ? "s" : ""} Before`} />
                                            </ListItem>
                                        ))}
                                    </List>
                                    {/* Show Time Picker only if there are selected days */}
                                    {reminderDaysBeforeArray.length > 0 && (
                                        <TimePicker
                                            size="small"
                                            sx={{ width: '100%' }}
                                            label="Reminder Time"
                                            value={reminderDaysBeforeTime ? dayjs(reminderDaysBeforeTime, 'h:mm A') : null}
                                            onChange={handleReminderDaysBeforeTimeChange}
                                            renderInput={(params) => <TextField fullWidth margin="dense" {...params} />}
                                        />
                                    )}
                                </TabPanel>
                                {/* Day of Tab Panel */}
                                <TabPanel value="dayOf" sx={{ padding: '10px 10px 10px 10px' }}>
                                    <TimePicker
                                        size="small"
                                        sx={{ width: '100%', marginTop: '15px' }}
                                        label="Reminder Time"
                                        value={reminderDayOfTime ? dayjs(reminderDayOfTime, 'h:mm A') : null}
                                        onChange={handleReminderDayOfTimeChange}
                                        renderInput={(params) => <TextField fullWidth margin="dense" {...params} />}
                                    />

                                    {/* Reset Button */}
                                    <Box display="flex" justifyContent="flex-end" mt={2}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleResetReminderDayOfTime}
                                        >
                                            Reset
                                        </Button>
                                    </Box>

                                </TabPanel>
                            </TabContext>

                        </CardContent>
                    </Card>


                    {/* Privacy */}
                    <Typography variant="subtitle2" sx={{ marginTop: 2 }}>Privacy</Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isPrivate}
                                onChange={handleIsPrivateChange}
                            />
                        }
                        label={isPrivate ? "Private (Only You)" : "Public (Visible to Attendees)"}
                    />

                </Box>
            </div>
        </div>

    );
}

export default TodoAddNew;
