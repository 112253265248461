import React, {useContext, useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {PAContext} from "../../Services/PAContext";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";

import Dashboard from '../../components/Restricted/Admins/Dashboard';
import Files from "../../components/Restricted/Admins/Files";
import Assistants from '../../components/Restricted/Admins/Assistants';
import AttendeesList from "../../components/Restricted/Admins/AttendeesList";
import Events from "../../components/Restricted/Admins/Events";

const items = [
    'Dashboard',
    'OpenAI Files',
    'OpenAI Assistants',
    'Attendees',
    'Events'
];

function Admins() {

    const navigate = useNavigate();

    const { dataArray } = useContext(PAContext);
    const { AdminsActions } = dataArray;

    const [selectedItem, setSelectedItem] = useState(items[0]);
    const [dashboardNotices, setDashboardNotices] = useState([]);
    const [filesNotices, setFilesNotices] = useState([]);
    const [assistantsNotices, setAssistantsNotices] = useState([]);
    const [attendeesList, setAttendeesList] = useState([]);
    const [eventsNotices, setEventsNotices] = useState([]);


    useEffect(() => {

        for (let i = AdminsActions.length - 1; i >= 0; i--) {
            const _incoming = AdminsActions[i];

            console.log('Action:', _incoming.action);

            switch (_incoming.action) {
                case "filesList":
                    setFilesNotices(_incoming);
                    break;
                case "attendeesList":
                    setAttendeesList(_incoming);
                    break;
                default:
                // Handle other cases if needed
            }

            // Remove the one item we just processed
            AdminsActions.splice(i, 1);
        }

    }, [AdminsActions])

    const handleItemClick = (item) => {
        console.log(item);
        setSelectedItem(item);

        switch (item) {
            case 'Dashboard':
                navigate('/Admins/Dashboard');
                break;
            case 'OpenAI Files':
                navigate('/Admins/Files');
                break;
            case 'OpenAI Assistants':
                navigate('/Admins/Assistants');
                break;
            case 'Attendees':
                navigate('/Admins/Attendees');
                break;
            case 'Events':
                navigate('/Admins/Events');
                break;
            default:
        }

    };





    return (
        <div>

            <List
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    p: 0,
                    m: 0, // Remove default margin
                    minWidth: 'max-content', // Ensure List takes minimum width required
                }}
            >
                {items.map((item, _index) => (
                    <ListItem key={_index} onClick={() => handleItemClick(item)}>
                        <ListItemText primary={item} sx={{cursor:'pointer'}}/>
                    </ListItem>
                ))}
            </List>
            {

                /*
                <Drawer
                variant="permanent"
                sx={{
                    width: 200,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 200,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Toolbar />
                <List>
                    {items.map((item) => (
                        <ListItem button key={item} onClick={() => handleItemClick(item)}>
                            <ListItemText primary={item} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
                 */
            }


            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>

                <Routes>
                    <Route
                        path=""
                        element={
                            <Dashboard
                                dashboardNotices={dashboardNotices}
                            />
                        }
                    />
                    <Route
                        path="Dashboard"
                        element={
                            <Dashboard
                                dashboardNotices={dashboardNotices}
                            />
                        }
                    />
                    <Route
                        path="Files"
                        element={
                            <Files
                                filesNotices={filesNotices}
                            />
                        }
                    />
                    <Route
                        path="Assistants"
                        element={
                            <Assistants
                                assistantsNotices={assistantsNotices}
                            />
                        }
                    />
                    <Route
                        path="Attendees"
                        element={
                            <AttendeesList
                                attendeesList={attendeesList}
                            />
                        }
                    />
                    <Route
                        path="Events"
                        element={
                            <Events
                                eventsNotices={eventsNotices}
                            />
                        }
                    />
                </Routes>

            </Box>
        </div>
    )
}

export default Admins;
