import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, Divider, List, ListItem, ListItemText } from "@mui/material";
import IconButton from "@mui/material/IconButton";

function InfoReceipts({ isOpen, onClose }) {



    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
            {/* Dialog Title */}
            <DialogTitle
                sx={{
                    color: '#151269',
                    textAlign: 'center',
                    backgroundColor: 'rgba(198, 226, 255, 0.8)',
                    paddingRight: '30px'
                }}
            >
                Event Receipts
            </DialogTitle>

            <DialogTitle
                sx={{
                    fontSize: '18px',
                    textAlign: 'center',
                    color: '#151269',
                    backgroundColor: 'rgba(198, 226, 255, 0.8)',
                    padding: 0
                }}
            >
                Receipts are the way to go!.
            </DialogTitle>

            {/* Close Button */}
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: '#151269',
                }}
            >
                <i className="icon-cross" style={{ fontSize: '18px', padding: '5px' }} />
            </IconButton>

            {/* Dialog Content */}
            <DialogContent>
                <List>
                    {/* Selecting Dates and Days */}
                    <ListItem>
                        <ListItemText
                            primary={
                                <span>
                                    <i className="icon-calendar3" title="Date and Time" style={{ fontSize: "18px", marginRight: "8px", color: "#1976d2" }} />
                                    Selecting Dates and Days
                                </span>
                            }
                            secondary={
                                <Typography variant="body2">
                                    Including a personal supplies list, the ability to assign items to others.
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider />

                    {/* Updating Meal Location */}
                    <ListItem>
                        <ListItemText
                            primary={
                                <span>
                                    <i className="icon-map-marker" title="Meal Location" style={{ fontSize: "18px", marginRight: "8px", color: "#1976d2" }} />
                                    Selecting/Updating Meal Location
                                </span>
                            }
                            secondary={
                                <Typography variant="body2">
                                    and an “Essentials” list for recurring items that remain constant across trips.
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider />

                    {/* Adding/Removing Meal Attendees */}
                    <ListItem>
                        <ListItemText
                            primary={
                                <span>
                                    <i className="icon-user-24" title="Attendees" style={{ fontSize: "18px", marginRight: "8px", color: "#1976d2" }} />
                                    Adding/Removing Meal Attendees
                                </span>
                            }
                            secondary={
                                <Typography variant="body2">
                                    In addition, if you're not sure what to pack other than the basics, check out our supply suggestion lists
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider />

                </List>
            </DialogContent>
        </Dialog>
    );
}

export default InfoReceipts;
