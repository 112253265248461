import React, {useEffect, useState} from 'react';

import {DateCalendar, DayCalendarSkeleton, PickersDay, TimePicker} from "@mui/x-date-pickers";
import {
    Chip,
    FormControl,
    InputLabel, MenuItem,
    Select,
    Switch,
    Tab,
    TextField
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import './datesTimes.css';
import Typography from "@mui/material/Typography";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TimeTable from "../../../MasterEventObjects/TimeTable";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import {updateChipStyle} from "../../ButtonStyle/Update";
import {daysOfWeekMap} from "../../Helpers/DatesAndTimes";
import {useLocation} from "react-router-dom";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";


// Extend dayjs to work with localized formats and weekdays
dayjs.extend(localizedFormat);
dayjs.extend(weekday);



function DatesTimesContent(props) {

    const location = useLocation();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    useEffect(() => {
        if (location.state?.showSidebar !== undefined && location.state.showSidebar) {
            props.setShowEventItemConfig(location.state.showSidebar);
        }
    }, [location.state]);

    const [eventTimetable, setEventTimetable] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);

    const [datesCount, setDatesCount] = useState(0);

    const [ eventDaysCount, setEventDaysCount ] = useState(0);
    const [ eventDaysStartingOnDay, setEventDaysStartingOnDay ] = useState('');

    const [tabValue, setTabValue] = useState("dates");

    const [ datesTimeOptionChecked, setDatesTimeOptionChecked ] = useState(false);
    const [ daysTimeOptionChecked, setDaysTimeOptionChecked ] = useState(false);

    const [datesBeginTimeValue, setDatesBeginTimeValue] = useState(null);
    const [datesEndTimeValue, setDatesEndTimeValue] = useState(null);
    const [daysBeginTimeValue, setDaysBeginTimeValue] = useState(null);
    const [daysEndTimeValue, setDaysEndTimeValue] = useState(null);



    useEffect(() => {

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning?.time_table) {
            console.log("Set time_table : " , usePA_GlobalStore.getState().PA_GlobalObject.Planning?.time_table);

            // Populate selectedDates from the event's time_table.dates
            const eventDates = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates.map(dateObj => dayjs(dateObj.date, 'YYYY-MM-DD'));
            setDatesCount(eventDates.length);
            setSelectedDates(eventDates);

            // Handle begin and end times for Dates
            if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates.length > 0) {
                const firstDateObj = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates[0];
                const lastDateObj = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates[usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates.length - 1];

                // Set the begin and end times for Dates (use default if not available)
                const beginTime = dayjs(firstDateObj['begin-time'] || '12:00 AM', 'h:mm A');
                const endTime = dayjs(lastDateObj['end-time'] || '11:59 PM', 'h:mm A');

                setDatesBeginTimeValue(beginTime);
                setDatesEndTimeValue(endTime);

                // Check if the event is a full-day event (begin-time is 12:00 AM and end-time is 11:59 PM)
                const isFullDayEvent = beginTime.format('h:mm A') === '12:00 AM' && endTime.format('h:mm A') === '11:59 PM';

                // Set the time option toggle based on whether it's a full-day event or not
                setDatesTimeOptionChecked(!isFullDayEvent); // Disable toggle if it's a full-day event
            } else {
                setDatesTimeOptionChecked(false); // Set to false if no dates are present
            }

            // Populate selectedDays from the event's time_table.days (e.g., 'day 1', 'Wednesday')
            const eventDays = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days.map(dayObj => {
                // Find the key that starts with "day "
                const dayKey = Object.keys(dayObj).find(k => k.startsWith('day '));
                const dayValue = dayObj[dayKey]; // Will be undefined if key not found, but that's okay

                return { key: dayKey, value: dayValue };
            });

            setSelectedDays(eventDays);
            setEventDaysCount(eventDays.length);

            if (eventDays.length > 0) {
                setEventDaysStartingOnDay(eventDays[0].value);

                // Handle begin and end times for Days
                const firstDayObj = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days[0];
                const lastDayObj = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days[usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days.length - 1];

                const beginTime = dayjs(firstDayObj['begin-time'] || '12:00 AM', 'h:mm A');
                const endTime = dayjs(lastDayObj['end-time'] || '11:59 PM', 'h:mm A');

                setDaysBeginTimeValue(beginTime);
                setDaysEndTimeValue(endTime);

                // Check if the event is a full-day event for days
                const isFullDayEventForDays = beginTime.format('h:mm A') === '12:00 AM' && endTime.format('h:mm A') === '11:59 PM';

                // Set the time option toggle based on whether it's a full-day event for days or not
                setDaysTimeOptionChecked(!isFullDayEventForDays); // Disable toggle if it's a full-day event
            } else {
                setDaysTimeOptionChecked(false); // Set to false if no days are present
            }

            console.log("Set the SelectedDates : " + JSON.stringify(eventDates));
            console.log("Set the SelectedDays : " + JSON.stringify(eventDays));
            console.log("############################");

        }

        /*
            this holds our initial values to compare against when changes are made
         */
        setEventTimetable(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table);

    }, [PA_GlobalObject.Planning.time_table]);

    const handleTabValueChange = (event, newValue) => {
        console.log("handleTabValueChange");
        setTabValue(newValue);
    };
    useEffect(() => {
        console.log("tabValue : "+tabValue);
    }, [tabValue]);

    function handleEventDaysCountChange(e){
        console.log("handleEventDaysCountChange : "+parseInt(e.target.value, 10));

        const newDaysCount = parseInt(e.target.value, 10);
        setEventDaysCount(newDaysCount);

        // Validate the input and ensure it's a number
        if (isNaN(newDaysCount) || newDaysCount <= 0) {
            return; // Don't update if the value is invalid or zero
        }

        console.log("eventDaysStartingOnDay : "+eventDaysStartingOnDay);

        // We know the starting day (e.g., "Wednesday", "Friday") from state: eventDaysStartingOnDay
        const startDayIndex = daysOfWeekMap[eventDaysStartingOnDay] ?? NaN; // Fallback to NaN if the day is invalid

        console.log("startDayIndex : "+startDayIndex);

        // Array to store the new selectedDays
        const newSelectedDays = [];

        // Generate the selected days based on the count and starting day
        for (let i = 0; i < newDaysCount; i++) {
            const dayIndex = (startDayIndex + i) % 7; // Wrap around if the index goes beyond 6 (Saturday)
            const dayName = dayjs().day(dayIndex).format('dddd'); // Get the full day name (e.g., "Wednesday")

            newSelectedDays.push({
                key: `day ${i + 1}`, // "Day 1", "Day 2", etc.
                value: dayName,       // Corresponding day name
            });
        }

        // Update the selectedDays array in state
        setSelectedDays(newSelectedDays);
    }
    function handleEventDaysStartingOnChange(event) {
        const newStartingDay = event.target.value; // Get the new starting day (e.g., "Wednesday")
        console.log("handleEventDaysStartingOnChange : "+newStartingDay);

        // We already have the eventDaysCount from the state (how many days the event lasts)
        const newSelectedDays = [];

        // Get the index of the new starting day
        const startDayIndex = daysOfWeekMap[newStartingDay] ?? NaN; // Fallback to NaN if the day is invalid

        // Loop through the number of days (eventDaysCount) and calculate the days
        for (let i = 0; i < eventDaysCount; i++) {
            const dayIndex = (startDayIndex + i) % 7; // Wrap around the days of the week (if needed)
            const dayName = dayjs().day(dayIndex).format('dddd'); // Get the day name for each day

            // Push the new day to the array
            newSelectedDays.push({
                key: `day ${i + 1}`, // "Day 1", "Day 2", etc.
                value: dayName,       // The actual day name (e.g., "Wednesday")
            });
        }

        // Update the state with the new selectedDays array and the new starting day
        setSelectedDays(newSelectedDays);
        setEventDaysStartingOnDay(newStartingDay);
    }

    function handleUpdatedDatesBeginTime(newValue){
        setDatesBeginTimeValue(newValue);
    }
    function handleUpdatedDatesEndTime(newValue){
        setDatesEndTimeValue(newValue);
    }
    function handleUpdatedDaysBeginTime(newValue){
        setDaysBeginTimeValue(newValue);
    }
    function handleUpdatedDaysEndTime(newValue){
        setDaysEndTimeValue(newValue);
    }

    useEffect(() => {
        console.log("the updated selectedDates : ", selectedDates);
    }, [selectedDates]);

    useEffect(() => {
        console.log("the updated selectedDays : ", selectedDays);
    }, [selectedDays]);

    function getMarkersForDate(date, _planning) {

        const markers = [];

        // Check for meals
        const mealDates = _planning.meals.flatMap((entry) => {
            const _time_table = entry.time_table;
            return _time_table.dates.map((item) => dayjs(item.date, "YYYY-MM-DD")); // Return dayjs objects
        });
        if (mealDates?.some((mealDate) => mealDate.isSame(date, "day"))) {
            markers.push("meal");
        }

        // Check for lodging
        const lodgingDates = _planning.lodging.flatMap((entry) => {
            const _time_table = entry.time_table;
            return _time_table.dates.map((item) => dayjs(item.date, "YYYY-MM-DD")); // Return dayjs objects
        });
        if (lodgingDates?.some((lodgingDate) => lodgingDate.isSame(date, "day"))) {
            markers.push("lodging");
        }

        // Check for activities
        const activityDates = _planning.activities.flatMap((entry) => {
            const _time_table = entry.time_table;
            return _time_table.dates.map((item) => dayjs(item.date, "YYYY-MM-DD")); // Return dayjs objects
        });
        if (activityDates?.some((activityDate) => activityDate.isSame(date, "day"))) {
            markers.push("activity");
        }

        return markers;
    }
    function isHighlighted(date, _planning) {
        // Check if the provided date exists in the selectedDates array
        return selectedDates.some(selectedDate => date.isSame(selectedDate, 'day'));
    }
    function ServerDay(props) {
        const { day, outsideCurrentMonth, planningData, ...other } = props;

        const isSelected = !outsideCurrentMonth && isHighlighted(dayjs(day));
        const markers = getMarkersForDate(dayjs(day), planningData); // Get markers for the current day

        return (
            <PickersDay
                {...other}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
                onClick={() => handleDayClick(day)}
                sx={{
                    ...(isSelected
                        ? {
                            backgroundColor: "#1976d2", // Blue circle for selected
                            color: "white", // Ensure date text remains white
                            borderRadius: "50%",
                            "&:hover": {
                                backgroundColor: "#115293",
                            },
                        }
                        : {
                            color: outsideCurrentMonth ? "#ccc" : "inherit", // Default color for text
                        }),
                    position: "relative", // Allow absolute positioning for markers
                }}
            >

                <span>{day.format("D")}</span>

                {/* Render dots for markers */}
                {markers.length > 0 && (
                    <div
                        style={{
                            position: "absolute",
                            top: "4px", // Position dots near the top
                            right: "4px", // Align dots to the right
                            display: "flex",
                            gap: "3px", // Space between dots
                        }}
                    >
                        {markers.map((marker, index) => (
                            <span
                                key={index}
                                style={{
                                    width: "5px",
                                    height: "5px",
                                    borderRadius: "50%",
                                    backgroundColor:
                                        marker === "meal"
                                            ? "#FF6347" // Red for meals
                                            : marker === "lodging"
                                                ? "#32CD32" // Green for lodging
                                                : "#FFD700", // Yellow for activities
                                }}
                            ></span>
                        ))}
                    </div>
                )}
            </PickersDay>
        );
    }

    const handleDayClick = (day) => {
        const clickedDate = dayjs(day);

        if (selectedDates.length === 0) {
            // Case 1: No dates selected yet, so the first click selects one date.
            setSelectedDates([clickedDate]);

        } else if (selectedDates.length === 1) {
            // Case 2: Second click selects a second date and fills in all the dates in between.
            const firstDate = selectedDates[0];

            if (clickedDate.isBefore(firstDate)) {
                // If the second clicked date is before the first, fill dates in reverse.
                const newDates = [];
                let currentDate = clickedDate;
                while (currentDate.isBefore(firstDate) || currentDate.isSame(firstDate, 'day')) {
                    newDates.push(currentDate);
                    currentDate = currentDate.add(1, 'day');
                }
                setSelectedDates(newDates);
            } else {
                // Otherwise, fill dates from the first date to the second clicked date.
                const newDates = [];
                let currentDate = firstDate;
                while (currentDate.isBefore(clickedDate) || currentDate.isSame(clickedDate, 'day')) {
                    newDates.push(currentDate);
                    currentDate = currentDate.add(1, 'day');
                }
                setSelectedDates(newDates);
            }

        } else {
            // Case 3: Third or subsequent click.
            const firstDate = selectedDates[0];
            const lastDate = selectedDates[selectedDates.length - 1];

            if (clickedDate.isBefore(firstDate)) {
                // If clicked before the first date, extend the range to the new earlier date.
                const newDates = [];
                let currentDate = clickedDate;
                while (currentDate.isBefore(lastDate) || currentDate.isSame(lastDate, 'day')) {
                    newDates.push(currentDate);
                    currentDate = currentDate.add(1, 'day');
                }
                setSelectedDates(newDates);

            } else if (clickedDate.isAfter(lastDate)) {
                // If clicked after the last date, extend the range to the new later date.
                const newDates = [...selectedDates];
                let currentDate = lastDate.add(1, 'day');
                while (currentDate.isBefore(clickedDate) || currentDate.isSame(clickedDate, 'day')) {
                    newDates.push(currentDate);
                    currentDate = currentDate.add(1, 'day');
                }
                setSelectedDates(newDates);

            } else {
                // If clicked within the current range, toggle the range based on click count.
                const isOddClick = selectedDates.length % 2 !== 0;

                if (isOddClick) {
                    // Shrink the range from the beginning to the clicked date.
                    const newDates = selectedDates.filter(date => date.isSameOrBefore(clickedDate, 'day'));
                    setSelectedDates(newDates);
                } else {
                    // Extend the range from the clicked date to the last date.
                    const newDates = selectedDates.filter(date => date.isSameOrAfter(clickedDate, 'day'));
                    setSelectedDates(newDates);
                }
            }
        }
    };

    // Render custom days Chips layout
    const renderDaysLayout = () => {
        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        // Helper function to get the index of the day of the week (0-6, where 0 = Sunday)
        const getDayOfWeekIndex = (dayName) => daysOfWeek.indexOf(dayName.slice(0, 3));

        // Organize selectedDays into a grid of full weeks (always 7 days per row)
        const weeks = [];
        let currentWeek = Array(7).fill(null); // Start with an empty week (7 slots)

        selectedDays.forEach((day) => {
            const dayIndex = getDayOfWeekIndex(day.value); // Get the index (0-6)

            // Place the day into the correct slot in the current week
            currentWeek[dayIndex] = day;

            // If the last day (Saturday) is reached, store the full week and reset
            if (dayIndex === 6) {
                weeks.push([...currentWeek]); // Store a copy of the array
                currentWeek = Array(7).fill(null); // Reset for next week
            }
        });

        // If any days remain, pad the remaining slots and store the last week
        if (currentWeek.some((day) => day !== null)) {
            weeks.push([...currentWeek]);
        }

        return (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '8px', paddingBottom: '20px' }}>
                {weeks.map((week, weekIndex) => (
                    <Box
                        key={weekIndex}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center', // Centers the week layout
                            gap: '6px', // Small spacing between Chips
                            width: '100%',
                        }}
                    >
                        {week.map((day, dayIndex) => (
                            <Box
                                key={dayIndex}
                                sx={{
                                    flex: '1 1 calc(100% / 7 - 6px)',  // Ensures even width for each slot WITH SPACING
                                    maxWidth: '50px', // Prevents oversized Chips
                                    aspectRatio: '1', // Keeps it square
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {day ? (
                                    <Chip
                                        label={
                                            <Typography variant="body2" sx={{ textAlign: 'center' }}>
                                                {day.value.slice(0, 2)}
                                            </Typography>
                                        }
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgba(0, 123, 255, 0.2)',
                                            borderRadius: '10px', // Rounded corners
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    />
                                ) : (
                                    <Chip
                                        label={
                                            <Typography variant="body2" sx={{ textAlign: 'center', color:'#FFF' }}>
                                                {daysOfWeek[0].slice(0, 2)}
                                            </Typography>
                                        }
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgb(251, 251, 251)',
                                            borderRadius: '10px', // Rounded corners
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    />
                                )}
                            </Box>
                        ))}
                    </Box>
                ))}
            </Box>
        );
    };

    const renderTimePickers = (
        isChecked,             // Toggle for showing time pickers
        beginTimeValue,        // State for the begin time value
        endTimeValue,          // State for the end time value
        handleBeginTimeChange, // Handler for the begin time change
        handleEndTimeChange,   // Handler for the end time change
        prefix                 // A prefix to differentiate between 'days' or 'dates'
    ) => {
        if (!isChecked) return null; // If the switch is off, don't render the time pickers

        return (
            <>
                <Grid item key={`${prefix}_begin_time`} sx={{ marginBottom: '20px' }}>
                    <TimePicker
                        sx={{ width: '100%' }}
                        id={`${prefix}_begin_time_picker`} // Prefix to differentiate
                        label="Begin Time"
                        value={beginTimeValue}
                        onChange={handleBeginTimeChange} // Handles time changes
                    />
                </Grid>
                <Grid item key={`${prefix}_end_time`} sx={{ marginBottom: '20px' }}>
                    <TimePicker
                        sx={{ width: '100%' }}
                        id={`${prefix}_end_time_picker`}
                        label="End Time"
                        value={endTimeValue}
                        onChange={handleEndTimeChange} // Handles time changes
                    />
                </Grid>
            </>
        );
    };


    function buildUpdatedTimeTable() {
        const newTimeTable = new TimeTable();

        const formatTime = (time) => time ? dayjs(time).format('h:mm A') : null;
        const isAllDay = datesBeginTimeValue !== null && datesEndTimeValue !== null;

        // ✨ Process selectedDates
        selectedDates.forEach((_date, index) => {
            const formattedDate = dayjs(_date).format('YYYY-MM-DD');
            const dayOfWeek = dayjs(_date).format('dddd');

            newTimeTable.dates.push({
                date: formattedDate,
                dayOfWeek,
                'all-day': isAllDay,
                'begin_time': formatTime(datesBeginTimeValue),
                'end_time': formatTime(datesEndTimeValue),
                notes: '',
            });

            newTimeTable.days.push({
                [`day ${index + 1}`]: dayOfWeek,
                'all-day': isAllDay,
                'begin_time': formatTime(datesBeginTimeValue),
                'end_time': formatTime(datesEndTimeValue),
                notes: '',
            });
        });

        // If no dates, fallback to selectedDays
        if (selectedDates.length === 0 && selectedDays.length > 0) {
            const dayIsAllDay = daysBeginTimeValue !== null && daysEndTimeValue !== null;

            selectedDays.forEach((day) => {
                newTimeTable.days.push({
                    [day.key]: day.value,
                    'all-day': dayIsAllDay,
                    'begin_time': formatTime(daysBeginTimeValue),
                    'end_time': formatTime(daysEndTimeValue),
                    notes: '',
                });
            });
        }

        console.log("🆕 New TimeTable:", JSON.parse(JSON.stringify(newTimeTable)));

        setEventTimetable(JSON.parse(JSON.stringify(newTimeTable)));

        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning,
                time_table: JSON.parse(JSON.stringify(newTimeTable)),
            },
        }));
    }


    function handlePromptChangeDatesOK() {
        console.log("🟡 handlePromptChangeDatesOK");

        buildUpdatedTimeTable();
    }


    function updateEventComponentsTimeTable(startDate, endDate) {
        const planning = usePA_GlobalStore.getState().PA_GlobalObject.Planning;
        const eventTimeTable = planning.time_table;

        if (!eventTimeTable?.days || !startDate || !endDate) {
            console.warn("Event time_table.days or selected date range is invalid.");
            return;
        }

        // Helper to generate dates and corresponding days
        const generateDatesAndDays = (start, end) => {
            const generatedDates = [];
            const generatedDays = [];
            const dayRangeStart = dayjs(start);
            const dayRangeEnd = dayjs(end);

            let currentDate = dayRangeStart;
            let dayNumber = 1; // Start from day 1

            while (currentDate.isSameOrBefore(dayRangeEnd)) {
                const dayOfWeek = currentDate.format("dddd");

                generatedDates.push({
                    date: currentDate.format("YYYY-MM-DD"),
                    dayOfWeek: dayOfWeek,
                });

                generatedDays.push({
                    [`day ${dayNumber}`]: dayOfWeek,
                });

                currentDate = currentDate.add(1, "day");
                dayNumber++;
            }

            return { generatedDates, generatedDays };
        };

        const { generatedDates, generatedDays } = generateDatesAndDays(startDate, endDate);

        // Update the event time_table
        const updatedEventTimeTable = {
            ...eventTimeTable,
            dates: generatedDates,
            days: generatedDays,
        };

        // Helper function to rebuild component time_table
        const rebuildComponentTimeTable = (component, isLodging = false) => {
            const originalDays = component.time_table?.days || [];
            if (originalDays.length === 0) {
                return component; // Skip if no days are present
            }

            // Regenerate the days array, matching new structure while retaining existing data
            const updatedDays = originalDays.map((day, index) => {
                const dayKey = Object.keys(day)[0];
                const dayNumber = parseInt(dayKey.replace("day ", ""), 10);
                const correspondingGeneratedDay = generatedDays.find(
                    (generatedDay) => Object.keys(generatedDay)[0] === `day ${dayNumber}`
                );

                if (!correspondingGeneratedDay) return null;

                return {
                    ...correspondingGeneratedDay, // Replace with the new day structure
                    begin_time: day.begin_time || (isLodging ? undefined : ""), // Retain existing begin_time
                    ...(isLodging && {
                        checkin_time: day.checkin_time || (index === 0 ? "4:00 PM" : undefined),
                        checkout_time:
                            day.checkout_time ||
                            (index === originalDays.length - 1 ? "10:00 AM" : undefined),
                    }),
                    notes: day.notes || "", // Retain existing notes
                };
            }).filter(Boolean); // Remove any `null` entries

            // Generate updated dates using only the relevant days
            const updatedDates = updatedDays.map((day) => {
                const dayKey = Object.keys(day)[0];
                const dayNumber = parseInt(dayKey.replace("day ", ""), 10);
                const correspondingDate = generatedDates[dayNumber - 1]; // Match by day number

                if (!correspondingDate) return null;

                return {
                    ...correspondingDate,
                    ...(isLodging && {
                        checkin_time: day.checkin_time,
                        checkout_time: day.checkout_time,
                    }),
                    ...(!isLodging && {
                        begin_time: day.begin_time || "",
                    }),
                    notes: day.notes || "",
                };
            }).filter(Boolean); // Remove null entries from mismatched days

            return {
                ...component,
                time_table: {
                    ...component.time_table,
                    dates: updatedDates,
                    days: updatedDays,
                },
            };
        };

        // Update lodging, meals, and activities
        const updatedLodging = planning.lodging.map((lodging) => rebuildComponentTimeTable(lodging, true));
        const updatedMeals = planning.meals.map((meal) => rebuildComponentTimeTable(meal));
        const updatedActivities = planning.activities.map((activity) => rebuildComponentTimeTable(activity));

        // Save the updated components and event time_table back to the global store
        usePA_GlobalStore.setState((prev) => ({
            PA_GlobalObject: {
                ...prev.PA_GlobalObject,
                Planning: {
                    ...prev.PA_GlobalObject.Planning,
                    time_table: updatedEventTimeTable, // Update the event time_table with new days and dates
                    lodging: updatedLodging,
                    meals: updatedMeals,
                    activities: updatedActivities,
                },
            },
        }));

        console.log("Updated Event Components and Event Time Table:", {
            time_table: updatedEventTimeTable,
            lodging: updatedLodging,
            meals: updatedMeals,
            activities: updatedActivities,
        });
    }








    return (

        <div className="dates-times-selection-container" >
            {/* show the header */}
            <div
                className="dates-times-selection-header"
            >
                <div className="dates-times-selection-header-text">
                    <h5>Event Dates / Days</h5>
                </div>
                <div className="dates-times-selection-header-chip-holder">
                    <Chip
                        sx={updateChipStyle}
                        label="Save"
                        size="small"
                        onClick={handlePromptChangeDatesOK}
                    />
                </div>
            </div>


            {/* show the calendar inputs */}

            <div className="dates-times-selection-list-items-container">
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TabContext sx={{ position: 'relative' }} value={tabValue}>
                        <Box
                            sx={{
                                width: '100%',
                                marginTop: '0',
                                borderBottom: 1,
                                borderColor: 'divider',
                                backgroundColor: 'rgba(251, 251, 251, 0.8)',
                                backdropFilter: 'blur(6px)',
                                zIndex: '2'
                            }}
                        >
                            <TabList
                                centered
                                variant="fullWidth"
                                onChange={handleTabValueChange}
                                aria-label="Event Dates / Days"
                            >
                                <Tab
                                    label={datesCount > 0 ? (datesCount === 1 ? '1 Date' : `${datesCount} Dates`) : 'Dates'}
                                    value="dates"
                                />
                                <Tab
                                    label={eventDaysCount > 0 ? (eventDaysCount === 1 ? '1 Day' : `${eventDaysCount} Days`) : 'Days'}
                                    value="days"
                                />
                            </TabList>
                        </Box>

                        {/* Dates Tab Panel */}
                        <TabPanel value="dates" sx={{ padding: '0 10px 10px 10px' }}>
                            {/* Read-only calendar */}
                            <Box>
                                <DateCalendar
                                    value={selectedDates.length > 0 ? selectedDates[0] : dayjs()}
                                    renderLoading={() => <DayCalendarSkeleton />}
                                    showDaysOutsideCurrentMonth
                                    slots={{
                                        day: ServerDay,
                                    }}
                                    slotProps={{
                                        day: { planningData: usePA_GlobalStore.getState().PA_GlobalObject.Planning },
                                    }}
                                />
                            </Box>

                            <Box className="dates-times-selection-times-text" sx={{ marginBottom: '20px' }}>
                                Is this an Event with Begin and End times?
                                If so, choose the start time and end time.
                            </Box>

                            <Box className="dates-times-selection-times-text" sx={{ textAlign: 'right', marginBottom: '20px' }}>
                                <Switch
                                    checked={datesTimeOptionChecked}
                                    onChange={(e) => setDatesTimeOptionChecked(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Box>

                            {/* Time Pickers for Dates */}
                            <Box>
                                {renderTimePickers(
                                    datesTimeOptionChecked,
                                    datesBeginTimeValue,
                                    datesEndTimeValue,
                                    handleUpdatedDatesBeginTime,
                                    handleUpdatedDatesEndTime,
                                    'dates'
                                )}
                            </Box>
                        </TabPanel>

                        {/* Days Tab Panel */}
                        <TabPanel value="days" sx={{ padding: '0 10px 10px 10px' }}>
                            <Box className="dates-times-selection-times-text" sx={{ marginBottom: '20px' }}>
                                {renderDaysLayout()}
                            </Box>

                            <Box sx={{ marginBottom: '20px' }}>
                                <TextField
                                    fullWidth
                                    id="event_date_days"
                                    label="Number of Days"
                                    value={eventDaysCount}
                                    onChange={handleEventDaysCountChange}
                                    type="number"
                                    inputProps={{ min: 1 }}
                                />
                            </Box>

                            <Box sx={{ marginBottom: '20px' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="event-days-select-label">Starting On Day</InputLabel>
                                    <Select
                                        labelId="event-days-select-label"
                                        id="event_days_select"
                                        value={eventDaysStartingOnDay}
                                        label="Starting On Day"
                                        onChange={handleEventDaysStartingOnChange}
                                    >
                                        <MenuItem value="">Not Selected</MenuItem>
                                        <MenuItem value="Sunday">Sunday</MenuItem>
                                        <MenuItem value="Monday">Monday</MenuItem>
                                        <MenuItem value="Tuesday">Tuesday</MenuItem>
                                        <MenuItem value="Wednesday">Wednesday</MenuItem>
                                        <MenuItem value="Thursday">Thursday</MenuItem>
                                        <MenuItem value="Friday">Friday</MenuItem>
                                        <MenuItem value="Saturday">Saturday</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box className="dates-times-selection-times-text" sx={{ marginBottom: '20px' }}>
                                Is this an Event with Begin and End times?
                                If so, choose the start time and end time.
                            </Box>

                            <Box className="dates-times-selection-times-text" sx={{ textAlign: 'right', marginBottom: '20px' }}>
                                <Switch
                                    checked={daysTimeOptionChecked}
                                    onChange={(e) => setDaysTimeOptionChecked(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Box>

                            {/* Time Pickers for Days */}
                            <Box>
                                {renderTimePickers(
                                    daysTimeOptionChecked,
                                    daysBeginTimeValue,
                                    daysEndTimeValue,
                                    handleUpdatedDaysBeginTime,
                                    handleUpdatedDaysEndTime,
                                    'days'
                                )}
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>

        </div>
    )

}

export default DatesTimesContent;
