import React, {useContext, useEffect, useState} from 'react';
import {
    Avatar, Button,
    Card,
    CardActions,
    CardContent,
    CardHeader, Checkbox, CircularProgress,
    Dialog, DialogActions, DialogContent,
    DialogTitle, FormControlLabel,
    Menu,
    MenuItem,
    MenuList, TextField
} from "@mui/material";
import {red} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import {capitalizeFirstLetter} from "../../Helpers/TextStyling";
import {PAContext} from "../../../Services/PAContext";


function AttendeeCard(props) {

    const { dataArray, removeFirstIncomingAdminsActions } = useContext(PAContext);
    const { AdminsActions } = dataArray;

    const [attendee, setAttendee] = useState(props.attendee);

    useEffect(()=> {
        setAttendee(props.attendee);
    }, [props.attendee]);


    const formatPhoneNumber = (prefix, phone) => {
        return `${prefix} ${phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}`;
    };

    const [anchorAttendeeListItemMenu, setAnchorAttendeeListItemMenu] = useState(null);
    const handleAttendeeListItemMenuOpen = (event) => {
        setAnchorAttendeeListItemMenu(event.currentTarget);
    };
    const handleAttendeeListItemMenuClose = () => {
        setAnchorAttendeeListItemMenu(null);
    };

    const [open, setOpen] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const [processing, setProcessing] = useState(false);
    const [success, setSuccess] = useState(false);

    const encode64 = (input) => btoa(unescape(encodeURIComponent(input)));

    const handleOpen = () => {
        handleAttendeeListItemMenuClose();  // ✅ Close the menu before opening dialog
        setOpen(true);
    };
    const handleClose = () => {
        setPassword("");
        setConfirmPassword("");
        setError("");
        setShowPassword(false);
        setOpen(false);
    };
    const handleSubmit = () => {
        if (password !== confirmPassword) {
            setError("Passwords do not match!");
            return;
        }
        setProcessing(true);
        setError("");

        console.log("attendee : ", attendee);

        const _send_string = {
            action: "adminServicesSetAttendeesAccountPassword",
            attendee_id: attendee.id,
            password: encode64(password),
            password_confirm: encode64(confirmPassword)
        };

        console.log("_send_string : ", _send_string);

        dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");
    };


    useEffect(() => {
        const processAdminPipeline = async () => {
            for (const _incoming of AdminsActions) {
                console.log("_incoming:", JSON.stringify(_incoming, null, "\t"));

                if (_incoming.result === "ok") {
                    if (_incoming.action === "adminServicesSetAttendeesAccountPasswordResults") {
                        setSuccess(true);
                        setProcessing(false);
                    }
                } else {
                    console.error("Error processing action:", _incoming.reason);
                    if (_incoming.action === "adminServicesSetAttendeesAccountPasswordResults") {
                        setError(_incoming.reason);
                        setProcessing(false);
                    }
                }

                removeFirstIncomingAdminsActions();
            }
        };

        processAdminPipeline();
    }, [AdminsActions]);


    
    return (

        <Card
            sx={{
                width: '250px', // Fixed width for each card
                flexShrink: 0, // Prevent cards from shrinking
                padding: '16px',
                margin: '16px',
                borderRadius: '8px'
            }}
        >

            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        {capitalizeFirstLetter(attendee.fname.charAt(0))}
                    </Avatar>
                }
                title={`${capitalizeFirstLetter(attendee.fname)} ${capitalizeFirstLetter(attendee.lname)}`}
                subheader={dayjs(attendee.created).format('MMM D, YYYY')}
                subheaderTypographyProps={{ sx: { fontSize: '11px', color: 'text.secondary' } }} // Change font size and color
                action={
                    <i
                        className="icon-menu2"
                        onClick={handleAttendeeListItemMenuOpen}
                    />
                }
            />
            <Menu
                sx={{ width: 320, maxWidth: '100%' }}
                anchorEl={anchorAttendeeListItemMenu}
                id="activity-item-menu"
                open={Boolean(anchorAttendeeListItemMenu)}
                onClose={handleAttendeeListItemMenuClose}

                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuList
                    dense
                    sx={{ width: 320, maxWidth: '100%' }}
                >
                    <MenuItem
                        data-id="paswword"
                        onClick={handleOpen}
                    >
                        <ListItemIcon sx={{color:'#F00'}}>
                            <i className="icon-lock2" title="Set Password"/>
                        </ListItemIcon>
                        <ListItemText>Set Password</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
            <CardContent sx={{display:'grid'}}>
                <Typography variant="subtitle2">
                    Nickname : {attendee.nickname}
                </Typography>

                <Typography variant="caption" color="text.secondary">
                    {attendee.email}
                </Typography>

                <Typography variant="caption" color="text.secondary">
                    Role : {attendee.role}
                </Typography>

                <Typography variant="caption" color="text.secondary">
                    Phone: {formatPhoneNumber(attendee.phone_prefix, attendee.phone)}
                </Typography>

                <Typography variant="caption" color="text.secondary">
                    Language : {attendee.language}
                </Typography>
            </CardContent>

            <CardActions
                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly', // Evenly distribute space between items
                    alignItems: 'center',
                }}
            >
                {/* Events */}
                <Badge badgeContent={attendee.events.length} color="primary">
                    <i className="icon-calendar" title="Events"/>
                </Badge>

                {/* Menu Items */}
                <Badge badgeContent={attendee.menu_items.length} color="primary">
                    <i className="icon-folder-heart" title="Categories"/>
                </Badge>

                {/* Essentials */}
                <Badge badgeContent={attendee.essentials.length} color="primary">
                    <i className="icon-checklist2" title="Essentials"/>
                </Badge>

                {/* Documents */}
                <Badge badgeContent={attendee.documents.length} color="primary">
                    <i className="icon-file-check" title="Documents"/>
                </Badge>
            </CardActions>

            <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
                <DialogTitle>{success ? "✅ Password Set Successfully!" : "Set The Password"}</DialogTitle>
                <DialogContent>
                    {success ? (
                        <Typography color="success.main">🎉 Password changed successfully!</Typography>
                    ) : (
                        <>
                            <TextField label="New Password" type={showPassword ? "text" : "password"} fullWidth margin="dense" value={password} onChange={(e) => setPassword(e.target.value)} error={Boolean(error)} helperText={error} />
                            <TextField label="Confirm Password" type={showPassword ? "text" : "password"} fullWidth margin="dense" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} error={Boolean(error)} helperText={error} />
                            <FormControlLabel control={<Checkbox checked={showPassword} onChange={() => setShowPassword(!showPassword)} />} label="Show Password" sx={{ marginTop: "10px" }} />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {success ? <Button onClick={handleClose} color="primary" variant="contained">Done</Button> : <>
                        <Button onClick={handleClose} color="secondary" disabled={processing}>Cancel</Button>
                        <Button onClick={handleSubmit} color="primary" variant="contained" disabled={processing}>{processing ? <CircularProgress size={24} color="inherit" /> : "Submit"}</Button>
                    </>}
                </DialogActions>
            </Dialog>
        </Card>

    )

}

export default AttendeeCard;
