import React, {useContext, useEffect, useState} from 'react';

import {useNavigate} from "react-router-dom";
import {
    Button, Checkbox,
    Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider,
    FormControl, FormControlLabel,
    InputLabel, ListItemText, MenuItem, Select,
    Switch,
    TextField
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {PAContext} from "../../../Services/PAContext";
import Box from "@mui/material/Box";
import './supplies.css';
import Typography from "@mui/material/Typography";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {addChipStyle} from "../../ButtonStyle/Add";
import SupplyItem from "../../../MasterEventObjects/SupplyItem";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";



function SuppliesAddNew(props) {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Account, Attendees, SampleAttendees, Categories } = dataArray; // Fetch event attendees

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated && !PA_GlobalObject.Planning.is_template
        ? Attendees
        : SampleAttendees);
    useEffect(() => {
        setAttendeesReferenceArray(
            dataArray.authenticated
                ? Attendees
                : SampleAttendees
        );
    }, [dataArray.authenticated, Attendees, SampleAttendees]);

    const [supplyItem, setSupplyItem] = useState(() => {
        const newSupplyItem = new SupplyItem();
        newSupplyItem.assigned_by = Account.id ? Account.id : attendeesReferenceArray[0].id;  // Set created_by dynamically
        newSupplyItem.assigned_to = Account.id ? [Account.id] : [attendeesReferenceArray[0].id];
        return newSupplyItem;
    });
    useEffect(() => {
        console.log("supplyItem : ", supplyItem);
    }, [supplyItem]);

    const handleChange = (field, value) => {
        setSupplyItem((prev) => ({ ...prev, [field]: value }));
    };

    function handleSuppliesAddOK() {
        console.log("handleSuppliesAddOK : ", supplyItem);

        if (!supplyItem.name.trim()) return alert("Name is required.");

        // Generate separate SupplyItem objects for each assigned attendee
        const newSupplyItems = supplyItem.assigned_to.map((attendeeId) => ({
            ...supplyItem,
            assigned_to: attendeeId, // Each To-Do has a single assigned attendee
        }));

        console.log("newSupplyItems",newSupplyItems);
        // Update the global state, adding the new To-Dos to the existing list
        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                supplies: [...(prev.Planning.supplies || []), ...newSupplyItems], // Append new supply items
            },
        }));

        //if they selected to add the supply item as an essential
        //then we need to add it to their account

        if (supplyItem.essential) {
            const _send_string = {};
            _send_string.action = "E_addNewAccountEssentialItem";
            _send_string.essential_item = supplyItem;

            console.log("_sendASteint : ", _send_string);

            dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");
        }

        //clear out the supply item name
        handleChange('name', '');

    }

    function handlePromptAddNewClose() {
        props.setShowEventItemExtendedConfig(false);
        navigate('/Planner/Supplies')
    }

    const [selectedCategory, setSelectedCategory] = useState('supplies');
    const [subCategoryOptions, setSubCategoryOptions] = useState(Categories);

    useEffect(() => {
        //populate the subCategoryOptions based on the selectedCategory

        if (Categories !== undefined && Categories.length > 0) {
            const selectedCategoryName = selectedCategory === "supplies" ? "Supply Categories" : "Food Categories";
            const selectedCategoryObject = Categories.find((_cat) => _cat.category_name === selectedCategoryName);

            if (selectedCategoryObject !== undefined) {
                const sortedSubCategoryOptions = [...selectedCategoryObject.categories].sort();

                console.log("sortedSubCategoryOptions : ", sortedSubCategoryOptions);

                setSubCategoryOptions(sortedSubCategoryOptions);
            } else {
                // Handle the case where selectedCategoryObject is undefined
                console.error(`Category "${selectedCategoryName}" not found in Categories`);
            }

        }

    }, [Categories]);

    const [openCategoryExplanationDialog, setOpenCategoryExplanationDialog] = useState(false);

    function handleOpenCategoryExplanationDialog (){
        setOpenCategoryExplanationDialog(true);
    }

    function handleCategoryExplanationDialogClose(){
        setOpenCategoryExplanationDialog(false);
    }



    return (

        <div className="supplies-list-container" >
            {/* show the header */}
            <div
                className="supplies-list-header"
            >
                <div className="supplies-list-header-text">
                    <h5>New Supplies</h5>
                </div>
                <div className="supplies-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        color="error"
                        onClick={handlePromptAddNewClose}
                    />
                    <Chip
                        sx={addChipStyle}
                        label="Add"
                        size="small"
                        onClick={handleSuppliesAddOK}
                    />
                </div>
            </div>


            {/* show all the fields */}
            <div className="supplies-list-add-item-container">
                <Grid container spacing={2} direction="column">

                    <Grid item style={{ flex: '1' }}>
                        <TextField
                            size="small"
                            fullWidth
                            id="name"
                            label="Name"
                            required
                            value={supplyItem.name}
                            onChange={(e) => handleChange("name", e.target.value)}
                        />
                    </Grid>

                    <Grid item style={{ flex: '1', position:'relative' }}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel id="demo-simple-select-standard-label">Category</InputLabel>
                            <Select
                                size="small"
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={supplyItem.category}
                                onChange={(e) => handleChange("category", e.target.value)}
                                label="Category"
                            >
                                <MenuItem value="supplies">
                                    <em>Supplies</em>
                                </MenuItem>
                                <MenuItem value="food">
                                    <em>Food</em>
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <i
                            className="icon-question-circle"
                            style={{position:'absolute', top:'15px', right:'0', color:'green', cursor:'pointer'}}
                            onClick={handleOpenCategoryExplanationDialog}
                        />
                        <Dialog
                            open={openCategoryExplanationDialog}
                            onClose={handleCategoryExplanationDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Organization and Flexibility"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Knowing the Attendees address allows us to provide the following:
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    <strong>Transportation</strong> : We can show routes to the destination
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    <strong>Flights</strong> : We can show flight prices for trips where a flight might be involved.
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    <strong>Timezone</strong> : Before and after the trip we can send scheduled reminders at the correct time.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCategoryExplanationDialogClose} autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>

                    <Grid item style={{ flex: '1' }}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel id="demo-simple-select-standard-label">Sub Category</InputLabel>
                            <Select
                                size="small"
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={supplyItem.sub_category}
                                onChange={(e) => handleChange("sub_category", e.target.value)}
                                label="Sub Category"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {
                                    subCategoryOptions.map((_item, index) => (
                                        <MenuItem key={index} value={_item} >
                                            <em>{_item}</em>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item style={{ flex: '1' }}>

                        {/* Assign To */}
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Assign To</InputLabel>
                            <Select
                                size="small"
                                multiple
                                label="Assign To"
                                value={supplyItem.assigned_to}
                                onChange={(e) => handleChange("assigned_to", e.target.value)}
                                renderValue={(selected) =>
                                    selected
                                        .map(id => attendeesReferenceArray.find(att => att.id === id)) // Convert IDs to attendee objects
                                        .filter(Boolean) // Remove any undefined values (if ID is not found)
                                        .sort((a, b) => a.fname.localeCompare(b.fname)) // Sort by first name
                                        .map(att => att.nickname || `${att.fname} ${att.lname}`) // Get display name
                                        .join(", ")
                                }
                            >
                                {(() => {
                                    // Get attendees already in the event
                                    const eventAttendees = usePA_GlobalStore
                                        .getState()
                                        .PA_GlobalObject.Planning.attendees
                                        .map(att => att.id); // Extract IDs

                                    // Separate attendees into event attendees & other attendees
                                    const assignedEventAttendees = attendeesReferenceArray
                                        .filter(att => eventAttendees.includes(att.id)) // Attendees already in the event
                                        .sort((a, b) => a.fname.toLowerCase().localeCompare(b.fname.toLowerCase())); // Sort alphabetically

                                    const otherAttendees = attendeesReferenceArray
                                        .filter(att => !eventAttendees.includes(att.id)) // Not in event
                                        .sort((a, b) => a.fname.toLowerCase().localeCompare(b.fname.toLowerCase())); // Sort alphabetically

                                    // Return an array instead of a Fragment
                                    const menuItems = [];

                                    // Event Attendees
                                    assignedEventAttendees.forEach(att => {
                                        menuItems.push(
                                            <MenuItem key={att.id} value={att.id} disableRipple>
                                                <Checkbox
                                                    checked={supplyItem.assigned_to.includes(att.id)}
                                                    onChange={(e) => handleChange("assigned_to", e.target.value)}
                                                />
                                                <ListItemText primary={att.nickname || `${att.fname} ${att.lname}`} />
                                            </MenuItem>
                                        );
                                    });

                                    // Add Divider if both lists have attendees
                                    if (assignedEventAttendees.length > 0 && otherAttendees.length > 0) {
                                        menuItems.push(
                                            <MenuItem key="divider" disabled>
                                                <Divider sx={{ width: "100%" }} />
                                            </MenuItem>
                                        );
                                    }

                                    // Other Attendees
                                    otherAttendees.forEach(att => {
                                        menuItems.push(
                                            <MenuItem key={att.id} value={att.id} disableRipple>
                                                <Checkbox
                                                    checked={supplyItem.assigned_to.includes(att.id)}
                                                    onChange={(e) => handleChange("assigned_to", e.target.value)}
                                                />
                                                <ListItemText primary={att.nickname || `${att.fname} ${att.lname}`} />
                                            </MenuItem>
                                        );
                                    });

                                    return menuItems; // Return array instead of Fragment
                                })()}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Box>
                        <Typography variant="subtitle2" sx={{ marginTop: 2 }}>Privacy</Typography>
                        <FormControlLabel
                            control={<Switch checked={supplyItem.private} onChange={() => handleChange("private", !supplyItem.private)} />}
                            label={
                                <Typography sx={{ fontSize: "14px" }}>
                                    {supplyItem.private ? "Private (Only You)" : "Public (Visible to Attendees)"}
                                </Typography>
                            }
                        />
                    </Box>

                    <Box>
                        <Typography variant="subtitle2" sx={{ marginTop: 2 }}>Essentials</Typography>
                        <Typography variant="caption" sx={{ marginTop: 2 }}>Add this item to the Essentials list that is available for each event.
                            Note: You can add items for other attendees but it won't mark it as an essential.
                            You can only add essential items for your account.</Typography>
                        {/* Show message when not authenticated */}
                        {!dataArray.authenticated && (
                            <Typography sx={{ fontSize: "12px", color: "red", margin: "5px 0" }}>
                                You must be logged in to use the Essentials feature.
                            </Typography>
                        )}

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={supplyItem.essential}
                                    onChange={() => handleChange("essential", !supplyItem.essential)}
                                    disabled={!dataArray.authenticated} // Disable when not authenticated
                                />
                            }
                            label={
                                <Typography sx={{ fontSize: "14px", color: !dataArray.authenticated ? "gray" : "inherit" }}>
                                    {supplyItem.essential ? "Will be added to Essentials" : "Will not add to Essentials"}
                                </Typography>
                            }
                        />
                    </Box>
                </Grid>

            </div>


            {/* show the summary area */}
            <div className="supplies-list-items-summary-container">


            </div>

        </div>

    )
}

export default SuppliesAddNew;
