
import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import { useLocation } from 'react-router-dom';
import PAAttendee from "../MasterEventObjects/Attendee";
import sample_attendees from "../sampleData/Attendees";

import {
    addEventCoverImageFullSizeAndThumbnail,
    addEventCoverImageThumbnail,
    addKeyValue, browserStorageAddEvent,
    browserStorageUpdateAllEvents, browserStorageUpdateTemplates,
    getAllEventsFromDatabase, getAllTemplatesFromDatabase,
    removeEventsFromDatabase
} from './BrowserDatabaseFunctions';
import {openDb} from "./BrowserDatabaseIDB";
import E_uploadEventAttendeePhotosResults, {
    E_deleteEventPhotosResults,
    E_getEventFullSizeImageResults,
    E_getEventThumbnailImageResults,
    E_updateEventAttendeePhotoPrivateResults,
    E_uploadEventAttendeePhotoThumbnailResults, E_uploadEventImageResults
} from "../components/EventFeatures/IncomingActions/IncomingEventPhotoActions";
import E_getEventChangesResults, {
    E_updateEventNameResults
} from "../components/EventFeatures/IncomingActions/IncomingEventActions";
import {
    E_addEventActivityResults,
    E_deleteEventActivityResults,
    E_updateEventActivityResults
} from "../components/EventFeatures/IncomingActions/IncomingEventActivities";
import {
    E_getEventMessagesResults
} from "../components/EventFeatures/IncomingActions/IncomingEventMessages";
import PAEvent from "../MasterEventObjects/PAEvent";
import E_setEventAllChangesResults from "../components/EventFeatures/IncomingActions/IncomingEventActions";

import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import TimeTable from "../MasterEventObjects/TimeTable";
import Attendee from "../MasterEventObjects/Attendee";
import Lodging from "../MasterEventObjects/Lodging";
import PALocation from "../MasterEventObjects/Location";
import Activity from "../MasterEventObjects/Activity";
import Meal from "../MasterEventObjects/Meal";
import Idea from "../MasterEventObjects/Idea";
import Flight from "../MasterEventObjects/Flight";
import {
    createTimeTableForActivity, createTimeTableForFlight,
    createTimeTableForLodging,
    createTimeTableForMeal
} from "../components/Helpers/EventTimeTableConversion";
import {convertEventVersion1To2} from "../components/Helpers/EventConverter";
import usePA_GlobalStore from "./ZustandGlobalStore";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);



const PAContext = createContext();


const PAContextProvider = ({ children }) => {
    console.log("PAContextProvider");

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const location = useLocation();

    let mdnIncomingTotalMessageSize = 0;
    let mdnIncomingReceivedMessageSize = 0;

    let leftover_incoming_stuff = "";
    let incoming_stuff_length = 0;

    const [ dataArray, setDataArray ] = useState({
        Websocket: null,
        ws_connected: false,
        isMobileView: false,
        showSuccessSnackbar: false,
        progressBar: 0,
        sideMenuWidth: 0,
        Account: {},
        Categories: [],
        Attendees: [],
        SampleAttendees: sample_attendees.map((_att) => ({
            ..._att,
            isSample: true
        })),
        Events: [],

        IncomingActions: [],
        IncomingEventActions: [],
        ConciergeActions: [],
        ConciergeMapActions: [],

        ConciergeScanningDocumentActions: [],

        FeaturesPipeline: [],
        LibraryPipeline: [],
        MapPipeline: [],
        ConciergePipeline: [],
        FlightPlanningPipeline: [],
        SuggestionsPipeline: [],
        SignupPipeline: [],
        EventUpgradePipeline: [],
        SuppliesPipeline: [],
        DialogPipeline: [],

        authenticated: false,
        loginFailed: false,
        loading: false,
        loadingProgress: 0,

        ConciergePlanning: [],

        SuggestionsLodging: [],
        SuggestionsFlights: [],
        SuggestionsMeals: [],
        SuggestionsTours: [],
        SuggestionsUnique: [],

        PlanningLibrary: [],
        Partnerships: [],
        workingOnEventId: "",

        AdminsActions: [],

    });
    const dataArrayRef = useRef(dataArray);
    const userAccount = useRef(null);
    const [ userAuthenticated, setUserAuthenticated ] = useState(false);

    useEffect(() => {
        dataArrayRef.current = dataArray;
    }, [dataArray]);

    useEffect(() => {
        console.log("----------------------- the userAccount has been updated : "+JSON.stringify(userAccount, null, '\t'));
    }, [userAccount]);
    useEffect(() => {
        console.log("----------------------- the userAuthenticated has been updated : "+JSON.stringify(userAuthenticated, null, '\t'));
    }, [userAuthenticated]);



    const setWsConnected = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            ws_connected: value,
        }));
    };
    const setIsMobileView = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            isMobileView: value,
        }));
    };
    const setShowSuccessSnackbar = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            showSuccessSnackbar: value,
        }));
    };
    const setProgressBar = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            progressBar: value,
        }));
    };
    const setSideMenuWidth = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            sideMenuWidth: value,
        }));
    };
    const setAccount = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Account: value,
        }));
    };
    const clearAccount = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Account: {},
        }));
    };

    const addEvent = (newEvent) => {

        console.log("adding an Event to dataArray.Events!! : "+newEvent.title+" : "+newEvent.name);
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Events: [...prevDataArray.Events, newEvent],
        }));
    };
    const updateEvent = (updatedEvent) => {
        console.log("Updating an Event in dataArray.Events: ", updatedEvent);

        setDataArray((prevDataArray) => {
            // Find the index of the event to update
            const eventIndex = prevDataArray.Events.findIndex(event => event.id === updatedEvent.id);

            if (eventIndex !== -1) {
                // Update the existing event
                const updatedEvents = [...prevDataArray.Events];
                updatedEvents[eventIndex] = updatedEvent;

                return {
                    ...prevDataArray,
                    Events: updatedEvents,
                };
            } else {
                console.warn("Event not found. No update performed.");
                return prevDataArray; // No change if the event doesn't exist
            }
        });
    };
    const updateEventLodging = (event_id, updatedEventLodgingArray) => {
        setDataArray((prevDataArray) => {
            // Find the index of the event to update
            const index = prevDataArray.Events.findIndex((event) => event.id === event_id);

            if (index !== -1) {
                // If the event exists, replace the lodging with the updated Lodgings
                const updatedEvents = [...prevDataArray.Events];
                updatedEvents[index].lodging = updatedEventLodgingArray;
                updatedEvents[index].need_to_save = true;

                return {
                    ...prevDataArray,
                    Events: updatedEvents,
                };
            } else {
                // If the event doesn't exist, simply return the previous state
                return prevDataArray;
            }
        });
    };
    const updateEventActivities = (event_id, updatedEventActivitiesArray) => {
        setDataArray((prevDataArray) => {
            // Find the index of the event to update
            const index = prevDataArray.Events.findIndex((event) => event.id === event_id);

            if (index !== -1) {
                // If the event exists, replace the activities with the updated Activities
                const updatedEvents = [...prevDataArray.Events];
                updatedEvents[index].activities = updatedEventActivitiesArray;
                updatedEvents[index].need_to_save = true;

                return {
                    ...prevDataArray,
                    Events: updatedEvents,
                };
            } else {
                // If the event doesn't exist, simply return the previous state
                return prevDataArray;
            }
        });
    };
    const updateEventMeals = (event_id, updatedEventMealsArray) => {
        setDataArray((prevDataArray) => {
            // Find the index of the event to update
            const index = prevDataArray.Events.findIndex((event) => event.id === event_id);

            if (index !== -1) {
                // If the event exists, replace the meals with the updated Meals
                const updatedEvents = [...prevDataArray.Events];
                updatedEvents[index].meals = updatedEventMealsArray;
                updatedEvents[index].need_to_save = true;

                return {
                    ...prevDataArray,
                    Events: updatedEvents,
                };
            } else {
                // If the event doesn't exist, simply return the previous state
                return prevDataArray;
            }
        });
    };
    const updateEventFlights = (event_id, updatedEventFlightsArray) => {
        setDataArray((prevDataArray) => {
            // Find the index of the event to update
            const index = prevDataArray.Events.findIndex((event) => event.id === event_id);

            if (index !== -1) {
                // If the event exists, replace the flights with the updated Flights
                const updatedEvents = [...prevDataArray.Events];
                updatedEvents[index].flights = updatedEventFlightsArray;
                updatedEvents[index].need_to_save = true;

                return {
                    ...prevDataArray,
                    Events: updatedEvents,
                };
            } else {
                // If the event doesn't exist, simply return the previous state
                return prevDataArray;
            }
        });
    };
    const updateEventMessages = (_incoming) => {
        console.log("updateEventMessages");

        setDataArray((prevDataArray) => {
            // Find the index of the event to update
            const index = prevDataArray.Events.findIndex((event) => event.id === _incoming.event_id);

            if (index !== -1) {
                // If the event exists, replace the messages with the updated Messages
                const updatedEvents = [...prevDataArray.Events];

                // Combine current messages with new, checking for duplicates
                const currentMessageIds = new Set(updatedEvents[index].messages.map(msg => msg.id));
                const newMessages = _incoming.messages_list.filter(msg => !currentMessageIds.has(msg.id));

                // Proceed with adding the new messages if they are not already present
                const updatedMessages = [...updatedEvents[index].messages, ...newMessages];

                // Calculate the latest timestamp
                const latest_message_timestamp = updatedMessages.reduce((latest, message) => {
                    return latest > message.created ? latest : message.created;
                }, 0); // Start with 0 or an initial very old timestamp

                updatedEvents[index].messages = updatedMessages;
                updatedEvents[index].latest_message_timestamp = latest_message_timestamp;
                updatedEvents[index].need_to_save = true;

                return {
                    ...prevDataArray,
                    Events: updatedEvents,
                };
            } else {
                // If the event doesn't exist, simply return the previous state
                return prevDataArray;
            }
        });
    };
    const clearEvents = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Events: [],
        }));
    };

    const addCategory = (newCategory) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Categories: [...prevDataArray.Categories, newCategory],
        }));
    };
    const clearCategories = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Categories: [],
        }));
    };

    const addAttendee = (newAttendee) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Attendees: [...prevDataArray.Attendees, newAttendee],
        }));
    };
    const updateAttendee = (updatedAttendee) => {

        console.log("updating the specific Attendee : ", updatedAttendee);

        //find the Attendee and update it
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Attendees: [...prevDataArray.Attendees.map((attendee) =>
                attendee.id === updatedAttendee.id ? updatedAttendee : attendee
            )]
        }));
    };
    const clearAttendees = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Attendees: [],
        }));
    };
    const addIncomingActions = (newIncomingActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            IncomingActions: [...prevDataArray.IncomingActions, newIncomingActions],
        }));
    };
    const addIncomingEventActions = (newIncomingEventActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            IncomingEventActions: [...prevDataArray.IncomingEventActions, newIncomingEventActions],
        }));
    };
    const addIncomingConciergeActions = (newIncomingConciergeActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            ConciergeActions: [...prevDataArray.ConciergeActions, newIncomingConciergeActions],
        }));
    };
    const addIncomingConciergeMapActions = (newIncomingConciergeMapActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            ConciergeMapActions: [...prevDataArray.ConciergeMapActions, newIncomingConciergeMapActions],
        }));
    };

    const addIncomingConciergeScanningDocumentActions = (newIncomingConciergeScanningDocumentActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            ConciergeScanningDocumentActions: [...prevDataArray.ConciergeScanningDocumentActions, newIncomingConciergeScanningDocumentActions],
        }));
    };

    const addIncomingFlightPlanningActions = (newIncomingFlightPlanningActions) => {
        console.log('Incoming Flights:', newIncomingFlightPlanningActions);

        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            FlightPlanningPipeline: [...prevDataArray.FlightPlanningPipeline, newIncomingFlightPlanningActions],
        }));

    };


    const addIncomingConciergeSuggestionActions = (newIncomingConciergeSuggestionActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsPipeline: [...prevDataArray.SuggestionsPipeline, newIncomingConciergeSuggestionActions],
        }));
    };

    const addIncomingConciergeSignupActions = (newIncomingConciergeSignupActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SignupPipeline: [...prevDataArray.SignupPipeline, newIncomingConciergeSignupActions],
        }));
    };

    const removeFirstConciergeSignupActions = () => {
        setDataArray(prevDataArray => ({
            ...prevDataArray,
            SignupPipeline: prevDataArray.SignupPipeline.slice(1), // Removes the first element
        }));
    };

    const addIncomingEventUpgradeActions = (newIncomingEventUpgradeActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            EventUpgradePipeline: [...prevDataArray.EventUpgradePipeline, newIncomingEventUpgradeActions],
        }));
    };
    const removeFirstEventUpgradeActions = () => {
        setDataArray(prevDataArray => ({
            ...prevDataArray,
            EventUpgradePipeline: prevDataArray.EventUpgradePipeline.slice(1), // Removes the first element
        }));
    };

    const addIncomingSuppliesActions = (newIncomingSuppliesActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuppliesPipeline: [...prevDataArray.SuppliesPipeline, newIncomingSuppliesActions],
        }));
    };
    const removeFirstSuppliesActions = () => {
        setDataArray(prevDataArray => ({
            ...prevDataArray,
            SuppliesPipeline: prevDataArray.SuppliesPipeline.slice(1), // Removes the first element
        }));
    };
    const setAuthenticated = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            authenticated: value,
        }));
    };
    const setLoginFailed = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            loginFailed: value,
        }));
    };
    const setPlanning = (_new_planning_event) => {
        console.log("setPlanning _new_planning_event : "+JSON.stringify(_new_planning_event, null, '\t'));

        if (_new_planning_event.title !== ''){
            setDataArray((prevDataArray) => ({
                ...prevDataArray,
                Planning: _new_planning_event
            }));

            /*
                see if there's an Event in browser storage that needs to be updated as well
             */

        }

    };

    function updatePlanningTimeTable(){
        console.log("updatePlanningTimeTable");

        /*
            go through all of the different features (activities, flights, lodging, meals) to update the Event timetable
            so it encompasses all of the dates/days
         */

        const { activities, meals, lodging, flights } = dataArray.current.Planning;

        let minDate = null;
        let maxDate = null;

        // Helper function to update min and max dates
        const updateMinMaxDates = (date) => {
            const parsedDate = dayjs(date, 'MM/DD/YYYY'); // Assuming date format is MM/DD/YYYY
            if (!parsedDate.isValid()) return;

            if (minDate === null || parsedDate.isBefore(minDate)) {
                minDate = parsedDate;
            }
            if (maxDate === null || parsedDate.isAfter(maxDate)) {
                maxDate = parsedDate;
            }
        };

        // Process Activities (assuming they have begin_date and end_date)
        activities.forEach((activity) => {
            updateMinMaxDates(activity.begin_date);
            updateMinMaxDates(activity.end_date);
        });

        // Process Meals (assuming they have a date)
        meals.forEach((meal) => {
            updateMinMaxDates(meal.date);
        });

        // Process Lodgings (assuming they have begin_date and end_date)
        lodging.forEach((_lodging) => {
            updateMinMaxDates(_lodging.begin_date);
            updateMinMaxDates(_lodging.end_date);
        });

        // Process Flights (assuming they have depart_timestamp and arrive_timestamp)
        flights.forEach((flight) => {
            const departDate = dayjs(flight.depart_timestamp);  // Assuming timestamp format is valid ISO format
            const arriveDate = dayjs(flight.arrive_timestamp);
            updateMinMaxDates(departDate.format('MM/DD/YYYY'));  // Convert timestamp to date
            updateMinMaxDates(arriveDate.format('MM/DD/YYYY'));  // Convert timestamp to date
        });

        // If no valid dates found, return an empty TimeTable
        if (!minDate || !maxDate) {
            return new TimeTable();
        }

        // Generate the full range of dates from minDate to maxDate
        const newTimeTable = new TimeTable();
        let currentDate = minDate;
        while (currentDate.isSameOrBefore(maxDate)) {
            newTimeTable.dates.push({
                date: currentDate.format("MM/DD/YYYY"),
                dayOfWeek: currentDate.format("dddd"),
                'all-day': true, // Assuming these are all-day events, modify if needed
                notes: "",       // You can customize this based on the specific event
            });
            currentDate = currentDate.add(1, 'day'); // Move to the next day
        }

//        dataArray.current.Planning['time_table'] = newTimeTable;

        console.log("dataArray.current.Planning : "+JSON.stringify(dataArray.current.Planning, null, '\t'));

    }

    const addPlanningAttendee = (_new_attendee) => {

        console.log("adding new Attendee to Event : "+_new_attendee["fname"]);

        setDataArray((prevDataArray) => {
            const existingAttendeeIndex = prevDataArray.Planning.attendees.findIndex(attendee => attendee.id === _new_attendee.id);

            // If attendee exists, update their status to 0; active
            if (existingAttendeeIndex !== -1) {
                return {
                    ...prevDataArray,
                    Planning: {
                        ...prevDataArray.Planning,
                        attendees: prevDataArray.Planning.attendees.map((attendee, index) =>
                            index === existingAttendeeIndex ? { ...attendee, status: 0 } : attendee
                        ),
                    },
                };
            }

            // If attendee doesn't exist, add them to the attendees array
            return {
                ...prevDataArray,
                Planning: {
                    ...prevDataArray.Planning,
                    attendees: [...prevDataArray.Planning.attendees, _new_attendee],
                },
            };
        });
    };

    const removePlanningAttendee = (_attendee_id) => {
        console.log("removing Attendee from Event");

        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {
                ...prevDataArray.Planning,
                attendees: prevDataArray.Planning.attendees.map(attendee =>
                    attendee.id === _attendee_id ? { ...attendee, status: 4 } : attendee
                ),
            }
        }));
    };

    const setPlanningBudget = (_budget_total) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {
                ...prevDataArray.Planning,
                budget_total: _budget_total,
                need_to_save: true
            }
        }));

    };
    const setPlanningBudgetCurrency = (_budget_currency) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {
                ...prevDataArray.Planning,
                budget_currency: _budget_currency,
                need_to_save: true
            }
        }));

    };

    const setPlanningLodging = (_event_id, _event_lodging_array) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {
                ...prevDataArray.Planning,
                lodging: _event_lodging_array,
                need_to_save: true
            }
        }));

        updateEventLodging(_event_id, _event_lodging_array);

    };
    const setPlanningActivities = (_event_id, _event_activities_array) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {
                ...prevDataArray.Planning,
                activities: _event_activities_array,
                need_to_save: true
            }
        }));

        updateEventActivities(_event_id, _event_activities_array);

    };
    const setPlanningMeals = (_event_id, _event_meals_array) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {
                ...prevDataArray.Planning,
                meals: _event_meals_array,
                need_to_save: true
            }
        }));

        updateEventMeals(_event_id, _event_meals_array);

    };
    const setPlanningFlights = (_event_id, _event_flights_array) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {
                ...prevDataArray.Planning,
                flights: _event_flights_array,
                need_to_save: true
            }
        }));

        updateEventFlights(_event_id, _event_flights_array);

    };

    const addPlanningActivity = (event_id, newEventActivity) => {

        setDataArray((prevDataArray) => {
            // Check if the event_id matches Planning.id

            if (prevDataArray.Planning.id !== event_id) {
                return prevDataArray; // Return previous state without changes
            }

            // Proceed with adding the new activity
            const updatedActivities = [...prevDataArray.Planning.activities, newEventActivity];

            //update the Event Activities
            updateEventActivities(event_id, updatedActivities);

            return {
                ...prevDataArray,
                Planning: {
                    ...prevDataArray.Planning,
                    activities: updatedActivities,
                    need_to_save: true
                }
            };
        });
    };
    const updatePlanningActivity = (event_id, updatedEventActivity) => {

        setDataArray((prevDataArray) => {
            // Check if the event_id matches Planning.id

            if (prevDataArray.Planning.id !== event_id) {
                return prevDataArray; // Return previous state without changes
            }

            // Proceed with updating the activities
            const updatedActivities = prevDataArray.Planning.activities.map(activity =>
                activity.id === updatedEventActivity.id ? updatedEventActivity : activity
            );

            //update the Event Activities
            updateEventActivities(event_id, updatedActivities);

            return {
                ...prevDataArray,
                Planning: {
                    ...prevDataArray.Planning,
                    activities: updatedActivities,
                    need_to_save: true
                }
            };
        });
    };
    const deletePlanningActivity = (event_id, activity_id) => {
        setDataArray((prevDataArray) => {
            // Check if the event_id matches Planning.id
            if (prevDataArray.Planning.id !== event_id) {
                return prevDataArray; // Return previous state without changes
            }

            // Proceed with deleting the activity
            const updatedActivities = prevDataArray.Planning.activities.filter(activity => activity.id !== activity_id);

            //update the Event Activities
            updateEventActivities(event_id, updatedActivities);

            return {
                ...prevDataArray,
                Planning: {
                    ...prevDataArray.Planning,
                    activities: updatedActivities,
                    need_to_save: true
                }
            };
        });
    };


    const addPlanningMessages = (_incoming) => {
        console.log("addPlanningMessages");

        set_PA_GlobalObject((prev) => {
            // Ensure messages exist before mapping
            const existingMessages = prev.Planning.messages || [];

            // Get existing message IDs to prevent duplicates
            const currentMessageIds = new Set(existingMessages.map(msg => msg.id));

            // Filter out messages that already exist
            const newMessages = _incoming.messages_list.filter(msg => !currentMessageIds.has(msg.id));

            // Combine existing and new messages
            const updatedMessages = [...existingMessages, ...newMessages];

            // Find the latest timestamp
            const latestTimestamp = updatedMessages.reduce((latest, message) => {
                return latest > message.created ? latest : message.created;
            }, 0); // Default value 0 ensures a valid timestamp

            return {
                ...prev, // Retain all existing properties
                Planning: {
                    ...prev.Planning, // Keep existing Planning properties
                    messages: updatedMessages,
                    latest_message_timestamp: latestTimestamp,
                    need_to_save: true
                }
            };
        });
    };


    const clearPlanning = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {},
        }));
    };

    const addSuggestionsLodging = (newSuggestionsLodging) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsLodging: [...prevDataArray.SuggestionsLodging, newSuggestionsLodging],
        }));
    };
    const clearSuggestionsLodging = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsLodging: [],
        }));
    };

    const addSuggestionsFlights = (newSuggestionsFlights) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsFlights: [...prevDataArray.SuggestionsFlights, newSuggestionsFlights],
        }));
    };
    const clearSuggestionsFlights = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsFlights: [],
        }));
    };

    const addSuggestionsMeals = (newSuggestionsMeals) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsMeals: [...prevDataArray.SuggestionsMeals, newSuggestionsMeals],
        }));
    };
    const clearSuggestionsMeals= () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsMeals: [],
        }));
    };

    const addSuggestionsTours = (newSuggestionsUnique) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsTours: [...prevDataArray.SuggestionsTours, newSuggestionsUnique],
        }));
    };
    const clearSuggestionsTours = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsTours: [],
        }));
    };

    const addSuggestionsUnique = (newSuggestionsUnique) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsUnique: [...prevDataArray.SuggestionsUnique, newSuggestionsUnique],
        }));
    };
    const clearSuggestionsUnique = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsUnique: [],
        }));
    };

    const addPlanningLibrary = (newPlanningLibrary) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            PlanningLibrary: [...prevDataArray.PlanningLibrary, newPlanningLibrary],
        }));
    };
    const clearPlanningLibrary = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            PlanningLibrary: [],
        }));
    };
    const addPartnership = (newPartnership) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Partnerships: [...prevDataArray.Partnerships, newPartnership],
        }));
    };
    const clearPartnerships = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Partnerships: [],
        }));
    };
    const setWorkingOnEventId = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            workingOnEventId: value,
        }));
    };



    const addIncomingAdminsActions = (newIncomingAdminsActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            AdminsActions: [...prevDataArray.AdminsActions, newIncomingAdminsActions],
        }));
    };
    const removeFirstIncomingAdminsActions = () => {
        setDataArray(prevDataArray => ({
            ...prevDataArray,
            AdminsActions: prevDataArray.AdminsActions.slice(1), // Removes the first element
        }));
    };

    const addIncomingDialogActions = (newIncomingDialogActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            DialogPipeline: [...prevDataArray.DialogPipeline, newIncomingDialogActions],
        }));
    };
    const removeFirstDialogActions = () => {
        setDataArray(prevDataArray => ({
            ...prevDataArray,
            DialogPipeline: prevDataArray.DialogPipeline.slice(1), // Removes the first element
        }));
    };


    const websocketRef = useRef(null);
    let [tryingToConnect, setTryingToConnect] = useState(false);

    useEffect(() => {
        const connectWebSocket = () => {
            if (!tryingToConnect && !dataArray.ws_connected) {
                console.log('Trying to make WebSocket connection...');
                setTryingToConnect(true);

                websocketRef.current = new WebSocket('wss://plannedadventure.com:8001/paws');
                setDataArray((prevData) => ({ ...prevData, Websocket: websocketRef.current }));

                websocketRef.current.onopen = () => {
                    console.log('WebSocket connection established.');
                    setWsConnected(true);
                    setTryingToConnect(false);

                    console.log('userAccount : '+JSON.stringify(userAccount));

                    if (!userAuthenticated && userAccount.current !== null){
                        const _send_string = {};
                        _send_string.action = "E_processUserLoginReconnect";
                        _send_string.username = userAccount.current.username;
                        _send_string.password = userAccount.current.password;
                        _send_string.last_checkin = userAccount.current.last_checkin;

                        console.log('trying to reauthorize : '+JSON.stringify(_send_string));

                        websocketRef.current.send(JSON.stringify(_send_string) + "|^");

                    }

                    //see if there are any new or updated templates
                    getAllTemplatesFromDatabase().then((_templates) => {

                        if (_templates && Array.isArray(_templates)) {
                            const _template_list = _templates.map((_t) => {
                                const _item = {};
                                _item.template_id = _t.template_id;
                                _item.timestamp = _t.template_timestamp;
                                return _item;
                            })

                            const _sendString = {};
                            _sendString.action = "T_getTemplateChanges";
                            _sendString.current_templates = _template_list;

                            websocketRef.current.send(JSON.stringify(_sendString) + "|^");
                        }
                    });

                    if (dataArray.Categories.length === 0){
                        const _sendString = {};
                        _sendString.action = "E_getAllCategories";
                        websocketRef.current.send(JSON.stringify(_sendString) + "|^");
                    }
                };

                websocketRef.current.onmessage = (event) => {
                    console.log('WebSocket message received:', event.data);
                    processIncoming(event.data);
                };

                websocketRef.current.onclose = (event) => {
                    console.log('WebSocket connection closed:', event.code, event.reason);
                    setWsConnected(false);
                    setUserAuthenticated(false);
                    reconnectWebSocket();
                };

                websocketRef.current.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };
            }
        };

        const reconnectWebSocket = () => {
            console.log('Reconnecting in 5 seconds...');
            setTimeout(() => {
                if (!dataArray.ws_connected) {
                    connectWebSocket();
                }
            }, 5000);
        };

        connectWebSocket();

        function processIncoming(_blob) {
            console.log('processIncoming : ' + _blob);

            let reader = new FileReader();

            reader.onload = function (event) {

                let incomingData = event.target.result;

                if (leftover_incoming_stuff.length === 0) {
                    mdnIncomingTotalMessageSize = eval(incomingData.substring(0, incomingData.indexOf("{")));
                }

                leftover_incoming_stuff += incomingData;
                let terminationIndex = leftover_incoming_stuff.indexOf("|^");

                if (terminationIndex === -1) {
                    mdnIncomingReceivedMessageSize = leftover_incoming_stuff.length;

                    //update the progress bar
                    if ((mdnIncomingReceivedMessageSize === 0) || (mdnIncomingReceivedMessageSize === mdnIncomingTotalMessageSize)){
                        //nothing to show
                        setProgressBar(0);
                    } else {
                        setProgressBar( (mdnIncomingReceivedMessageSize / mdnIncomingTotalMessageSize) * 100 );
                    }

                } else {
                    //this part contains the last piece of the JSONObject for processing
                }


                while (terminationIndex > -1) {
                    let processString = leftover_incoming_stuff.slice(leftover_incoming_stuff.indexOf('{'), terminationIndex);

                    //just add the string, it gets turned into JSON later
//                    _incoming_stack.push(processString);
                    processIncomingAction(processString);

                    mdnIncomingTotalMessageSize = 0;
                    mdnIncomingReceivedMessageSize = 0;

                    //update the progress bar
                    setProgressBar(0);

                    //remove the part we just sent for processing
                    leftover_incoming_stuff = leftover_incoming_stuff.slice(terminationIndex + 2);
                    terminationIndex = leftover_incoming_stuff.indexOf("|^");

                    //let's see about the size of the next content
                    if (leftover_incoming_stuff.length > 0 && leftover_incoming_stuff.indexOf("{") !== -1) {
                        try {
                            incoming_stuff_length = eval(leftover_incoming_stuff.substring(0, leftover_incoming_stuff.indexOf("{")));
                            mdnIncomingTotalMessageSize = eval(leftover_incoming_stuff.substring(0, leftover_incoming_stuff.indexOf("{")));
                            if (terminationIndex === -1) {
                                mdnIncomingReceivedMessageSize = leftover_incoming_stuff.length;
                            }
                        } catch (e) {
                        }
                    }

                }
            };

            reader.readAsText(_blob);
        }

        function processIncomingAction(processString) {

            try {
                const _incoming = JSON.parse(processString);
                console.log("the Incoming action : " + JSON.stringify(_incoming.action));

                const latestDataArray = dataArray;

                const action = _incoming.action;

                if (action === "timelyUpdate") {

//                    setDataArray((prevData) => ({...prevData, timelyUpdate: 0}));

                } else if (action === "E_processUserLoginResults" || action === "E_processUserLoginUsingGoogleJWTResults") {

                    processUserLogin(_incoming);

                } else if (action === "E_processUserLogoutResults") {

                    processUserLogout(_incoming);

                } else if (action === "E_getAllEventsResults") {
                    console.log("Number of events: " + _incoming.events.length);

                    const _newly_converted_events = [];
                    for (const specificEvent of _incoming.events) {

                        const _event = convertEventVersion1To2(specificEvent);

                        addEvent(_event);

                        _newly_converted_events.push(_event);
                    }

                    console.log("@@@@@ added "+_newly_converted_events.length+" events");

                    //update the browser database
//                    browserStorageUpdateAllEvents(_incoming.events);
                    browserStorageUpdateAllEvents(_newly_converted_events);

                } else if (action === "E_getEventChangesResults") {

                    E_getEventChangesResults(_incoming, PA_GlobalObject, set_PA_GlobalObject);

                } else if (action === "E_getAllCategoriesResults") {
                    console.log("E_getAllCategoriesResults: ", _incoming.categories);

                    for (const _category of _incoming.categories) {
                        addCategory(_category);
                    }
                } else if (action === "E_getMasterAttendeeListResults") {
                    console.log("Number of attendees: " + _incoming.attendees.length);

                    //clear any existing data
                    clearAttendees();

                    for (const _attendee of _incoming.attendees) {

                        const _newAttendee = new PAAttendee(
                            _attendee.fname,
                            _attendee.lname,
                            _attendee.email
                        );

                        _newAttendee.id = _attendee.id;
                        _newAttendee.created = _attendee.created;
                        _newAttendee.image = _attendee.image;
                        _newAttendee.avatar = _attendee.avatar;

                        _newAttendee.nickname = _attendee.nickname;

                        _newAttendee.address = _attendee.address;
                        _newAttendee.city = _attendee.city;
                        _newAttendee.state = _attendee.state;
                        _newAttendee.zip = _attendee.zip;
                        _newAttendee.country = _attendee.country;

                        _newAttendee.dob = _attendee.dob;
                        _newAttendee.language = _attendee.language;

                        _newAttendee.phone_prefix = _attendee.phone_prefix;
                        _newAttendee.phone = _attendee.phone;
                        _newAttendee.mask_phone_number = _attendee.mask_phone_number;

                        _newAttendee.preferred_airport = _attendee.preferred_airport;

                        _newAttendee.push_notifications = _attendee.push_notifications;

                        _newAttendee.events = _attendee.events;

                        _newAttendee.status = _attendee.status;

                        console.log("Adding attendee : ", _newAttendee);
                        addAttendee(_newAttendee);
                    }

                } else if (action === "signupConciergeGetWelcomeMessageResults") {
                    addIncomingConciergeSignupActions(_incoming);
                } else if (action === "signupConciergeVerifyEmailAddressResults") {
                    addIncomingConciergeSignupActions(_incoming);
                } else if (action === "signupConciergeVerifyVerificationCodeResults") {
                    addIncomingConciergeSignupActions(_incoming);
                } else if (action === "signupConciergeCheckUsernameAndPasswordResults") {
                    addIncomingConciergeSignupActions(_incoming);



                } else if (action.startsWith("getAllAttendeeAssistantThreadsResults")) {

                    addIncomingConciergeActions(_incoming);

                } else if (action.startsWith("getAttendeeAssistantThreadMessagesResults")) {

                    addIncomingConciergeActions(_incoming);



                } else if (action.startsWith("planningConciergeService")) {

                    addIncomingConciergeActions(_incoming);

                } else if (action.startsWith("planningConciergeChatService")) {

                    addIncomingConciergeActions(_incoming);

                } else if (action.startsWith("planningConciergeMapService")) {

                    addIncomingConciergeMapActions(_incoming);

                } else if (action.startsWith("planningConciergeScanningService")) {

                    addIncomingConciergeScanningDocumentActions(_incoming);

                } else if (action.startsWith("planningConciergeSuggestionService")) {

                    addIncomingConciergeSuggestionActions(_incoming);




                } else if (action.startsWith("adminServicesUpdateOpenAiAssistantThreadUserLoginResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesUpdateOpenAiAssistantThreadUserLogoutResults")) {

                    addIncomingAdminsActions(_incoming);


                } else if (action.startsWith("adminServicesGetOpenAiAssistantFunctionListResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesGetOpenAiAssistantListResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesUpdateOpenAiAssistantPropertiesResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesDeleteOpenAiAssistantResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesCreateOpenAiAssistantThreadResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesCreateOpenAiAssistantLodgingNegotiatorThreadResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesGetOpenAiAssistantThreadListResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesGetOpenAiAssistantThreadMessagesResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesSendOpenAiAssistantThreadMessageResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesSendOpenAiAssistantThreadMessageStatus")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesUpdateOpenAiAssistantThreadMetadataResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesDeleteOpenAiAssistantThreadResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesSetAttendeesAccountPasswordResults")) {

                    addIncomingAdminsActions(_incoming);



                } else if (action.startsWith("adminServicesGetOpenAiFilesListResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesGetOpenAiFileContentsResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesGetOpenAiFileAssistantListResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesDeleteOpenAiFileResults")) {

                    addIncomingAdminsActions(_incoming);



                } else if (action.startsWith("adminServicesGetMasterAttendeeListResults")) {

                    addIncomingAdminsActions(_incoming);





                    /*
                       for the master Contact list : updating
                   */
                } else if (action === "updateMasterContactsAttendeeFieldsResults") {
                    //this is a response to making updates from the Contacts Dialog
                    addIncomingDialogActions(_incoming);



                } else if (action === "E_addNewEventResults") {
                    console.log("A new Event just created");

                    const newly_converted_event = convertEventVersion1To2(_incoming.newly_created_event);

                    addEvent(newly_converted_event);

                    browserStorageAddEvent(newly_converted_event);

                    /*
                        for the Event : saving the entire Event from the PLanner
                    */
                } else if (action === "E_setEventAllChangesResults") {

                    E_setEventAllChangesResults(_incoming, usePA_GlobalStore.getState().PA_GlobalObject.Planning);


                    /*
                        for the Event Details
                     */
                } else if (action === "E_updateEventCoverImageResults") {
                    //this should contain both the full size and the thumbnail image data

                    if (_incoming.result === "ok"){
                        addEventCoverImageFullSizeAndThumbnail(
                            _incoming.event_id,
                            _incoming.file_name,
                            _incoming.full_data,
                            _incoming.thumbnail_data
                            ).then((result) => {
                            if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.id === _incoming.event_id){
                                set_PA_GlobalObject((prev) => ({
                                    Planning: {
                                        ...prev.Planning, // Retain all existing fields in Planning
                                        file_name: _incoming.file_name,
                                        cover_image_full_data: _incoming.full_data,
                                        cover_image_thumbnail: _incoming.thumbnail_data
                                    },
                                }));
                            }
                        })
                    }
                } else if (action === "E_updateEventCoverImageThumbnailResults") {
                    //update the browser database and update the Planning if it's the same id
                    console.log("_incoming", _incoming);
                    if (_incoming.result === "ok"){
                        addEventCoverImageThumbnail(_incoming.event_id, _incoming.thumbnail_data).then((result) => {
                            if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.id === _incoming.event_id){
                                set_PA_GlobalObject((prev) => ({
                                    Planning: {
                                        ...prev.Planning, // Retain all existing fields in Planning
                                        cover_image_thumbnail : _incoming.thumbnail_data
                                    },
                                }));
                            }
                        });
                    }
                } else if (action === "E_updateEventNameResults") {

                    E_updateEventNameResults(
                        _incoming,
                        dataArrayRef.current['Events'], // Current Events array
                        usePA_GlobalStore.getState().PA_GlobalObject.Planning, // Current Planning object
                        setDataArray // State setter for dataArray
                    );




                    /*
                        for the Event Attendees
                     */

                } else if (action === "E_updateAttendeePrivilegeResults") {

//                    E_updateAttendeePrivilegeResults(_incoming);




                    /*
                        for the Event Activities
                     */
                } else if (action === "E_addNewActivityResults") {

                    E_addEventActivityResults(_incoming).then(newActivity=> {
                        if (newActivity && _incoming.result === "ok"){
                            addPlanningActivity(_incoming.event_id, newActivity);
                        }
                    });

                } else if (action === "E_updateEventActivityResults") {

                    E_updateEventActivityResults(_incoming).then(updatedActivity=> {
                        if (updatedActivity && _incoming.result === "ok"){
                            updatePlanningActivity(_incoming.event_id, updatedActivity);
                        }
                    });

                } else if (action === "E_deleteEventActivityResults") {

                    E_deleteEventActivityResults(_incoming).then(deletedActivity=> {
                        if (deletedActivity && _incoming.result === "ok"){
                            deletePlanningActivity(_incoming.event_id, _incoming.activity_id);
                        }
                    });




                } else if (action === "E_uploadEventImageResults") {

                    E_uploadEventImageResults(_incoming);

                } else if (action === "E_uploadEventAttendeePhotoThumbnailResults") {

                    E_uploadEventAttendeePhotoThumbnailResults(_incoming);

                } else if (action === "E_uploadEventAttendeePhotosResults") {

                    E_uploadEventAttendeePhotosResults(_incoming, usePA_GlobalStore.getState().PA_GlobalObject.Planning);

                } else if (action === "E_getEventThumbnailImageResults") {

                    E_getEventThumbnailImageResults(_incoming, usePA_GlobalStore.getState().PA_GlobalObject.Planning);

                } else if (action === "E_getEventFullSizeImageResults") {

                    E_getEventFullSizeImageResults(_incoming);

                } else if (action === "E_updateEventAttendeePhotoPrivateResults") {

                    E_updateEventAttendeePhotoPrivateResults(_incoming);

                } else if (action === "E_deleteEventPhotosResults") {

                    E_deleteEventPhotosResults(_incoming);


                    /*
                        for the Event Messages
                    */
                } else if (action === "E_getEventMessagesResults") {
                    /*
                        this contains an array of messages
                        - add them to the browser database
                     */

                    E_getEventMessagesResults(_incoming).then(success=> {
                        if (success && _incoming.result === "ok"){
                            //add messages to the Event list and the Planning if the Event IDs match
                            addPlanningMessages(_incoming);
//                            updateEventMessages(_incoming);
                            addIncomingEventActions(_incoming);
                        }
                    });



                    /*
                    for things related Event supplies
                 */
                } else if (action === "E_addNewAccountEssentialItemResults") {
                    addIncomingSuppliesActions(_incoming);




                    /*
                        for the Flight Planner
                     */
                } else if (action === "E_getDuffelFlightsResults") {
                    addIncomingFlightPlanningActions(_incoming);



                /*
                    for things related to the buying a new Event
                 */
                } else if (action === "E_checkNewEventPlanPromoCodeResults") {
                    addIncomingEventUpgradeActions(_incoming);

                } else if (action === "E_createNewEventFromPlanningResults") {
                    addIncomingEventUpgradeActions(_incoming);


                /*
                    for things related to the saving/updating the Event
                 */
                } else if (action === "E_saveEventUsingNewVersionResults") {
                    addIncomingEventUpgradeActions(_incoming);



                /*
                   for things related to Templates
                */
                } else if (action === "T_getTemplateChangesResults") {
                    processGetTemplateChanges(_incoming);




                }

            } catch (e) {
                console.log("error : " + e);
            }

        }

        function processUserLogin(_incoming) {
            console.log("processUserLogin : " + JSON.stringify(_incoming, null, '\t'));

            try {

                if (_incoming.result === "ok") {

                    setAuthenticated(true);

                    //create the Account object
                    const _account = {};
                    _account.id = _incoming.user_id;
                    _account.created = _incoming.created;
                    _account.last_checkin = _incoming.last_checkin;
                    _account.role = _incoming.role;
                    _account.username = _incoming.username;
                    _account.password = _incoming.password;
                    _account.last_password_change = _incoming.last_password_change;
                    _account.fname = _incoming.fname;
                    _account.lname = _incoming.lname;
                    _account.nickname = _incoming.nickname;
                    _account.image = _incoming.image;
                    _account.avatar = _incoming.avatar;
                    _account.language = _incoming.language;
                    _account.address = _incoming.address;
                    _account.city = _incoming.city;
                    _account.state = _incoming.state;
                    _account.zip = _incoming.zip;
                    _account.country = _incoming.country;
                    _account.phone_prefix = _incoming.phone_prefix;
                    _account.phone = _incoming.phone;
                    _account.mask_phone_number = _incoming.mask_phone_number;
                    _account.email = _incoming.email;
                    _account.auto_login = _incoming.auto_login;
                    _account.notifications = _incoming.notifications;
                    _account.push_notifications = _incoming.push_notifications;
                    _account.menu_items = _incoming.menu_items;
                    _account.events = _incoming.events;
                    _account.documents = _incoming.documents;
                    _account.my_attendees = _incoming.my_attendees;
                    _account.preferred_airport = _incoming.preferred_airport;
                    _account.essentials = _incoming.essentials;
                    _account.pay_plan = _incoming.pay_plan;
                    _account.dark_mode = _incoming.dark_mode;
                    _account.show_welcome_tips = _incoming.show_welcome_tips;
                    _account.status = _incoming.status;

                    setAccount(_account);
                    userAccount.current = _account;
                    setUserAuthenticated(true);

                    //add the message for the Assistant Thread
                    const _assistant_message = {};
                    _assistant_message.action = "userLoggedIn";
                    _assistant_message.user_id = _incoming.user_id;
                    addIncomingAdminsActions(_assistant_message);
                    addIncomingConciergeActions(_assistant_message);

                    /*
                        before adding the _account to browser storage, see if there is an existing entry
                        this is so if you switch accounts it won't load Events from an Account belonging to another
                        person
                     */
                    //checkUserAccount(_account);
                    openDb('pa', 1, function (upgradeDb) {
                        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                            upgradeDb.createObjectStore('pa_data');
                            upgradeDb.createObjectStore('pa_photos');
                        }
                    }).then(function (db) {
                        const tx = db.transaction('pa_data', 'readonly');
                        const store = tx.objectStore('pa_data');
                        return store.get('Account')
                    }).then(function (_user_account) {
                        if (_user_account && _user_account.id === _account.id) {
                            console.log('Account found and id does match.');

                            //see if the Events are already in the browser database
                            getAllEventsFromDatabase(_incoming.user_id).then(eventsInDatabase => {

                                if (eventsInDatabase && eventsInDatabase.length > 0) {

                                    //add Events to the
                                    for (const _event of eventsInDatabase) {
                                        addEvent(_event);
                                    }

                                    // Check if we have the latest versions of all Events
                                    const extractedEvents = eventsInDatabase.map(event => ({ id: event.id, last_mod: event.last_mod }));

                                    const _sendString = {};
                                    _sendString.action = "E_getEventChanges";
                                    _sendString.user_id = _account.id;
                                    _sendString.events = extractedEvents;

                                    websocketRef.current.send(JSON.stringify(_sendString) + "|^");

                                } else {
                                    // Handle the case when no events are found in the database
                                    // Get all events from the server
                                    console.log("No Events in browser storage!! Get from server");
                                    const _sendString = {};
                                    _sendString.action = "E_getAllEvents";
                                    _sendString.user_id = _account.id;

                                    websocketRef.current.send(JSON.stringify(_sendString) + "|^");

                                }
                            }).catch(error => {
                                // Handle errors occurred during retrieving events from the database
                                console.error("Error occurred while retrieving events from the database:", error);
                            });

                        } else {
                            console.log('Account not found or id does not match.');
                            removeEventsFromDatabase().then(r => {
                                //add the Account to storage
                                addKeyValue("Account", _account);
                            });

                            //get the list of Events from server
                            const _sendString = {};
                            _sendString.action = "E_getAllEvents";
                            _sendString.user_id = _account.id;

                            websocketRef.current.send(JSON.stringify(_sendString) + "|^");

                        }
                        return null;
                    }).catch(function (error) {
                        console.error("An error occurred:", error);
                        throw error; // Re-throw the error to be caught by the caller
                    });

                    /*
                        get any OpenAi Threads associated with this Attendee
                     */
                    const _sendString = {};
                    _sendString.action = "getAllAttendeeAssistantThreads";
                    websocketRef.current.send(JSON.stringify(_sendString) + "|^");


                } else {
                    //bad login
                    console.log("login failed .....");

                    loginFailed();

                }

            } catch (e) {
                console.log(e);
            }

        }

        function loginFailed() {
            console.log("loginFailed");
            //this alerts the Header and shows the message on the Dialog box
            setLoginFailed(true);
        }

        function processUserLogout(_incoming) {
            console.log("processUserLogout : " + JSON.stringify(_incoming, null, '\t'));

            try {

                if (_incoming.results === "ok") {

                    //add the message for the Assistant Thread
                    const _assistant_message = {};
                    _assistant_message.action = "userLoggedOut";
                    _assistant_message.user_id = _incoming.user_id;
                    addIncomingAdminsActions(_assistant_message);


                    //good logout
                    setAuthenticated(false);
                    clearAccount();
                    clearEvents();
                    clearAttendees();

                    setSideMenuWidth(0);

                } else {
                    //problems logging out
                }
            } catch (e) {
            }
        }

        function processGetTemplateChanges(_incoming){

            try {
                if (_incoming.result === 'ok'){
                    browserStorageUpdateTemplates(_incoming.changes).then((result) =>{
                        console.log("After processing Template changes : "+result);
                    });
                }
            } catch (e){

            }
        }



        // Cleanup on component unmount or before reconnecting
        return () => {
            console.log("Cleanup on component unmount or before reconnecting");
            if (websocketRef.current) {
                websocketRef.current.close();
            }
        };
    }, []); // Ensure dependencies are correctly managed


    return (

        <PAContext.Provider value={{ dataArray, dataArrayRef, setAccount, setIsMobileView, setShowSuccessSnackbar, setSideMenuWidth,
            clearEvents, addEvent, updateEvent,
            clearAccount, addAttendee, clearAttendees, updateAttendee, addIncomingActions, setAuthenticated,
            setLoginFailed,
            setPlanning, setPlanningBudget, setPlanningBudgetCurrency, addPlanningAttendee, removePlanningAttendee, setPlanningLodging, setPlanningActivities,
            setPlanningMeals, setPlanningFlights,
            addSuggestionsLodging, clearSuggestionsLodging,
            addSuggestionsFlights, clearSuggestionsFlights,
            addSuggestionsMeals, clearSuggestionsMeals,
            addSuggestionsTours, clearSuggestionsTours,
            addSuggestionsUnique, clearSuggestionsUnique,
            addPlanningLibrary, clearPlanning, addPartnership, clearPartnerships,
            setWorkingOnEventId,
            removeFirstConciergeSignupActions, removeFirstEventUpgradeActions, removeFirstSuppliesActions,
            removeFirstIncomingAdminsActions, removeFirstDialogActions }} >
            {children}
        </PAContext.Provider>

    );
};

export { PAContext, PAContextProvider };
