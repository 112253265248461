import React, {useEffect, useState} from 'react';

import {useNavigate} from "react-router-dom";

import {Avatar, Card, CardHeader, Chip} from "@mui/material";
import Grid from '@mui/material/Grid2';
import sample_attendees from "../../../sampleData/Attendees";
import "./attendees.css";
import PAEvent from "../../../MasterEventObjects/PAEvent";
import PAAttendee from "../../../MasterEventObjects/Attendee";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {addWaitChipStyle} from "../../ButtonStyle/AddWait";
import {addChipStyle} from "../../ButtonStyle/Add";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {formatName, stringAvatar, stringToColor} from "../../Helpers/TextStyling";


function AttendeesAddSamples(props) {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const [sortedAttendees, setSortedAttendees] = useState([]);


    useEffect(() => {
        // Sort the attendees by lname whenever the Attendees array changes
        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees !== undefined) {
            setSortedAttendees([...sample_attendees]
                .filter((_att) => {
                    return !usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.some((planningAtt) => planningAtt.id === _att.id);
                })
                .sort((attendeeA, attendeeB) => {
                    const lnameA = attendeeA.lname.toLowerCase();
                    const lnameB = attendeeB.lname.toLowerCase();

                    // Compare the lname values
                    if (lnameA < lnameB) return -1;
                    if (lnameA > lnameB) return 1;
                    return 0;
                }));
        }
    }, [sample_attendees]);



    function handlePromptAddSamplesClose() {
        navigate('/Planner/Attendees');
    }


    const [selectedAttendees, setSelectedAttendees] = useState([]);


    function toggleAttendeeSelection(attendee_id){
        console.log("Clicked on index : "+attendee_id);

        // Check if the attendee is already selected
        const isSelected = selectedAttendees.some((selected) => selected.id === attendee_id);

        console.log("Selected ? : "+isSelected);

        if (isSelected) {
            // If selected, remove from the selectedAttendees array
            setSelectedAttendees(selectedAttendees.filter((selected) => selected.id !== attendee_id));
        } else {
            // If not selected, add to the selectedAttendees array
            setSelectedAttendees([...selectedAttendees, sortedAttendees.find((_att) => _att.id === attendee_id)]);
        }

    }

    function handleAttendeesOK() {
        console.log("handleAttendeesOK");

        if (Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning).length === 0){

            console.log("Creating new Event");

            //create a new Event
            const newEvent = new PAEvent();
            newEvent.title = "A New Event";

            for (const _att of selectedAttendees){
                console.log("adding : "+JSON.stringify(_att));

                const _new_attendee = new PAAttendee();
                _new_attendee.id = _att.id;
                _new_attendee.fname = _att.fname;
                _new_attendee.lname = _att.lname;
                _new_attendee.email = _att.email;

                _new_attendee.balance = Math.floor(Math.random() * 2000);

                if (newEvent.attendees.length === 0){
                    _new_attendee.role = "admin";
                }
                _new_attendee.isSample = true;

                newEvent.attendees.push(_new_attendee);
            }

            set_PA_GlobalObject({ Planning: newEvent });

        } else {

            for (const _att of selectedAttendees){
                const attendeeExists = usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.some((planningAtt) => planningAtt.id === _att.id);

                if (!attendeeExists) {
                    console.log("adding : " + JSON.stringify(_att));

                    const _new_attendee = new PAAttendee();
                    _new_attendee.id = _att.id;
                    _new_attendee.fname = _att.fname;
                    _new_attendee.lname = _att.lname;
                    _new_attendee.email = _att.email;
                    _new_attendee.balance = parseFloat(0);

                    if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.length === 0){
                        _new_attendee.role = "planner";
                    }
                    _new_attendee.isSample = true;

                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.push(_new_attendee);
                }
            }

        }

        navigate('/Planner/Attendees');

    }


    return (

        <div className="attendee-list-container" >
            {/* show the header */}
            <div
                className="attendee-list-header"
            >
                <div className="attendee-list-header-text">
                    <h5>Sample Attendees</h5>
                </div>
                <div className="attendee-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Cancel"
                        size="small"
                        color="error"
                        onClick={handlePromptAddSamplesClose}
                    />
                    <Chip
                        sx={selectedAttendees.length===0 ? addWaitChipStyle : addChipStyle}
                        label={selectedAttendees.length===0?'Add': 'Add '+selectedAttendees.length}
                        size="small"
                        onClick={selectedAttendees.length===0 ? undefined : handleAttendeesOK}
                    />
                </div>
            </div>


            {/* show the list of sample attendees */}
            <div className="attendee-list-items-container">

                <Grid container direction="column">

                    {
                        sortedAttendees.map((attendee, index) => (

                            <Grid item key={index} sx={{display:'flex'}}>

                                <Card
                                    sx={{cursor:'pointer'}}
                                    className="attendee-list-item"
                                    onClick={() => toggleAttendeeSelection(attendee.id)}
                                >
                                    <CardHeader
                                        sx={{
                                            padding:0
                                        }}
                                        avatar={
                                            <Avatar
                                                {...stringAvatar(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}
                                                sx={{
                                                    boxShadow: '' +
                                                        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                        '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                        '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                    border: `1px solid ${stringToColor(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}`,
                                                    borderRadius: '10px',
                                                    textAlign: 'center',
                                                    fontWeight: '700',
                                                    backgroundColor: '#FFF',
                                                    color: 'black', // Text color (adjust as needed)
                                                    fontSize: '15px',
                                                    width: 30, // Adjust width
                                                    height: 30, // Adjust height
                                                }}
                                            />
                                        }
                                        title={`${formatName(attendee.lname)}, ${formatName(attendee.fname)}`}
                                        subheader={attendee.email}
                                        action={
                                            selectedAttendees.some((selected) => selected.id === attendee.id) ? (
                                                <i className="icon-check attendee-list-item-add-item-checkmark" />
                                            ) : (
                                                <i className="attendee-list-item-add-item-no-checkmark" />
                                            )
                                        }
                                    />
                                </Card>

                            </Grid>
                        ))
                    }
                </Grid>
            </div>

        </div>
    )
}

export default AttendeesAddSamples;
