import React, {useState, useEffect, useContext, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {PAContext} from "../../../Services/PAContext";
import Receipt from "../../../MasterEventObjects/Receipt";
import {
    Button,
    Card, CardActions,
    CardContent, CardHeader,
    CardMedia, Checkbox,
    Chip,
    FormControlLabel, FormGroup, Menu,
    MenuItem,
    Switch, TextField
} from "@mui/material";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {addChipStyle} from "../../ButtonStyle/Add";
import {useDropzone} from "react-dropzone";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import currencyList from "../../../sampleData/currencyList";
import './receipt.css';
import dayjs from "dayjs";


// Simulated AI function (Replace with actual API call)
const processReceiptsWithAI = async (receiptFiles, account) => {
    return receiptFiles.map((file, index) => ({
        id: dayjs().valueOf()+index,
        attendee_id: account?.id || "",
        amount: 0,
        currency: 'USD',
        file_type: file.type,
        file_name: file.name,
        file_size: file.size,
        full_data: file.full_data,
        full_data_loaded: false,
        thumbnail_data: "",
        thumbnail_loaded: false,
        location: {
            google_place_id:"",
            latitude: 0,
            longitude: 0,
            name:"",
            formatted_address:"",
            address:"",
            city:"",
            state:"",
            zip:"",
            country:"",
            phone_prefix:"",
            phone:"",
        },
        section: "General",
        section_item: 0,
        private: false,
        notes: "",
        last_modified: 0,
        status: 0,
        showDetails: false, // New: Controls the visibility of details
    }));
};

function ReceiptsAddNew() {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Account, Attendees, SampleAttendees } = dataArray; // Fetch event attendees

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated
        ? Attendees
        : SampleAttendees);
    useEffect(() => {
        setAttendeesReferenceArray(
            dataArray.authenticated
                ? Attendees
                : SampleAttendees
        );
    }, [dataArray.authenticated, Attendees, SampleAttendees]);

    const [listOfReceipts, setListOfReceipts] = useState([]);
    const [receiptFiles, setReceiptFiles] = useState([]);
    const [useExperimentalAi, setUseExperimentalAi] = useState(true);
    const [loadingAI, setLoadingAI] = useState(false);

    useEffect(() => {
        console.log("listOfReceipts : ", listOfReceipts);
    }, [listOfReceipts]);
    // Get event lodging, meals, and activities
    const eventLodging = PA_GlobalObject.Planning.lodging || [];
    const eventMeals = PA_GlobalObject.Planning.meals || [];
    const eventActivities = PA_GlobalObject.Planning.activities || [];

    const [manualReceipt, setManualReceipt] = useState(() => {
        const receipt = new Receipt();
        receipt.attendee_id = Account.id;
        return receipt;
    });
    // Dropzone setup to handle file uploads
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            // Ensure file type is valid
            if (!file.type || file.type === "0") {
                console.error(`Skipped "${file.name}" due to an invalid MIME type.`);
                return;
            }

            const validTypes = ["image/jpeg", "image/png", "image/gif", "image/webp", "application/pdf"];
            if (!validTypes.includes(file.type)) {
                console.error(`Skipped "${file.name}" due to an invalid file type: ${file.type}`);
                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                const receipt = {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    full_data: reader.result,
                    private: false,
                };
                setReceiptFiles((prev) => [...prev, receipt]);
            };
            reader.readAsDataURL(file);
        });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 10,
        accept: {
            "image/jpeg": [],
            "image/png": [],
            "image/gif": [],
            "image/webp": [],
            "application/pdf": [],
        }, // Explicitly define accepted MIME types
    });

    // Process AI Receipts (Only runs if AI is enabled)
    const [processedFiles, setProcessedFiles] = useState(new Set()); // Tracks already processed files

    useEffect(() => {
        if (useExperimentalAi && receiptFiles.length > 0) {
            // Find new files that haven't been processed yet
            const newFiles = receiptFiles.filter(file => !processedFiles.has(file.name));

            if (newFiles.length === 0) return; // No new files to process

            setLoadingAI(true);

            processReceiptsWithAI(newFiles, Account).then((processedReceipts) => {
                setListOfReceipts((prev) => [...prev, ...processedReceipts]);

                // Mark these files as processed
                setProcessedFiles(prev => new Set([...prev, ...newFiles.map(file => file.name)]));

                setLoadingAI(false);
            });
        }
    }, [useExperimentalAi, receiptFiles]);

    function handleReceiptChange(index, field, value, extra) {
        console.log("field:", field, "value:", value);

        setListOfReceipts((prevReceipts) =>
            prevReceipts.map((receipt, i) => {
                if (i !== index) return receipt; // Keep other receipts unchanged

                let _amount = receipt.amount; // Preserve existing amount
                let _location = receipt.location; // Preserve existing location

                if (field === "section_item") {
                    // Lookup object to find the default amount and location if available
                    const categoryLookup = {
                        lodging: eventLodging.find((lodging) => lodging.id === value),
                        meals: eventMeals.find((meal) => meal.id === value),
                        activities: eventActivities.find((activity) => activity.id === value),
                    };

                    const selectedCategory = categoryLookup[extra];

                    if (selectedCategory) {
                        _amount = selectedCategory.amount_total || 0;

                        // If the selected category has location data, update it
                        if (selectedCategory.location) {
                            _location = {
                                google_place_id: selectedCategory.location.google_place_id || "",
                                latitude: selectedCategory.location.latitude || 0,
                                longitude: selectedCategory.location.longitude || 0,
                                name: selectedCategory.location.name || "",
                                formatted_address: selectedCategory.location.formatted_address || "",
                                address: selectedCategory.location.address || "",
                                city: selectedCategory.location.city || "",
                                state: selectedCategory.location.state || "",
                                zip: selectedCategory.location.zip || "",
                                country: selectedCategory.location.country || "",
                                phone_prefix: selectedCategory.location.phone_prefix || "",
                                phone: selectedCategory.location.phone || "",
                            };
                        }
                    }
                }

                return {
                    ...receipt,
                    [field]: field === "amount" ? value : value, // Store amount as string temporarily
                    amount: field === "amount" ? value.replace(/[^0-9.]/g, "") : _amount, // Allow decimals
                    location: _location, // Update location if found
                };
            })
        );
    }

    function removeReceipt(index) {
        setListOfReceipts((prev) => prev.filter((_, i) => i !== index));
    }

    function addManualReceipt() {
        console.log("addManualReceipt : ", manualReceipt);

        const _newer_receipt= {
            id: manualReceipt.id,
            attendee_id: manualReceipt.attendee_id,
            amount: parseFloat(manualReceipt.amount),
            currency: manualReceipt.currency,
            file_type: manualReceipt.type,
            file_name: manualReceipt.name,
            file_size: manualReceipt.size,
            full_data: manualReceipt.full_data,
            full_data_loaded: manualReceipt.full_data_loaded,
            thumbnail_data: manualReceipt.thumbnail_data,
            thumbnail_loaded: manualReceipt.thumbnail_loaded,
            location: {
                google_place_id: manualReceipt['location'].google_place_id,
                latitude: manualReceipt['location'].latitude,
                longitude: manualReceipt['location'].longitude,
                name:manualReceipt['location'].name,
                formatted_address:manualReceipt['location'].formatted_address,
                address:manualReceipt['location'].address,
                city:manualReceipt['location'].city,
                state:manualReceipt['location'].state,
                zip:manualReceipt['location'].zip,
                country:manualReceipt['location'].country,
                phone_prefix:manualReceipt['location'].phone_prefix,
                phone:manualReceipt['location'].phone,
            },
            section: manualReceipt.section,
            section_item: manualReceipt.section_item,
            private: manualReceipt.private,
            notes: manualReceipt.notes,
            last_modified: manualReceipt.last_modified,
            status: manualReceipt.status,
            showDetails: false, // New: Controls the visibility of details
            selectedAction: null,
        }

        console.log("_newer_receipt : ", _newer_receipt);
        setListOfReceipts((prev) => [...prev, _newer_receipt]);

        const newReceipt = new Receipt();
        newReceipt.attendee_id = Account.id;
        newReceipt.selectedAction = null; // 🔹 Track selection for each receipt

        setManualReceipt(newReceipt);
    }

    const [anchorChangeCurrencyEl, setAnchorChangeCurrencyEl] = useState(null);
    const openChangeCurrency = Boolean(anchorChangeCurrencyEl);
    const handleOpenChangeCurrency = (event) => {
        setAnchorChangeCurrencyEl(event.currentTarget);
    };
    const handleChangeCurrencyClick = (event, code) => {
        console.log("changing currency to : "+code);
        const _new_currency_code = code;
        setManualReceipt((prev) => ({
            ...prev,
            currency: _new_currency_code,
        }));

        handleChangeCurrencyClose();
    };
    const handleChangeCurrencyClose = () => {
        setAnchorChangeCurrencyEl(null);
    };

    function handleFooterItemClick(index, action) {
        setListOfReceipts((prevReceipts) =>
            prevReceipts.map((receipt, i) =>
                i === index
                    ? {
                        ...receipt,
                        selectedAction: receipt.selectedAction === action ? null : action, // Toggle the action
                        showDetails: receipt.selectedAction !== action, // Only show details if a new action is selected
                    }
                    : { ...receipt, showDetails: false } // Hide details for all other receipts
            )
        );
    }

    function handleReceiptsAddOK() {
        console.log("listOfReceipts : ", listOfReceipts);

        // Ensure amount is always a valid number before calling .toFixed(2)
        const processedReceipts = listOfReceipts.map(receipt => ({
            ...receipt,
            amount: parseFloat(receipt.amount) || 0, // Ensure it's a valid number
        }));

        console.log("processedReceipts : ", processedReceipts);

        // Add the receipts to the global object
        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning,
                receipts: [...(prev.Planning.receipts || []), ...processedReceipts],
            },
        }));

        navigate("/Planner/Receipts");
    }

    function handlePromptAddNewClose() {
        navigate("/Planner/Receipts");
    }



    return (
        <div className="receipts-list-container" >
            {/* show the header */}
            <div
                className="receipts-list-header"
            >
                <div className="receipts-list-header-text">
                    <h5>New Receipts</h5>
                </div>
                <div className="receipts-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Cancel"
                        size="small"
                        color="error"
                        onClick={handlePromptAddNewClose}
                    />
                    <Chip
                        sx={addChipStyle}
                        label={`Add ${listOfReceipts.length> 0?listOfReceipts.length:''}`}
                        size="small"
                        onClick={handleReceiptsAddOK}
                    />
                </div>
            </div>

            {/* show all the fields */}
            <div className="receipts-add-new-item-container">
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" }, // One column on mobile, two on larger screens
                        gap: 2,
                        width: "100%"
                    }}
                >
                    {/* Left Side: Dropzone + AI Toggle in a Card */}
                    <Card sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            {/* Dropzone for File Uploads */}
                            <Box
                                {...getRootProps()}
                                sx={{
                                    border: "2px dashed gray",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    borderRadius: "8px"
                                }}
                            >
                                <input {...getInputProps()} />
                                {isDragActive
                                    ? <Typography variant="caption">Drop the files here...</Typography>
                                    : <Typography variant="caption">Drag & drop receipts here, or click to select</Typography>}
                            </Box>

                            {/* AI Processing Toggle */}
                            <FormControlLabel
                                control={<Switch checked={useExperimentalAi} onChange={() => setUseExperimentalAi(!useExperimentalAi)} />}
                                label={
                                    <Typography variant="caption" color="textSecondary">
                                        Use AI
                                    </Typography>
                                }
                            />

                            {/* AI Helper Text */}
                            <Typography variant="caption" color="textSecondary">
                                AI will extract receipt data.
                            </Typography>
                        </CardContent>
                    </Card>
                    {/* Right Side: Manual Receipt Entry */}
                    <Card sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                        <CardHeader
                            title="Manual Entry"
                            titleTypographyProps={{ fontSize: '16px' }}
                        />

                        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            {/* Amount Input & Currency Selector in One Row */}
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <TextField
                                    label="Amount"
                                    name="amount"
                                    value={manualReceipt.amount}
                                    onChange={(e) => setManualReceipt(prev => ({ ...prev, amount: e.target.value }))}
                                    variant="standard"
                                    sx={{ flex: 1, textAlign: "right" }} // Takes available space
                                />
                                <IconButton
                                    sx={{ fontSize: '12px', color: 'initial' }}
                                    onClick={(e) => setAnchorChangeCurrencyEl(e.currentTarget)}
                                >
                                    {manualReceipt.currency}
                                </IconButton>
                            </Box>

                            {/* Right-aligned Add button */}
                            <Button
                                variant="contained"
                                onClick={addManualReceipt}
                                sx={{ alignSelf: "flex-end" }}
                            >
                                Add
                            </Button>

                            {/* Currency Selection Menu */}
                            <Menu
                                anchorEl={anchorChangeCurrencyEl}
                                open={Boolean(anchorChangeCurrencyEl)}
                                onClose={() => setAnchorChangeCurrencyEl(null)}
                            >
                                {currencyList.map((option) => (
                                    <MenuItem
                                        key={option.code}
                                        selected={option.code === manualReceipt.currency}
                                        onClick={() => {
                                            setManualReceipt(prev => ({ ...prev, currency: option.code }));
                                            setAnchorChangeCurrencyEl(null);
                                        }}
                                    >
                                        {option.code} : {option.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </CardContent>
                    </Card>
                </Box>

                {/* Receipt Cards */}
                {listOfReceipts.map((receipt, index) => (
                    <Card key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "10px", marginTop:'20px' }}>
                        {/* Show CardMedia only if there’s a file */}
                        {
                            receipt.file_type ? (
                                receipt.file_type.startsWith("image/") ? (
                                    <CardMedia component="img" height="100" image={receipt.full_data} alt={receipt.file_name} />
                                ) : (
                                    <CardMedia
                                        sx={{ height: 100, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f0f0f0" }}
                                    >
                                        <Typography variant="caption">{receipt.file_type}</Typography>
                                    </CardMedia>
                                )
                            ) :null
                        }

                        {/* Business Location Details */}
                        <Box sx={{ padding: '0 16px 16px 16px', width: '100%' }}>
                            {/* Receipt # and Amount on the same line */}
                            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "100%", marginTop: '10px' }}>
                                {/* Left-aligned Receipt # */}
                                <Typography variant="body2" color="textSecondary">
                                    Receipt #{index + 1}
                                </Typography>
                                {/* Right-aligned Amount */}
                                <Chip
                                    label={`${receipt.amount ? parseFloat(receipt.amount).toFixed(2) : "0.00"} ${receipt.currency}`}
                                    size="small"
                                    variant="outlined"
                                    color="success"
                                    sx={addChipStyle}
                                />
                            </Box>

                            {/* Location Details Below (if present) */}
                            {receipt.location && receipt.location.name && (
                                <Box sx={{ marginTop: '8px' }}>
                                    <Typography variant="body2" color="textSecondary">
                                        {receipt.location.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {receipt.location.formatted_address}
                                    </Typography>
                                    {receipt.location.phone && (
                                        <Typography variant="body2" color="textSecondary">
                                            {receipt.location.phone_prefix} {receipt.location.phone}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                            {/* Public/Private Toggle Below */}
                            <Box sx={{ marginTop: '8px', display: "flex", justifyContent: "flex-end" }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!receipt.private}
                                            onChange={() => handleReceiptChange(index, "private", !receipt.private)}
                                        />
                                    }
                                    label="Public"
                                />
                            </Box>
                            {/* Receipt Link */}
                            {
                                receipt.section && receipt.section_id !== "" ? (
                                    <Box sx={{ marginTop: '8px', display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 1 }}>
                                        {(() => {
                                            let iconClass = "icon-helm"; // Default icon
                                            let sectionName = receipt.section; // Default to section name

                                            // Check section type and update icon & section name accordingly
                                            switch (receipt.section) {
                                                case "Meals":
                                                    iconClass = "icon-fork-knife2";
                                                    sectionName = eventMeals.find((meal) => meal.id === receipt.section_item)?.title ||
                                                        eventMeals.find((meal) => meal.id === receipt.section_item)?.name ||
                                                        "Meal";
                                                    break;
                                                case "Activities":
                                                    iconClass = "icon-list-heart";
                                                    sectionName = eventActivities.find((activity) => activity.id === receipt.section_item)?.title || "Activity";
                                                    break;
                                                case "Lodging":
                                                    iconClass = "icon-bed";
                                                    sectionName = eventLodging.find((lodging) => lodging.id === receipt.section_item)?.name || "Lodging";
                                                    break;
                                                case "Shopping":
                                                    iconClass = "icon-bag";
                                                    sectionName = "Shopping";
                                                    break;
                                                default:
                                                    break;
                                            }

                                            return (
                                                <>
                                                    <i className={iconClass} title={sectionName} />
                                                    <Typography variant="body2" color="textSecondary">
                                                        {sectionName}
                                                    </Typography>
                                                </>
                                            );
                                        })()}
                                    </Box>
                                ) : null
                            }
                        </Box>

                        <CardActions sx={{ width: "100%" }}>
                            <div className="receipts-actions-add-list-item-footer-container">
                                <div
                                    style={{
                                        color: '#f09637',
                                        backgroundColor: receipt.selectedAction === "Location" ? '#EDEDED' : 'initial',
                                        flex: 1,
                                    }}
                                    className="receipts-actions-add-list-item-footer-dates-container"
                                    onClick={() => handleFooterItemClick(index, 'Location')}
                                >
                                    <i className="icon-map-marker" title="Location"/>
                                </div>
                                <div
                                    style={{
                                        color: '#f09637',
                                        backgroundColor: receipt.selectedAction === "Link" ? '#EDEDED' : 'initial',
                                        flex: 1,
                                    }}
                                    className="receipts-actions-add-list-item-footer-dates-container"
                                    onClick={() => handleFooterItemClick(index,'Link')}
                                >
                                    <i className="icon-link" title="Link"/>
                                </div>
                                <div
                                    style={{
                                        color: '#f09637',
                                        backgroundColor: receipt.selectedAction === "Amount" ? '#EDEDED' : 'initial',
                                        flex: 1,
                                    }}
                                    className="receipts-actions-add-list-item-footer-dates-container"
                                    onClick={() => handleFooterItemClick(index,'Amount')}
                                >
                                    <i className="icon-wallet" title="Amount"/>
                                </div>
                                <div
                                    style={{
                                        color: '#f09637',
                                        backgroundColor: receipt.showDetails && receipt.selectedAction === "Notes" ? '#EDEDED' : 'initial',
                                        flex: 1,
                                    }}
                                    className="receipts-actions-add-list-item-footer-dates-container"
                                    onClick={() => handleFooterItemClick(index,'Notes')}
                                >
                                    <i className="icon-pencil2" title="Notes"/>
                                </div>
                                <div
                                    style={{
                                        color: '#F00',
                                        flex: 1,
                                    }}
                                    className="receipts-actions-add-list-item-footer-dates-container"
                                >
                                    <i
                                        className="icon-trash"
                                        title="Delete"
                                        onClick={() => removeReceipt(index)}
                                    />
                                </div>
                            </div>
                        </CardActions>

                        <CardContent sx={{width: '100%', padding: '20px 10px', display:receipt.showDetails?'block':'none'}}>
                            {
                                receipt.selectedAction === "Location" ? (
                                    <>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Business Name"
                                            value={receipt['location'].name}
                                            onChange={(e) => handleReceiptChange(index, "name", e.target.value)}
                                        />

                                        {/* Address */}
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Address"
                                            value={receipt['location'].address}
                                            onChange={(e) => handleReceiptChange(index, "address", e.target.value)}
                                            sx={{ marginTop: "10px" }}
                                        />
                                        {/* City */}
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="City"
                                            value={receipt['location'].city}
                                            onChange={(e) => handleReceiptChange(index, "city", e.target.value)}
                                            sx={{ marginTop: "10px" }}
                                        />
                                        {/* State */}
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="State"
                                            value={receipt['location'].state}
                                            onChange={(e) => handleReceiptChange(index, "state", e.target.value)}
                                            sx={{ marginTop: "10px" }}
                                        />
                                        {/* Zip */}
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Zip"
                                            value={receipt['location'].zip}
                                            onChange={(e) => handleReceiptChange(index, "zip", e.target.value)}
                                            sx={{ marginTop: "10px" }}
                                        />
                                        {/* Country */}
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Country"
                                            value={receipt['location'].country}
                                            onChange={(e) => handleReceiptChange(index, "country", e.target.value)}
                                            sx={{ marginTop: "10px" }}
                                        />
                                        {/* Phone Prefix */}
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Phone Prefix"
                                            value={receipt['location'].phone_prefix}
                                            onChange={(e) => handleReceiptChange(index, "phone_prefix", e.target.value)}
                                            sx={{ marginTop: "10px" }}
                                        />
                                        {/* Phone */}
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Phone"
                                            value={receipt['location'].phone}
                                            onChange={(e) => handleReceiptChange(index, "phone", e.target.value)}
                                            sx={{ marginTop: "10px" }}
                                        />
                                    </>
                                ) : null
                            }
                            {
                                receipt.selectedAction === "Link" ? (
                                    <Box sx={{ marginTop: "10px", display: "flex", flexDirection: "column", gap: 1 }}>
                                        {/* Lodging Category */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={receipt.section === "Lodging"}
                                                    onChange={() => handleReceiptChange(index, "section", "Lodging")}
                                                />
                                            }
                                            label="🏨 Lodging"
                                        />
                                        <FormGroup sx={{ pl: 4 }}> {/* Indented list of subcategories */}
                                            {eventLodging.map((lodging) => (
                                                <FormControlLabel
                                                    key={lodging.id}
                                                    control={
                                                        <Checkbox
                                                            checked={receipt.section === "Lodging" && receipt.section_item === lodging.id}
                                                            onChange={() => {
                                                                handleReceiptChange(index, "section", "Lodging");
                                                                handleReceiptChange(index, "section_item", lodging.id, "lodging");
                                                            }}
                                                        />
                                                    }
                                                    label={lodging.name}
                                                />
                                            ))}
                                        </FormGroup>

                                        {/* Meals Category */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={receipt.section === "Meals"}
                                                    onChange={() => handleReceiptChange(index, "section", "Meals")}
                                                />
                                            }
                                            label="🍽 Meals"
                                        />
                                        <FormGroup sx={{ pl: 4 }}>
                                            {eventMeals.map((meal) => (
                                                <FormControlLabel
                                                    key={meal.id}
                                                    control={
                                                        <Checkbox
                                                            checked={receipt.section === "Meals" && receipt.section_item === meal.id}
                                                            onChange={() => {
                                                                handleReceiptChange(index, "section", "Meals");
                                                                handleReceiptChange(index, "section_item", meal.id, "meals");
                                                            }}
                                                        />
                                                    }
                                                    label={meal.title || meal.name}
                                                />
                                            ))}
                                        </FormGroup>

                                        {/* Activities Category */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={receipt.section === "Activities"}
                                                    onChange={() => handleReceiptChange(index, "section", "Activities")}
                                                />
                                            }
                                            label="🎉 Activities"
                                        />
                                        <FormGroup sx={{ pl: 4 }}>
                                            {eventActivities.map((activity) => (
                                                <FormControlLabel
                                                    key={activity.id}
                                                    control={
                                                        <Checkbox
                                                            checked={receipt.section === "Activities" && receipt.section_item === activity.id}
                                                            onChange={() => {
                                                                handleReceiptChange(index, "section", "Activities");
                                                                handleReceiptChange(index, "section_item", activity.id, "activities");
                                                            }}
                                                        />
                                                    }
                                                    label={activity.title}
                                                />
                                            ))}
                                        </FormGroup>

                                        {/* Shopping & Gas (Single Categories without Subcategories) */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={receipt.section === "Shopping"}
                                                    onChange={() => handleReceiptChange(index, "section", "Shopping")}
                                                />
                                            }
                                            label="🛍 Shopping"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={receipt.section === "Gas"}
                                                    onChange={() => handleReceiptChange(index, "section", "Gas")}
                                                />
                                            }
                                            label="⛽ Gas"
                                        />
                                    </Box>
                                ) : null
                            }
                            {
                                receipt.selectedAction === "Amount" ? (
                                    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <TextField
                                            fullWidth
                                            label="Amount"
                                            name="amount"
                                            type="text"
                                            value={receipt.amount}
                                            onChange={(e) => handleReceiptChange(index, "amount", e.target.value)}
                                            variant="standard"
                                            sx={{
                                                textAlign: 'right',
                                                flex: 1,  // Take up available space
                                            }}
                                        />
                                        <IconButton
                                            sx={{ fontSize: '12px', color: 'initial', marginLeft: '8px' }}
                                            aria-label="change-currency"
                                            onClick={(e) => setAnchorChangeCurrencyEl(e.currentTarget)}
                                        >
                                            {receipt.currency}
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorChangeCurrencyEl}
                                            open={Boolean(anchorChangeCurrencyEl)}
                                            onClose={() => setAnchorChangeCurrencyEl(null)}
                                        >
                                            {currencyList.map((option) => (
                                                <MenuItem
                                                    key={option.code}
                                                    selected={option.code === receipt.currency}
                                                    onClick={() => {
                                                        handleReceiptChange(index, "currency", option.code);
                                                        setAnchorChangeCurrencyEl(null);
                                                    }}
                                                >
                                                    {option.code} : {option.name}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Box>
                                ) : null
                            }
                            {
                                receipt.selectedAction === "Notes" ? (
                                    <TextField
                                        fullWidth
                                        label="Notes"
                                        multiline
                                        rows={4}
                                        value={receipt.notes}
                                        onChange={(e) => handleReceiptChange(index, "notes", e.target.value)}
                                    />
                                ) : null
                            }
                        </CardContent>
                    </Card>
                ))}
            </div>

        </div>
    );
}

export default ReceiptsAddNew;