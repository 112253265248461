import React, {useContext, useEffect, useState} from 'react';

import {Card, CardContent, Chip} from "@mui/material";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    Timeline,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {PAContext} from "../../../Services/PAContext";




function ItineraryView() {

    /*
        shows all that's going on for each day of the Event
        so cycle through each day
     */

    const { PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Account, Attendees, SampleAttendees } = dataArray;

    const [attendeesReferenceArray, setAttendeesReferenceArray] = useState(
        dataArray.authenticated ? [...SampleAttendees, ...Attendees] : SampleAttendees
    );

    useEffect(() => {
        setAttendeesReferenceArray(
            dataArray.authenticated
                ? [...SampleAttendees, ...Attendees] // ✅ Merge SampleAttendees with Attendees on login
                : SampleAttendees
        );
    }, [dataArray.authenticated, Attendees, SampleAttendees]);

    const [eventHasDates, setEventHasDates] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0);
    const [eventHasDays, setEventHasDays] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0);
    const [sanitizedContent, setSanitizedContent] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.eventIntro);


    const [selectedTimetable, setSelectedTimetable] = useState(eventHasDates || !eventHasDays ? "dates" : "days");
    const handlePromptTimetableClick = (timetable) => {
        setSelectedTimetable(timetable);
    }

    const [selectedSection, setSelectedSection] = useState("Intro");
    const handleSectionClick = (sectionName, type) => {
        setSelectedSection(sectionName);
    }

    const [sortedItems, setSortedItems] = useState([]);
    useEffect(() => {
        console.log("change the view to : "+selectedSection);
        console.log("selectedTimetable : "+selectedTimetable);

        if (selectedSection === "Intro"){




        } else {

            /*
            this is either going to look like 2024-09-25 for dates or day 2 for days
         */

            if (!usePA_GlobalStore.getState().PA_GlobalObject.Planning
                || !usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities
                || !usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals
                || !usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging) {
                return;
            }

            // Filter activities based on view type
            const filteredActivities = usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities.filter(activity => {
                if (selectedTimetable === 'dates') {
                    // Ensure time_table.dates exists and is not an empty array
                    if (activity.time_table?.dates?.length > 0) {
                        return activity.time_table.dates[0]['date'] === selectedSection
                            || activity.time_table.dates[activity.time_table.dates.length - 1]['date'] === selectedSection;
                    }
                    return false;
                } else {
                    // Ensure time_table.days exists and is not an empty array
                    if (activity.time_table?.days?.length > 0) {
                        return Object.keys(activity.time_table.days[0]).includes(selectedSection)
                            || Object.keys(activity.time_table.days[activity.time_table.days.length - 1]).includes(selectedSection);
                    }
                    return false;
                }
            });

            // Filter meals based on view type
            const filteredMeals = usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals.filter(meal => {
                if (selectedTimetable === 'dates') {
                    // Ensure time_table.dates exists and is not an empty array
                    if (meal.time_table?.dates?.length > 0) {
                        return meal.time_table.dates[0]['date'] === selectedSection;
                    }
                    return false;
                } else {
                    // Ensure time_table.days exists and is not an empty array
                    if (meal.time_table?.days?.length > 0) {
                        return Object.keys(meal.time_table.days[0]).includes(selectedSection);
                    }
                    return false;
                }
            });

            // Filter lodgings based on view type
            const filteredLodgings = usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging.filter(lodging => {
                console.log(" lodging time_table : " , lodging);

                if (selectedTimetable === 'dates') {
                    // Ensure time_table.dates exists and is not an empty array
                    if (lodging.time_table?.dates?.length > 0) {
                        return lodging.time_table.dates[0]['date'] === selectedSection
                            || lodging.time_table.dates[lodging.time_table.dates.length - 1]['date'] === selectedSection;
                    }
                    return false;
                } else {
                    // Ensure time_table.days exists and is not an empty array
                    if (lodging.time_table?.days?.length > 0) {
                        return Object.keys(lodging.time_table.days[0]).includes(selectedSection)
                            || Object.keys(lodging.time_table.days[lodging.time_table.days.length - 1]).includes(selectedSection);
                    }
                    return false;
                }
            });

            // Filter flights based on view type
            const filteredFlights = usePA_GlobalStore.getState().PA_GlobalObject.Planning.flights.filter(flight => {
                if (selectedTimetable === 'dates') {
                    // Check if the depart_timestamp matches the itineraryViewSection date
                    return dayjs(flight.depart_timestamp).isSame(dayjs(selectedSection, 'YYYY-MM-DD'), 'day');
                } else {
                    // Ensure time_table.days exists and is not an empty array
                    if (flight.time_table?.days?.length > 0) {
                        return Object.keys(flight.time_table.days[0]).includes(selectedSection)
                            || Object.keys(flight.time_table.days[flight.time_table.days.length - 1]).includes(selectedSection);
                    }
                    return false;
                }
            });

            // Combine and sort items
            const combinedItems = [
                ...filteredActivities.map(activity => ({ ...activity, type: 'activity' })),
                ...filteredMeals.map(meal => ({ ...meal, type: 'meal' })),
                ...filteredLodgings.map(lodging => ({
                    ...lodging,
                    type: 'lodging',
                    time: lodging.begin_date !== '' ?
                        dayjs(selectedSection, 'YYYY-MM-DD').isSame(dayjs(lodging.begin_date, 'MM/DD/YYYY'), 'day') ? lodging.check_in : lodging.check_out :
                        lodging.time_table.dates[0]['date'] === selectedSection ? lodging.time_table.dates[0]['checkin_time'] : lodging.time_table.dates[lodging.time_table.dates.length - 1]['checkout_time'],
                    date: lodging.begin_date !== '' ?
                        dayjs(selectedSection, 'YYYY-MM-DD').isSame(dayjs(lodging.begin_date, 'MM/DD/YYYY'), 'day') ? lodging.begin_date : lodging.end_date :
                        lodging.time_table.dates[0]['date'] === selectedSection ?
                            dayjs(lodging.time_table.dates[0]['date'] , 'YYYY-MM-DD').format('MM/DD/YYYY')
                            :
                            dayjs(lodging.time_table.dates[lodging.time_table.dates.length - 1]['date'], 'YYYY-MM-DD').format('MM/DD/YYYY')
                })),
                ...filteredFlights.map(flight => ({ ...flight, type: 'flight' })),
            ];

            console.log("combinedItems : ", combinedItems);

            const sortedItems = combinedItems.sort((a, b) => {
                const getDateTime = (item) => {

                    switch (item.type) {
                        case "activity" :
                            return dayjs(`${item['time_table'].dates[0]?.date} ${item['time_table'].dates[0]?.begin_time}`, 'YYYY-MM-DD hh:mm A');
                        case "meal" :
                            return dayjs(`${item.date} ${item.time}`, 'MM/DD/YYYY hh:mm A');
                        case "lodging" :
                            return dayjs(`${item.date} ${item.time}`, 'MM/DD/YYYY h:mm A');
                        case "flight" :
                            return dayjs(item.depart_timestamp);
                        default :
                            return dayjs();
                    }

                };

                const aTime = getDateTime(a);
                const bTime = getDateTime(b);

                return aTime.isBefore(bTime) ? -1 : 1;
            });

            console.log("sortedItems : ", sortedItems);

            setSortedItems(sortedItems);
        }

    }, [selectedSection]);

    function formatTime(time) {
        if (!time) return null;

        // Handle Day.js objects or string times
        const formattedTime = dayjs(time, ['h:mm A', 'HH:mm']).format('h:mm A');

        // Check for Noon or Midnight
        if (formattedTime === '12:00 PM') return 'Noon';
        if (formattedTime === '12:00 AM') return 'Midnight';

        return formattedTime;
    }

    const [selectedAttendee, setSelectedAttendee] = useState(Account.id);
    const handleChipClick = ((_attendee_id) => {
        console.log("Show itinerary for : "+_attendee_id);

        setSelectedAttendee(_attendee_id);



    });



    return (

        <div className="itinerary-list-container">
            {/* show the header */}
            <div
                className="itinerary-list-header"
            >
                <div className="itinerary-list-header-text">
                    <h5>Itinerary</h5>
                </div>
                <div className="itinerary-section-header-chip-holder">
                    {/* Person Icon to Show Attendees */}
                    <i
                        className="icon-group"
                        style={{ fontSize: '20px', cursor: 'pointer' }}
                        onClick={() => handlePromptTimetableClick('attendees')}
                    />
                    <Badge
                        badgeContent={
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length
                        }
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: 'white', // Customize the dot color here
                                border: '1px solid #ddd', // Make it a circle
                                width: '16px', // Adjust the dot size as needed
                                height: '16px',
                                borderRadius: '50%', // Make it a circle
                            },
                        }}
                    >
                        <Chip
                            sx={selectedTimetable === "dates" ? selectedChipStyle : unselectedChipStyle}
                            label="Dates"
                            size="small"
                            onClick={() => handlePromptTimetableClick('dates')}
                        />
                    </Badge>
                    <Badge
                        badgeContent={
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length
                        }
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: 'white', // Customize the dot color here
                                border: '1px solid #ddd', // Make it a circle
                                width: '16px', // Adjust the dot size as needed
                                height: '16px',
                                borderRadius: '50%', // Make it a circle
                            },
                        }}
                    >
                        <Chip
                            sx={{
                                ...(selectedTimetable === "days" ? selectedChipStyle : unselectedChipStyle),
                                marginLeft: '10px'
                            }}
                            label="Days"
                            size="small"
                            onClick={() => handlePromptTimetableClick('days')}
                        />
                    </Badge>
                </div>
            </div>

            {/* show the horizontal list of Attendees, dates, or days */}
            <div className="itinerary-list-items-container">
                <Box sx={{display: 'flex', flexDirection: 'row', overflowX: 'auto', padding: '0.5rem'}}>
                    {
                        selectedTimetable === "dates" ? (
                            <>
                                {/* Show the Intro button */}
                                <Chip
                                    sx={selectedSection === "Intro" ? selectedChipStyle : unselectedChipStyle}
                                    label="Intro"
                                    clickable
                                    onClick={() => handleSectionClick("Intro")}
                                    style={{ margin: '0.5rem', height: '50px' }}
                                />

                                {usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0 ? (
                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates
                                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                                        .map((date, index) => (
                                            <Chip
                                                key={index}
                                                sx={selectedSection === date.date ? selectedChipStyle : unselectedChipStyle}
                                                label={
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Typography variant="body2">
                                                            {dayjs(date.date).format('ddd')}
                                                        </Typography> {/* Day abbreviation */}
                                                        <Typography variant="caption">
                                                            {dayjs(date.date).format('M/D')}
                                                        </Typography> {/* Date */}
                                                    </div>
                                                }
                                                style={{
                                                    margin: '0.5rem',
                                                    height: '50px',
                                                    width: '70px'
                                                }} // Adjust Chip size as needed
                                                onClick={() => handleSectionClick(`${date.date}`, 'date')}
                                            />
                                        ))
                                ) : <></>}
                            </>
                        ) : selectedTimetable === "days" ? (
                            <>
                                {/* Show the Intro button */}
                                <Chip
                                    sx={selectedSection === "Intro" ? selectedChipStyle : unselectedChipStyle}
                                    label="Intro"
                                    clickable
                                    onClick={() => handleSectionClick("Intro")}
                                    style={{ margin: '0.5rem', height: '50px' }}
                                />

                                {usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0 ? (
                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days.map((day, index) => {
                                        const dayKey = Object.keys(day).find(k => k.startsWith('day '));
                                        const dayName = day[dayKey]; // e.g., 'Wednesday'
                                        const dayAbbreviation = dayName.slice(0, 3); // Shorten to 'Wed'

                                        return (
                                            <Chip
                                                key={index}
                                                sx={selectedSection === Object.keys(day)[0] ? selectedChipStyle : unselectedChipStyle}
                                                label={
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Typography variant="body2">
                                                            {dayAbbreviation}
                                                        </Typography> {/* Shortened day name */}
                                                        <Typography variant="caption">
                                                            {capitalizeFirstLetter(dayKey)} {/* Capitalize 'day' */}
                                                        </Typography> {/* Display day number */}
                                                    </div>
                                                }
                                                style={{
                                                    margin: '0.5rem',
                                                    height: '50px',
                                                    width: '70px'
                                                }} // Adjust Chip size as needed
                                                onClick={() => handleSectionClick(`${capitalizeFirstLetter(dayKey)}`, 'days')}
                                            />
                                        );
                                    })
                                ) : <></>}
                            </>
                        ) : selectedTimetable === "attendees" ? (
                            <>
                                {(() => {
                                    // Get all attendees from Planning.attendees
                                    const planningAttendeeIds = new Set(PA_GlobalObject.Planning.attendees?.map(att => att.id) || []);

                                    // Get unique attendee IDs from to_do items that are not in Planning.attendees
                                    const to_doAttendeeIds = new Set(
                                        PA_GlobalObject.Planning.to_do
                                            ?.map(item => item.assigned_to)
                                            .flat() // If assigned_to is an array, flatten it
                                            .filter(id => !planningAttendeeIds.has(id)) || []
                                    );

                                    // Merge Planning.attendees IDs and to_do-assigned attendee IDs
                                    const allAttendeeIds = new Set([...planningAttendeeIds, ...to_doAttendeeIds]);

                                    // Retrieve attendee details from dataArray.Attendees or SampleAttendees
                                    const allAttendees = Array.from(allAttendeeIds).map(id =>
                                        Attendees.find(att => att.id === id) ||
                                        SampleAttendees.find(att => att.id === id) ||
                                        { id, fname: "Unknown", nickname: "Unknown" } // Fallback for unknown attendees
                                    );

                                    return allAttendees
                                        .sort((a, b) => {
                                            // Ensure current user comes first
                                            if (a.id === Account.id) return -1;
                                            if (b.id === Account.id) return 1;

                                            // Sort alphabetically by nickname or first name
                                            const nameA = capitalizeFirstLetter(a.nickname || a.fname).toLowerCase();
                                            const nameB = capitalizeFirstLetter(b.nickname || b.fname).toLowerCase();
                                            return nameA.localeCompare(nameB);
                                        })
                                        .map((_att, index) => (
                                            <Chip
                                                sx={selectedAttendee === _att.id ? selectedChipStyle : unselectedChipStyle}
                                                label={capitalizeFirstLetter(_att.nickname || _att.fname)}
                                                clickable
                                                onClick={() => handleChipClick(_att.id)}
                                                style={{ margin: '0.5rem' }}
                                            />
                                        ));
                                })()}
                            </>
                        ) : (
                            <></>
                        )
                    }

                </Box>
            </div>

            <div className="itinerary-section-list-items-container">
                {
                    selectedSection === "Intro" ? (
                        <Card>
                            <div>
                                <img
                                    src={
                                        PA_GlobalObject.Planning.cover_image_full_data !== "" ? PA_GlobalObject.Planning.cover_image_full_data : PA_GlobalObject.Planning.cover_image_thumbnail
                                    }
                                    style={{maxWidth: '330px'}}
                                    alt=""
                                />
                            </div>

                            <CardContent sx={{paddingTop: '0'}}>
                                <Box sx={{mt: 2, padding: 2}}>
                                    <div dangerouslySetInnerHTML={{__html: sanitizedContent}}/>
                                </Box>
                            </CardContent>
                        </Card>
                    ) : null
                }
                {
                    selectedSection !== "Intro" && sortedItems.length === 0 ? (
                        <Typography variant="subtitle2" align="center" color="textSecondary">
                            The day is free to do whatever you enjoy!
                        </Typography>
                    ) : (
                        selectedSection !== "Intro" ? (
                            <Timeline>
                                {sortedItems.map((item, index) => {
                                    const isActivity = item.type === 'activity';
                                    const isMeal = item.type === 'meal';
                                    const isLodging = item.type === 'lodging';
                                    const isFlight = item.type === 'flight';
                                    const isLastItem = index === sortedItems.length - 1;

                                    return (
                                        <TimelineItem key={item.id}>
                                            <TimelineOppositeContent>
                                                {
                                                    isLodging ? (
                                                        <>
                                                            {
                                                                selectedTimetable === "dates" ? (
                                                                    dayjs(selectedSection, 'YYYY-MM-DD').isSame(item.time_table?.dates?.[0]?.date, 'day') ? (
                                                                        <>
                                                                            <Typography variant="caption" color="textSecondary" component="div">
                                                                                Check In:
                                                                            </Typography>
                                                                            <Typography variant="caption" color="textSecondary" component="div">
                                                                                {item.time_table?.dates?.[0]?.checkin_time}
                                                                            </Typography>
                                                                        </>
                                                                    ) : dayjs(selectedSection, 'YYYY-MM-DD').isSame(item.time_table?.dates?.[item.time_table.dates.length - 1]?.date, 'day') ? (
                                                                        <>
                                                                            <Typography variant="caption" color="textSecondary" component="div">
                                                                                Check Out:
                                                                            </Typography>
                                                                            <Typography variant="caption" color="textSecondary" component="div">
                                                                                {item.time_table?.dates?.[item.time_table.dates.length - 1]?.checkout_time}
                                                                            </Typography>
                                                                        </>
                                                                    ) : null
                                                                ) : (
                                                                    selectedSection === Object.keys(item.time_table?.days?.[0])[0] ? (
                                                                        <>
                                                                            <Typography variant="caption" color="textSecondary" component="div">
                                                                                Check In:
                                                                            </Typography>
                                                                            <Typography variant="caption" color="textSecondary" component="div">
                                                                                {item.time_table?.days?.[0]?.checkin_time}
                                                                            </Typography>
                                                                        </>
                                                                    ) : selectedSection === Object.keys(item.time_table?.days?.[item.time_table.days.length - 1])[0] ? (
                                                                        <>
                                                                            <Typography variant="caption" color="textSecondary" component="div">
                                                                                Check Out:
                                                                            </Typography>
                                                                            <Typography variant="caption" color="textSecondary" component="div">
                                                                                {item.time_table?.days?.[item.time_table.days.length - 1]?.checkout_time}
                                                                            </Typography>
                                                                        </>
                                                                    ) : null
                                                                )
                                                            }
                                                        </>
                                                    ) : isActivity ? (
                                                        <>
                                                            {
                                                                selectedTimetable === "dates" ? (
                                                                    <>
                                                                        {item['time_table'].dates[0].begin_time && (
                                                                            <Typography variant="caption" color="textSecondary" component="div">
                                                                                {formatTime(item['time_table'].dates[0].begin_time)}
                                                                            </Typography>
                                                                        )}
                                                                        {item['time_table'].dates[item['time_table'].dates.length - 1].end_time && (
                                                                            <Typography variant="caption" color="textSecondary" component="div">
                                                                                {formatTime(item['time_table'].dates[item['time_table'].dates.length - 1].end_time)}
                                                                            </Typography>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {item['time_table'].days[0].begin_time && (
                                                                            <Typography variant="caption" color="textSecondary" component="div">
                                                                                {formatTime(item['time_table'].days[0].begin_time)}
                                                                            </Typography>
                                                                        )}
                                                                        {item['time_table'].days[item['time_table'].days.length - 1].end_time && (
                                                                            <Typography variant="caption" color="textSecondary" component="div">
                                                                                {formatTime(item['time_table'].days[item['time_table'].days.length - 1].end_time)}
                                                                            </Typography>
                                                                        )}
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                    ) : isFlight ? (
                                                        // Flight logic
                                                        <>
                                                            {item.depart_timestamp && (
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    {`Depart: ${formatTime(dayjs(item.depart_timestamp).format('h:mm A'))}`}
                                                                </Typography>
                                                            )}
                                                            {item.arrive_timestamp && (
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    {`Arrive: ${formatTime(dayjs(item.arrive_timestamp).format('h:mm A'))}`}
                                                                </Typography>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {
                                                                selectedTimetable === "dates" ? (
                                                                    // Default logic for single time
                                                                    <Typography variant="caption" color="textSecondary" component="div">
                                                                        {formatTime(item.time_table?.dates?.[0]?.begin_time || item.time)}
                                                                    </Typography>
                                                                ) : (
                                                                    // Default logic for single time
                                                                    <Typography variant="caption" color="textSecondary" component="div">
                                                                        {formatTime(item.time_table?.days?.[0]?.begin_time || item.time)}
                                                                    </Typography>
                                                                )
                                                            }
                                                        </>

                                                    )}
                                            </TimelineOppositeContent>

                                            <TimelineSeparator>
                                                {isActivity ? (
                                                    <TimelineDot color="success">
                                                        <i className="icon-list-heart"
                                                           style={{fontSize: '1rem'}}/> {/* Adjust the size if necessary */}
                                                    </TimelineDot>
                                                ) : isMeal ? (
                                                    <TimelineDot color="info">
                                                        <i className="icon-fork-knife2"
                                                           style={{fontSize: '1rem'}}/> {/* Adjust the size if necessary */}
                                                    </TimelineDot>
                                                ) : isLodging ? (
                                                    <TimelineDot color="warning">
                                                        <i className="icon-bed"
                                                           style={{fontSize: '1rem'}}/> {/* Adjust the size if necessary */}
                                                    </TimelineDot>
                                                ) : isFlight ? (
                                                    <TimelineDot color="warning">
                                                        <i className="icon-airplane"
                                                           style={{fontSize: '1rem'}}/> {/* Adjust the size if necessary */}
                                                    </TimelineDot>
                                                ) : null}
                                            </TimelineSeparator>

                                            <TimelineContent>
                                                <Typography variant="subtitle2">
                                                    {isActivity ? item.title : isMeal ? item.location.name !== "" ? item.location.name : item.name : isLodging ? item.location.name : item.depart_airport_city}
                                                </Typography>
                                                <Typography variant="caption">
                                                    {
                                                        isActivity ?
                                                            item.location.address
                                                            :
                                                            isMeal ?
                                                                item.location.address !== "" ? item.location.address : item.eat_in_details
                                                                :
                                                                isLodging ?
                                                                    item.location.formatted_address : item.depart_airport_city
                                                    }
                                                </Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    );
                                })}
                            </Timeline>
                        ) : null

                    )
                }
            </div>


        </div>

    )
}

export default ItineraryView;
