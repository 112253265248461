import React, {useEffect, useRef, useState} from 'react';

import {Checkbox,ListItem} from "@mui/material";
import Grid from '@mui/material/Grid2';

import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";






function LodgingLocationTab(props){

    const { set_PA_GlobalObject } = usePA_GlobalStore();

    const [eventLodging, setEventLodging] = useState(props.eventLodging);
    useEffect(() => {
        setEventLodging(props.eventLodging);
    }, [props.eventLodging]);

    useEffect(() => {
        // Update only the specific lodging in the Planning.lodging array
        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                lodging: prev.Planning.lodging.map((lodging) =>
                    lodging.id === eventLodging.id ? eventLodging : lodging // Update the matching lodging, keep others unchanged
                ),
            },
        }));
    }, [eventLodging]);

    const [uniqueLocations, setUniqueLocations] = useState([]);
    const [selectedLodgingLocation, setSelectedLodgingLocation] = useState(eventLodging.location || {});

    // Extract unique locations on component mount
    useEffect(() => {
        const meals = usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals || [];
        const lodging = usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging || [];
        const activities = usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities || [];

        // Combine all locations
        const allLocations = [
            ...meals.map((meal) => meal.location),
            ...lodging.map((lodging) => lodging.location),
            ...activities.map((activity) => activity.location),
        ];

        // Filter unique locations by Google Place ID or another unique property
        const unique = Array.from(
            new Map(
                allLocations
                    .filter((location) => location.name) // Exclude locations without a name
                    .map((location) => [location.google_place_id, location])
            ).values()
        );

        // Sort the unique locations alphabetically by name
        const sortedUnique = unique.sort((a, b) => {
            const nameA = (a.name || "").toLowerCase();
            const nameB = (b.name || "").toLowerCase();
            return nameA.localeCompare(nameB);
        });

        // Separate the matching location from the rest
        const matchingLocation = sortedUnique.filter(
            (location) =>
                selectedLodgingLocation &&
                location.google_place_id === selectedLodgingLocation.google_place_id
        );
        const otherLocations = sortedUnique.filter(
            (location) =>
                !selectedLodgingLocation ||
                location.google_place_id !== selectedLodgingLocation.google_place_id
        );

        // Combine matching location(s) on top with the rest alphabetically sorted
        const finalLocationList = [...matchingLocation, ...otherLocations];

        setUniqueLocations(finalLocationList);
    }, [selectedLodgingLocation]);

    function handleLocationSelect(location) {
        console.log("location : ", location);
        setSelectedLodgingLocation(location);

        // Update the eventLodging with the selected location
        setEventLodging((prevLodging) => ({
            ...prevLodging,
            location,
        }));
    }


    return (

        <Grid item style={{flex: '1'}}>

            <List dense>
            {uniqueLocations.map((location, index) => (
                <ListItem
                    key={index}
                    sx={{ alignItems: "flex-start" }}
                    onClick={() => handleLocationSelect(location)}
                    button
                >
                    <Checkbox
                        checked={
                            selectedLodgingLocation.google_place_id ===
                            location.google_place_id
                        }
                        onChange={() => handleLocationSelect(location)}
                        sx={{ padding: "0 8px" }}
                    />
                    <ListItemText
                        primary={location.name || "Unnamed Location"}
                        secondary={location.address || "No Address Available"}
                    />
                </ListItem>
            ))}
        </List>
        </Grid>

    );

}

export default LodgingLocationTab;