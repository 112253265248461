import React, {useContext, useEffect, useState} from 'react';
import {Checkbox, Chip, Divider, FormControlLabel, ListItem, ListItemSecondaryAction} from "@mui/material";

import Box from "@mui/material/Box";

import './supplies.css';
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {addChipStyle} from "../../ButtonStyle/Add";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";
import {addWaitChipStyle} from "../../ButtonStyle/AddWait";
import InfoSupplyEssentials from "../../Dialogs/InfoSupplyEssentials";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import PAAttendee from "../../../MasterEventObjects/Attendee";
import dayjs from "dayjs";


function SuppliesAddEssentials(props){

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Account } = dataArray;

    function handleSuppliesAddOK() {
        console.log("handleSuppliesAddOK : ", selectedEssentials);

        /*
            so here we need to take the essential and turn it into a supplyItem
         */

        const supplyItemsFromEssentials = selectedEssentials.map((essential, index) => ({
            id: dayjs().valueOf() + index,
            name: essential.name,
            category: essential.category,
            sub_category: essential.sub_category,
            private: essential.private,
            assigned_by: Account.id === undefined ? PA_GlobalObject.Planning.attendees[0].id : Account.id,
            assigned_to: Account.id === undefined ? PA_GlobalObject.Planning.attendees[0].id : Account.id,
            essential: true,
            essential_id: essential.id,
            notes: essential.notes,
            status: essential.status,
        }));

        console.log("supplyItemsFromEssentials : ", supplyItemsFromEssentials);

        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                supplies: [...(prev.Planning.supplies || []), ...supplyItemsFromEssentials], // Append new supply items
            },
        }));

        handlePromptAddNewClose();
    }

    function handlePromptAddNewClose() {
        props.setShowEventItemExtendedConfig(false);
        navigate('/Planner/Supplies')
    }

    useEffect(()=>{
        console.log("essentials : ", Account.essentials);

    }, [Account.essentials]);

    // Group essentials by sub_category
    const [selectedEssentials, setSelectedEssentials] = useState([]);
    useEffect(() => {
        console.log("selectedEssentials", selectedEssentials);
    }, [selectedEssentials]);
    const groupedEssentials = (Account.essentials ?? []).reduce((acc, item) => {
        if (!acc[item.sub_category]) {
            acc[item.sub_category] = [];
        }
        acc[item.sub_category].push(item);
        return acc;
    }, {});
    const allEssentialIds = Object.values(groupedEssentials).flat().map(item => item.id);

    const allSelected = selectedEssentials.length === allEssentialIds.length && allEssentialIds.length > 0;
    const handleToggleAll = () => {
        setSelectedEssentials(allSelected ? [] : allEssentialIds); // If all are selected, clear; otherwise, select all
    };

    // Function to toggle selected items
    const toggleEssential = (item) => {
        console.log("toggleEssential : ", item);

        setSelectedEssentials((prevSelected) =>
            prevSelected.some((selected) => selected.id === item.id)
                ? prevSelected.filter((selected) => selected.id !== item.id) // Remove item if it exists
                : [...prevSelected, item] // Add item if not exists
        );
    };


    const [isSupplyEssentialsInfoDialogOpen, setSupplyEssentialsInfoDialogOpen] = useState(false);



    return (
        <div className="supplies-list-container" >
            {/* show the header */}
            <div
                className="supplies-list-header"
            >
                <div className="supplies-list-header-text">
                    <h5>Supply Essentials</h5>
                </div>
                <div className="supplies-list-header-info-icon-holder">
                    <i
                        className="icon-info-circle"
                        style={{fontSize: '20px', padding: '5px'}}
                        onClick={() => setSupplyEssentialsInfoDialogOpen(!isSupplyEssentialsInfoDialogOpen)}
                    />
                </div>
                <div className="supplies-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Cancel"
                        size="small"
                        color="error"
                        onClick={handlePromptAddNewClose}
                    />
                    <Chip
                        sx={{
                            ...(selectedEssentials.length === 0 ? addWaitChipStyle : addChipStyle), // Spread first sx
                            marginLeft: '10px' // Add margin without overriding styles
                        }}
                        label={selectedEssentials.length === 0 ? 'Add' : 'Add ' + selectedEssentials.length}
                        size="small"
                        onClick={handleSuppliesAddOK}
                    />
                </div>
            </div>

            {/* Select All Checkbox */}
            <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                <FormControlLabel
                    control={<Checkbox checked={allSelected} onChange={handleToggleAll}/>}
                    label="Select All"
                    labelPlacement="start" // Moves the checkbox to the right side
                    sx={{ marginLeft: "auto", marginRight: '10px' }} // Ensures proper right alignment
                    componentsProps={{
                        typography: { variant: "body2" }, // Apply body2 variant to label
                    }}
                />
            </Box>

            {/* show all the fields */}
            <div className="supplies-essentials-list-items-container">

                {/* Essentials List */}
                <Box sx={{ overflowY: "auto", padding: "0 10px" }}>
                    {Object.keys(groupedEssentials).length === 0 ? (
                        <Typography sx={{ textAlign: "center", color: "text.secondary", padding: 2 }}>No essentials available.</Typography>
                    ) : (
                        Object.entries(groupedEssentials)
                            .sort(([categoryA], [categoryB]) => categoryA.localeCompare(categoryB))
                            .map(([category, items]) => (
                                <Box key={category} sx={{ marginBottom: 2 }}>
                                    {/* Category Title */}
                                    <Typography variant="subtitle1" sx={{ paddingY: 1 }}>{category}</Typography>
                                    <Divider />

                                    {/* List of Items */}
                                    <List>
                                        {items
                                            .sort((a, b) => (a.name?.toLowerCase() ?? "").localeCompare(b.name?.toLowerCase() ?? ""))
                                            .map((item) => (
                                            <ListItem key={item.id} sx={{ display: "flex", justifyContent: "space-between" }}>
                                                {/* Item Name */}
                                                <ListItemText
                                                    primary={capitalizeFirstLetter(item.name)}
                                                    sx={{
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        paddingLeft: "15px",
                                                    }}
                                                    primaryTypographyProps={{ variant: "body2" }}
                                                />
                                                {/* Checkbox */}
                                                <ListItemSecondaryAction>
                                                    <Checkbox
                                                        checked={selectedEssentials.some((selected) => selected.id === item.id)}
                                                        onChange={() => toggleEssential(item)}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                        ))
                    )}
                </Box>
            </div>


            <InfoSupplyEssentials
                isOpen={isSupplyEssentialsInfoDialogOpen}
                onClose={() => setSupplyEssentialsInfoDialogOpen(false)}
            />

        </div>
    )
}

export default SuppliesAddEssentials;
