import React, {useContext, useEffect, useState} from 'react';

import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Chip,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Menu,
    MenuItem,
    MenuList,
    Paper
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import ListItemText from "@mui/material/ListItemText";
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";

import lodgingHeaderImage from "../../../graphics/th-513411954.jpg";
import Typography from "@mui/material/Typography";

import TodoCard from "./TodoCard";
import './todo.css';

import dayjs from "dayjs";
import Badge from "@mui/material/Badge";

import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import InfoTodo from "../../Dialogs/InfoTodo";



function TodoList() {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Account, Attendees, SampleAttendees } = dataArray;

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated ? Attendees : SampleAttendees);
    useEffect(() => {
        setAttendeesReferenceArray(dataArray.authenticated ? Attendees : SampleAttendees);
    }, [dataArray.authenticated, Attendees, SampleAttendees]);

    const [selectedAttendee, setSelectedAttendee] = useState(Account.id);
    const [eventTodos, setEventTodos] = useState([]);

    const attendeesWithTodoCounts = PA_GlobalObject.Planning.attendees.map((attendee) => {
        const todoCount = PA_GlobalObject.Planning.to_do.reduce((count, item) =>
            count + ((item.assigned_to === attendee.id && (!item.private || item.assigned_to === Account.id)) ? 1 : 0), 0
        );
        return { ...attendee, todoCount };
    });
    const attendeesWithTodos = attendeesWithTodoCounts.filter(attendee => attendee.todoCount > 0);
    const attendeesWithoutTodos = attendeesWithTodoCounts.filter(attendee => attendee.todoCount === 0);
    attendeesWithTodos.sort((a, b) => b.todoCount - a.todoCount);
    attendeesWithoutTodos.sort((a, b) => a.nickname.localeCompare(b.nickname));
    const sortedAttendees = [...attendeesWithTodos, ...attendeesWithoutTodos];


    const [anchorTodosListMenu, setAnchorTodosListMenu] = useState(null);
    const handleTodosListMenuOpenMenu = (event) => {
        setAnchorTodosListMenu(event.currentTarget);
    };
    const handleTodosListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorTodosListMenu(event.currentTarget);

        if (dataId === "new-entry"){
            navigate('/Planner/Todo/AddNew');
        }
    };
    const handleTodosListMenuClose = () => {
        setAnchorTodosListMenu(null);
    };

    useEffect(() => {
        console.log("Planning.to_do : "+JSON.stringify(PA_GlobalObject.Planning.to_do, null, '\t'));

        if (PA_GlobalObject.Planning.to_do !== undefined) {
            setEventTodos([...PA_GlobalObject.Planning.to_do]);
        }

        if (PA_GlobalObject.Planning.to_do && PA_GlobalObject.Planning.to_do.length > 0) {
            for (const _to_do of PA_GlobalObject.Planning.to_do) {
                console.log("_to_do : "+JSON.stringify(_to_do, null, '\t'));
            }
        }
    }, [PA_GlobalObject.Planning.to_do]);

    const updateTodoDetails = (updatedTodo) => {
        // Find the index of the updated Idea in eventTodos
        const todoIndex = eventTodos.findIndex((todo) => todo.id === updatedTodo.id);

        if (todoIndex !== -1) {
            // If the to_do item is found, update it in the event to_do array
        }

    };

    const handleChipClick = (_attendee_id) => {
        console.log("Show todo items for : "+_attendee_id);

        setSelectedAttendee(_attendee_id);

        if (PA_GlobalObject.Planning.to_do !== undefined) {
            const filteredItems = PA_GlobalObject.Planning.to_do.filter(item => item.assigned_to === _attendee_id);

            console.log("filteredItems : "+JSON.stringify(filteredItems, null, '\t'));

            // Group the filtered items by category and subcategory


        }
    }


    const [todoToDelete, setTodoToDelete] = useState(null);  // Holds the to-do to delete for confirmation dialog
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);  // Controls the confirmation dialog visibility
    const handleDeleteTodo = (_todoId) => {
        // Remove the activity with the given ID
        const updatedTodos = eventTodos.filter(_to_do => _to_do.id !== _todoId);

        // You can call a function to persist this change or update parent state
        setEventTodos(eventTodos);

        //this updates the activities array at the Global level
        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                to_do: updatedTodos, // Update only the title
            },
        }));

        closeDeleteDialog();

    };
    const openDeleteDialog = (_to_do) => {
        setTodoToDelete(_to_do);  // Set the to_do to be deleted
        setDeleteDialogOpen(true);  // Open the confirmation dialog
    };
    const closeDeleteDialog = () => {
        setTodoToDelete(null);  // Reset the to_do to be deleted
        setDeleteDialogOpen(false);  // Close the confirmation dialog
    };


    const [isTodoInfoDialogOpen, setTodoInfoDialogOpen] = useState(false);


    return (
        <div className="todo-list-container">

            {/* show the header */}
            <div
                className="todo-list-header"
            >
                <div className="todo-list-header-text">
                    <h5>To-Do</h5>
                </div>
                <div className="todo-list-header-info-icon-holder">
                    <i
                        className="icon-info-circle"
                        style={{fontSize: '20px', padding: '5px'}}
                        onClick={() => setTodoInfoDialogOpen(!isTodoInfoDialogOpen)}
                    />
                </div>
                <div className="todo-list-header-chip-holder">

                    <Chip
                        sx={menuChipStyle}
                        label={
                            <i className="icon-menu2" style={{fontSize: '16px'}}/>
                        }
                        size="small"
                        onClick={handleTodosListMenuOpenMenu}
                    />
                    <Menu
                        sx={{width: 320, maxWidth: '100%'}}
                        anchorEl={anchorTodosListMenu}
                        id="todos-menu"
                        open={Boolean(anchorTodosListMenu)}
                        onClose={handleTodosListMenuClose}

                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuList
                            dense
                            sx={{width: 320, maxWidth: '100%'}}
                        >
                            <MenuItem
                                data-id="new-entry"
                                onClick={handleTodosListMenuClick}>
                                <ListItemText>New Entry</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>


            {/* show the list of attendees */}
            <div className="todo-list-attendee-list-container">
                {
                    sortedAttendees.length > 0 ? (
                        sortedAttendees.map((attendee, index) => (
                            <Badge
                                key={index}
                                badgeContent={attendee.todoCount > 0 ? attendee.todoCount : null}
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: 'white',
                                        border: '1px solid #ddd',
                                        width: '16px',
                                        height: '16px',
                                        borderRadius: '50%',
                                        top: '10px',
                                        right: '10px',
                                    },
                                }}
                            >
                                <Chip
                                    sx={selectedAttendee === attendee.id ? selectedChipStyle : unselectedChipStyle}
                                    label={capitalizeFirstLetter(attendee.nickname)}
                                    clickable
                                    onClick={() => handleChipClick(attendee.id)}
                                    style={{margin: '0.5rem'}}
                                />
                            </Badge>
                        ))
                    ) : (
                        <div style={{padding: '20px'}}>
                            <i className="icon-warning" style={{fontSize: '11px', color: 'red', marginRight: '10px'}}/>
                            <Typography variant="caption">Need to add some Attendees first</Typography>
                        </div>
                    )
                }
            </div>


            {/* show the list of Todos */}
            <div className="todo-list-items-container">

                {
                    eventTodos && eventTodos.length > 0 ? (
                        eventTodos
                            .filter(item => item.assigned_to.includes(selectedAttendee))
                            .sort((a, b) => {
                                // First, compare by schedule_date
                                const dateComparison = dayjs(a.schedule_date).diff(dayjs(b.schedule_date));

                                // If schedule_date is the same, compare by schedule_begin_time
                                if (dateComparison !== 0) {
                                    return dateComparison;
                                } else {
                                    return dayjs(a.schedule_begin_time, 'HH:mm A').diff(dayjs(b.schedule_begin_time, 'HH:mm A'));
                                }
                            })
                            .map((todoDetails, index) => (

                                <Grid item key={index} sx={{display: 'flex'}}>

                                    <TodoCard
                                        to_do={todoDetails}
                                        onDeleteTodo={() => openDeleteDialog(todoDetails)}
                                    />

                                </Grid>

                            ))
                    ) : (
                        <Grid item>
                            <Paper elevation={2} sx={{margin: '20px'}}>
                                <Card>
                                    <CardMedia
                                        sx={{height: 140}}
                                        image={lodgingHeaderImage}
                                        title="Add Todo Items"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h7" component="div">
                                            Add any type of Todo Item
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            There are several ways to easily add Lodgings:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            1. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the BUSINESS icon and search the area for <strong>Hotels, Motels,
                                            Campgrounds</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            2. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the HOUSE icon and search the area for <strong>VRBO, AirBnB, Personal
                                            Residence</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            3. <strong>From a PDF Receipt</strong>.
                                            We'll have our AI Concierge extract the relevant details from the PDF and
                                            let you see what we've
                                            found. You can edit the results before we actually add the details to the
                                            Itinerary.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            4. <strong>Camping</strong>. Use your GPS to pinpoint your location. Click
                                            on the
                                            <i className="icon-menu2"/> above.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            5. <strong>Enter manually</strong>. Fill in the input boxes with the exact
                                            Lodging details.
                                        </Typography>

                                    </CardContent>
                                </Card>

                            </Paper>
                        </Grid>
                    )

                }
            </div>

            {/* Confirmation Dialog */}
            <Dialog
                open={isDeleteDialogOpen}
                onClose={closeDeleteDialog}
            >
                <DialogTitle>Delete To-Do Item</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the item "{todoToDelete?.title}"? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDeleteTodo(todoToDelete.id)} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>


            <InfoTodo
                isOpen={isTodoInfoDialogOpen}
                onClose={() => setTodoInfoDialogOpen(false)}
            />

        </div>
    )

}

export default TodoList;
