import React, {useState} from "react";
import {Card, CardContent, Typography, Box, CardHeader, Chip, Dialog, DialogContent} from "@mui/material";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";



const formatAmount = (amount, currency = "USD") => {
    return `${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currency}`;
};




function ReceiptCard({ section, receipts, onDelete }) {

    const { PA_GlobalObject } = usePA_GlobalStore();

    // Calculate total amounts per currency
    const totalsByCurrency = receipts.reduce((acc, receipt) => {
        const currency = receipt.currency || "USD"; // Default to USD if missing
        const amount = parseFloat(receipt.amount) || 0;

        if (acc[currency]) {
            acc[currency] += amount;
        } else {
            acc[currency] = amount;
        }

        return acc;
    }, {});

    // Get event lodging, meals, and activities
    const eventLodging = PA_GlobalObject.Planning.lodging || [];
    const eventMeals = PA_GlobalObject.Planning.meals || [];
    const eventActivities = PA_GlobalObject.Planning.activities || [];

    const [openImage, setOpenImage] = useState(false);
    const [imageSrc, setImageSrc] = useState("");

    const handleOpenImage = (src) => {
        setImageSrc(src);
        setOpenImage(true);
    };

    const handleCloseImage = () => {
        setOpenImage(false);
    };

    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
            }}
        >
            <CardHeader
                title={section}
                titleTypographyProps={{ fontSize: '18px' }}
                action={
                    <Box sx={{ textAlign: "right" }}>
                        {Object.entries(totalsByCurrency).map(([currency, total]) => (
                            <Typography key={currency} variant="body2" sx={{ fontWeight: "bold", color: "green" }}>
                                {formatAmount(total, currency)}
                            </Typography>
                        ))}
                    </Box>
                }
            />
            <CardContent>

                {/* Render Receipts for this Section */}
                {receipts.map((receipt) => {
                    console.log("receipt : ", receipt);

                    return (
                        <Box
                            key={receipt.id}
                            sx={{borderBottom: "1px solid #ddd", paddingBottom: '10px', marginBottom: '15px'}}
                        >
                            <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
                                {/* Left-aligned Receipt Info (Only Shows When Linked) */}
                                {receipt.section_item !== 0 && (() => {
                                    const sectionDataMap = {
                                        Meals: { data: eventMeals, displayKeys: ["title", "name"] },
                                        Lodging: { data: eventLodging, displayKeys: ["name"] },
                                        Activities: { data: eventActivities, displayKeys: ["title"] },
                                    };

                                    const sectionDetails = sectionDataMap[receipt.section];
                                    if (sectionDetails) {
                                        const relatedEntry = sectionDetails.data.find(item => item.id === receipt.section_item);

                                        if (relatedEntry) {
                                            const displayText = sectionDetails.displayKeys.map(key => relatedEntry[key]).find(Boolean);

                                            return displayText ? (
                                                <Typography variant="body2" color="textSecondary" sx={{ flexShrink: 0 }}>
                                                    {displayText}
                                                </Typography>
                                            ) : null;
                                        }
                                    }
                                    return null;
                                })()}

                                {/* Spacer to push the amount to the right */}
                                <Box sx={{ flexGrow: 1 }} />

                                {/* Right-aligned Amount */}
                                <Typography variant="subtitle2" color="textSecondary">
                                    {formatAmount(receipt.amount, receipt.currency)}
                                </Typography>
                            </Box>

                            {/* Notes (if available) */}
                            {receipt.notes && (
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    {receipt.notes}
                                </Typography>
                            )}

                            {/* Icons Section */}
                            <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ fontSize:'14px', gap: 1 }}>
                                {/* Link Icon (Only if section_item exists) */}
                                {receipt.section_item !== 0 && (
                                    <i
                                        style={{ color: "green" }}
                                        className="icon-link"
                                        title="Linked Item"></i>
                                )}

                                {/* Image Icon (Only if data exists) */}
                                {receipt.full_data && receipt.full_data !== "" && (
                                    <i
                                        className="icon-file-check"
                                        title="View Receipt Image"
                                        style={{cursor: "pointer", color: "blue"}}
                                        onClick={() => handleOpenImage(receipt.full_data)}
                                    ></i>
                                )}

                                {/* Privacy Icon (Toggles between Private & Public) */}
                                <i
                                    style={{color: receipt.private ? "green" : "orange"}}
                                    className={receipt.private ? "icon-eye-cross" : "icon-eye"}
                                    title={receipt.private ? "Private" : "Public"}
                                ></i>

                                {/* Delete Icon (Always visible) */}
                                <i
                                    className="icon-trash"
                                    title="Delete Receipt"
                                    onClick={() => onDelete(receipt.id)}
                                    style={{ cursor: "pointer", color: "red" }}
                                ></i>
                            </Box>

                        </Box>
                    );
                })}
            </CardContent>

            {/* Dialog for Full-Screen Image */}
            <Dialog open={openImage} onClose={handleCloseImage} maxWidth="md" fullWidth>
                <DialogContent sx={{ textAlign: "center" }}>
                    <img src={imageSrc} alt="Receipt" style={{ maxWidth: "100%", maxHeight: "80vh" }} />
                </DialogContent>
            </Dialog>

        </Card>
    );
}

export default ReceiptCard;
