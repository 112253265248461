import React, {useContext, useEffect, useState} from 'react';

import './plannerResponsive.css';
import {
    Button, Card, CardContent, CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Menu,
    MenuItem,
    MenuList,
    Paper
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {PAContext} from "../../../Services/PAContext";
import {getAllEventsFromDatabase, getEventFromDatabase} from "../../../Services/BrowserDatabaseFunctions";
import PAEvent from "../../../MasterEventObjects/PAEvent";

import dayjs from "dayjs";
import ListItemIcon from "@mui/material/ListItemIcon";
import Grid from '@mui/material/Grid2';
import {Route, Routes, useNavigate} from "react-router-dom";
import DetailsView from "../../EventFeatures/Details/DetailsView";
import LocationDetails from "../../EventFeatures/Location/LocationDetails";
import DatesTimesContent from "../../EventFeatures/DatesTimes/DatesTimesContent";
import ItineraryView from "../../EventFeatures/Itinerary/ItineraryView";
import ActivitiesList from "../../EventFeatures/Activities/ActivitiesList";
import ActivitiesAddNew from "../../EventFeatures/Activities/ActivitiesAddNew";
import ActivitiesEdit from "../../EventFeatures/Activities/ActivitiesEdit";
import IdeasList from "../../EventFeatures/Ideas/IdeasList";
import AttendeesList from "../../EventFeatures/Attendees/AttendeesList";
import AttendeesAddNew from "../../EventFeatures/Attendees/AttendeesAddNew";
import AttendeesAddPrevious from "../../EventFeatures/Attendees/AttendeesAddPrevious";
import AttendeesAddSamples from "../../EventFeatures/Attendees/AttendeesAddSamples";
import AttendeeEdit from "../../EventFeatures/Attendees/AttendeeEdit";
import LodgingList from "../../EventFeatures/Lodging/LodgingList";
import LodgingAddFromMap from "../../EventFeatures/Lodging/LodgingAddFromMap";
import LodgingAddFromSuggestions from "../../EventFeatures/Lodging/LodgingAddFromSuggestions";
import LodgingAddFromPDF from "../../EventFeatures/Lodging/LodgingAddFromPDF";
import LodgingSuggestions from "../../EventFeatures/Lodging/LodgingSuggestions";
import FlightsList from "../../EventFeatures/Flights/FlightsList";
import MessagesList from "../../EventFeatures/Messages/MessagesList";
import MealsList from "../../EventFeatures/Meals/MealsList";
import PhotosList from "../../EventFeatures/Photos/PhotosList";
import PhotosAddNew from "../../EventFeatures/Photos/PhotosAddNew";
import SuggestionsTourRadar from "../../EventFeatures/Suggestions/SuggestionsTourRadar";
import SuggestionsUnique from "../../EventFeatures/Suggestions/SuggestionsUnique";
import SuppliesList from "../../EventFeatures/Supplies/SuppliesList";
import SuppliesAddEssentials from "../../EventFeatures/Supplies/SuppliesAddEssentials";
import SuppliesAddNew from "../../EventFeatures/Supplies/SuppliesAddNew";
import ReceiptList from "../../EventFeatures/Receipts/ReceiptList";
import TodoList from "../../EventFeatures/Todo/TodoList";
import WeatherDetails from "../../EventFeatures/Weather/WeatherDetails";
import PAAttendee from "../../../MasterEventObjects/Attendee";

import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import WeatherUsingVisualCrossing from "../../../Services/WeatherUsingVisualCrossing";
import SuppliesAddSuggestions from "../../EventFeatures/Supplies/SuppliesAddSuggestions";
import TodoAddNew from "../../EventFeatures/Todo/TodoAddNew";
import SavePlanning from "../../Dialogs/SavePlanning";
import GoogleMapMobile from "../../../Pages/PlanningPage/GoogleMapMobile";
import BusinessExpenseAuthorizationDialog from "../../Dialogs/BusinessExpenseAuthorizationDialog";
import BusinessExpenseReport from "../../Dialogs/BusinessExpenseReport";
import ReceiptsAddNew from "../../EventFeatures/Receipts/ReceiptsAddNew";
import {AnimatePresence, motion} from "framer-motion";



function PlannerResponsive(props){

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray, setAccount, setPlanningBudget, setPlanningBudgetCurrency } = useContext(PAContext);
    const { Account, authenticated } = dataArray;


    const [selectedMenu, setSelectedMenu] = useState(null); // Track selected mobile menu
    const [currentFeature, setCurrentFeature] = useState("Map");

    const [zoomInOnLocation, setZoomInOnLocation] = useState(props.zoomInOn);
    const [searchingForLocation, setSearchingForLocation] = useState(props.searchingFor);
    const [suggestionForLocation, setSuggestionForLocation] = useState("");

    const [showEventItemConfig, setShowEventItemConfig] = useState(false);
    const [showEventItemExtendedConfig, setShowEventItemExtendedConfig] = useState(false);
    const [itineraryViewSection, setItineraryViewSection] = useState("");
    const [itineraryViewType, setItineraryViewType] = useState("");

    const [eventBudgetTotal, setEventBudgetTotal] = useState(0);
    const [eventBudgetActual, setEventBudgetActual] = useState(0);
    const [eventBudgetCurrency, setEventBudgetCurrency] = useState(0);

    const [clearEvent, setClearEvent] = useState(false);
    const [eventId, setEventId] = useState("");
    const [eventName, setEventName] = useState(PA_GlobalObject.Planning.title || 'My New Event');
    const [eventBeginDate, setEventBeginDate] = useState(null);
    const [eventEndDate, setEventEndDate] = useState(null);
    const [formattedEventDates, setFormattedEventDates] = useState("");

    const [eventAttendeesUpdated, setAttendeesUpdated] = useState(false);
    const [editAttendeeId, setEditAttendeeId] = useState("");
    const [selectedAttendeeForSupplies, setSelectedAttendeeForSupplies] = useState("");

    const [ideasFromMap, setIdeasFromMap] = useState({});
    const [activitiesFromMap, setActivitiesFromMap] = useState({});
    const [lodgingFromMap, setLodgingFromMap] = useState({});
    const [mealsFromMap, setMealsFromMap] = useState({});
    const [conciergePlanningItemsFromMap, setConciergePlanningItemsFromMap] = useState([]);

    const [lodgingFromSuggestions, setLodgingFromSuggestions] = useState({});
    const [lodgingPhotosFromMap, setLodgingPhotosFromMap] = useState([]);
    const [viewSuggestionLodgingMarkers, setViewSuggestionLodgingMarkers] = useState(false);
    const [viewSuggestionLodgingMarkersFilter, setViewSuggestionLodgingMarkersFilter] = useState([]);
    const [lodgingSuggestionNameFromMap, setLodgingSuggestionNameFromMap] = useState("");

    const [pullWeatherReport, setPullWeatherReport] = useState(false);
    const [weatherReportReceived, setWeatherReportReceived] = useState(false);
    const [weatherReportReceivedSuccessOpen, setWeatherReportReceivedSuccessOpen] = useState(false);

    const [tourSuggestionsTourCities, setTourSuggestionsTourCities] = useState([]);


    useEffect(() => {
        console.log("Checking Planning ... ", usePA_GlobalStore.getState().PA_GlobalObject.Planning);
        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning === undefined || usePA_GlobalStore.getState().PA_GlobalObject.Planning.title === ""){

            console.log("Creating new Event");
            const newEvent = new PAEvent();
            newEvent.title = "My New Event";
            newEvent.status = 10;

            if (Account === undefined || Object.keys(Account).length === 0){
                //create the default Planner Attendee
                console.log("Need to create the default Planner Attendee");
                const _planner = new PAAttendee(
                    'Sample',
                    'Planning',
                    'sample.planning@example.com');
                _planner.id = "100000";
                _planner.role = 'planner';
                _planner.nickname = 'Planner';
                _planner.isSample = true;
                newEvent.attendees.push(_planner);

                setAccount(_planner);
            } else {
                //they are logged in so use their account
                console.log("User is logged in");

                const _planner = new PAAttendee(
                    Account.fname,
                    Account.lname,
                    Account.email);
                _planner.id = Account.id;
                _planner.created = Account.created;
                _planner.role = Account.role;
                _planner.nickname = Account.nickname;
                _planner.language = Account.language;

                _planner.address = Account.address;
                _planner.city = Account.city;
                _planner.state = Account.state;
                _planner.zip = Account.zip;
                _planner.country = Account.country;

                _planner.email = Account.email;
                _planner.dob = Account.dob;

                _planner.phone_prefix = Account.phone_prefix;
                _planner.phone = Account.phone;
                _planner.mask_phone_number = Account.mask_phone_number;

                _planner.events = Account.events;
                _planner.documents = Account.documents;

                newEvent.attendees.push(_planner);

            }

            setSearchingForLocation("");
            setFormattedEventDates("");

            //clear the budget summary
            setEventBudgetTotal(0);
            setEventBudgetActual(0);

            set_PA_GlobalObject({ Planning: newEvent });

            console.log("eventName : "+newEvent.title);
            setEventName(newEvent.title);

            console.log("after setPlanning");
        } else {
            //an event already exists
            console.log("the current Planning.title : " + usePA_GlobalStore.getState().PA_GlobalObject.Planning.title);
            setEventName(usePA_GlobalStore.getState().PA_GlobalObject.Planning.title);

            //change the map location if a location exists



        }

        //see if there is a location that needs to be gotten
        console.log("is searching : "+props.isSearching);
        if (props.isSearching){
            //see what they're searching for
            console.log("searching for : "+props.searchingFor);




        }

    }, []);
    useEffect(() => {
        console.log("--- PA_GlobalObject.Planning got triggered!!!! ",usePA_GlobalStore.getState().PA_GlobalObject.Planning);

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.id !== eventId){
            //a new Event was loaded
            setShowEventItemConfig(false);
            setEventId(usePA_GlobalStore.getState().PA_GlobalObject.Planning.id);
        }

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning && usePA_GlobalStore.getState().PA_GlobalObject.Planning.title !== ''){
            setEventName(usePA_GlobalStore.getState().PA_GlobalObject.Planning.title);

            //move the map
            if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging?.[0]?.location?.google_place_id) {
                setZoomInOnLocation({
                    google_place_id: usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging[0].location.google_place_id,
                    latitude: usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging[0].location.latitude,
                    longitude: usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging[0].location.longitude,
                });
            }

        }
    }, [PA_GlobalObject.Planning]);


    useEffect(() => {
        console.log("eventBudgetTotal");
        setPlanningBudget(eventBudgetTotal);
    }, [eventBudgetTotal]);
    useEffect(() => {
        console.log("eventBudgetActual triggered!!");
//        setPlanning({
//            ...Planning,
//            budget_actual: eventBudgetActual
//        });
    }, [eventBudgetActual]);
    useEffect(() => {
        setPlanningBudgetCurrency(eventBudgetCurrency);
    }, [eventBudgetCurrency]);

    useEffect(() => {
        console.log("props.isSearching or props.searchingFor has been triggered ");
        if (props.isSearching){
            setSearchingForLocation(props.searchingFor);
        }
    }, [props.isSearching, props.searchingFor]);

    useEffect(() => {
        console.log("searchingForLocation has been triggered ");

    }, [searchingForLocation]);

    useEffect(() => {

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning !== undefined && usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table'] !== undefined) {

            const _time_table = usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table'];
            console.log("the _time_table : " , _time_table);

            // Showing the dates takes precedence over days
            const _datesArray = _time_table.dates;
            const _daysArray = _time_table.days;

            console.log("the _daysArray : " , _daysArray);

            // Utility function to format the time and replace 12:00 AM/PM with Midnight/Noon
            const formatTime = (time) => {
                const formattedTime = dayjs(time, 'h:mm A').format('h:mm A');
                if (formattedTime === '12:00 AM') return 'Midnight';
                if (formattedTime === '12:00 PM') return 'Noon';
                return formattedTime;
            };

            // Function to check if it's a full-day event based on the times
            const isFullDay = (beginTime, endTime) => {
                return (
                    dayjs(beginTime, 'h:mm A').format('h:mm A') === '12:00 AM' &&
                    dayjs(endTime, 'h:mm A').format('h:mm A') === '11:59 PM'
                );
            };

            // First, handle the dates (dates take precedence over days)
            if (_datesArray.length > 0) {
                // Parse dates to get minimum and maximum
                const minDateObj = _datesArray.reduce((min, current) => dayjs(min.date).isBefore(dayjs(current.date)) ? min : current);
                const maxDateObj = _datesArray.reduce((max, current) => dayjs(max.date).isAfter(dayjs(current.date)) ? max : current);

                const minDate = dayjs(minDateObj.date);
                const maxDate = dayjs(maxDateObj.date);

                // Format the time if available, using the formatTime utility to handle 12:00 AM/PM
                const minTime = minDateObj['begin-time'] ? formatTime(minDateObj['begin-time']) : null;
                const maxTime = maxDateObj['end-time'] ? formatTime(maxDateObj['end-time']) : null;

                // Check if the event spans a full day (12:00 AM to 11:59 PM)
                const fullDayEvent = isFullDay(minDateObj['begin-time'], maxDateObj['end-time']);
                const sameYear = minDate.year() === maxDate.year();

                // Format dates
                const minDateString = minDate.format('D MMM YYYY');
                const maxDateString = maxDate.format('D MMM YYYY');

                // Combine formatted dates and times
                if (minDate.isSame(maxDate, 'day')) {
                    // If dates are the same, show one date and the time range if it's not a full-day event
                    if (!fullDayEvent && minTime && maxTime) {
                        setFormattedEventDates(`${minDate.format('D MMM')}, ${minTime} - ${maxTime} ${minDate.format('YYYY')}`);
                    } else {
                        setFormattedEventDates(minDateString); // Show only date if it's a full-day event
                    }
                } else {
                    //different begin and end dates
                    // show the date range and time range if available and not a full-day event
                    if (!fullDayEvent && minTime && maxTime) {
                        setFormattedEventDates(`${minDateString} ${minTime} - ${maxDateString} ${maxTime} ${maxDate.format('YYYY')}`);
                    } else {
                        setFormattedEventDates(`${minDate.format('D MMM')} - ${maxDateString}`); // Show only date range if it's a full-day event
                    }
                }
            } else if (_daysArray.length > 0) {
                // Handle days if no dates are available
                if (_daysArray.length === 1) {
                    setFormattedEventDates(`1 day ${_daysArray[0]['day 1']}`);
                } else {
                    const _last_day = 'day ' + _daysArray.length;
                    setFormattedEventDates(`${_daysArray.length} days ${_daysArray[0]['day 1']} - ${_daysArray[_daysArray.length - 1][_last_day]}`);
                }
            } else {
                // No dates or days available
                setFormattedEventDates('');
            }

        }
    }, [PA_GlobalObject.Planning?.time_table]);

    const [isBusinessExpenseReportOpen, setIsBusinessExpenseReportOpen] = useState(false);
    const [isExpenseAuthDialogOpen, setIsExpenseAuthDialogOpen] = useState(false);

    const [isSavePlanningDialogOpen, setIsSavePlanningDialogOpen] = useState(false);

    /*
        for the Events menu list
     */
    useEffect(() => {
        console.log("clearEvent");

        if(clearEvent){
            setSearchingForLocation("");
            setFormattedEventDates("");

            //clear the budget summary
            setEventBudgetTotal(0);
            setEventBudgetActual(0);

            setClearEvent(false);
        }

    },[clearEvent]);

    const handleEventMenuClick = (event_id) => {
        console.log("handleEventMenuClick");
        console.log("event_id : "+event_id);

        setAnchorEventMenu(null);

        setClearEvent(true);

        //load the Event from the browser database
        getEventFromDatabase(event_id).then(specificEvent => {
            console.log("specificEvent : "+specificEvent);

            if (specificEvent !== null){

                const formattedEvent = new PAEvent(specificEvent);

                console.log("the new Event : ", formattedEvent);

                //set the Event
                set_PA_GlobalObject({ Planning: formattedEvent });

                /*
                    //see if there are any NEW Event messages
                 */
                const _send_string = {};
                _send_string.action = "E_getEventMessages";
                _send_string.event_id = specificEvent.id;

                if (specificEvent.messages.length === 0){
                    _send_string.latest_message_timestamp = 0;
                } else {
                    _send_string.latest_message_timestamp = specificEvent.messages.reduce((latest, message) => {
                        return message.created > latest ? message.created : latest;
                    }, 0);
                }

                dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");


                /*
                    let AI know of the Event switch?
                 */


            } else {
                //no Event found
                console.log("specificEvent NOT FOUND");

            }
        });

    }

    function savePlanning(){

        //are they logged in
        if (authenticated && Account.user_id !== undefined){
            console.log("is logged in");

            //check if an Event name has been given
            //is this a bucket list item or an actual Event
            if (PA_GlobalObject.Planning.title === ""){
                //show the DialogBox to get the title

                const _sendString = {};
                _sendString.action = "E_setEventAllChanges";
                _sendString.event = PA_GlobalObject.Planning;

//                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

            }

        } else {
            console.log("needs to login");


        }

    }
    function clearPlanning(){
        //show the prompt
        setOpenDialogDelete(true);

    }
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    function handleDialogDeleteClose() {
        setOpenDialogDelete(false);
    }
    function handleDialogDeleteOK(){
        setOpenDialogDelete(false);

        console.log("Creating new Event");
        const newEvent = new PAEvent();
        newEvent.title = "My New Event";
        newEvent.status = 10;

        if (Account === undefined || Object.keys(Account).length === 0){
            //create the default Planner Attendee
            console.log("Need to create the default Planner Attendee");
            const _planner = new PAAttendee(
                'Sample',
                'Planning',
                'sample.planning@example.com');
            _planner.id = "100000";
            _planner.role = 'planner';
            _planner.nickname = 'Planner';
            _planner.isSample = true;
            newEvent.attendees.push(_planner);

            setAccount(_planner);
        } else {
            //they are logged in so use their account
            console.log("User is logged in");

            const _planner = new PAAttendee(
                Account.fname,
                Account.lname,
                Account.email);
            _planner.id = Account.id;
            _planner.created = Account.created;
            _planner.role = Account.role;
            _planner.nickname = Account.nickname;
            _planner.language = Account.language;

            _planner.address = Account.address;
            _planner.city = Account.city;
            _planner.state = Account.state;
            _planner.zip = Account.zip;
            _planner.country = Account.country;

            _planner.email = Account.email;
            _planner.dob = Account.dob;

            _planner.phone_prefix = Account.phone_prefix;
            _planner.phone = Account.phone;
            _planner.mask_phone_number = Account.mask_phone_number;

            _planner.events = Account.events;
            _planner.documents = Account.documents;

            newEvent.attendees.push(_planner);

        }

        setSearchingForLocation("");
        setFormattedEventDates("");

        //clear the budget summary
        setEventBudgetTotal(0);
        setEventBudgetActual(0);

        set_PA_GlobalObject({ Planning: newEvent });

        console.log("eventName : "+newEvent.title);
        setEventName(newEvent.title);

        console.log("after setPlanning");
    }



    /*
        for the Features icon
     */
    const [anchorFeaturesMenu, setAnchorFeaturesMenu] = useState(null);
    const handleFeaturesMenuOpen = (event) => {
        setAnchorFeaturesMenu(event.currentTarget);
    };
    const handleFeaturesMenuClose = () => {
        setAnchorFeaturesMenu(null);
    };
    const handleEventFeaturesMenuClick = (featureName) => {
        // Handle the click event here
        console.log(`Clicked on ${featureName}`);
        setAnchorFeaturesMenu(null);

        //decide whether to show the console or not
        if (featureName !== "Save" && featureName !== "Clear"){
            if (!showEventItemConfig){
                //if it's not showing then show it
                setShowEventItemConfig(true);
                setCurrentFeature(featureName);

            } else {
                //if it is showing then decide whether it is just changing features and needs to stay open
                // - or - if they are just meaning to close it
                if (featureName === currentFeature){
                    setShowEventItemConfig(false);
                    setCurrentFeature("Map");
                } else {
                    setCurrentFeature(featureName);
                }
            }
        }
        switch (featureName){
            case "BusinessAuthorizations":
                setIsExpenseAuthDialogOpen(!isExpenseAuthDialogOpen);
                break;
            case "BusinessExpenseReport":
                setIsBusinessExpenseReportOpen(!isBusinessExpenseReportOpen);
                break;
            case "Save":
                setIsSavePlanningDialogOpen(!isSavePlanningDialogOpen);
                break;
            case "Details":
                navigate('/Planner/Details')
                break;
            case "Location":
                navigate('/Planner/Location')
                break;
            case "Dates":
                navigate('/Planner/Dates')
                break;
            case "Map":
                navigate('/Planner/MapMobile')
                break;
            case "Itinerary":
                navigate('/Planner/Itinerary')
                break;
            case "Documents":
                navigate('/Planner/Documents')
                break;
            case "Activities":
                navigate('/Planner/Activities')
                break;
            case "Ideas":
                navigate('/Planner/Ideas')
                break;
            case "Attendees":
                navigate('/Planner/Attendees')
                break;
            case "Lodging":
                navigate('/Planner/Lodging')
                break;
            case "Flights":
                navigate('/Planner/Flights')
                break;
            case "carRentals":
                navigate('/Planner/CarRentals')
                break;
            case "Cruises":
                navigate('/Planner/Cruises')
                break;
            case "Messages":
                navigate('/Planner/Messages')
                break;
            case "Meals":
                navigate('/Planner/Meals')
                break;
            case "Photos":
                navigate('/Planner/Photos')
                break;
            case "Supplies":
                navigate('/Planner/Supplies')
                break;
            case "Receipts":
                navigate('/Planner/Receipts')
                break;
            case "Todo":
                navigate('/Planner/Todo')
                break;
            case "Weather":
                navigate('/Planner/Weather')
                break;
            case "Clear":
                clearPlanning();
                break;
            default:
        }

    }




    /*
        for the Event submenu
     */
    const [anchorEventMenu, setAnchorEventMenu] = useState(null);

    const handleEventMenuOpen = (event) => {
        setAnchorEventMenu(event.currentTarget);
    };    const handleEventMenuClose = () => {
        setAnchorEventMenu(null);
    };

    const [events, setEvents] = useState([]);
    useEffect(() => {
        if (anchorEventMenu) { // Only fetch when menu is opened
            getAllEventsFromDatabase(Account.id)
                .then((_events) => {
                    console.log("Fetched events: ", _events);
                    if (_events) {
                        setEvents(
                            _events.sort((a, b) => {
                                const aFirstDate = dayjs(a.time_table?.dates?.[0]?.date || '');
                                const bFirstDate = dayjs(b.time_table?.dates?.[0]?.date || '');

                                if (!aFirstDate.isValid()) return 1;
                                if (!bFirstDate.isValid()) return -1;
                                return bFirstDate.diff(aFirstDate);
                            })
                        );
                    }
                })
                .catch((error) => console.error("Error fetching events:", error));
        }
    }, [anchorEventMenu]); // Runs when the menu opens

    useEffect(() => {
        console.log("pullWeatherReport : "+pullWeatherReport);
        if (pullWeatherReport){
            console.log("Need to pull the weather report");
            setWeatherReportReceived(false);

            try {
                /*
                    look at the Planning.time_table to see if dates array has entries
                    and look at any Planning.lodging.location to
                    see if the weather report should be retrieved
                 */

                const hasDates = PA_GlobalObject.Planning?.time_table?.dates?.length > 0;
                const begin_date = dayjs(PA_GlobalObject.Planning.time_table.dates[0].date); // First date in the time table
                const hasLocation = PA_GlobalObject.Planning?.lodging?.some(lodge => lodge.location && lodge.location.latitude && lodge.location.longitude);
                const daysUntilBeginDate = begin_date.diff(dayjs(), 'day');

                // Proceed only if both conditions are met
                if (hasDates && hasLocation && daysUntilBeginDate <= 14) {

                    const results = WeatherUsingVisualCrossing(PA_GlobalObject.Planning);

                    if (results) {
                        setWeatherReportReceived(true);
                        setWeatherReportReceivedSuccessOpen(true);
                    } else {
                        setWeatherReportReceived(true);
                    }

                }
            } catch (e){
                console.log(e);
            }

        }

    }, [pullWeatherReport]);


    const formatEventTime = (timeTable) => {
        if (!timeTable) return '';

        // Helper function to format the time (handle begin and end time)
        const formatTimeRange = (beginTime, endTime) => {
            const formattedBeginTime = dayjs(`2024-01-01 ${beginTime}`).format('hA');
            const formattedEndTime = endTime ? dayjs(`2024-01-01 ${endTime}`).format('hA') : '';
            return formattedEndTime ? `${formattedBeginTime} - ${formattedEndTime}` : formattedBeginTime;
        };

        // Function to format the date range
        const getDateRange = () => {
            const { dates } = timeTable;

            if (dates && dates.length > 0) {
                const beginDate = dayjs(dates[0].date);
                const endDate = dayjs(dates[dates.length - 1].date);

                if (beginDate.isSame(endDate, 'day')) {
                    // Same day with begin and end time
                    const beginTime = dates[0]['begin_time'] || '12:00 AM';
                    const endTime = dates[0]['end_time'] || '11:59 PM';
                    return `${beginDate.format('D MMM YYYY')} ${formatTimeRange(beginTime, endTime)}`;
                } else if (beginDate.isSame(endDate, 'year')) {
                    // Same year, different days
                    return `${beginDate.format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
                } else {
                    // Different years
                    return `${beginDate.format('D MMM YYYY')} - ${endDate.format('D MMM YYYY')}`;
                }
            }
            return null;
        };

        // Function to format the days (if no dates exist)
        const getDaysRange = () => {
            const { days } = timeTable;

            if (days && days.length > 0) {
                const firstDay = days[0]['day 1'];
                const lastDay = days[days.length - 1][`day ${days.length}`];
                const dayCount = days.length;

                if (dayCount === 1 || firstDay === lastDay) {
                    return `1 Day ${firstDay}`; // Singular "Day"
                }

                return `${dayCount} Days ${firstDay} - ${lastDay}`; // Plural "Days"
            }
            return null;
        };

        // Return the event's date range or days as a formatted string
        const dateRange = getDateRange();
        const daysRange = getDaysRange();

        // Return either the date range or days range
        return dateRange || daysRange || '';
    };

    const MotionSlider = ({ images }) => {
        const [current, setCurrent] = useState(0);

        useEffect(() => {
            if (images.length <= 1) return;

            const timer = setInterval(() => {
                setCurrent(prev => (prev + 1) % images.length);
            }, 3000);

            return () => clearInterval(timer);
        }, [images]);

        return (
            <div style={{ overflow: 'hidden', height: '150px', position: 'relative' }}>
                <AnimatePresence initial={false}>
                    <motion.img
                        key={images[current]}
                        src={images[current]}
                        alt={`event-photo-${current}`}
                        initial={{ x: 300, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -300, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        style={{
                            width: '100%',
                            height: '150px',
                            objectFit: 'cover',
                            position: 'absolute',
                            top: 0,
                            left: 0
                        }}
                    />
                </AnimatePresence>
            </div>
        );
    };

    const EventCard = ({ event, accountId, onClick }) => {
        const photoAlbumImages = event.photos?.filter(
            (photo) => photo.attendee_id === accountId && photo.photo_album
        ).map(photo => photo.full_data) || [];

        const hasAlbum = photoAlbumImages.length > 0;

        const fallbackImage = event.cover_image_thumbnail || "https://plannedadventure.com/assets/images/user_bg1.png";

        return (
            <Card
                onClick={() => onClick(event.id)}
                sx={{
                    cursor: 'pointer',
                    height: '220px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                {hasAlbum ? (
                    <MotionSlider images={photoAlbumImages} />
                ) : (
                    <CardMedia
                        component="img"
                        height="150"
                        image={fallbackImage}
                        alt={event.title}
                        sx={{ objectFit: 'cover' }}
                    />
                )}

                <CardContent sx={{ paddingTop: 0, paddingBottom: '15px !important' }}>
                    <Typography variant="subtitle2" display="block">{event.title}</Typography>
                    <Typography variant="caption" display="block">{formatEventTime(event.time_table)}</Typography>
                </CardContent>
            </Card>
        );
    };


    return (
        <div className="dashboard-container">


            {/* this is the space to view/add/edit/delete any of the features */}
            <Grid
                container
                sx={{
                    height: '100vh', // Full viewport height
                }}
            >
                <Grid
                    item
                    sx={{
                        flexGrow: 1, // Fill available space
                        overflowY: 'auto', // Allow scrolling if content overflows
                        paddingBottom: '80px', // Leave space for the bottom menu (adjust to your menu height)
                    }}
                >
                    <div className="planning-event-routes-holder">
                        <Paper style={{
                            backgroundColor: 'rgb(251, 251, 251)',
                            overflowX: 'hidden',
                            overflowY: 'auto'
                        }}
                    >
                            <Routes>
                                <Route
                                    path="Details"
                                    element={
                                        <DetailsView
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setEventName={setEventName}
                                        />}
                                />
                                <Route
                                    path="Location"
                                    element={
                                        <LocationDetails
                                            setZoomInOnLocation={setZoomInOnLocation}
                                            searchingForLocation={searchingForLocation}
                                            setSearchingForLocation={setSearchingForLocation}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setPullWeatherReport={setPullWeatherReport}
                                            _global_map_ref={props._global_map_ref}
                                        />}
                                />

                                <Route
                                    path="Dates"
                                    element={
                                        <DatesTimesContent
                                            setEventName={setEventName}
                                            setEventBeginDate={setEventBeginDate}
                                            setEventEndDate={setEventEndDate}
                                            setPullWeatherReport={setPullWeatherReport}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Itinerary/*"
                                    element={
                                        <ItineraryView
                                            setItineraryViewSection={setItineraryViewSection}
                                            setItineraryViewType={setItineraryViewType}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Activities"
                                    element={
                                        <ActivitiesList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />
                                <Route path="Activities/Add" element={<ActivitiesAddNew />} />
                                <Route path="Activities/Edit" element={<ActivitiesEdit />} />

                                <Route
                                    path="Ideas"
                                    element={
                                        <IdeasList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />

                                <Route
                                    path="Attendees"
                                    element={
                                        <AttendeesList
                                            setEditAttendeeId={setEditAttendeeId}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route path="Attendees/AddNew"
                                       element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>}/>
                                <Route path="Attendees/AddPrevious"
                                       element={<AttendeesAddPrevious setAttendeesUpdated={setAttendeesUpdated}/>}/>
                                <Route path="Attendees/AddSamples"
                                       element={<AttendeesAddSamples setAttendeesUpdated={setAttendeesUpdated}/>}/>
                                <Route path="Attendees/Edit" element={<AttendeeEdit editAttendeeId={editAttendeeId}/>}/>



                                <Route
                                    path="Lodging"
                                    element={
                                        <LodgingList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/AddFromMap"
                                    element={
                                        <LodgingAddFromMap
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            lodgingFromMap={lodgingFromMap}
                                            lodgingPhotosFromMap={lodgingPhotosFromMap}
                                            eventBeginDate={eventBeginDate}
                                            eventEndDate={eventEndDate}
                                            setEventBeginDate={setEventBeginDate}
                                            setEventEndDate={setEventEndDate}
                                            eventBudgetActual={eventBudgetActual}
                                            setEventBudgetActual={setEventBudgetActual}
                                            setEventBudgetCurrency={setEventBudgetCurrency}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/AddFromSuggestions"
                                    element={
                                        <LodgingAddFromSuggestions
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            lodgingFromSuggestions={lodgingFromSuggestions}
                                            eventBeginDate={eventBeginDate}
                                            eventEndDate={eventEndDate}
                                            setEventBeginDate={setEventBeginDate}
                                            setEventEndDate={setEventEndDate}
                                            eventBudgetActual={eventBudgetActual}
                                            setEventBudgetActual={setEventBudgetActual}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/AddFromPDF"
                                    element={
                                        <LodgingAddFromPDF
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/Suggestions"
                                    element={
                                        <LodgingSuggestions
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setViewSuggestionLodgingMarkersFilter={setViewSuggestionLodgingMarkersFilter}
                                            lodgingSuggestionNameFromMap={lodgingSuggestionNameFromMap}
                                            setCurrentFeature={setCurrentFeature}
                                            setLodgingFromSuggestions={setLodgingFromSuggestions}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />

                                <Route
                                    path="Flights"
                                    element={
                                        <FlightsList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route path="Flights/SearchAndBook" element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>} />
                                <Route path="Flights/Search" element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>} />
                                <Route path="Flights/EnterDetails" element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>} />

                                <Route
                                    path="MapMobile"
                                    element={
                                        <GoogleMapMobile
                                            _global_map_ref={props._global_map_ref}
                                            zoomInOnLocation={zoomInOnLocation}
                                            searchingForLocation={searchingForLocation}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setCurrentFeature={setCurrentFeature}
                                            viewSuggestionLodgingMarkers={viewSuggestionLodgingMarkers}
                                            viewSuggestionLodgingMarkersFilter={viewSuggestionLodgingMarkersFilter}
                                            setLodgingSuggestionNameFromMap={setLodgingSuggestionNameFromMap}
                                            setIdeasFromMap={setIdeasFromMap}
                                            setLodgingFromMap={setLodgingFromMap}
                                            setLodgingPhotosFromMap={setLodgingPhotosFromMap}
                                            setMealsFromMap={setMealsFromMap}
                                            conciergePlanningItemsFromMap={conciergePlanningItemsFromMap}
                                            setConciergePlanningItemsFromMap={setConciergePlanningItemsFromMap}
                                            tourSuggestionsTourCities={tourSuggestionsTourCities}
                                            clearEvent={clearEvent}
                                        />
                                    }
                                />

                                <Route
                                    path="Messages"
                                    element={
                                        <MessagesList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Meals"
                                    element={
                                        <MealsList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />

                                <Route
                                    path="Photos"
                                    element={
                                        <PhotosList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route
                                    path="Photos/AddNew"
                                    element={
                                        <PhotosAddNew />
                                    }
                                />

                                <Route
                                    path="Suggestions/Tours"
                                    element={
                                        <SuggestionsTourRadar
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setTourSuggestionsTourCities={setTourSuggestionsTourCities}
                                        />
                                    }
                                />

                                <Route
                                    path="Suggestions/Unique"
                                    element={
                                        <SuggestionsUnique
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />


                                <Route
                                    path="Supplies/*"
                                    element={
                                        <SuppliesList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            selectedAttendeeForSupplies={selectedAttendeeForSupplies}
                                            setSelectedAttendeeForSupplies={setSelectedAttendeeForSupplies}
                                        />}
                                />
                                <Route path="Supplies/AddEssentials"
                                       element={<SuppliesAddEssentials
                                           selectedAttendeeForSupplies={selectedAttendeeForSupplies}
                                           setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                                       />}/>
                                <Route path="Supplies/AddNew"
                                       element={<SuppliesAddNew
                                           selectedAttendeeForSupplies={selectedAttendeeForSupplies}
                                           setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                                       />}/>
                                <Route
                                    path="Supplies/AddSuggestions"
                                    element={
                                        <SuppliesAddSuggestions
                                            selectedAttendeeForSupplies={selectedAttendeeForSupplies}
                                            setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Receipts"
                                    element={
                                        <ReceiptList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route
                                    path="Receipts/AddNew"
                                    element={
                                        <ReceiptsAddNew
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Todo"
                                    element={
                                        <TodoList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Todo/AddNew"
                                    element={
                                        <TodoAddNew
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Weather"
                                    element={
                                        <WeatherDetails
                                            pullWeatherReport={pullWeatherReport}
                                            setWeatherReportReceived={setWeatherReportReceived}
                                            setWeatherReportReceivedSuccessOpen={setWeatherReportReceivedSuccessOpen}
                                        />}
                                />


                            </Routes>
                        </Paper>
                    </div>
                </Grid>
            </Grid>

            <div className="mobile-menu-content-container">

                <div className="mobile-bottom-row">
                    {/* Replace with 4 clickable divs in mobile */}
                    <div className="mobile-menu-item" onClick={handleFeaturesMenuOpen}>
                        <i className="icon-home3"></i>
                        <span>Home</span>
                    </div>

                    <div className="mobile-menu-item">
                        <i className="icon-bubbles2"></i>
                        <span>Messaging</span>
                    </div>

                    {/* the Event list  */}
                    <div className="mobile-menu-item" onClick={handleEventMenuOpen}>
                        <i className="icon-road-sign"></i>
                        <span>Events</span>
                    </div>
                    <Menu
                        sx={{ width: 320, maxWidth: '100%' }}
                        anchorEl={anchorEventMenu}
                        id="event-menu"
                        open={Boolean(anchorEventMenu)}
                        onClose={handleEventMenuClose}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuList dense sx={{ width: 320, maxWidth: '100%' }}>
                            {/* ✅ Only map events after fetching */}
                            {
                                events.length > 0 ? (
                                    events.map((event) => (
                                        <EventCard
                                            event={event}
                                            accountId={Account.id}
                                            onClick={handleEventMenuClick}
                                        />
                                    ))
                                ) : (
                                    <MenuItem disabled>
                                        <Typography variant="caption">No Events Found</Typography>
                                    </MenuItem>
                                )}
                        </MenuList>

                    </Menu>

                    {/* the Event features list; only shows if there is an Event selected  */}
                    {
                        usePA_GlobalStore.getState().PA_GlobalObject.Planning.title.length > 0 ? (
                            <>
                                <div className="mobile-menu-item" onClick={handleFeaturesMenuOpen}>
                                    <i className="icon-helm"></i>
                                    <span>Features</span>
                                </div>
                                <Menu
                                    sx={{maxWidth: '100%'}}
                                    anchorEl={anchorFeaturesMenu}
                                    id="features-menu"
                                    open={Boolean(anchorFeaturesMenu)}
                                    onClose={handleFeaturesMenuClose}

                                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                >
                                    <MenuList dense sx={{width: 320, maxWidth: '100%'}}>
                                        <MenuItem key="event_save" onClick={() => handleEventFeaturesMenuClick('Save')}>
                                            <ListItemIcon>{<i className="icon-floppy-disk"/>}</ListItemIcon>
                                            <ListItemText>SAVE</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}/>
                                        </MenuItem>
                                        <MenuItem key="event_itinerary" onClick={() => handleEventFeaturesMenuClick('Itinerary')}>
                                            <ListItemIcon>{<i className="icon-calendar"/>}</ListItemIcon>
                                            <ListItemText>Itinerary</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}/>
                                        </MenuItem>
                                        <MenuItem key="event_map" onClick={() => handleEventFeaturesMenuClick('Map')}>
                                            <ListItemIcon>{<i className="icon-map"/>}</ListItemIcon>
                                            <ListItemText>Map</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}/>
                                        </MenuItem>
                                        <MenuItem key="event_details" onClick={() => handleEventFeaturesMenuClick('Details')}>
                                            <ListItemIcon>{<i className="icon-settings-icons"/>}</ListItemIcon>
                                            <ListItemText>Cover</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}/>
                                        </MenuItem>
                                        <MenuItem key="event_time_table" onClick={() => handleEventFeaturesMenuClick('Dates')}>
                                            <ListItemIcon>{<i className="icon-calendar3"/>}</ListItemIcon>
                                            <ListItemText>Dates/Days</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}/>
                                        </MenuItem>

                                        <MenuItem key="event_documents"
                                                  onClick={() => handleEventFeaturesMenuClick('Documents')}>
                                            <ListItemIcon>{<i className="icon-file-check"/>}</ListItemIcon>
                                            <ListItemText>Documents</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.documents?.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_activities"
                                                  onClick={() => handleEventFeaturesMenuClick('Activities')}>
                                            <ListItemIcon>{<i className="icon-list-heart"/>}</ListItemIcon>
                                            <ListItemText>Activities</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_ideas" onClick={() => handleEventFeaturesMenuClick('Ideas')}>
                                            <ListItemIcon>{<i className="icon-lamp-bright"/>}</ListItemIcon>
                                            <ListItemText>Ideas</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.ideas.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_attendees"
                                                  onClick={() => handleEventFeaturesMenuClick('Attendees')}>
                                            <ListItemIcon>{<i className="icon-user-24"/>}</ListItemIcon>
                                            <ListItemText>Attendees</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_lodging" onClick={() => handleEventFeaturesMenuClick('Lodging')}>
                                            <ListItemIcon>{<i className="icon-bed"/>}</ListItemIcon>
                                            <ListItemText>Lodging</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_flights" onClick={() => handleEventFeaturesMenuClick('Flights')}>
                                            <ListItemIcon>{<i className="icon-airplane"/>}</ListItemIcon>
                                            <ListItemText>Flights</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.flights.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_messages" onClick={() => handleEventFeaturesMenuClick('Messages')}>
                                            <ListItemIcon>{<i className="icon-smartphone-text"/>}</ListItemIcon>
                                            <ListItemText>Messages</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.messages.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_meals" onClick={() => handleEventFeaturesMenuClick('Meals')}>
                                            <ListItemIcon>{<i className="icon-fork-knife2"/>}</ListItemIcon>
                                            <ListItemText>Meals</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_photos" onClick={() => handleEventFeaturesMenuClick('Photos')}>
                                            <ListItemIcon>{<i className="icon-picture2"/>}</ListItemIcon>
                                            <ListItemText>Photos</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.photos.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_supplies" onClick={() => handleEventFeaturesMenuClick('Supplies')}>
                                            <ListItemIcon>{<i className="icon-checklist2"/>}</ListItemIcon>
                                            <ListItemText>Supplies</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.supplies.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_receipts" onClick={() => handleEventFeaturesMenuClick('Receipts')}>
                                            <ListItemIcon>{<i className="icon-receipt"/>}</ListItemIcon>
                                            <ListItemText>Receipts</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.receipts.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_todo" onClick={() => handleEventFeaturesMenuClick('Todo')}>
                                            <ListItemIcon>{<i className="icon-checklist-time"/>}</ListItemIcon>
                                            <ListItemText>To-Do</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    usePA_GlobalStore.getState().PA_GlobalObject.Planning.to_do.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_weather" onClick={() => handleEventFeaturesMenuClick('Weather')}>
                                            <ListItemIcon>{<i className="icon-sun-wind"/>}</ListItemIcon>
                                            <ListItemText>Weather</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}/>
                                        </MenuItem>
                                        <MenuItem key="event_business_authorization" onClick={() => handleEventFeaturesMenuClick('BusinessAuthorizations')}>
                                            <ListItemIcon>{<i className="icon-wallet"/>}</ListItemIcon>
                                            <ListItemText>Business Authorizations</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_business_expense_report" onClick={() => handleEventFeaturesMenuClick('BusinessExpenseReport')}>
                                            <ListItemIcon>{<i className="icon-wallet"/>}</ListItemIcon>
                                            <ListItemText>Business Expense Report</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                            </Typography>
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </>
                        ) : (
                            <></>
                        )
                    }

                    {/* the Account list  */}
                    <div className="mobile-menu-item">
                        <i className="icon-business-card"></i>
                        <span>Account</span>
                    </div>

                </div>
            </div>

            {/* Business Expense Authorization Dialog */}
            <BusinessExpenseAuthorizationDialog
                isOpen={isExpenseAuthDialogOpen}
                onClose={() => setIsExpenseAuthDialogOpen(false)}
            />

            {/* Business Expense Report Dialog */}
            <BusinessExpenseReport
                isOpen={isBusinessExpenseReportOpen}
                onClose={() => setIsBusinessExpenseReportOpen(false)}
            />

            <SavePlanning
                isOpen={isSavePlanningDialogOpen}
                onClose={() => setIsSavePlanningDialogOpen(false)}
            />

            <Dialog
                open={openDialogDelete}
                onClose={handleDialogDeleteClose}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to clear all of the information for this Event?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDeleteClose}>Cancel</Button>
                    <Button onClick={handleDialogDeleteOK} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    );

}

export default PlannerResponsive

