import React, {useContext, useEffect, useState} from 'react';
import {Route, Routes} from "react-router-dom";

import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";

import {PAContext} from "../../../Services/PAContext";
import Grid from '@mui/material/Grid2';

import './planning.css';
import {
    Alert,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Snackbar,
    Tooltip
} from "@mui/material";

import '../../../css/Lindua/style.css';
import Suggestions from "../../../Pages/PlanningPage/Suggestions";

import GoogleMap from "../../../Pages/PlanningPage/GoogleMap";

import ActivitiesList from "../../EventFeatures/Activities/ActivitiesList";
import ActivitiesAddNew from "../../EventFeatures/Activities/ActivitiesAddNew";
import ActivitiesEdit from "../../EventFeatures/Activities/ActivitiesEdit";
import LocationDetails from "../../EventFeatures/Location/LocationDetails";
import FeaturesList from "../../../Pages/PlanningPage/FeaturesList";
import WeatherDetails from "../../EventFeatures/Weather/WeatherDetails";
import WeatherUsingVisualCrossing from "../../../Services/WeatherUsingVisualCrossing";
import DatesTimesContent from "../../EventFeatures/DatesTimes/DatesTimesContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AttendeesList from "../../EventFeatures/Attendees/AttendeesList";
import AttendeesAddNew from "../../EventFeatures/Attendees/AttendeesAddNew";
import AttendeesAddPrevious from "../../EventFeatures/Attendees/AttendeesAddPrevious";
import AttendeesAddSamples from "../../EventFeatures/Attendees/AttendeesAddSamples";
import PAEvent from "../../../MasterEventObjects/PAEvent";
import AttendeeEdit from "../../EventFeatures/Attendees/AttendeeEdit";
import SuppliesList from "../../EventFeatures/Supplies/SuppliesList";
import SuppliesAddNew from "../../EventFeatures/Supplies/SuppliesAddNew";
import SuppliesAddSuggestions from "../../EventFeatures/Supplies/SuppliesAddSuggestions";
import SuppliesAddEssentials from "../../EventFeatures/Supplies/SuppliesAddEssentials";
import LodgingList from "../../EventFeatures/Lodging/LodgingList";
import LodgingSuggestions from "../../EventFeatures/Lodging/LodgingSuggestions";
import dayjs from "dayjs";
import LodgingAddFromMap from "../../EventFeatures/Lodging/LodgingAddFromMap";
import SuggestionsUnique from "../../EventFeatures/Suggestions/SuggestionsUnique";
import BudgetSummary from "../../EventFeatures/Budget/BudgetSummary";
import LodgingAddFromSuggestions from "../../EventFeatures/Lodging/LodgingAddFromSuggestions";
import SampleLibraryEvent1 from "../../../sampleData/SampleLibraryEvent1";
import DetailsView from "../../EventFeatures/Details/DetailsView";
import SuggestionsTourRadar from "../../EventFeatures/Suggestions/SuggestionsTourRadar";
import LodgingAddFromPDF from "../../EventFeatures/Lodging/LodgingAddFromPDF";
import PAAttendee from "../../../MasterEventObjects/Attendee";
import PhotosList from "../../EventFeatures/Photos/PhotosList";
import PhotosAddNew from "../../EventFeatures/Photos/PhotosAddNew";
import FlightsList from "../../EventFeatures/Flights/FlightsList";
import MealsList from "../../EventFeatures/Meals/MealsList";
import IdeasList from "../../EventFeatures/Ideas/IdeasList";
import ItineraryView from "../../EventFeatures/Itinerary/ItineraryView";
import ItineraryViewSection from "../../EventFeatures/Itinerary/ItineraryViewSection";
import ConciergeServiceNew from "../../../Pages/PlanningPage/ConciergeServiceNew";
import MessagesList from "../../EventFeatures/Messages/MessagesList";
import TodoList from "../../EventFeatures/Todo/TodoList";
import ReceiptList from "../../EventFeatures/Receipts/ReceiptList";
import TodoAddNew from "../../EventFeatures/Todo/TodoAddNew";
import ReceiptsAddNew from "../../EventFeatures/Receipts/ReceiptsAddNew";


function PlannerContent(props) {

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray, setAccount, setPlanningBudget, setPlanningBudgetCurrency } = useContext(PAContext);
    const { Account } = dataArray;

    const [zoomInOnLocation, setZoomInOnLocation] = useState(props.zoomInOn);
    const [searchingForLocation, setSearchingForLocation] = useState(props.searchingFor);
    const [suggestionForLocation, setSuggestionForLocation] = useState("");

    const [eventBudgetTotal, setEventBudgetTotal] = useState(0);
    const [eventBudgetActual, setEventBudgetActual] = useState(0);
    const [eventBudgetCurrency, setEventBudgetCurrency] = useState(0);
    const [eventBudgetExceeded, setEventBudgetExceeded] = useState(false);

    const [currentFeature, setCurrentFeature] = useState("Map");

    const [itineraryViewSection, setItineraryViewSection] = useState("");
    const [itineraryViewType, setItineraryViewType] = useState("");


    const [ideasFromMap, setIdeasFromMap] = useState({});
    const [activitiesFromMap, setActivitiesFromMap] = useState({});
    const [lodgingFromMap, setLodgingFromMap] = useState({});
    const [mealsFromMap, setMealsFromMap] = useState({});
    const [conciergePlanningItemsFromMap, setConciergePlanningItemsFromMap] = useState([]);

    const [lodgingFromSuggestions, setLodgingFromSuggestions] = useState({});
    const [lodgingPhotosFromMap, setLodgingPhotosFromMap] = useState([]);

    const [clearEvent, setClearEvent] = useState(false);
    const [eventId, setEventId] = useState("");
    const [eventName, setEventName] = useState("");
    const [eventBeginDate, setEventBeginDate] = useState(null);
    const [eventEndDate, setEventEndDate] = useState(null);
    const [formattedEventDates, setFormattedEventDates] = useState("");

    const [eventAttendeesUpdated, setAttendeesUpdated] = useState(false);

    const [editAttendeeId, setEditAttendeeId] = useState("");
    const [selectedAttendeeForSupplies, setSelectedAttendeeForSupplies] = useState("");

    const [viewSuggestionLodgingMarkers, setViewSuggestionLodgingMarkers] = useState(false);
    const [viewSuggestionLodgingMarkersFilter, setViewSuggestionLodgingMarkersFilter] = useState([]);

    const [lodgingSuggestionNameFromMap, setLodgingSuggestionNameFromMap] = useState("");

    const [tourSuggestionsTourCities, setTourSuggestionsTourCities] = useState([]);

    const [showEventItemConfig, setShowEventItemConfig] = useState(false);
    const [showEventItemExtendedConfig, setShowEventItemExtendedConfig] = useState(false);

    const [pullWeatherReport, setPullWeatherReport] = useState(false);
    const [weatherReportReceived, setWeatherReportReceived] = useState(false);

    const [weatherReportReceivedSuccessOpen, setWeatherReportReceivedSuccessOpen] = useState(false);
    const handleSnackbarWeatherReportReceivedClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setWeatherReportReceivedSuccessOpen(false);
    };

    useEffect(() => {
        console.log("clearEvent");

        setSearchingForLocation("");
        setFormattedEventDates("");

        //clear the budget summary
        setEventBudgetTotal(0);
        setEventBudgetActual(0);

        setClearEvent(false);

    },[clearEvent]);

    useEffect(() => {
        console.log("Checking Planning ... ", usePA_GlobalStore.getState().PA_GlobalObject.Planning);
        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning === undefined || usePA_GlobalStore.getState().PA_GlobalObject.Planning.title === ""){

            console.log("Creating new Event");
            const newEvent = new PAEvent();
            newEvent.title = "My New Event";
            newEvent.status = 10;

            if (Account === undefined || Object.keys(Account).length === 0){
                //create the default Planner Attendee
                console.log("Need to create the default Planner Attendee");
                const _planner = new PAAttendee(
                    'Sample',
                    'Planning',
                    'sample.planning@example.com');
                _planner.id = "100000";
                _planner.role = 'planner';
                _planner.nickname = 'Planner';
                _planner.isSample = true;
                newEvent.attendees.push(_planner);

                setAccount(_planner);
            } else {
                //they are logged in so use their account
                console.log("User is logged in");

                const _planner = new PAAttendee(
                    Account.fname,
                    Account.lname,
                    Account.email);
                _planner.id = Account.id;
                _planner.created = Account.created;
                _planner.role = Account.role;
                _planner.nickname = Account.nickname;
                _planner.language = Account.language;

                _planner.address = Account.address;
                _planner.city = Account.city;
                _planner.state = Account.state;
                _planner.zip = Account.zip;
                _planner.country = Account.country;

                _planner.email = Account.email;
                _planner.dob = Account.dob;

                _planner.phone_prefix = Account.phone_prefix;
                _planner.phone = Account.phone;
                _planner.mask_phone_number = Account.mask_phone_number;

                _planner.events = Account.events;
                _planner.documents = Account.documents;

                newEvent.attendees.push(_planner);

            }

            setSearchingForLocation("");
            setFormattedEventDates("");

            //clear the budget summary
            setEventBudgetTotal(0);
            setEventBudgetActual(0);

            set_PA_GlobalObject({ Planning: newEvent });

            console.log("eventName : "+newEvent.title);
            setEventName(newEvent.title);

            console.log("after setPlanning");
        } else {
            //an event already exists
            console.log("the current Planning.title : " + usePA_GlobalStore.getState().PA_GlobalObject.Planning.title);
            setEventName(usePA_GlobalStore.getState().PA_GlobalObject.Planning.title);

            //change the map location if a location exists



        }

        //see if there is a location that needs to be gotten
        console.log("is searching : "+props.isSearching);
        if (props.isSearching){
            //see what they're searching for
            console.log("searching for : "+props.searchingFor);




        }

    }, []);

    useEffect(() => {
        console.log("--- PA_GlobalObject.Planning got triggered!!!! ",usePA_GlobalStore.getState().PA_GlobalObject.Planning);

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.id !== eventId){
            //a new Event was loaded
            setShowEventItemConfig(false);
            setEventId(usePA_GlobalStore.getState().PA_GlobalObject.Planning.id);
        }

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning && usePA_GlobalStore.getState().PA_GlobalObject.Planning.title !== ''){
            setEventName(usePA_GlobalStore.getState().PA_GlobalObject.Planning.title);

            //move the map
            if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging?.[0]?.location?.google_place_id) {
                setZoomInOnLocation({
                    google_place_id: usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging[0].location.google_place_id,
                    latitude: usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging[0].location.latitude,
                    longitude: usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging[0].location.longitude,
                });
            }

        }
    }, [PA_GlobalObject.Planning]);

    useEffect(() => {
        console.log("Event name has changed : "+eventName);

    }, [eventName]);

    useEffect(() => {
        console.log("eventBudgetTotal");
        setPlanningBudget(eventBudgetTotal);
    }, [eventBudgetTotal]);
    useEffect(() => {
        console.log("eventBudgetActual triggered!!");
//        setPlanning({
//            ...Planning,
//            budget_actual: eventBudgetActual
//        });
    }, [eventBudgetActual]);
    useEffect(() => {
        setPlanningBudgetCurrency(eventBudgetCurrency);
    }, [eventBudgetCurrency]);

    useEffect(() => {
        console.log("props.isSearching or props.searchingFor has been triggered ");
        if (props.isSearching){
            setSearchingForLocation(props.searchingFor);
        }
    }, [props.isSearching, props.searchingFor]);

    useEffect(() => {
        console.log("searchingForLocation has been triggered ");

    }, [searchingForLocation]);

    useEffect(() => {

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning !== undefined && usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table'] !== undefined) {

            const _time_table = usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table'];
            console.log("the _time_table : " , _time_table);

            // Showing the dates takes precedence over days
            const _datesArray = _time_table.dates;
            const _daysArray = _time_table.days;

            console.log("the _daysArray : " , _daysArray);

            // Utility function to format the time and replace 12:00 AM/PM with Midnight/Noon
            const formatTime = (time) => {
                const formattedTime = dayjs(time, 'h:mm A').format('h:mm A');
                if (formattedTime === '12:00 AM') return 'Midnight';
                if (formattedTime === '12:00 PM') return 'Noon';
                return formattedTime;
            };

            // Function to check if it's a full-day event based on the times
            const isFullDay = (beginTime, endTime) => {
                return (
                    dayjs(beginTime, 'h:mm A').format('h:mm A') === '12:00 AM' &&
                    dayjs(endTime, 'h:mm A').format('h:mm A') === '11:59 PM'
                );
            };

            // First, handle the dates (dates take precedence over days)
            if (_datesArray.length > 0) {
                // Parse dates to get minimum and maximum
                const minDateObj = _datesArray.reduce((min, current) => dayjs(min.date).isBefore(dayjs(current.date)) ? min : current);
                const maxDateObj = _datesArray.reduce((max, current) => dayjs(max.date).isAfter(dayjs(current.date)) ? max : current);

                const minDate = dayjs(minDateObj.date);
                const maxDate = dayjs(maxDateObj.date);

                // Format the time if available, using the formatTime utility to handle 12:00 AM/PM
                const minTime = minDateObj['begin-time'] ? formatTime(minDateObj['begin-time']) : null;
                const maxTime = maxDateObj['end-time'] ? formatTime(maxDateObj['end-time']) : null;

                // Check if the event spans a full day (12:00 AM to 11:59 PM)
                const fullDayEvent = isFullDay(minDateObj['begin-time'], maxDateObj['end-time']);
                const sameYear = minDate.year() === maxDate.year();

                // Format dates
                const minDateString = minDate.format('D MMM YYYY');
                const maxDateString = maxDate.format('D MMM YYYY');

                // Combine formatted dates and times
                if (minDate.isSame(maxDate, 'day')) {
                    // If dates are the same, show one date and the time range if it's not a full-day event
                    if (!fullDayEvent && minTime && maxTime) {
                        setFormattedEventDates(`${minDate.format('D MMM')}, ${minTime} - ${maxTime} ${minDate.format('YYYY')}`);
                    } else {
                        setFormattedEventDates(minDateString); // Show only date if it's a full-day event
                    }
                } else {
                    //different begin and end dates
                    // show the date range and time range if available and not a full-day event
                    if (!fullDayEvent && minTime && maxTime) {
                        setFormattedEventDates(`${minDateString} ${minTime} - ${maxDateString} ${maxTime} ${maxDate.format('YYYY')}`);
                    } else {
                        setFormattedEventDates(`${minDate.format('D MMM')} - ${maxDateString}`); // Show only date range if it's a full-day event
                    }
                }
            } else if (_daysArray.length > 0) {
                // Handle days if no dates are available
                if (_daysArray.length === 1) {
                    setFormattedEventDates(`1 day ${_daysArray[0]['day 1']}`);
                } else {
                    const _last_day = 'day ' + _daysArray.length;
                    setFormattedEventDates(`${_daysArray.length} days ${_daysArray[0]['day 1']} - ${_daysArray[_daysArray.length - 1][_last_day]}`);
                }
            } else {
                // No dates or days available
                setFormattedEventDates('');
            }

        }
    }, [PA_GlobalObject.Planning?.time_table]);

    useEffect(() => {
        console.log("showEventItemConfig has been triggered ");

    }, [showEventItemConfig]);

    useEffect(() => {
        console.log("showEventItemExtendedConfig has been triggered ");

    }, [showEventItemExtendedConfig]);

    useEffect(() => {
        console.log("Show all the suggestions for : "+suggestionForLocation);

    }, [suggestionForLocation]);

    useEffect(() => {
        //update the ConciergePlanning

    }, [conciergePlanningItemsFromMap]);

    useEffect(() => {
        console.log("pullWeatherReport : "+pullWeatherReport);
        if (pullWeatherReport){
            console.log("Need to pull the weather report");
            setWeatherReportReceived(false);

            try {
                /*
                    look at the Planning.time_table to see if dates array has entries
                    and look at any Planning.lodging.location to
                    see if the weather report should be retrieved
                 */

                const hasDates = PA_GlobalObject.Planning?.time_table?.dates?.length > 0;
                const begin_date = dayjs(PA_GlobalObject.Planning.time_table.dates[0].date); // First date in the time table
                const hasLocation = PA_GlobalObject.Planning?.lodging?.some(lodge => lodge.location && lodge.location.latitude && lodge.location.longitude);
                const daysUntilBeginDate = begin_date.diff(dayjs(), 'day');

                // Proceed only if both conditions are met
                if (hasDates && hasLocation && daysUntilBeginDate <= 14) {

                    const results = WeatherUsingVisualCrossing(PA_GlobalObject.Planning);

                    if (results) {
                        setWeatherReportReceived(true);
                        setWeatherReportReceivedSuccessOpen(true);
                    } else {
                        setWeatherReportReceived(true);
                    }

                }
            } catch (e){
                console.log(e);
            }

        }

    }, [pullWeatherReport]);


    /*
        for the total expense icon
     */
    const [openBudgetExplanationDialog, setOpenBudgetExplanationDialog] = useState(false);

    function handleOpenBudgetExplanationDialog (){
        setOpenBudgetExplanationDialog(true);
    }

    function handleBudgetExplanationDialogClose(){
        setOpenBudgetExplanationDialog(false);
    }

    const [ showConciergeServiceWindow, setShowConciergeServiceWindow ] = useState(false);
    function toggleShowConciergeServiceWindow(){
        setShowConciergeServiceWindow(!showConciergeServiceWindow);
    }
    const [ showCalendarTodoWindow, setShowCalendarTodoWindow ] = useState(false);
    function toggleShowCalendarTodoWindow(){
        setShowCalendarTodoWindow(!showCalendarTodoWindow);
    }

    return (

        <div id="planning_div" className="planning-div">

            {/* First row */}
            <Grid container spacing={2} justify="space-between" sx={{position: 'relative', marginTop: '10px'}}>
                {/* this is the horizontal list of features icons */}
                <Grid item sx={{marginRight:'120px'}}>
                    <Paper
                        sx={{
                            display: 'flex',
                            position: 'relative',
                            backgroundColor: 'rgb(251, 251, 251)',
                            whiteSpace: 'nowrap',
                            paddingTop: '10px',
                            marginRight: '10px',
                            overflowX: 'auto',
                            overflowY: 'hidden'
                        }}
                    >
                        {/* Features List */}
                        <FeaturesList
                            currentFeature={currentFeature}
                            showEventItemConfig={showEventItemConfig}
                            setShowEventItemConfig={setShowEventItemConfig}
                            setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                            setClearEvent={setClearEvent}
                            eventBudgetTotal={eventBudgetTotal}
                            eventBudgetActual={eventBudgetActual}
                            weatherReportReceived={weatherReportReceived}
                        />

                    </Paper>
                </Grid>

                {/* this is the calendar/to-do icon */}
                <Grid item sx={{position:'absolute', top:'1px', right:'60px'}}>
                    <Paper
                        sx={{
                            display: 'flex',
                            backgroundColor: 'rgb(251, 251, 251)',
                            paddingTop: '2px'
                        }}>

                        <div className="planning-budget-list">
                            <Tooltip title="Calendar & To-Do List">
                                <IconButton
                                    sx={{
                                        position: 'relative',
                                        boxShadow: 2,
                                        borderRadius: 2,
                                        textAlign: 'center',
                                        fontWeight: '700',
                                        color: '#F00'
                                    }}
                                    onClick={()=>handleOpenBudgetExplanationDialog()}
                                >
                                    <i className="icon-calendar-user" style={{fontSize:'28px', padding:'5px'}}/>
                                </IconButton>
                            </Tooltip>
                            <Dialog
                                open={openBudgetExplanationDialog}
                                onClose={handleBudgetExplanationDialogClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    Budget Summary
                                </DialogTitle>
                                <DialogContent>

                                    {/* add the budget summary breakdown */}
                                    <BudgetSummary
                                        eventBudgetTotal={eventBudgetTotal}
                                        setEventBudgetTotal={setEventBudgetTotal}
                                        eventBudgetActual={eventBudgetActual}
                                    />

                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleBudgetExplanationDialogClose} autoFocus>
                                        OK
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </Paper>
                </Grid>

                {/* this is the AI concierge icon */}
                <Grid item sx={{position:'absolute', top:'1px', right:'0'}}>
                    <Paper
                        sx={{
                            display: 'flex',
                            backgroundColor: 'rgb(251, 251, 251)',
                            paddingTop: '2px'
                        }}>

                        <div className="planning-library-list">
                            <Tooltip title="Concierge Service">
                                <IconButton
                                    sx={{
                                        boxShadow: 2,
                                        borderRadius: 2,
                                        textAlign: 'center',
                                        fontWeight: '700',
                                        color: '#F00'
                                    }}
                                    onClick={toggleShowConciergeServiceWindow}
                                >
                                    <i className="icon-robot" style={{fontSize:'28px', padding:'5px'}}/>
                                </IconButton>
                            </Tooltip>
                        </div>

                    </Paper>
                </Grid>
            </Grid>


            {/* Event Summary Row */}
            <div className="planning-div-summary-container">
                {/* Event title on the left */}
                <div className="planning-div-summary-event-title">{eventName}</div>

                {/* Right-aligned Chip and Dates */}
                <div className="planning-div-summary-right">
                    {usePA_GlobalStore.getState().PA_GlobalObject.Planning?.status === 10 && (
                        <Chip
                            sx={{ marginRight: '10px', backgroundColor: '#abd154' }}
                            size="small"
                            label="Planning"
                        />
                    )}
                    {usePA_GlobalStore.getState().PA_GlobalObject.Planning?.is_template && (
                        <Chip
                            sx={{ marginRight: '10px', backgroundColor: '#abd154' }}
                            size="small"
                            label="Template"
                        />
                    )}
                    <span className="planning-div-summary-dates">{formattedEventDates}</span>
                </div>
            </div>






            {/* Main Content Area */}
            <div style={{display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '10px'}}>

                {/* this is the space to view/add/edit/delete any of the features */}
                <div style={{
                    marginLeft: '10px',
                    display: showEventItemConfig ? 'flex' : 'none',
                    flexDirection: 'column',
                    flexBasis: '350px', /* Set a base width for this section */
                    maxWidth: '350px',  /* Prevent it from expanding too wide */
                    flexShrink: 0       /* Prevent it from shrinking too much */
                }}>
                    <div className="planning-event-routes-holder">
                        <Paper style={{
                            backgroundColor: 'rgb(251, 251, 251)',
                        }}
                        >
                            <Routes>
                                <Route
                                    path="Details"
                                    element={
                                        <DetailsView
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setEventName={setEventName}
                                        />}
                                />
                                <Route
                                    path="Location"
                                    element={
                                        <LocationDetails
                                            setZoomInOnLocation={setZoomInOnLocation}
                                            searchingForLocation={searchingForLocation}
                                            setSearchingForLocation={setSearchingForLocation}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setPullWeatherReport={setPullWeatherReport}
                                            _global_map_ref={props._global_map_ref}
                                        />}
                                />

                                <Route
                                    path="Dates"
                                    element={
                                        <DatesTimesContent
                                            setEventName={setEventName}
                                            setEventBeginDate={setEventBeginDate}
                                            setEventEndDate={setEventEndDate}
                                            setPullWeatherReport={setPullWeatherReport}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Itinerary/*"
                                    element={
                                        <ItineraryView
                                            setItineraryViewSection={setItineraryViewSection}
                                            setItineraryViewType={setItineraryViewType}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Activities"
                                    element={
                                        <ActivitiesList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />
                                <Route path="Activities/Add" element={<ActivitiesAddNew/>}/>
                                <Route path="Activities/Edit" element={<ActivitiesEdit/>}/>

                                <Route
                                    path="Ideas"
                                    element={
                                        <IdeasList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />

                                <Route
                                    path="Attendees"
                                    element={
                                        <AttendeesList
                                            setEditAttendeeId={setEditAttendeeId}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route path="Attendees/AddNew"
                                       element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>}/>
                                <Route path="Attendees/AddPrevious"
                                       element={<AttendeesAddPrevious setAttendeesUpdated={setAttendeesUpdated}/>}/>
                                <Route path="Attendees/AddSamples"
                                       element={<AttendeesAddSamples setAttendeesUpdated={setAttendeesUpdated}/>}/>
                                <Route path="Attendees/Edit" element={<AttendeeEdit editAttendeeId={editAttendeeId}/>}/>


                                <Route
                                    path="Lodging"
                                    element={
                                        <LodgingList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/AddFromMap"
                                    element={
                                        <LodgingAddFromMap
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            lodgingFromMap={lodgingFromMap}
                                            lodgingPhotosFromMap={lodgingPhotosFromMap}
                                            eventBeginDate={eventBeginDate}
                                            eventEndDate={eventEndDate}
                                            setEventBeginDate={setEventBeginDate}
                                            setEventEndDate={setEventEndDate}
                                            eventBudgetActual={eventBudgetActual}
                                            setEventBudgetActual={setEventBudgetActual}
                                            setEventBudgetCurrency={setEventBudgetCurrency}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/AddFromSuggestions"
                                    element={
                                        <LodgingAddFromSuggestions
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            lodgingFromSuggestions={lodgingFromSuggestions}
                                            eventBeginDate={eventBeginDate}
                                            eventEndDate={eventEndDate}
                                            setEventBeginDate={setEventBeginDate}
                                            setEventEndDate={setEventEndDate}
                                            eventBudgetActual={eventBudgetActual}
                                            setEventBudgetActual={setEventBudgetActual}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/AddFromPDF"
                                    element={
                                        <LodgingAddFromPDF
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/Suggestions"
                                    element={
                                        <LodgingSuggestions
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setViewSuggestionLodgingMarkersFilter={setViewSuggestionLodgingMarkersFilter}
                                            lodgingSuggestionNameFromMap={lodgingSuggestionNameFromMap}
                                            setCurrentFeature={setCurrentFeature}
                                            setLodgingFromSuggestions={setLodgingFromSuggestions}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />

                                <Route
                                    path="Flights"
                                    element={
                                        <FlightsList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route path="Flights/SearchAndBook"
                                       element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>}/>
                                <Route path="Flights/Search"
                                       element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>}/>
                                <Route path="Flights/EnterDetails"
                                       element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>}/>

                                <Route
                                    path="Messages"
                                    element={
                                        <MessagesList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Meals"
                                    element={
                                        <MealsList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />

                                <Route
                                    path="Photos"
                                    element={
                                        <PhotosList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route
                                    path="Photos/AddNew"
                                    element={
                                        <PhotosAddNew/>
                                    }
                                />

                                <Route
                                    path="Suggestions/Tours"
                                    element={
                                        <SuggestionsTourRadar
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setTourSuggestionsTourCities={setTourSuggestionsTourCities}
                                        />
                                    }
                                />

                                <Route
                                    path="Suggestions/Unique"
                                    element={
                                        <SuggestionsUnique
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />


                                <Route
                                    path="Supplies/*"
                                    element={
                                        <SuppliesList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                                            selectedAttendeeForSupplies={selectedAttendeeForSupplies}
                                            setSelectedAttendeeForSupplies={setSelectedAttendeeForSupplies}
                                        />}
                                />

                                <Route
                                    path="Receipts"
                                    element={
                                        <ReceiptList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route
                                    path="Receipts/AddNew"
                                    element={
                                        <ReceiptsAddNew
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Todo"
                                    element={
                                        <TodoList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route
                                    path="Todo/AddNew"
                                    element={
                                        <TodoAddNew
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Weather"
                                    element={
                                        <WeatherDetails
                                            pullWeatherReport={pullWeatherReport}
                                            setWeatherReportReceived={setWeatherReportReceived}
                                            setWeatherReportReceivedSuccessOpen={setWeatherReportReceivedSuccessOpen}
                                        />}
                                />


                            </Routes>
                        </Paper>
                    </div>
                </div>


                {/* this is the EXTENDED space to view/add/edit/delete any of the features */}
                <div style={{
                    display: showEventItemExtendedConfig ? 'flex' : 'none',
                    flexDirection: 'column',
                    flexBasis: '350px', /* Set a base width for this section */
                    maxWidth: '350px',  /* Prevent it from expanding too wide */
                    flexShrink: 0       /* Prevent it from shrinking too much */
                }}>

                    <div className="planning-event-routes-extended-holder">
                        <Paper style={{
                            backgroundColor: 'rgb(251, 251, 251)',
                        }}
                        >
                            <Routes>
                                <Route
                                    path="Itinerary/Section"
                                    element={
                                        <ItineraryViewSection
                                            itineraryViewSection={itineraryViewSection}
                                            itineraryViewType={itineraryViewType}
                                            setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                                        />
                                    }
                                />

                                <Route path="Supplies/AddEssentials"
                                       element={<SuppliesAddEssentials
                                           selectedAttendeeForSupplies={selectedAttendeeForSupplies}
                                           setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                                       />}/>
                                <Route path="Supplies/AddNew"
                                       element={<SuppliesAddNew
                                           selectedAttendeeForSupplies={selectedAttendeeForSupplies}
                                           setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                                       />}/>
                                <Route
                                    path="Supplies/AddSuggestions"
                                    element={
                                        <SuppliesAddSuggestions
                                            selectedAttendeeForSupplies={selectedAttendeeForSupplies}
                                            setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                                        />
                                    }
                                />
                            </Routes>
                        </Paper>
                    </div>
                </div>


                {/* this holds the Suggestions and then the map and concierge holder */}
                <div style={{
                    flex: '1',
                    minWidth: '300px',
                    maxWidth: '100%',
                    maxHeight: 'calc(100vh - 170px)',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}>

                    {/* this is a collapsible area for showing selection lists
                        for example, selecting flights or lists of hotels to choose from
                     */}
                    <div className="planning-whiteboard-list-holder">

                    </div>

                    {/* Google Map and Concierge Side-by-Side Holder */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        overflow: 'hidden'
                    }}>

                        {/* Google Map */}
                        <div className="planning-map-area-container">
                            <Paper sx={{
                                backgroundColor: 'rgb(251, 251, 251)',
                            }}
                            >
                                <GoogleMap
                                    _global_map_ref={props._global_map_ref}
                                    zoomInOnLocation={zoomInOnLocation}
                                    searchingForLocation={searchingForLocation}
                                    setShowEventItemConfig={setShowEventItemConfig}
                                    setCurrentFeature={setCurrentFeature}
                                    viewSuggestionLodgingMarkers={viewSuggestionLodgingMarkers}
                                    viewSuggestionLodgingMarkersFilter={viewSuggestionLodgingMarkersFilter}
                                    setLodgingSuggestionNameFromMap={setLodgingSuggestionNameFromMap}
                                    setIdeasFromMap={setIdeasFromMap}
                                    setLodgingFromMap={setLodgingFromMap}
                                    setLodgingPhotosFromMap={setLodgingPhotosFromMap}
                                    setMealsFromMap={setMealsFromMap}
                                    conciergePlanningItemsFromMap={conciergePlanningItemsFromMap}
                                    setConciergePlanningItemsFromMap={setConciergePlanningItemsFromMap}
                                    tourSuggestionsTourCities={tourSuggestionsTourCities}
                                    clearEvent={clearEvent}
                                />
                            </Paper>
                        </div>

                        {/* Concierge */}
                        {
                            showConciergeServiceWindow ? (
                                <ConciergeServiceNew
                                    searchingForLocation={searchingForLocation}
                                    conciergePlanningItemsFromMap={conciergePlanningItemsFromMap}
                                    clearEvent={clearEvent}
                                />

                            ) : (
                                <></>
                            )
                        }

                        {/* Calendar & To-Do */}
                        {
                            showCalendarTodoWindow ? (
                                <Typography>Calendar & To-Do</Typography>
                            ) : (
                                <></>
                            )
                        }

                    </div>

                    <div className="planning-suggestions-list-holder">
                        <Paper style={{backgroundColor: 'rgb(251, 251, 251)', padding: '10px 0 0 0'}}>
                        <div className="planning-suggestions-list-title">Concierge Suggestions</div>
                            <div className="planning-suggestions-list">
                                <Suggestions
                                    setShowEventItemConfig={setShowEventItemConfig}
                                    searchingForLocation={searchingForLocation}
                                    zoomInOnLocation={zoomInOnLocation}
                                    setSuggestionForLocation={setSuggestionForLocation}
                                    setViewSuggestionLodgingMarkers={setViewSuggestionLodgingMarkers}
                                    clearEvent={clearEvent}
                                    _global_map_ref={props._global_map_ref}
                                />
                            </div>
                        </Paper>
                    </div>

                </div>
            </div>

            <Snackbar
                open={weatherReportReceivedSuccessOpen}
                autoHideDuration={2500}
                onClose={handleSnackbarWeatherReportReceivedClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarWeatherReportReceivedClose} severity="success" sx={{ width: '100%' }}>
                    Received Weather Report
                </Alert>
            </Snackbar>


        </div>

    )




}

export default PlannerContent;
