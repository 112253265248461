import dayjs from "dayjs";

export const capitalizeFirstLetter = (string) => {
    if (!string) {
        return ''; // Return an empty string if the input is null, undefined, or an empty string
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function capitalizeString(word) {
    return word.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

export const formatName = (name) => {
    // Capitalize the first letter and make the rest lowercase
    if (!name) {
        return ''; // Return an empty string if the input is null, undefined, or an empty string
    }
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

export function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function adjustColor(color, amount) {
    return `#${color.slice(1).replace(/../g, (colorComponent) =>
        (`0${Math.min(255, Math.max(0, parseInt(colorComponent, 16) + amount)).toString(16)}`).slice(-2)
    )}`;
}

export function stringAvatar(name) {
    // Set fallback initials for single names or empty strings
    const nameParts = name.split(' ');
    const initials = nameParts[0][0] + (nameParts[1]?.[0] || '');

    const borderColor = stringToColor(name);
    const boxShadowColor = adjustColor(borderColor, -20); // Slightly darken the border color

    return {
        sx: {
            boxShadow: `0 0 5px ${boxShadowColor}`,
            border: `1px solid ${borderColor}`,
            borderRadius: '10px',
            textAlign: 'center',
            fontWeight: '700',
            backgroundColor: '#FFF',
            color: 'black', // Text color (adjust as needed)
        },
        children: initials.toUpperCase(),
    };
}

export function formatTime(time) {
    if (!time) return null;

    // Handle Day.js objects or string times
    const formattedTime = dayjs(time, ['h:mm A', 'HH:mm']).format('h:mm A');

    // Check for Noon or Midnight
    if (formattedTime === '12:00 PM') return 'Noon';
    if (formattedTime === '12:00 AM') return 'Midnight';

    return formattedTime;
}
