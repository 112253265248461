import React, {useContext, useEffect, useState} from 'react';

import {PAContext} from "../../../Services/PAContext";
import {Card, CardContent, CardMedia, Chip, Menu, MenuItem, MenuList, Paper} from "@mui/material";
import Grid from '@mui/material/Grid2';
import './receipt.css';
import Badge from "@mui/material/Badge";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import Typography from "@mui/material/Typography";
import ReceiptCard from "./ReceiptCard";
import {PieChart} from "@mui/x-charts/PieChart";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";
import InfoReceipts from "../../Dialogs/InfoReceipts";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import ListItemText from "@mui/material/ListItemText";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import lodgingHeaderImage from "../../../graphics/th-513411954.jpg";




function ReceiptList(props){

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Account, Attendees, SampleAttendees  } = dataArray;

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated ? Attendees : SampleAttendees);
    useEffect(() => {
        setAttendeesReferenceArray(dataArray.authenticated ? Attendees : SampleAttendees);
    }, [dataArray.authenticated, Attendees, SampleAttendees]);

    const [selectedAttendee, setSelectedAttendee] = useState(Account.id);
    const [eventReceipts, setEventReceipts] = useState([]);
    const [filteredReceipts, setFilteredReceipts] = useState([]);


    useEffect(() => {
        if (PA_GlobalObject.Planning.receipts) {
            filterReceipts();
        }
    }, [PA_GlobalObject.Planning.receipts, selectedAttendee]);

    const [anchorReceiptsListMenu, setAnchorReceiptsListMenu] = useState(null);
    const handleReceiptsListMenuOpenMenu = (event) => {
        setAnchorReceiptsListMenu(event.currentTarget);
    };
    const handleReceiptsListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorReceiptsListMenu(event.currentTarget);

        if (dataId === "new-entry"){
            navigate('/Planner/Receipts/AddNew');
        }
    };
    const handleReceiptsListMenuClose = () => {
        setAnchorReceiptsListMenu(null);
    };
    
    const handleChipClick = (_attendee_id) => {
        console.log("Show receipts for : "+_attendee_id);
        setSelectedAttendee(_attendee_id);
    }

    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }

    const attendeesWithReceiptCounts = PA_GlobalObject.Planning.attendees.map((attendee) => {
        const receiptCount = PA_GlobalObject.Planning.receipts.reduce((count, item) =>
            count + ((item.attendee_id === attendee.id && (!item.private || item.attendee_id === Account.id)) ? 1 : 0), 0
        );
        return { ...attendee, receiptCount };
    });
    const attendeesWithReceipts = attendeesWithReceiptCounts.filter(attendee => attendee.receiptCount > 0);
    const attendeesWithoutReceipts = attendeesWithReceiptCounts.filter(attendee => attendee.receiptCount === 0);
    attendeesWithReceipts.sort((a, b) => b.receiptCount - a.receiptCount);
    attendeesWithoutReceipts.sort((a, b) => a.nickname.localeCompare(b.nickname));
    const sortedAttendees = [...attendeesWithReceipts, ...attendeesWithoutReceipts];


    const filterReceipts = () => {
        console.log("filterReceipts for : "+selectedAttendee);

        const filteredItems = PA_GlobalObject.Planning.receipts.filter(receipt =>
            receipt.attendee_id === selectedAttendee && (!receipt.private || receipt.attendee_id === Account.id)
        );

        console.log("setting the filterReceipts to : "+filteredItems.length);

        setEventReceipts(filteredItems);
        setFilteredReceipts(filteredItems); // Initialize with all filtered receipts
    };

    const filterReceiptsByCategory = (category) => {
        const filteredByCategory = eventReceipts.filter(receipt => receipt.section === category);
        setFilteredReceipts(filteredByCategory);
    };

    const categories = [
        'General',
        'Lodging',
        'Meals',
        'Activities',
        'Supplies',
        'Shopping',
        'Flights',
    ];

    const categoryIcons = {
        General: 'icon-ruby',
        Activities: 'icon-theater',
        Flights: 'icon-airplane',
        Meals: 'icon-fork-knife2',
        Lodging: 'icon-bed',
        Shopping: 'icon-tag2',
        Supplies: 'icon-bag',
    };

    const getCategoryCounts = () => {
        const counts = categories.reduce((acc, category) => {
            acc[category] = 0;
            return acc;
        }, {});

        eventReceipts.forEach((receipt) => {
            counts[receipt.section] = (counts[receipt.section] || 0) + 1;
        });

        return counts;
    };

    const getSortedCategories = () => {
        const counts = getCategoryCounts();

        return categories.sort((a, b) => counts[b] - counts[a]);
    };

    const aggregateData = () => {
        const dataMap = {};

        filteredReceipts.forEach(receipt => {
            const section = receipt.section;
            const amount = parseFloat(receipt.amount) || 0; // Ensure it's a number

            if (dataMap[section]) {
                dataMap[section] += amount; // Sum the amounts correctly
            } else {
                dataMap[section] = amount;
            }
        });

        // Convert to sorted array and back to an ordered object
        return Object.fromEntries(
            Object.entries(dataMap).sort(([a], [b]) => a.localeCompare(b))
        );
    };

    const pieChartData = Object.entries(aggregateData())
        .map(([section, amount], index) => ({
            id: index,
            name: section,
            value: amount,
            label: section, // Ensure amount is a valid number
        }));

    const handleDelete = (receiptId) => {
        setFilteredReceipts((prevReceipts) => prevReceipts.filter(receipt => receipt.id !== receiptId));

        // Remove from Global Store (PA_GlobalObject)
        set_PA_GlobalObject((prev) => {
            return {
                ...prev,
                Planning: {
                    ...prev.Planning,
                    receipts: prev.Planning.receipts.filter(receipt => receipt.id !== receiptId)
                }
            };
        });

    };

    const [isReceiptsInfoDialogOpen, setReceiptsInfoDialogOpen] = useState(false);


    return (

        <div className="receipts-list-container">

            {/* show the header */}
            <div className="receipts-list-header">
                <div className="receipts-list-header-text">
                    <h5>Receipts</h5>
                </div>
                <div className="receipts-list-header-info-icon-holder">
                    <i
                        className="icon-info-circle"
                        style={{fontSize: '20px', padding: '5px'}}
                        onClick={() => setReceiptsInfoDialogOpen(!isReceiptsInfoDialogOpen)}
                    />
                </div>
                <div className="receipts-list-header-chip-holder">
                    <Chip
                        sx={menuChipStyle}
                        label={
                            <i className="icon-menu2" style={{fontSize: '16px'}}/>
                        }
                        size="small"
                        onClick={handleReceiptsListMenuOpenMenu}
                    />
                    <Menu
                        sx={{width: 320, maxWidth: '100%'}}
                        anchorEl={anchorReceiptsListMenu}
                        id="receipts-menu"
                        open={Boolean(anchorReceiptsListMenu)}
                        onClose={handleReceiptsListMenuClose}

                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuList
                            dense
                            sx={{width: 320, maxWidth: '100%'}}
                        >
                            <MenuItem
                                data-id="new-entry"
                                onClick={handleReceiptsListMenuClick}>
                                <ListItemText>New Entry</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>

            {/* Attendee List */}
            <div className="receipts-list-attendee-list-container">
                {sortedAttendees.length > 0 ? (
                    sortedAttendees.map((attendee, index) => (
                        <Badge
                            key={index}
                            badgeContent={attendee.receiptCount > 0 ? attendee.receiptCount : null}
                            sx={{
                                '& .MuiBadge-badge': {
                                    backgroundColor: 'white',
                                    border: '1px solid #ddd',
                                    width: '16px',
                                    height: '16px',
                                    borderRadius: '50%',
                                    top: '10px',
                                    right: '10px',
                                },
                            }}
                        >
                            <Chip
                                sx={selectedAttendee === attendee.id ? selectedChipStyle : unselectedChipStyle}
                                label={capitalizeFirstLetter(attendee.nickname)}
                                clickable
                                onClick={() => handleChipClick(attendee.id)}
                                style={{margin: '0.5rem'}}
                            />
                        </Badge>
                    ))
                ) : (
                    <div style={{padding: '20px'}}>
                        <i className="icon-warning" style={{fontSize: '11px', color: 'red', marginRight: '10px'}}/>
                        <Typography variant="caption">Need to add some Attendees first</Typography>
                    </div>
                )}
            </div>

            {/* now show the pie chart */}
            {
                pieChartData.length > 0 ? (
                    <div>
                        <PieChart
                            series={[
                                {
                                    data: pieChartData,
                                    innerRadius: 10,
                                    outerRadius: 50,
                                    paddingAngle: 5,
                                    cornerRadius: 5,
                                    startAngle: -45,
                                    endAngle: 315,
                                    cx: 125,
                                    cy: 45 + pieChartData.length * 10,
                                },
                            ]}
                            slotProps={{
                                legend: {
                                    direction: "column", // Align legend items vertically
                                    position: { vertical: "middle", horizontal: "right" }, // Moves legend to the right
                                    labelStyle: {
                                        fontSize: 12, // Smaller font size for legend
                                        fontWeight: "bold", // Make legend stand out
                                        fill: "#333", // Adjust text color for better visibility
                                    },
                                },
                            }}
                            width={350}
                            height={100 + pieChartData.length * 20}
                        />
                    </div>
                ) : null
            }


            {/* Receipts List */}
            <div
                className="receipts-list-items-container"
                style={{
                    height: pieChartData.length > 0 ? 'calc(100vh - 550px)' : 'calc(100vh - 350px)'
                }}
            >
                {
                    filteredReceipts && filteredReceipts.length > 0 ? (
                        Object.entries(
                            filteredReceipts.reduce((acc, receipt) => {
                                acc[receipt.section] = acc[receipt.section] || []; // Initialize category if not present
                                acc[receipt.section].push(receipt); // Group receipts by section
                                return acc;
                            }, {})
                        ).map(([section, receipts]) => (
                            <ReceiptCard key={section} section={section} receipts={receipts} onDelete={handleDelete} />
                        ))
                    ) : (
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ borderRadius: '5px', overflow: 'hidden' }}>
                                <Card>
                                    <CardMedia
                                        sx={{ height: 200 }}
                                        image={lodgingHeaderImage}
                                        title="Manage Your Event Budget"
                                    />

                                    <CardContent sx={{ padding: '24px' }}>
                                        {/* Title */}
                                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, color: '#6d4c41' }}>
                                            🧾 No Receipts Yet
                                        </Typography>

                                        {/* Subhead */}
                                        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 3 }}>
                                            Keep track of every dollar and make your event planning stress-free.
                                        </Typography>

                                        {/* Receipt Management Features */}
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            {/* Track Expenses */}
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                                                <i className="icon-money-pouch" style={{ fontSize: '24px', color: '#388e3c' }} />
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                        Track Expenses
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Log every expense — meals, gas, flights — and see where your money’s going.
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Upload Receipts */}
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                                                <i className="icon-upload" style={{ fontSize: '24px', color: '#1976d2' }} />
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                        Upload Receipts
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Snap a photo or drag in a PDF. All your receipts, all in one place.
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Set Budgets */}
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                                                <i className="icon-head-money" style={{ fontSize: '24px', color: '#f57c00' }} />
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                        Set Budgets
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Define limits by category — lodging, activities, food — and stay in control.
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Split Costs */}
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                                                <i className="icon-split" style={{ fontSize: '24px', color: '#7b1fa2' }} />
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                        Split Costs
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Track who paid and who owes. Automatically split group expenses.
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Export Reports */}
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                                                <i className="icon-share" style={{ fontSize: '24px', color: '#455a64' }} />
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                        Export Reports
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Get detailed summaries — perfect for reimbursements or sharing with attendees.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>

                                        {/* Call to Action */}
                                        <Box sx={{ mt: 4, textAlign: 'center' }}>
                                            <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#616161' }}>
                                                Organized finances make unforgettable events.
                                            </Typography>
                                            <Typography
                                                variant="button"
                                                sx={{
                                                    mt: 2,
                                                    display: 'inline-block',
                                                    color: '#fff',
                                                    backgroundColor: '#6d4c41',
                                                    padding: '8px 16px',
                                                    borderRadius: '8px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    // Trigger your add receipt action here
                                                }}
                                            >
                                                ➕ Add Your First Receipt
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Paper>
                        </Grid>

                    )
                }
            </div>

            <InfoReceipts
                isOpen={isReceiptsInfoDialogOpen}
                onClose={() => setReceiptsInfoDialogOpen(false)}
            />
        </div>
    )
}

export default ReceiptList;